import React, { } from "react";
import { RowCore } from "../containers/CoreElement";
import icon_ship_progress from '../assets/images/icon_ship_progress.svg'
import { DashedLine, IconProgress, IconProgressRotate, Line } from "./MissionBarProgressElement";
import '../styles/core.css';

export default function MissionBarProgress({progress, returnedFleet=false}){

    let width = 100 - progress;

    return(
        <>
        <RowCore style={{alignItems: 'center'}}>
            { !returnedFleet ?
                <>
                <Line style={{width: `${progress}%`}}/>
                <IconProgress src={icon_ship_progress}/>
                <DashedLine style={{width: `${width}%`}}/>
                </>
                :
                <>
                <DashedLine style={{width: `${width}%`}}/>
                <IconProgressRotate src={icon_ship_progress}/>
                <Line style={{width: `${progress}%`}}/>
                </>
            }
        </RowCore>
        </>
    );
}