import styled from "styled-components";
import { ImgIcon } from "../containers/CoreElement";


export const IconProgress = styled(ImgIcon)`
    margin-right: 0%;
    width: 20px;
    height: 18px;
`
export const IconProgressRotate = styled(IconProgress)`
    transform: rotate(180deg);
`
export const Line = styled.div`
    border: none;
    border-top: 2px solid #8D0C50;
    margin: 20px 0;
    width: 100%;
    transition: width 0.35s ease-in-out;
`

export const DashedLine = styled(Line)`
    border-top: 2px dashed #8D0C50;
`