import { HTTP } from "../http/http-common";

export function changeStyle(path, locationPathname) {
  return locationPathname === path ? '#FF0087' : 'transparent'
}

export function numberToAbbreviate(number) {
  if (number >= 1e3 && number < 1e6) {
    const abbreviation = (number / 1e3).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    return abbreviation + 'k';
  } else if (number >= 1e6 && number < 1e9) {
    const abbreviation = (number / 1e6).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    return abbreviation + 'M';
  } else {
    return number.toString();
  }
}

export function abbreviateNumber(number) {
  if (number >= 1000000) {
    return parseInt(number / 1000000).toString() + "M";
  } else if (number >= 100000) {
    return parseInt(number / 1000).toString() + "K";
  } else {
    return number.toString();
  }
}

export function isEmpty(object) {
  return Object.keys(object).length === 0
}


export function numberToThousandSeparator(number) {
  if (number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else {
    return 0
  }
}
export function numberToThousandSeparatorLeaderboardRanking(number) {
  if (number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  } else {
    return 0
  }
}

export function capitalizeFirstLetter(text) {
  const lowerCaseText = text.toLowerCase();
  return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1);
}

export function transforSecondsToDate(seconds, reload=true){
  if (seconds <= 0 && reload){
    window.location.reload();
  }
  var days = Math.floor(seconds / 86400);
  var hours = Math.floor((seconds % 86400) / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;
  var formattedTime = ""
  // console.log(hours)
  // console.log(minutes)
  // console.log(remainingSeconds)
  if (parseInt(days) > 0) {
    formattedTime += parseInt(days) + "d ";
    formattedTime += parseInt(hours) + "h ";
    formattedTime += parseInt(minutes) + "m ";
    formattedTime += parseInt(remainingSeconds) + "s";
  } else {
    if (parseInt(hours) > 0) {
      formattedTime += parseInt(hours) + "h ";
      formattedTime += parseInt(minutes) + "m ";
      formattedTime += parseInt(remainingSeconds) + "s";
    } else {
      if (parseInt(minutes) > 0) {
        formattedTime += parseInt(minutes) + "m ";
        formattedTime += parseInt(remainingSeconds) + "s";
      } else {
        formattedTime += parseInt(remainingSeconds) + "s";
      }
    }
  }

  return formattedTime;
}

export function convertTimestamp(seconds, count=0, reload=true) {

  const currentTimestamp = new Date().getTime();
  const currentTimestampInSeconds = Math.floor(currentTimestamp / 1000);
  seconds = seconds - currentTimestampInSeconds

  return transforSecondsToDate(seconds, reload);
}


export function convertTimestampMinutes(minutes) {

  var seconds = minutes * 60;

  return transforSecondsToDate(seconds);
}


export function convertProgress(data, count = 0) {
  const currentTimestamp = new Date().getTime();
  const currentTimestampInSeconds = Math.floor(currentTimestamp / 1000);
  var time_process = currentTimestampInSeconds - data.time_to_start_date;
  var total_time = data.time_to_finish - data.time_to_start_date;
  return parseInt((time_process * 100) / total_time);
}


export function convertProgressToPercentege(card, count) {
  var value = convertProgress(card, count);
  if (value >= 100) {
    window.location.reload();
    return value;
  } else {
    return value;
  }
}

export function getValue(item, attribute) {
  return attribute.split('.').reduce((obj, attr) => obj[attr], item);
};

export function calculateSimilarity(str1, str2) {
  const len1 = str1.length;
  const len2 = str2.length;

  // Crear una matriz de distancia
  const distanceMatrix = [];
  for (let i = 0; i <= len1; i++) {
    distanceMatrix[i] = [i];
  }
  for (let j = 0; j <= len2; j++) {
    distanceMatrix[0][j] = j;
  }

  // Calcular la distancia de edición
  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
      distanceMatrix[i][j] = Math.min(
        distanceMatrix[i - 1][j] + 1, // Deletions
        distanceMatrix[i][j - 1] + 1, // Insertions
        distanceMatrix[i - 1][j - 1] + cost // Substitutions
      );
    }
  }

  // Calcular la similitud como inverso de la distancia de edición
  const similarity = 1 - distanceMatrix[len1][len2] / Math.max(len1, len2);
  return similarity;
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Texto copiado al portapapeles');
    })
    .catch((error) => {
      console.log('Error al copiar al portapapeles:', error);
    });
}

export const globalData = {
  crownsAvailable: null,
};

export function updateNavs() {
  return new Promise((resolve, reject) => {
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };

    const crownsRequest = HTTP.request('player/crowns/balance/', config)
      .then((res) => {
        console.log(res);
        if (res.data["result"]) {
          document.getElementById("crowns-availables").innerText = numberToThousandSeparator(res.data["crowns_available"]);
          globalData.crownsAvailable = res.data["crowns_available"];
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });

    const mineralsRequest = HTTP.request('minerals/', config)
      .then((res) => {
        if (res.data.result) {
          document.getElementById("current-peroxium").innerText = abbreviateNumber(res.data.minerals.peroxium);
          document.getElementById("current-trantium").innerText = abbreviateNumber(res.data.minerals.trantium);
          document.getElementById("current-obscurian").innerText = abbreviateNumber(res.data.minerals.obscurian);
          document.getElementById("current-mercoxium").innerText = abbreviateNumber(res.data.minerals.mercoxium);
          document.getElementById("current-crelyte").innerText = abbreviateNumber(res.data.minerals.crelyte);
          document.getElementById("current-bits").innerText = abbreviateNumber(res.data.minerals.bits);
          document.getElementById("current-bits-resp").innerText = abbreviateNumber(res.data.minerals.bits);
        }
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });

    // Esperar a que ambas solicitudes se completen antes de resolver la promesa
    Promise.all([crownsRequest, mineralsRequest])
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateCountMessages() {
  if (sessionStorage.getItem('token')) {
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('messages/count/', config)
      .then((res) => {
        if (res.data.result) {
          document.getElementById("count-messages").innerText = res.data.count;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {

      })
  }
}



export function getCurrentUTCTime() {
  const now = new Date();

  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');

  const utcTime = `${hours}:${minutes}:${seconds} UTC`;
  return utcTime;
}

export function mineralToIcon(mineral, ingot=false){
  let mineralIcon = '';

  switch (mineral.toLowerCase()){
      case 'peroxium':
          if(ingot){
            mineralIcon = "/icons/icon_peroxium_ingot.png";
          } else{
            mineralIcon = "/icons/icon_peroxium.svg";
          }
          break;

      case 'trantium':
          if(ingot){
            mineralIcon = "/icons/icon_trantium_ingot.png";
          } else{
            mineralIcon = "/icons/icon_trantium.svg";
          }
          break;
          
      case 'obscurian':
          if(ingot){
            mineralIcon = "/icons/icon_obscurian_ingot.png";
          } else{
            mineralIcon = "/icons/icon_obscurian.png";
          }
          break;
          
      case 'mercoxium':
          if(ingot){
            mineralIcon = "/icons/icon_mercoxium_ingot.png";
          } else{
            mineralIcon = "/icons/icon_type_mercoxium.png";
          }
          break;
          
      case 'bits':
          mineralIcon = "/icons/icon_type_blue_coin_inventory.svg";
          break;

      case 'crelyte':
          if(ingot){
            mineralIcon = "/icons/icon_crelyte_ingot.png";
          } else{
            mineralIcon = "/icons/icon_type_crelyte.png";
          }
          break;

      case 'aurum':
          mineralIcon = "/icons/icon_mineral.svg";
          break;

      default:
          mineralIcon = "";
  }

  return mineralIcon;
}

export function emailValidation(email) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}