import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { HTTP } from '../../http/http-common';
import { 
  PrimaryHomeNav, 
  MenuLink, 
  Menu,
  NavbarSection1,
  Hour,
  Messages,
  NumMessages,
  ImgLine,
  Li,
  Icon,
  Version,
  IconLeaderboard,
  ContainerMenuLink,
  MessagesElipse,
  ImagePromo
} from './NavHomeElement'

import navbar_line from '../../../assets/images/navbar_line.svg'

import commanders_icon from '../../../assets/images/icon_commanders.svg'
import dashboard_icon from '../../../assets/images/dashboard_icon.svg'
import facilities_icon from '../../../assets/images/facilities_icon.svg'
import image1 from '../../../assets/images/navbar-home-image-1.svg'
import image2 from '../../../assets/images/navbar-home-image-2.svg'
import { getCurrentUTCTime } from '../../utils/utils';

export function NavbarHomeComponent(){

  const location = useLocation();

  const [timeUTC, setTimeUTC] = useState("...")
  const [version, setVersion] = useState("...")

  const stylesActive = {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),linear-gradient(0deg, #E71383, #E71383),linear-gradient(0deg, rgba(231, 19, 131, 0.2), rgba(231, 19, 131, 0.2))', 
    border: '0.5px solid #E71383'
  }

  const fetchVersion = () => {
    if (sessionStorage.getItem('token')){
      var config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
      };
      HTTP.request('version/', config)
      .then((res) => {
          if (res.data.result){
            setVersion(res.data.version)
          }
      })
      .catch((err) => {
          console.log(err);
      });
    }
  };

  useEffect(() => {
    fetchVersion();
    setInterval(() => {
      setTimeUTC(getCurrentUTCTime());
    }, 1000);
  }, [])

  return (
    <>
      <PrimaryHomeNav>
        <NavbarSection1>
          <Hour>
            {timeUTC}
          </Hour>
          <Messages to='/messages/all'>
            <MessagesElipse>
              <NumMessages id='count-messages'>
                {0}
              </NumMessages>
            </MessagesElipse>
          </Messages>
        </NavbarSection1>
        <ImgLine src={navbar_line}/>
        <Menu>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('dashboard') ? stylesActive : {}}>
              <MenuLink to= "/dashboard">
                <Icon src={dashboard_icon}/>
                Dashboard
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('commanders') ? stylesActive : {}}>
              <MenuLink to="/commanders/crew">
                <Icon src={commanders_icon} style={{width: '20px'}}/>
                Commanders
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          {/*<Li>
            <MenuLink to="/outposts">
              <Icon src={outposts_icon}/>
              Outposts
            </MenuLink>
        </Li>*/}
          <Li>
            <ContainerMenuLink style={location.pathname.includes('routes/expeditions') || location.pathname.includes('routes/mining') ? stylesActive : {}}>
              <MenuLink to="/routes/expeditions">
                <Icon src={'/icons/icon_type_header_routes.svg'} style={{width: '20px'}}/>
                Missions
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          {/*<Li>
            <MenuLink to="/routes/active">
              <Icon src={'/icons/icon_type_header_routes.svg'}/>
              Routes Active
            </MenuLink>
        </Li>*/}
          <Li>
            <ContainerMenuLink style={location.pathname.includes('shipyard') ? stylesActive : {}}>
              <MenuLink to="/shipyard/hangar">
                <Icon src={'/icons/icon_shipyard.svg'}/>
                Shipyard
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('facilities') ? stylesActive : {}}>
              <MenuLink to={"/facilities" }>
                <Icon src={facilities_icon}/>
                Facilities
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('leaderboard') ? stylesActive : {}}>
              <MenuLink to={"/leaderboard/current-week" }>
                <IconLeaderboard src={'/icons/icon_leaderboard.svg'}/>
                Leaderboard
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('player-market') ? stylesActive : {}}>
              <MenuLink to={"https://marketplace.spacemisfits.com/" } target="_blank">
                <IconLeaderboard src={'/icons/icon_type_player_market.svg'}/>
                Player Market
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('altura') ? stylesActive : {}}>
              {/*TODO: Replace by line 151 and 152 to enable module*/}
              <MenuLink to={"/altura/export" }>
                <Icon src={'/icons/icon_type_nft_gateway.svg'}/>
                NFT Gateway
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('crown-shop') ? stylesActive : {}}>
              <MenuLink to={"https://spacemisfits.com/user/crown/"} target="_blank">
                <IconLeaderboard src={'/icons/icon_type_crown_shop.svg'}/>
                CROWN Shop
              </MenuLink>
            </ContainerMenuLink>
          </Li>
          <Li>
            <ContainerMenuLink style={location.pathname.includes('pledge-pool') ? stylesActive : {}}>
              <MenuLink to={"https://stake.spacemisfits.com/pledge"} target="_blank"> 
                <IconLeaderboard src={'/icons/icon_type_pledge_pool.svg'}/>
                Pledge Pool
              </MenuLink>
            </ContainerMenuLink>
          </Li>
       {/*<Li>
            <MenuLink to={"/leaderboard" }>
              <Icon src={'/icons/icon_marketplace.svg'}/>
              Marketplace
            </MenuLink>
      </Li>*/}
        </Menu>
        <ImgLine src={navbar_line}/>
        <Version>
          Fleet v{version}
        </Version>
      </PrimaryHomeNav>
      <div style={{marginTop:"5px"}}>
        <a href="https://spacemisfits.com/user/crown/">
          <ImagePromo  src={image1} >
          </ImagePromo>
        </a>
        <a href="https://discord.gg/space-misfits-560969562753859585">
          <ImagePromo src={image2}>
          </ImagePromo>
        </a>
      </div>
    </>
  )
}