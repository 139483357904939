import '../containers/hooks/styles.css'
import {
RectangleContainerGeneral,
RectangleContainerGeneral2,
RectangleImage,
RectangleContainerComplete,
RectangleRowText,
RectangleRowsContainer
} from './ReusableRectangleElement';
import {Container } from '../containers/CoreElement';



const ReusableRectangleRented = (props) =>  {
    
     return(
        <>  
  <RectangleContainerGeneral>
        <RectangleContainerGeneral2>
            <RectangleImage src={props.image}/>
            <RectangleContainerComplete style={{ right:"0px", left:"0px", justifyContent:"center", alignItems:"center", backgroundColor: props.rectangleBackground}} >
                <RectangleRowsContainer style={{display:"grid", paddingLeft:"90px", paddingRight:"90px"}}>
                <RectangleRowText  >You already rented this Outpost, check its “Routes” by going to your  Routes  section.</RectangleRowText>
                <RectangleRowText  >Once the rented period expires you can rent it here again.</RectangleRowText>

                <Container style={{justifyContent:"center", alignItems:"center", display:"flex", paddingBottom:"0px", width:"max-content", padding:"0px 3px 0px 3px", margin:"0 auto"}}>
                <RectangleRowText style={{fontWeight:"bold"}} >RENT TIME LEFT: <RectangleRowText >{props.time}</RectangleRowText></RectangleRowText>
                </Container>
                
                </RectangleRowsContainer>
            </RectangleContainerComplete>
            
        </RectangleContainerGeneral2>
    </RectangleContainerGeneral>
    

    
    </>
    ) 
};
export default ReusableRectangleRented;