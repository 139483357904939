import styled from "styled-components";

import { BtnPolygon, TextPoppins, TextPoppinsBold, TextTitiliumWeb } from "../containers/CoreElement";

export const TittleV3 = styled.div`
    display: flex;
    margin-top: 4%;
`

export const TittleTextV4 = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-right: 5px;
`

export const TittleTextV5 = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
`

export const InputSetAmount = styled.input`
    border: none;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    height: 40px;
    width: 70%;
`

export const TittleTextV2 = styled(TextTitiliumWeb)`
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
`

export const ElementsComposeYourFleet = styled.div`
    margin: 0px 10px 1px 0px;
    height: fit-content;
    border: 1.33091px solid #E71383;
`

export const NameElementCYF = styled.div`
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 8px;
    gap: 4px;
    background: #48052C;
`

export const TextNameElementCYF = styled.span`
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
`

export const ImageElementCYFContainer = styled.div`
    position: relative;
`

export const ImageElementCYF = styled.img`
    width: 100%;
    
`

export const ContainerDataCYF = styled.div`
    padding: 15px;
    background: rgba(231, 19, 131, 0.2);
    border: 1.33091px solid #E71383;
`

export const TittleTextV7 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
`

export const ButtonAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 10px;
    background: #E71383;
    cursor: pointer;
`
export const ButtonRemove = styled(ButtonAdd)`

`

export const TextPoppinsInfo = styled(TextPoppins)`
    font-size: 11px;
`
export const TextPoppinsInfoBold = styled(TextPoppinsBold)`
    font-size: 11px;
`

export const TextTextTitiliumWebInfo = styled(TextTitiliumWeb)`
    font-size: 11px;
`

export const ContainerPolygonLeftAdd = styled(BtnPolygon)`
    width: 35%;
    height: 25px;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);    
    clip-path: polygon(0 100%, 0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%);
    position: absolute;
    bottom: 0px;
    left: 0px;
`
export const ContainerPolygonLeftRemove = styled(ContainerPolygonLeftAdd)`
    top: 159px;
`
export const ContainerPolygonRightAdd = styled(BtnPolygon)`
    width: 30%;
    height: 25px;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    position: absolute;
    bottom: 0px;
    right: 0px;
`

export const ContainerPolygonRightRemove = styled(ContainerPolygonRightAdd)`
    top: 159px;
`

export const ContainerLifeBar = styled.div`
    margin-top: 5%;
`