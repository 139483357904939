export function ColsExportImport() {
    const columns = [
        {
            Header: "Fleet",
            width: "15%",
            accessor: "fleet"
        },
        {
            Header: "Amount",
            width: "5%",
            accessor: "amount"
        },
        {
            Header: "Type",
            width: "15%",
            accessor: "type"
        },
        {
            Header: "CROWN Cost",
            width: "15%",
            accessor: "cargo"
        },
        {
            Header: "Action",
            width: "15%",
            accessor: "shield"
        },
    ];

    return columns;
}
   
   