import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Route, Routes} from 'react-router-dom';

import icon_injured from '../../../../../../assets/images/icon_injured.svg'
import icon_healing from '../../../../../../assets/images/icon_healing.svg'
import icon_pick_up from '../../../../../../assets/images/icon_pick_up.svg'

import { RowSpaceBetween } from "../../../../../../containers/CoreElement";
import { BtnOptions1, ContainerOptions2, TextTitiliumWebOptions1 } from "../../CommanderElement";
import { Injured } from "./Injured/Injured";
import { Healing } from "./Healing/Healing";
import { PickUp } from "./PickUp/PickUp";
import IconSvg from "../../../../../../hooks/IconSvg";


export function MedBay(props){

    const location = useLocation();
    const navigate = useNavigate();
  
    const [activeRoute, setActiveRoute] = useState(location.pathname);
  
    const handleClick = (path) => {
        setActiveRoute(path);
        navigate(path);
    };

    useEffect(() => {
      props.setActiveRoute(props.activeRoute)
    }, [props]);
  
    
    return(
        <>
        <ContainerOptions2>
          <RowSpaceBetween>
            <BtnOptions1 onClick={() => handleClick('/commanders/med-bay/injured')} style={{backgroundColor: activeRoute === '/commanders/med-bay/injured' ? '#A40A5B' : 'transparent'}}>
              <IconSvg icon={icon_injured} width={'22px'} color={activeRoute === '/commanders/med-bay/injured' ? '#FFFFFF' : '#d2b8cd'}/>
              <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/med-bay/injured' ? '#FFFFFF' : '#d2b8cd'}}>INJURED</TextTitiliumWebOptions1>
            </BtnOptions1>
            <BtnOptions1 onClick={() => handleClick('/commanders/med-bay/healing')} style={{backgroundColor: activeRoute === '/commanders/med-bay/healing' ? '#A40A5B' : 'transparent'}}>
              <IconSvg icon={icon_healing} width={'20px'} color={activeRoute === '/commanders/med-bay/healing' ? '#FFFFFF' : '#d2b8cd'}/>
              <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/med-bay/healing' ? '#FFFFFF' : '#d2b8cd'}}>HEALING</TextTitiliumWebOptions1>
            </BtnOptions1>
            <BtnOptions1 onClick={() => handleClick('/commanders/med-bay/pick-up')} style={{backgroundColor: activeRoute === '/commanders/med-bay/pick-up' ? '#A40A5B' : 'transparent'}}>
              <IconSvg icon={icon_pick_up} width={'20px'} color={activeRoute === '/commanders/med-bay/pick-up' ? '#FFFFFF' : '#d2b8cd'}/>
              <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/med-bay/pick-up' ? '#FFFFFF' : '#d2b8cd'}}>PICK UP</TextTitiliumWebOptions1>
            </BtnOptions1>
          </RowSpaceBetween>
        </ContainerOptions2>
        <Routes>
            <Route path="injured" element={<Injured setActiveRoute={setActiveRoute} activeRoute={'/commanders/med-bay/injured'} commandersParam={props.commandersParam} extendedHeader={props.extendedHeader} selected={props.selected}/>}/>
            <Route path="healing" element={<Healing setActiveRoute={setActiveRoute} activeRoute={'/commanders/med-bay/healing'} commandersParam={props.commandersParam} extendedHeader={props.extendedHeader} selected={props.selected}/>}/>
            <Route path="pick-up" element={<PickUp setActiveRoute={setActiveRoute} activeRoute={'/commanders/med-bay/pick-up'} commandersParam={props.commandersParam} extendedHeader={props.extendedHeader} selected={props.selected}/>}/>
        </Routes>
        </>
    );
}