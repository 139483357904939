import React from "react";
import { GridViewSpecs } from "../containers/CoreElement";
import { RowCore } from "../containers/CoreElement";
import { ImgIcon } from "../containers/CoreElement";
import { TextTitiliumWeb } from "../containers/CoreElement";


export default function GridTraits({shield, armor, damage, mining, cargo, width, columns, spaceBetweenColumns,spaceBetweenRows, marginTop}){

    const traits = [
        {name: 'Shield', icon: '/icons/icon_shield.svg', value: shield},
        {name: 'Armor', icon: '/icons/icon_armor.svg', value: armor},
        {name: 'Damage', icon: '/icons/icon_damage.svg', value: damage},
        {name: 'Mining', icon: '/icons/icon_mining.svg', value: mining},
        {name: 'Cargo', icon: '/icons/icon_cargo.svg', value: cargo},
    ]

    return(
        <>
        <GridViewSpecs style={{width: width, gridTemplateColumns: `repeat(${columns}, 1fr)`, gridRowGap: `${spaceBetweenRows}`, columnGap: `${spaceBetweenColumns}`,marginTop: marginTop}}>
            <>
            { traits.map((trait, index) => (
                <RowCore style={{alignItems: 'center', height: '20px'}}>
                    <ImgIcon key={index} src={trait.icon} style={{width: '25px', height: '25px'}}/>
                    <TextTitiliumWeb style={{lineHeight: '0px', fontWeight: '400'}}>
                        {trait.name}: 
                    </TextTitiliumWeb>
                    <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%'}}>
                        ({trait.value}) 
                    </TextTitiliumWeb>
                </RowCore>
            ))
            }
            </>
        </GridViewSpecs>
        </>
    );
}