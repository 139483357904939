import styled from 'styled-components'

import {
    Link
} 
from "react-router-dom";
import { BtnPolygon, ContainerVoid, ImgIcon, RowSpaceBetween, TextOrbitron, TextPoppins } from '../../../containers/CoreElement';


export const Optional = styled.div`
    display: contents;

    @media (max-width: 1130px) {
        display: none;
    }
`

export const NavDivi = styled.img`
    height: 15px;
`

export const IconU = styled.img`
    margin-right: 5%;
    width: 24px;
    height: 24px;
`

export const IconPN = styled.img`
    width: 136px;
    height: 47.51px;
`

export const IconSM = styled.img`
    width: 192px;
    height: 80px;
    cursor: pointer;

    @media (max-width: 455px) {
        width: 150px;
    }
`

export const MainRowInfo = styled(RowSpaceBetween)`
    background-color: #5E0835; 
    padding: 10px 0px;
`
export const Responsive = styled.div`
    /* @media (max-width: 1200px) {
        display: none;
    } */
`

export const FirstRowInfo = styled(RowSpaceBetween)`
    align-items: center;
    justify-content: flex-start;
    margin-left: 2%;
    width: 20%;
    gap: 30px;

    @media (max-width: 700px) {
        width: 70%;
    }
`
export const SecondRowInfo = styled(RowSpaceBetween)`
    align-items: center;
    justify-content: flex-end;
    margin-right: 2%;
    width: 20%;
    padding: 0px 0px;
`
export const TextCurrency = styled(TextPoppins)`
    letter-spacing: 0; 
    font-weight: 400; 
    font-size: 16px;
    line-height: 0px;

    @media (max-width: 600px) {
        font-size: 12px
    }
`
export const IconCoin = styled(ImgIcon)`
    width: 20px;
    height: 20px;
    margin-right: 5%;
`
export const IconRotate = styled.img`
    margin-left: 6px;

    :hover{
        transform : rotate(180deg);
    }
`

export const Icon = styled.img`
    margin-left: 2px;
`
export const IconContract = styled(ImgIcon)`
    width: 15px;
    height: 20px;
    margin-right: 5%;
`
export const BtnContract = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`
export const IconWallet = styled(ImgIcon)`
    width: 20px;
    height: 18px;
    margin-right: 5%;
`
export const IconStatusCoin = styled(IconCoin)`
    width: 5px;
    height: 5px;
    margin-left: 5%;
`
export const TextCurrencyValue = styled(TextCurrency)`
    font-size: 15px;
    color: #43D05B;

    @media (max-width: 600px) {
        font-size: 13px
    }
`
export const TextContract = styled(TextCurrency)`
    font-weight: 300; 
    font-size: 15px;
    line-height: 0px;

    @media (max-width: 600px) {
        font-size: 13px
    }
`
export const TextSuscribed = styled(TextContract)`
    line-height: 15px;
`
export const TextCurrencyQuantity = styled(TextCurrency)`
    font-weight: 300; 
    font-size: 15px;
    line-height: 0px;

    @media (max-width: 600px) {
        font-size: 13px
    }
`
export const ContainerBackground = styled(ContainerVoid)`
    padding: 5px 7px;
`

export const PrimaryNav = styled.nav`
    z-index: 14;
    display: flex;
    padding: 0.18rem calc((1100px - 1000px) / 2);
    height: 81px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2F041A;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0px 3px 13px rgb(0 0 0 / 35%);
    justify-content: space-between;
    gap: 15px;
`

export const MenuLink = styled(Link)`
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    padding: 6px;

    border-radius:5px;
    &.active {
        color: #000000;
    }

    :hover{
        background: #40025f;
    }
`

export const MenuLink2 = styled(Link)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    padding: 6px;
    border-radius:5px;
    &.active {
        color: #000000;
    }

`

export const MenuLink2Responsive = styled(MenuLink2)`
    @media (max-width: 1130px) {
        display: none;
    }
`

export const LogoSM = styled.a`

` 

export const BtnSignOut = styled.div`
    color: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    padding: 0 1.2rem;
    height: 100%;
    &.active {
        color: #000000;
    }
`
export const MenuMain = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;

    /* @media screen and (max-width: 768px) {
        display: none;
    } */
`
export const TextUsername = styled(TextPoppins)`
    font-size: 13px;
    letter-spacing: 0px;
    display: flex;
`

export const BtnPlayFree = styled(BtnPolygon)`
    height: 40px;
    width: 140px;
    :hover{
        background: rgba(94, 8, 53, 0.8);
    }
`

export const WithdrawButton = styled.button`
    height: 30px;
    width: 80px;
    border: none;
    background-color: #E71383;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
`

export const TextWithdraw = styled(TextOrbitron)`
    letter-spacing: 0.02em;
    text-transform: none;
    font-size: 10px;
    font-weight: 700;
`

export const TextPlayFree = styled(TextOrbitron)`
    letter-spacing: 0.02em;
    text-transform: none;
    font-size: 15px;
    font-weight: 700;
`

export const DropDownGeneral = styled.div`
    padding-top: 20px;
    position: absolute;
    z-index: 1000;

    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    text-align: left;

    background-color: transparent;
    background-clip: padding-box;

    border-radius: 0.25rem;
`

export const DropDownContainer = styled.div`
    padding: 5px;
    background: #2F051A;
    border: 1px solid #970C55;
    border-radius: 5px;
    box-sizing: border-box;
    gap:3px;
    display: grid;
`

export const DropDownRow = styled(Link)`
    min-width: 136px;
    padding: 4px 8px;
    background: #5E0835;
    border: 0.5px solid #A20D5C;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: normal;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 6px;
`

export const Text = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
`