import React, {useEffect, useState} from "react";
import { GridViewSpecs, ImgIcon, RowCore, TextTitiliumWeb } from "../containers/CoreElement";
import { numberToAbbreviate } from '../components/utils/utils';
import { Tooltip } from "@mui/material";


export default function GridSpecs({specs, width, columns, spaceBetweenColumns,spaceBetweenRows, rewards=false, materials=false, marginTop}){

    const [arrayMaterial, setArrayMaterial] = useState([]);

    useEffect(() => {
        const nameMaterial = ['bits', 'peroxium', 'trantium', 'obscurian', 'mercoxium', 'crelyte', 'aurum']
        if(materials){
            const updatedArrayMaterial = [];
            nameMaterial.forEach(attribute => {
                if (attribute in specs) {
                    let objMaterial = {'name': attribute, 'quantity': specs[attribute]};
                    updatedArrayMaterial.push(objMaterial);
                } 
            })
            setArrayMaterial(updatedArrayMaterial);
        }
    }, [materials, specs])
    
    const specToIcon = (spec) => {
        let specIcon = '';

        switch (spec){
            case 'is_commander':
                specIcon = "/icons/icon_type_commander.svg";
                break;

            case 'is_nft':
                specIcon = "/icons/icon_type_nft.svg";
                break;

            case 'not_nft':
                specIcon = "/icons/icon_type_not_nft.svg";
                break;
    
            case 'is_packed':
                specIcon = "/icons/icon_type_package.svg";
                break;
                
            case 'is_ship':
                specIcon = "/icons/icon_type_ship.svg";
                break;
                
            case 'has_unique_traits':
                specIcon = "/icons/icon_type_specs_inventory.svg";
                break;

            case 'is_module':
                specIcon = "/icons/icon_type_pink_glass.svg";
                break;

            case 'is_component':
                specIcon = "/icons/icon_type_component.svg";
                break;

            case 'is_undefined':
                specIcon = "/icons/icon_type_undefined.svg";
                break;

            case 'is_mineral':
                specIcon = "/icons/icon_type_mineral.svg";
                    break;
            default:
                specIcon = "";
        }

        return specIcon;
    };

    const rewardToIcon = (reward) => {
        let specIcon = '';

        switch (reward.toLowerCase()){
            case 'peroxium':
                specIcon = "/icons/icon_peroxium.svg";
                break;

            case 'trantium':
                specIcon = "/icons/icon_trantium.svg";
                break;
                
            case 'obscurian':
                specIcon = "/icons/icon_obscurian.png";
                break;
                
            case 'mercoxium':
                specIcon = "/icons/icon_type_mercoxium.png";
                break;
                
            case 'bits':
                specIcon = "/icons/icon_type_blue_coin_inventory.svg";
                break;

            case 'crelyte':
                specIcon = "/icons/icon_type_element5.svg";
                break;

            case 'aurum':
                specIcon = "/icons/icon_mineral.svg";
                break;

            default:
                specIcon = "";
        }

        return specIcon;
    };

    return(
        
        <>
        { specs ?
            <GridViewSpecs style={{width: width, gridTemplateColumns: `repeat(${columns}, 1fr)`, gridRowGap: `${spaceBetweenRows}`, columnGap: `${spaceBetweenColumns}`,marginTop: marginTop}}>
            { rewards ? (
                <>
                { specs.map((spec, index) => (
                    <>
                    <RowCore style={{alignItems: 'center'}}>
                        <ImgIcon key={index} src={rewardToIcon(spec.name)} style={{width: '15px', height: '15px', marginRight:"3px"}}/>
                        <Tooltip title={spec.quantity} placement='top'>
                            <TextTitiliumWeb style={{fontSize:"13px", fontWeight: '300', lineHeight: '19px'}}>
                                {numberToAbbreviate(spec.quantity)}
                            </TextTitiliumWeb>
                        </Tooltip>
                    </RowCore>
                    </>
                ))}
                </>
            ) : materials ? (
                <>
                { arrayMaterial.map((spec, index) => (
                    <>
                    { spec.quantity > 0 ?
                        <RowCore style={{alignItems: 'center'}}>
                            <ImgIcon key={index} src={rewardToIcon(spec.name)} style={{width: '15px', height: '15px', marginRight:"3px"}}/>
                            <Tooltip title={spec.quantity} placement='top'>
                                <TextTitiliumWeb style={{fontSize:"19px"}}>
                                    {numberToAbbreviate(spec.quantity)}
                                </TextTitiliumWeb>
                            </Tooltip>
                        </RowCore>
                        :
                        null
                    }
                    </>
                ))}
                </>
            ) :
                <>
                { specs.map((spec, index) => (
                    <ImgIcon key={index} src={specToIcon(spec)} />
                ))}
                </>
            }

        </GridViewSpecs>
        :null
        }
        </>
    );
}