//Librerias INIT
import React, { useState, useEffect } from "react";
import { HTTP } from "../../../../http/http-common";

import "../../../../../containers/hooks/styles.css";

//Components
import TableMyFleet2 from "../../../../../tables/TableMyFleet2/TableMyFleet2";
import { FilterBar } from "../../../../../hooks/FilterBar";
import ReusableRectangleInfo from "../../../../../hooks/ReusableRectangleInfo";
//Icons
import PurplePlanet from "../../../../../assets/images/purple_planet.png";

import Aurum from "../../../../../assets/images/rewards_aurum.png";
import Bits from "../../../../../assets/images/rewards_bits.png";
import Mercoxium from "../../../../../assets/images/rewards_mercoxium-new.png";
import Obscurian from "../../../../../assets/images/rewards_obscurian.png";
import Peroxium from "../../../../../assets/images/rewards_peroxium.png";
import SMCW from "../../../../../assets/images/rewards_smcw.png";
import Trantium from "../../../../../assets/images/rewards_trantium.png";

//Elements

import {
  RouteMessageContainerGeneral,
  RouteMessageContainerElements,
  RouteMessageElement,
  RouteMessageElementTitle,
  RouteMessageTextTitle,
  RouteMessageElementData,
  RouteMessageRewardsContainerGeneral,
  RouteMessageRewardsElement,
  RouteMessageRewardsElementImage,
  RouteMessageRewardsElementDiv1,
  RouteMessageRewardsElementSubDiv1,
  RouteMessageRewardsElementSubDiv2,
  RouteMessageRewardsElementText,
  RouteMessageContainerGap,
  MessageContainerGeneral,
  MessageContainerData,
  MessageContainerRow,
  MessageIcon,
  MessageText,
  ContainerNewTag,
  ContainerNewTag1,
  Trapecio,
  TextTagNew,
  ButtonCloseContainer,
  ButtonCloseText,
  RouteMessageRewardsElementText2,
  CommanderImg,
  RouteMessageContainerCommander,
  CommanderContainerData,
  DamagedCard,
  ImgRepair,
  TitleStatsCard,
  StatsBold,
  CommanderImgContainer,
} from "../MessagesElement";
import GridSpecs from "../../../../../hooks/GridSpecs";
import { useMediaQuery } from '@mui/material'


import icon_commander_damage from "../../../../../assets/images/icon_commander_damage.svg";
import { RowCore, TextPoppins } from "../../../../../containers/CoreElement";
import PopUp from "../../../../../hooks/PopUp";

export function RouteMessage(props) {
  console.log(props);

  const [isTable, setIsTable] = useState("summary");

  const [isColor, setIsColor] = useState("#E71383");
  const [isColor2, setIsColor2] = useState("transparent");
  const [isColor3, setIsColor3] = useState("transparent");

  const [popupServerError, setPopupServerError] = useState(false);
  const [textServerError, setTextServerError] = useState("Server Error");

  const [isDataDamaged, setIsDataDamaged] = useState([]);
  const [isDataDestroyed, setIsDataDestroyed] = useState([]);

  const [typeMessage, setTypeMessage] = useState("System Message");

  const handleSummary = () => {
    setIsTable("summary");

    setIsColor("#E71383");
    setIsColor2("transparent");
    setIsColor3("transparent");
  };

  const handleRecoverable = () => {
    setIsTable("recoverable");

    setIsColor("transparent");
    setIsColor2("#E71383");
    setIsColor3("transparent");
  };

  const handleDestroyed = () => {
    setIsTable("destroyed");

    setIsColor("transparent");
    setIsColor2("transparent");
    setIsColor3("#E71383");
  };

  const toDateUTC = (date) => {
    const dateMsg = new Date(date);

    const dateFormat = dateMsg.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const hourFormat = dateMsg.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    });
    const dateHourUTC = `${dateFormat} ${hourFormat} UTC`;

    return dateHourUTC;
  };

  const cols = [
    {
      Header: "Fleet",
      width: "21%",
      accessor: "fleet",
    },
    {
      Header: "Amount",
      width: "21%",
      accessor: "amount",
    },
    {
      Header: "FP",
      width: "7%",
      accessor: "fp",
    },
    {
      Header: "Cargo",
      width: "7%",
      accessor: "cargo",
    },
    {
      Header: "Shield",
      width: "7%",
      accessor: "shield",
    },
    {
      Header: "Armor",
      width: "7%",
      accessor: "armor",
    },
    {
      Header: "Damage",
      width: "7%",
      accessor: "damage",
    },
  ];

  const elements = [
    {
      icon: "/icons/icon_type_ship_hangar_white.svg",
      text: "SUMMARY",
      action: handleSummary,
      backgroundItem: isColor,
    },
    {
      icon: "/icons/icon_recoverable.svg",
      text: "RECOVERABLE",
      action: handleRecoverable,
      backgroundItem: isColor2,
    },
    {
      icon: "/icons/icon_destroyed.svg",
      text: "DESTROYED",
      action: handleDestroyed,
      backgroundItem: isColor3,
    },
  ];

  const initTables = () => {
    var damagedResult = {};
    var destroyedResult = {};

    Object.entries(props.dataSummary).forEach(([name, objects]) => {
      for (let index = 0; index < objects.length; index++) {
        const element = objects[index];
        console.log(element);

        if (element.was_destroyed) {
          if (destroyedResult[name]) {
            destroyedResult[name].push(element);
          } else {
            destroyedResult[name] = [];
            destroyedResult[name].push(element);
          }
        } else if (element.was_damaged) {
          if (damagedResult[name]) {
            damagedResult[name].push(element);
          } else {
            damagedResult[name] = [];
            damagedResult[name].push(element);
          }
        }
      }
    });

    setIsDataDamaged(damagedResult);
    setIsDataDestroyed(destroyedResult);
  };

  useEffect(() => {
    initTables();
    setTypeMessage(props.dataRoute.p_mission_economic.type_of_route_name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const claimRewards = (reward) => {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("token")).token
        }`,
      },
      data: { reward: reward },
      method: "POST",
    };
    HTTP.request("claim/", config)
      .then((res) => {
        if (res.data.result) {
          window.location.reload();
        } else {
          setPopupServerError(true);
          setTextServerError(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const xs = useMediaQuery('(max-width:600px)')


  return (
    <>
      <RouteMessageContainerGeneral>
        <RouteMessageContainerGap>
          <RouteMessageContainerElements className="scroll">
            <div style={{ width: "99%" }}>
              <RouteMessageElement
                style={{
                  padding: "0px",
                  border: "0px",
                  background: "none",
                  display: "block",
                  position: "relative",
                }}
              >
                {!props.isOpen ? (
                  <ContainerNewTag1>
                    <Trapecio />
                    <TextTagNew>NEW</TextTagNew>
                  </ContainerNewTag1>
                ) : (
                  <ContainerNewTag />
                )}

                <RouteMessageElementData style={{ padding: "0px" }}>
                  <MessageContainerGeneral
                    style={{ background: "rgba(76, 10, 44, 0.737)" }}
                  >
                    <MessageContainerData
                      style={{ padding: "0px", width: "100%", border: "0px"}}
                    >
                      <MessageContainerRow style={{justifyContent: 'space-between'}}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "9px",
                          }}
                        >
                          {props.isRoute === false ? (
                            <div style={{ display: "flex", gap: xs ? "3px" : "9px"}}>
                              {" "}
                              <MessageIcon src="/icons/icon_type_system_message.svg"></MessageIcon>
                              <MessageText>System Message</MessageText>{" "}
                            </div>
                          ) : (
                            <div style={{ display: "flex", gap: xs ? "3px" : "9px" }}>
                              <MessageIcon
                                src={
                                  typeMessage.includes("expedition")
                                    ? "/icons/icon_type_expedition_completion.svg"
                                    : "/icons/icon_mining.svg"
                                }
                              ></MessageIcon>
                              <MessageText>
                                {typeMessage.includes("expedition")
                                  ? "Expedition Completed"
                                  : "Mining Completed"}
                              </MessageText>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: xs ? "3px" : "9px",
                          }}
                        >
                          <MessageIcon src="/icons/icon_type_calendar_missions.svg"></MessageIcon>
                          <MessageText>
                            {" "}
                            {toDateUTC(props.date)}
                          </MessageText>
                        </div>
                        <div
                          style={{
                            justifyContent: "flexEnd",
                            display: "flex",
                            alignItems: "center",
                            gap: xs ? "3px" : "9px",
                            marginRight: "6px",
                          }}
                        >
                          <MessageIcon src="/icons/icon_type_white_star_messages.svg"></MessageIcon>
                          <MessageIcon src="/icons/icon_type_message_messages.svg"></MessageIcon>
                        </div>
                      </MessageContainerRow>
                      <div
                        style={{
                          background: "#3C0009",
                          padding: "6px",
                          borderRadius: "3px",
                        }}
                      >
                        {/* <MessageContainerRow style={{ width: "100%" }}>
                          <div style={{ display: "flex", width: "100%" }}>
                            <MessageText1>{props.textPurple}</MessageText1>
                          </div>
                        </MessageContainerRow> */}
                        <MessageContainerRow>
                          <MessageText>{props.text}</MessageText>
                        </MessageContainerRow>
                      </div>
                    </MessageContainerData>
                  </MessageContainerGeneral>
                </RouteMessageElementData>
              </RouteMessageElement>
              <RouteMessageElement>
                <RouteMessageElementTitle>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <MessageIcon src="/icons/icon_type_messages_info.svg"></MessageIcon>
                    <RouteMessageTextTitle>Info</RouteMessageTextTitle>
                  </div>
                </RouteMessageElementTitle>

                <RouteMessageElementData>
                  <ReusableRectangleInfo
                    missions={true}
                    enemy_ships={props.enemys}
                    specs={props.expectedReward}
                    image={PurplePlanet}
                    mission_id={props.idRoute}
                    typeMessage={props.dataRoute}
                    difficulty="Easy"
                    value2="N*"
                    weak="Weak Spot"
                  ></ReusableRectangleInfo>
                </RouteMessageElementData>
              </RouteMessageElement>
              <RouteMessageElement>
                <RouteMessageElementTitle>
                  <div style={{ display: "flex", gap: "6px" }}>
                    <MessageIcon src="/icons/icon_type_messages_fleet.svg"></MessageIcon>
                    <RouteMessageTextTitle>Fleet</RouteMessageTextTitle>
                  </div>
                </RouteMessageElementTitle>
                <RouteMessageElementData>
                  <RouteMessageContainerCommander>
                    <CommanderImgContainer>
                      {props.commander.is_destroyed ? (
                        <DamagedCard>
                          <ImgRepair src={icon_commander_damage} />
                        </DamagedCard>
                      ) : null}
                      <CommanderImg
                        src={props.commander.commander.image_frame}
                      ></CommanderImg>
                    </CommanderImgContainer>
                    <CommanderContainerData>
                      <GridSpecs
                        specs={props.commander.commander.specs}
                        columns={props.commander.commander.specs.length}
                        spaceBetweenColumns={"15px"}
                      />
                      {props.commander.is_nft ? (
                        <RowCore style={{ width: "auto" }}>
                          <TitleStatsCard>
                            ID: <StatsBold>#{props.commander.id}</StatsBold>
                          </TitleStatsCard>
                        </RowCore>
                      ) : null}
                      <RowCore style={{ width: "auto" }}>
                        <TitleStatsCard>
                          LVL: <StatsBold>1</StatsBold>
                        </TitleStatsCard>
                      </RowCore>
                      <RowCore style={{ width: "auto" }}>
                        <TitleStatsCard>
                          Class:{" "}
                          <StatsBold>
                            {props.commander.commander.class}
                          </StatsBold>
                        </TitleStatsCard>
                      </RowCore>
                      <RowCore style={{ width: "auto" }}>
                        <TitleStatsCard>
                          Bonus:{" "}
                          <StatsBold>
                            {props.commander.commander.bonus.name}
                          </StatsBold>
                        </TitleStatsCard>
                      </RowCore>
                    </CommanderContainerData>
                  </RouteMessageContainerCommander>
                </RouteMessageElementData>
                <RouteMessageElementData>
                  <FilterBar
                    elements={elements}
                    columns="repeat(3,1fr)"
                  ></FilterBar>
                  {isTable === "summary" ? (
                    <TableMyFleet2
                      remove_element={""}
                      data={props.dataSummary}
                      cols={cols}
                    />
                  ) : null}
                  {isTable === "recoverable" ? (
                    <TableMyFleet2
                      remove_element={""}
                      data={isDataDamaged}
                      cols={cols}
                    />
                  ) : null}

                  {isTable === "destroyed" ? (
                    <TableMyFleet2
                      remove_element={""}
                      data={isDataDestroyed}
                      cols={cols}
                    />
                  ) : null}
                </RouteMessageElementData>
              </RouteMessageElement>
              {props.rewardsCondition === true ? (
                <div style={{ marginBottom: "15px" }}>
                  <RouteMessageElement>
                    <RouteMessageElementTitle>
                      <div style={{ display: "flex", gap: "6px" }}>
                        <MessageIcon src="/icons/icon_type_messages_rewards.svg"></MessageIcon>
                        <RouteMessageTextTitle>Rewards</RouteMessageTextTitle>
                      </div>
                    </RouteMessageElementTitle>
                    <RouteMessageElementData>
                      <RouteMessageRewardsContainerGeneral>
                        {props.rewards.map((reward) => (
                          <RouteMessageRewardsElement>
                            {reward.name === "Bits" ? (
                              <RouteMessageRewardsElementImage
                                src={Bits}
                              ></RouteMessageRewardsElementImage>
                            ) : null}
                            {reward.name === "Peroxium" ? (
                              <RouteMessageRewardsElementImage
                                src={Peroxium}
                              ></RouteMessageRewardsElementImage>
                            ) : null}
                            {reward.name === "Trantium" ? (
                              <RouteMessageRewardsElementImage
                                src={Trantium}
                              ></RouteMessageRewardsElementImage>
                            ) : null}
                            {reward.name === "Obscurian" ? (
                              <RouteMessageRewardsElementImage
                                src={Obscurian}
                              ></RouteMessageRewardsElementImage>
                            ) : null}
                            {reward.name === "Aurum" ? (
                              <RouteMessageRewardsElementImage
                                src={Aurum}
                              ></RouteMessageRewardsElementImage>
                            ) : null}
                            {reward.name === "SMCW" ? (
                              <RouteMessageRewardsElementImage
                                src={SMCW}
                              ></RouteMessageRewardsElementImage>
                            ) : null}
                            {reward.name === "Mercoxium" ? (
                              <RouteMessageRewardsElementImage
                                src={Mercoxium}
                              ></RouteMessageRewardsElementImage>
                            ) : null}
                            <RouteMessageRewardsElementDiv1>
                              <RouteMessageRewardsElementSubDiv1>
                                <RouteMessageRewardsElementText
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "15px",
                                    lineHeight: "15px",
                                    marginTop: "5%",
                                  }}
                                >
                                  {reward.name}
                                </RouteMessageRewardsElementText>
                              </RouteMessageRewardsElementSubDiv1>
                              <RouteMessageRewardsElementSubDiv2>
                                <div>
                                  <RouteMessageRewardsElementText2>
                                    Units
                                  </RouteMessageRewardsElementText2>
                                  <RouteMessageRewardsElementText
                                    style={{ lineHeight: "30px" }}
                                  >
                                    {reward.quantity}
                                  </RouteMessageRewardsElementText>
                                </div>
                              </RouteMessageRewardsElementSubDiv2>
                            </RouteMessageRewardsElementDiv1>
                          </RouteMessageRewardsElement>
                        ))}
                      </RouteMessageRewardsContainerGeneral>
                    </RouteMessageElementData>
                  </RouteMessageElement>
                  {!props.was_claimed ? (
                    <ButtonCloseContainer
                      onClick={() => claimRewards(props.reward_id)}
                    >
                      <ButtonCloseText
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "9px",
                        }}
                      >
                        <MessageIcon src="/icons/icon_type_claim_rewards.svg" />{" "}
                        CLAIM REWARDS
                      </ButtonCloseText>
                    </ButtonCloseContainer>
                  ) : null}
                </div>
              ) : null}
            </div>
          </RouteMessageContainerElements>
        </RouteMessageContainerGap>
      </RouteMessageContainerGeneral>
      <PopUp
        show={popupServerError}
        onClose={() => setPopupServerError(false)}
        onlyConfirm
      >
        <TextPoppins
          style={{ fontWeight: "400", fontSize: "15px", lineHeight: "27px" }}
        >
          {textServerError}
        </TextPoppins>
      </PopUp>
    </>
  );
}
