import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../http/http-common';

import {
  useNavigate,
  useLocation,
  Routes,
  Route
} from "react-router-dom";



import { Hangar } from './Pages/Hangar/Hangar';

import HeaderScreen from "../../../../hooks/HeaderScreen";

import {FilterBar} from "../../../../hooks/FilterBar";

import header from '../../../../assets/images/header_shipyard.png'

import { Loader } from '../../../../hooks/Loader';
import Forge from './Pages/Forge/Forge';
import Repair from './Pages/RepairBay/Repair';




export function Shipyard(props) {

  const location = useLocation();
  const navigate = useNavigate();



  const [loader, setLoader] = useState(false);


  const [headerExtension, setHeaderExtension] = useState(true);
  const [isOpen, setIsOpen] = useState();
  const [isOpenBlueprints, setIsOpenBlueprints] = useState();

  const [valueCountShips, setValueCountShips] = useState(0);
  const [valueLimitShips, setValueLimitShips] = useState(0);

  const closeHeader = () => {
      setHeaderExtension(false);
  }

  const closeCardHangarSelected = () => {
    setIsOpen(prevState => !prevState);
  }

  const closeCardForgeSelected = () => {
    setIsOpenBlueprints(prevState => !prevState);
  }

  const extendHeader = () => {
      setHeaderExtension(true);
      setIsOpen(false);
      setIsOpenBlueprints(false);
  }

  const handleClick = (path) => {
    navigate(path);

};



const elements = [

    {'icon' : '/icons/icon_type_ship_hangar_white.svg', 'text' :'HANGAR', 'action' : () => handleClick('/shipyard/hangar'), 'backgroundItem' : location.pathname==='/shipyard/hangar' ? '#FF0087' : 'transparent', 'color': location.pathname==='/shipyard/hangar' ? '#FFFFFF' : '#d2b8cd'},
    { 'icon' : '/icons/icon_type_forge.svg', 'text' :'FORGE', 'action' : () => handleClick('/shipyard/forge/blueprints'), 'backgroundItem' : location.pathname==='/shipyard/forge/blueprints' || location.pathname==='/shipyard/forge/in-process' || location.pathname==='/shipyard/forge/pick-up' ? '#FF0087' : 'transparent', 'color': location.pathname==='/shipyard/forge/blueprints' || location.pathname==='/shipyard/forge/in-process' || location.pathname==='/shipyard/forge/pick-up' ? '#FFFFFF' : '#d2b8cd'},
    { 'icon' : '/icons/icon_type_repair_bay.svg', 'text' :'REPAIR BAY', 'action' : () => handleClick('/shipyard/repair-bay/damaged'), 'backgroundItem' : location.pathname==='/shipyard/repair-bay/damaged' || location.pathname==='/shipyard/repair-bay/repairing' || location.pathname==='/shipyard/repair-bay/pick-up' ? '#FF0087' : 'transparent', 'color': location.pathname==='/shipyard/repair-bay/damaged' || location.pathname==='/shipyard/repair-bay/repairing' || location.pathname==='/shipyard/repair-bay/pick-up' ? '#FFFFFF' : '#d2b8cd'},

  ]
  
  useEffect(() => {
    if(location.pathname !== '/shipyard/hangar' || location.pathname !== '/shipyard/forge/blueprints'){
      setIsOpen(false);
      setIsOpenBlueprints(false);
    }
    fetchCountShips();
  }, [location.pathname]);

  const fetchCountShips = () => {
    setLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      method: "GET"
    };
    HTTP.request('ships/count/', config)
      .then((res) => {
        if (res.data.result){
          setValueLimitShips(res.data.response.limit)
          setValueCountShips(res.data.response.count)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };

  

  return (
    <>
    <Loader loading={loader}/>
    <HeaderScreen
      imgHeader = {header}
      extended= {headerExtension}
      title="SHIPYARD"
      icon="/icons/icon_type_header_shipyard.svg"
      textHelp=""
      onClickBack={extendHeader}
      countShips={{count: valueCountShips, limit: valueLimitShips}}
      shipyard={true}
    >
      Start by selecting an item from the list that you want to craft, an FT blueprint may be required in some cases.
    </HeaderScreen>

    <FilterBar
      background = 'rgba(141, 12, 80, 0.5)' 
      elements = {elements}
      columns = "repeat(3,1fr)"
    ></FilterBar>  

    <Routes>
      <Route path='hangar' element={<Hangar closeHeader = {closeHeader} isOpen = {isOpen} closeCardHangarSelected = {closeCardHangarSelected}></Hangar>}/>
      <Route path="forge" element={<Forge closeHeader ={closeHeader} isOpenBlueprints={isOpenBlueprints} closeCardForgeSelected={closeCardForgeSelected}/>}>
        <Route path="blueprints"/>
        <Route path="in-process"/>
        <Route path="pick-up"/>
      </Route>
      <Route path="repair-bay" element={<Repair closeHeader={closeHeader} isOpen={isOpen} closeCardSelect={setIsOpen}/>}>
        <Route path="damaged"/>
        <Route path="repairing"/>
        <Route path="pick-up"/>
      </Route>
    </Routes>

    </>
  )

}