import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../../../http/http-common';

import ReusableCardRegular from "../../../../../../hooks/ReusableCardRegular";

import CardHangarSelected from "../../../../../../hooks/CardHangarSelected";

import { 
  ContainerElements, ContainerHangar
 } from './HangarElement';
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids';
import Pagination from '../../../../../../hooks/Pagination';

import { Loader } from '../../../../../../hooks/Loader';
import { FilterBar } from '../../../../../../hooks/FilterBar';
import PopUp from '../../../../../../hooks/PopUp';
import { TextPoppins } from '../../../../../../containers/CoreElement';
import { updateNavs } from '../../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { Text } from '../../ShipyardElement';


 export function Hangar(props) {

  const [selectedInfo, setSelectedInfo] = useState({});
  const [, setIndex] = useState(-1);
  const [cardsHangar, setCardsHangar] = useState([]);
  const [loader, setLoader] = useState(false);

  const [popupServerError, setPopupServerError] = useState(false);
  const [textServerError, setTextServerError] = useState('Server Error');
  const [popUpInstantRepairAllHangar, setPopUpInstantRepairAllHangar] = useState(false);
  const [popUpRepairAllHangar, setPopUpRepairAllHangar] = useState(false);
  const [elementsHangar, setElementsHangar] = useState([]);

  const navigate = useNavigate();

  const fetchShipsRepairAllValues = () => {
    setLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('ships/repair/all/values/', config)
      .then((res) => {
        if (res.data.result){
          var elements_hangar = [
            {'iconValue' : '/icons/icon_type_blue_coin_inventory.svg','icon' : '/icons/icon_type_repair_all.svg', 'text' :'REPAIR ALL', 'value' : res.data.values["cost_repair_bits"], 'action' : () => setPopUpRepairAllHangar(true), 'backgroundItem' : "rgba(255, 0, 136, 0.0)", 'boxShadow':'0px 4px 4px 0px rgba(0, 0, 0, 0.336) inset'},
            { 'iconValue' : '/icons/icon_type_crown_coin_grey.png','icon' : '/icons/icon_type_repair_all.svg', 'text' :'INSTANT REPAIR ALL', 'value' : res.data.values["cost_repair_crowns"], 'action' : () => setPopUpInstantRepairAllHangar(true), 'backgroundItem' : "#AA68D8", 'boxShadow':'0px 4px 4px 0px rgba(0, 0, 0, 0.336) inset'},
          ]
          console.log(elements_hangar);
          setElementsHangar(elements_hangar);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setLoader(false);
      })
  };

  const handleExtended = (cards, index) => {
      let card = cards;
      card.repair = index % 2 === 0 ? false : true;
      setSelectedInfo(card);
      setIndex(index);
      props.closeHeader(true);
      props.closeCardHangarSelected(true);
  }

  const fetchShipsHangar = () => {
    setLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('hangar/', config)
      .then((res) => {
        if (res.data.result){
            setCardsHangar(res.data.ships)
        }
      })
      .catch((err) => {
        console.log(err);
        setPopupServerError(true);
        setTextServerError(err.response.statusText);
      })
      .finally(() => {
        setLoader(false);
      })
  };

  const fetchShipsRepairAll = (instantRepair) => {
    setLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      data: {"instant_repair": instantRepair},
      method: "POST"
    };
    HTTP.request('ships/repair/all/', config)
      .then((res) => {
        if (res.data.result){
          if (instantRepair){
            console.log("All Ships repaired successfullly!");
            navigate('/shipyard/repair-bay/pick-up')
            setPopUpInstantRepairAllHangar(false)
          }else{
            console.log("Start process repair all Ships successfully!");
            navigate('/shipyard/repair-bay/repairing')
            setPopUpInstantRepairAllHangar(false)
          }
          setPopUpRepairAllHangar(false);
          setPopUpInstantRepairAllHangar(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };


  useEffect(() => {
    updateNavs();
    fetchShipsRepairAllValues();
    fetchShipsHangar();
  }, []);

  

  return (

    <>
    <Loader loading={loader}/>
      { elementsHangar.length > 0 ?
        <FilterBar
          background = 'rgba(255, 0, 136, 0)' 
          boxShadow = {'0px 10px 10px 0px rgb(0, 0, 0) inset'}
          elements = {elementsHangar}
          columns = "repeat(2,1fr)"
        ></FilterBar>
        :
        null
      }
      {props.isOpen && (
        <CardHangarSelected
          item = {selectedInfo}
        ></CardHangarSelected>
      )}
      <ContainerHangar style={{height: cardsHangar.length !== 0  ? '650px' : '130px'}}>
        <FilterForGrids allElements={cardsHangar} sortByStatus sortByClass>
          { elementsToFilter => (
            <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not ships in hangar">
              { elementsToRender => (
                <ContainerElements>
                  {elementsToRender.map((card, index) => (
                    <>
                    <ReusableCardRegular
                      item={card}
                      action={() => handleExtended(card, index)}
                      buttonText={"Select"}
                    ></ReusableCardRegular>
                    </>

                  ))}
                </ContainerElements>
              )
              }
            </Pagination>
          )
          }
        </FilterForGrids>
      </ContainerHangar>

      <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
          <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            {textServerError}
          </TextPoppins>
      </PopUp>

      <PopUp show={popUpRepairAllHangar} onClose={() => setPopUpRepairAllHangar(false)} onConfirm={() => fetchShipsRepairAll(false)}>
          <Text>Are you sure you want to repair all?</Text>
      </PopUp>

      <PopUp show={popUpInstantRepairAllHangar} onClose={() => setPopUpInstantRepairAllHangar(false)} onConfirm={() => fetchShipsRepairAll(true)}>
        <Text>Are you sure you want to repair all instant?</Text>
      </PopUp>
    </>
  )
}