import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../../../http/http-common';



import { 
    ContainerElements, ContainerForge, Text
} from './ForgeElement'


import ReusableCardPickUp from "../../../../../../hooks/ReusableCardPickUp";
import Pagination from '../../../../../../hooks/Pagination';
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids';
import { Loader } from '../../../../../../hooks/Loader';
import { FilterBar } from '../../../../../../hooks/FilterBar';
import PopUp from '../../../../../../hooks/PopUp';
import { TextPoppins } from '../../../../../../containers/CoreElement';
import { updateNavs } from '../../../../../utils/utils';
import { useNavigate } from 'react-router-dom';



export function ForgePickUp() {

    const elementsForgePickUp = [

        {'icon' : '/icons/icon_type_pickup_all.svg', 'text' :'PICKUP ALL', 'action' : () => setPopUpPickUpAll(true), 'backgroundItem' : "#7C0242"},

    ]

    const [cardsForgePickUp, setCardsForgePickUp] = useState([]);
    const [loader, setLoader] = useState(false);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');
    const [popUpPickUpAll, setPopUpPickUpAll] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        updateNavs();
        fetchShipsForgeInPickups();
    }, []);

    const fetchForgeShipsPickUpAll = () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          method: "POST"
        };
        HTTP.request('ships/forge/all/pickup/', config)
          .then((res) => {
            if (res.data.result){
              setPopUpPickUpAll(false)
              navigate('/shipyard/hangar')
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
          
      };

    const fetchShipsForgeInPickups = () => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/forge/pickup/process/', config)
            .then((res) => {
            if (res.data.result){
                console.log(res.data);
                setCardsForgePickUp(res.data.pickups)
            }
            })
            .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
            })
            .finally(() => {
                setLoader(false);
              })
    };

    return (
        <>
            <Loader loading={loader}/>
            {elementsForgePickUp.length > 0 ?
              <FilterBar
                elements = {elementsForgePickUp}
                columns = "repeat(1,1fr)"
              ></FilterBar>  
              :
              null
            }
            <ContainerForge style={{height: cardsForgePickUp.length !== 0 ? '650px' : '170px'}}>
                <FilterForGrids allElements={cardsForgePickUp} sortByClass>
                    { elementsToFilter => (
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData='There are not ships in pick up'>
                            { elementsToRender => (
                                <ContainerElements>
                                    {elementsToRender.map((card) => (
                                    <ReusableCardPickUp 
                                        item={card}
                                    ></ReusableCardPickUp>
                                    ))}
                                </ContainerElements>
                            )
                            }
                        </Pagination>
                    )
                    }
                </FilterForGrids>
            </ContainerForge>

            <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
                <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                    {textServerError}
                </TextPoppins>
            </PopUp>

            <PopUp show={popUpPickUpAll} onClose={() => setPopUpPickUpAll(false)} onConfirm={() => fetchForgeShipsPickUpAll()}>
                <Text>Are you sure you want to pick up all?</Text>
            </PopUp>
        </>
    )

}