import styled from 'styled-components'
import { 
    ContainerVoid,
    GridViewCore,
    TextTitiliumWeb
} from '../../../../containers/CoreElement'
import { ImgCoin } from '../Commander/CommanderElement'

export const ContainerMission = styled.div`
    display: table;
    width: 100%;
    margin-top: 2%;
    margin-bottom: 0.5%;
`
export const ContainerMissionImg = styled.div`
    width: 25%;
    height: auto;
    min-height: 150px;
    position: relative;
`

export const ContainerTitleExpeditions = styled.div`
    border-left: 1px solid rgba(231, 19, 131, 1);
    border-right: 1px solid rgba(231, 19, 131, 1);
    width: 100%;
    height: 35px;
    background: rgba(141, 12, 80, 0.7);
    background: rgba(231, 19, 131, 0.4);
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
`
export const BtnOption = styled(ContainerVoid)`
    width: 50%;
    height: 100%;
    cursor: pointer;
`

export const ButtonGenerateRoute = styled.div`
    border: 1px solid rgba(231, 19, 131, 1);
    width: 100%;
    height: 35px;
    background-color:rgba(231, 19, 131, 1);
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    cursor: pointer;
`

export const ContainerOptions = styled(ContainerVoid)`
    border: 1px solid rgba(231, 19, 131, 1);
    width: 100%;
    height: auto;
    background: rgba(141, 12, 80, 0.5);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.61) inset;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
`

export const ContainerOptionsDesktop = styled(ContainerOptions)`

    @media (max-width: 600px) {
        display: none;
    }
`

export const ContainerOptionsCellphone = styled(ContainerOptions)`

    flex-direction: column;
    @media (min-width: 601px) {
        display: none;
    }
`

export const ContainerOption = styled(ContainerVoid)`
    cursor: pointer;
    width: 100%;
    height: 35px;
    background: rgba(141, 12, 80, 0.5);
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
`
export const ContainerOptionRefresh = styled(ContainerOption)`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.295) inset;
    background: #AA68D8;
`
export const ContainerOptionShowAll = styled(ContainerOption)`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.295) inset;
    background: #A40A5B;
`
export const IconOption = styled(ImgCoin)`
    margin-right: 3%;
    width: 15px;
    height: 30px;
`

export const TextCoins = styled.div`
    margin-left: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NumberCoins = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
`


export const TextTitle = styled(TextTitiliumWeb)`
    margin-left: 1.5%;
    font-weight: 400;
    line-height: 35px;
`
export const TextTitleShowAll = styled(TextTitle)`
    margin-left: 3%;
`


export const MissionImg = styled.img`
    width: 100%;
    position: absolute;
    bottom: 0;

`
export const ContainerDataMain = styled.div`
    width: 75%;
    height: auto;
    min-height: 150px;
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    padding: 16px;
    gap: 12px;
    margin-left: 20px;
    display: flex;
`
export const ContainerDataPanelMain1 = styled.div`
    width: 75%;
`

export const ContainerDataPanelMain2 = styled.div`
    width: 25%;
    flex-flow: column;
    justify-content: space-between;
    display: flex;
`

export const ContainerMissionPanel1 = styled.div`
    background: #520830;
    border: 1px solid #A20E5C;
    gap: 12px;
    padding: 10px;
    display: flex;
    margin-bottom: 20px;
`
export const ContainerMissionPanel2 = styled.div`
    background: #520830;
    border: 1px solid #A20E5C;
    gap: 12px;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`

export const LineSeparate = styled.div`
    border: 0.5px solid #E71383;
    width: 0px;
    justify-self: center;
`

export const ElementMissionInfo = styled.div`
    background: #3e0624;
    background-blend-mode: soft-light;
    border: 0.5px solid #E71383;
    border-radius: 2px;
    padding: 10px;
    gap: 6px;
`

export const ElementCaracInfo = styled.div`
    background: #870b4c;
    border: 0.5px solid #E71383;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 10px;
    gap: 6px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
`

export const TextV1 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
`

export const ElementDuration = styled.div`
    display: flex;
    justify-content: space-between;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(19, 203, 231, 0.6);
    background-blend-mode: normal, hard-light;
    border: 0.5px solid #13CBE7;
    border-radius: 2px;
    padding: 10px 8px;
    gap: 6px;
`

export const IconClock = styled.img`

`

export const IconButtonSelectRoute = styled.img`

`

export const ContainerGeneral = styled(GridViewCore) `
    display: block;
    height: 500px;
    margin-top: 15px;
`

export const DivDownloadMayhem = styled.img`
    width: 99.63%;
    border: 1px solid rgba(231, 19, 131, 1);
`