import React, { useState, useEffect } from "react";
import { HTTP } from '../../../../../../http/http-common';

import icon_stats from '../../../../../../../assets/images/icon_stats.svg'
import icon_more from '../../../../../../../assets/images/icon_more.svg'
import icon_clone from '../../../../../../../assets/images/icon_clone.svg'
import icon_amount from '../../../../../../../assets/images/icon_available_slots.svg'

import { ColButtons, ContainerPolygon, IconArrow, PolygonLeft, PolygonRight, RowCore, TextPoppins, TextPoppinsBold } from "../../../../../../../containers/CoreElement";
import { BtnSelectCard, ContainerSection2Element, ContainerSection2ElementIcon, DropDownStatsRow, DropDownStatsIcon, DropDownStatsContainer, CardCommander, ColInfo, ColStats, ContainerCommanders, ContainerGrid, ContainerIconMedical, ContainerImgInfoProfile, ContainerImgProfile, ContainerInfoCommanders, ContainerInfoStats, ContainerStats, ContainerStatsProfile, ContainerTextIconButton, GridViewCoreCommanders, ImgCoin, ImgIconStats, ImgInfoProfile, ImgMedical, NumberCoins, Row, RowCoreBonus, RowCoreCenter, RowRevive, RowSpaceBetweenCenter, StatsBold, TextCoins, TextPoppinsInfo, TextPoppinsInfoBold, TextSelectCard, TextTextTitiliumWebInfo, TextTitleCard, TitleCardCommander, TitleInfoCommanders, TitleStats, TitleStatsCard, ContainerMinerals, Icon, ContainerInputCardSelected, InputSetAmount, ContainerSection2 } from "../../../CommanderElement";
import GridSpecs from "../../../../../../../hooks/GridSpecs";
import { FilterForGrids } from "../../../../../../../hooks/FilterForGrids";
import { DropdownStats } from "../../../../../../../hooks/DropdownStats";
import { BtnClone } from "../../../CommanderElement";
import Pagination from "../../../../../../../hooks/Pagination";
import PopUp from "../../../../../../../hooks/PopUp";
import { Loader } from "../../../../../../../hooks/Loader";
import { updateNavs } from "../../../../../../utils/utils";
import { useMediaQuery } from '@mui/material'


export function Clone(props) {

    const xs = useMediaQuery('(max-width:600px)')
    // const [selected, setSelected] = useState(false);
    const [commander, setCommander] = useState({});
    const [commanders, setCommanders] = useState([]);

    const [popUpClone, setPopUpClone] = useState(false);
    const [popUpError, setPopUpError] = useState(false);
    const [popUpTextError, setPopUpTextError] = useState("...");

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');
    const [inputAmountAdd, setInputAmountAdd] = useState(0);
    const [disabledClone, setDisabledClone] = useState(true);

    const [statsDropDown, setStatsDropDown] = useState(false);

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        updateNavs();
        fetchCommanderClone();
    }, [])

    useEffect(() => {
        props.setActiveRoute(props.activeRoute);
    }, [props])

    const btnClickSelected = (coman, index) => {
        setCommander(coman);
        console.log(index, coman);
        props.extendedHeader(false);
    }

    const fetchCommanderClone = () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('altura/get/bps/commanders/', config)
          .then((res) => {
            if (res.data.result){
                setCommanders(res.data.commanders)
            }
          })
          .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
          })
          .finally(() => {
            setLoader(false);
          })
      };

    const cloneCommander = (commanderId) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"commander_id": commanderId, "quantity": inputAmountAdd},
            method: "POST"
        };
        HTTP.request('commander/clone/', config)
        .then((res) => {
            if (res.data.result){
                props.extendedHeader(true);
            }else{
                setPopUpError(true)
                setPopUpTextError(res.data.response)
            }

            setPopUpClone(false)
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
        })
    }

    
    const handleDropDownStats = () => {
        setStatsDropDown(!statsDropDown);
    }
    
    const handleIncrement = (item) => {
        setInputAmountAdd(inputAmountAdd + 1)
        if (inputAmountAdd + 1 > 0) {
            setDisabledClone(false)
        }else{
            setDisabledClone(true)
        }
    }

    const handleDecrement = () => {
        if(inputAmountAdd >= 1){
            setInputAmountAdd(inputAmountAdd - 1)
            if (inputAmountAdd - 1 > 0) {
                setDisabledClone(false)
            }else{
                setDisabledClone(true)
            }
        }

    }

    const handleInputAmountAddChange = (event) => {
        var integerNumber = Math.round(event.target.value);
        integerNumber = parseInt(integerNumber, 10);
        setInputAmountAdd(integerNumber);
        console.log(integerNumber)
        if (integerNumber > 0){
            document.getElementById('idInputAmountAdd').value = integerNumber;
            setDisabledClone(false)
        }else{
            document.getElementById('idInputAmountAdd').value = '';
            setDisabledClone(true)
        }
    };

    return (
        <>
            <Loader loading={loader}/>
            {props.selected ?
                <ContainerInfoCommanders >
                    <div style={{ padding: '20px' }}>
                        <Row>
                            <ContainerImgInfoProfile style={{ width: '30%', height: "auto"}}>
                                <ImgInfoProfile src={commander.commander.image_frame} />
                            </ContainerImgInfoProfile>
                            <ColInfo style={{width: '100%'}}>
                                <TitleInfoCommanders>
                                    <span>{commander.commander.name}</span>
                                </TitleInfoCommanders>
                                {/* <DescriptionInfoCommanders>
                                    <span>{commander.commander.description}</span>
                                </DescriptionInfoCommanders> */}
                            </ColInfo>
                        </Row>
                        <ContainerInfoStats>
                            <RowSpaceBetweenCenter style={{width: xs ? '100%' : '60%'}}>
                                <GridSpecs specs={commander.commander.specs} spaceBetweenColumns={xs ? '4px' : '10px'} width={'25%'} columns={2} />
                                <TitleStats>Class: <StatsBold>{commander.commander.class}</StatsBold></TitleStats>
                                <TitleStats>Bonus: <StatsBold>{commander.commander.bonus.name}</StatsBold></TitleStats>
                            </RowSpaceBetweenCenter>
                        </ContainerInfoStats>
                        <div>
                            <ContainerStats onClick={handleDropDownStats}>
                                <RowSpaceBetweenCenter>
                                    <RowCoreCenter>
                                        <ImgIconStats src={icon_stats} />
                                        <TextPoppinsBold>Stats</TextPoppinsBold>
                                    </RowCoreCenter>
                                    <DropDownStatsIcon style={{width:'20px'}} src={ statsDropDown ? '/icons/icon_type_close_dropdown.svg' : icon_more}/>
                                </RowSpaceBetweenCenter>
                            </ContainerStats>
                            {
                                statsDropDown ?

                                    <DropDownStatsContainer>
                                        <DropDownStatsRow>
                                            <DropDownStatsIcon src='/icons/icon_type_fleet_points_dropdown.svg'></DropDownStatsIcon><TitleStats>Fleet Points: <StatsBold>+{commander.commander.fp}</StatsBold></TitleStats>
                                        </DropDownStatsRow>
                                        <DropDownStatsRow>
                                            <DropDownStatsIcon src='/icons/icon_type_shield_dropdown.svg'></DropDownStatsIcon><TitleStats>Shield: <StatsBold>{commander.commander.bonus.shield}%</StatsBold></TitleStats>
                                        </DropDownStatsRow>
                                        <DropDownStatsRow>
                                            <DropDownStatsIcon src='/icons/icon_type_armor_dropdown.svg'></DropDownStatsIcon><TitleStats>Armor: <StatsBold>{commander.commander.bonus.armor}%</StatsBold></TitleStats>
                                        </DropDownStatsRow>
                                        <DropDownStatsRow>
                                            <DropDownStatsIcon src='/icons/icon_type_damage_dropdown.svg'></DropDownStatsIcon><TitleStats>Damage: <StatsBold>{commander.commander.bonus.damage}%</StatsBold></TitleStats>
                                        </DropDownStatsRow>
                                        <DropDownStatsRow>
                                            <DropDownStatsIcon src='/icons/icon_type_load_dropdown.svg'></DropDownStatsIcon><TitleStats>Load: <StatsBold>{commander.commander.bonus.load}%</StatsBold></TitleStats>
                                        </DropDownStatsRow>
                                    </DropDownStatsContainer>
                                :
                                    null
                            }
                        </div>
                        <ContainerSection2>
                            <ContainerSection2Element style={{width: "33%"}}>
                                <ContainerSection2ElementIcon>
                                    <Icon src={icon_amount}></Icon>
                                </ContainerSection2ElementIcon>
                                <ContainerInputCardSelected>
                                    <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='Set amount' id='idInputAmountAdd' step="1" min={1} max={props.count} value={inputAmountAdd} onChange={handleInputAmountAddChange}/>
                                    <ColButtons>
                                        <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrement(props.item)}/>
                                        <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrement()}/>
                                    </ColButtons>
                                </ContainerInputCardSelected>
                                {/* <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='Set amount' id='idInputAmountAdd' step="1" min={1} max={props.count} value={inputAmountAdd} onChange={handleInputAmountAddChange}/> */}
                            </ContainerSection2Element>
                            <ContainerSection2Element>
                                <BtnClone style={{opacity: disabledClone ? 0.5 : 1}} disabled={disabledClone} onClick={() => setPopUpClone(true)}>
                                    <ContainerIconMedical>
                                        <ImgMedical src={icon_clone}/>
                                    </ContainerIconMedical>
                                    <ContainerTextIconButton>
                                        <RowRevive>
                                            <ContainerMinerals>
                                                <TextCoins>
                                                    <ImgCoin src={'/icons/icon_clone.svg'} style={{height: "20px", marginBottom: "5px"}}/>
                                                    <NumberCoins>CLONE</NumberCoins>
                                                </TextCoins>
                                                <TextCoins>
                                                    <ImgCoin src={'/icons/icon_type_blue_coin_inventory.svg'} />
                                                    <NumberCoins>{commander.commander.cost_instant_clone_bits * inputAmountAdd}</NumberCoins>
                                                </TextCoins>
                                                <TextCoins>
                                                    <ImgCoin src={'/icons/icon_type_crelyte.png'} />
                                                    <NumberCoins>{commander.commander.cost_instant_clone_crelyte * inputAmountAdd}</NumberCoins>
                                                </TextCoins>
                                            </ContainerMinerals>
                                        </RowRevive>
                                    </ContainerTextIconButton>
                                </BtnClone>
                            </ContainerSection2Element>
                        </ContainerSection2>
                    </div>
                </ContainerInfoCommanders>
                : null
            }
            <ContainerCommanders style={{height: commanders.length !== 0 ? '690px' : '180px'}}>
                <FilterForGrids allElements={commanders} commanders sortByType sortByClass>
                    { elementsToFilter => (
                        <ContainerGrid>
                            <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not clone commanders">
                                { elementsToRender => (
                                    <GridViewCoreCommanders>
                                        {elementsToRender.map((com, index) => (
                                            <CardCommander className='card-commander' key={com.commander.id} >
                                                <TitleCardCommander>
                                                    <TextTitleCard>
                                                        {com.commander.name}
                                                    </TextTitleCard>
                                                </TitleCardCommander>
                                                <ContainerImgProfile>
                                                    <ImgInfoProfile src={com.commander.image} />
                                                    <ContainerPolygon>
                                                        <PolygonLeft>
                                                            <TextPoppinsInfo>FP:<TextPoppinsInfoBold>+{com.commander.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                                                        </PolygonLeft>
                                                    </ContainerPolygon>
                                                    <ContainerPolygon style={{right: '0'}}>
                                                        <PolygonRight>
                                                            <TextTextTitiliumWebInfo>Lvl {com.commander.level}</TextTextTitiliumWebInfo>
                                                        </PolygonRight>
                                                    </ContainerPolygon>
                                                </ContainerImgProfile>
                                                <ContainerStatsProfile>
                                                    <GridSpecs specs={com.commander.specs} spaceBetweenColumns={'7px'} marginTop={'2%'} />
                                                    <ColStats>
                                                        <RowCore style={{ marginTop: "3px", marginBottom: "9px" }}>
                                                            <TitleStatsCard>Class: <StatsBold>{com.commander.class}</StatsBold></TitleStatsCard>
                                                        </RowCore>
                                                        <RowCoreBonus>
                                                            <TitleStatsCard>Bonus: <StatsBold>{com.commander.bonus.name}</StatsBold></TitleStatsCard>
                                                        </RowCoreBonus>
                                                    </ColStats>

                                                </ContainerStatsProfile>
                                                <DropdownStats textButton='Stats' iconButton={icon_stats} jsonObj={com.commander.bonus} />
                                                {com.commander.active_clone ? 
                                                <BtnSelectCard className='btn-select' onClick={() => btnClickSelected(com, index)}>
                                                    <TextSelectCard>Select</TextSelectCard>
                                                </BtnSelectCard>
                                                :
                                                <BtnSelectCard className='btn-select' onClick={() => window.open(com.commander.url_altura, '_blank')}>
                                                    <TextSelectCard>BUY NFT</TextSelectCard>
                                                </BtnSelectCard>
                                                }
                                            </CardCommander>
                                        ))}
                                    </GridViewCoreCommanders>
                                )
                                }
                            </Pagination>
                        </ContainerGrid>
                    )
                    }
                </FilterForGrids>
            </ContainerCommanders>

            <PopUp show={popUpClone} onClose={() => setPopUpClone(false)} onConfirm={() => cloneCommander(commander.commander.id)}>
                <TextPoppins style={{letterSpacing: '0'}}>
                    Are you sure you want to clone?
                </TextPoppins>
            </PopUp>

            <PopUp show={popUpError} onClose={() => setPopUpError(false)} onConfirm={() => setPopUpError(false)}>
                <TextPoppins style={{letterSpacing: '0'}}>
                    {popUpTextError}
                </TextPoppins>
            </PopUp>

            <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
                <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                    {textServerError}
                </TextPoppins>
            </PopUp>
        </>
    );
}

