import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import ReCAPTCHA from 'react-google-recaptcha';
import { BtnSignUp, SignUpForm, FormTitle, FormCheck, CheckLabel, FormErrorHeader, ErrorItems, FormSignInLink, FieldErrorStatus, PasswordDone, FormPassWordError, PassWordErrorItems, PasswordErrorStatus, FormControl, FormLabel, SignUpImage, ContainerRegister, InputConfirmPassword, InputEmail, InputPassword, InputUsername, TextButtons, FieldErrorHeaderStatus } from './RegisterElement';
import { FaUserAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { BiLockAlt } from 'react-icons/bi';
import { AiOutlineEyeInvisible, AiOutlineEye, AiOutlineCheck, AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';
import { FaRegSquare, FaRegCheckSquare } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import { TextPoppins } from '../../containers/CoreElement'
import { HTTP } from '../http/http-common';
import { emailValidation } from '../utils/utils';

import sign_banner from '../../assets/images/new-sign-banner.png'
import { Loader } from '../../hooks/Loader';

export function Register() {

    let navigate = useNavigate();

    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    // const [reCaptcha, setReCaptcha] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [agree, setAgree] = useState(false);
    const [passwordError, setPasswordError] = useState({});
    const [error, setError] = useState({});
    const [errorHeader, setErrorHeader] = useState('');
    const passwordRegex = /[!@#$%^&*()-/,.?":{}|<>]/g;
    const emailRegex = /[!#$%^&*()/,?":{}|<>]/g;
    const [loader, setLoader] = useState(false);

    const registerUser = async (credentials) => {
        setLoader(true);
        var config = {
            data: { "user": credentials.username, "pass": credentials.password, "confirm_pass": credentials.confirmPassword, "email": credentials.email, 'g-recaptcha-response': false /*reCaptcha*/ },
            method: "POST"
        };

        HTTP.request('register/', config)
            .then((res) => {
                if (res.data.result) {
                    sessionStorage.setItem('email_to_confirm', credentials.email);
                    navigate('/confirm', { state: {email: credentials.email}});
                }else{
                    setErrorHeader(res.data.response);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoader(false);
            });
            


    }

    const handleSubmit = async e => {
        e.preventDefault();
        let errors = {};
        let msgErrorHeader = '';
        if (!agree) {
            errors.agreeNull = 'Please agree to the Terms & Conditions';
        } else {
            delete errors.agreeNull;
        }
        if (password !== confirmPassword) {
            errors.passwordMatch = 'Passwords do not match';
        } else {
            delete errors.passwordMatch;
        }
        if (!email) {
            errors.emailEnter = 'Please enter your email';
        } else if (email && !emailValidation(email)) {
            errors.emailEnter = 'Please enter a valid email';
        } else if (email && email.match(emailRegex)) {
            errors.emailEnter = 'Please do not add special characters';
        } else {
            delete errors.emailEnter;
        }
        if (!username) {
            errors.usernameEnter = 'Please enter your username';
        } else if (username && username.length < 8) {
            errors.usernameEnter = 'Username must be at least 8 characters';
        } else {
            delete errors.usernameEnter;
        }


        if (!password) {
            errors.passwordEnter = 'Please enter your password';
        } else if (password && password.length < 8) {
            errors.passwordEnter = 'Invalid password';
        } else if (password && !password.match(passwordRegex)) {
            errors.passwordEnter = 'Invalid password';
        } else if (password && !password.match(/[0-9]/g)) {
            errors.passwordEnter = 'Invalid password';
        } else {
            delete errors.passwordEnter;
        }

        if (!confirmPassword) {
            errors.confirmPasswordEnter = 'Please enter your confirm password';
        } else if (confirmPassword && confirmPassword.length < 8) {
            errors.confirmPasswordEnter = 'Invalid confirmation password';
        } else if (confirmPassword && !confirmPassword.match(passwordRegex)) {
            errors.confirmPasswordEnter = 'Invalid confirmation password';
        } else if (confirmPassword && !confirmPassword.match(/[0-9]/g)) {
            errors.confirmPasswordEnter = 'Invalid confirmation password';
        } else {
            delete errors.confirmPasswordEnter;
        }

        // if (!reCaptcha) {
        //     errors.captchaInvalid = 'Invalid captcha';
        // } else {
        //     delete errors.captchaInvalid;
        // }

        if (password === confirmPassword && Object.keys(errors).length === 0 && (passwordError.errorMinimum === false || passwordError.errorSpecial === false || passwordError.errorNumber === false)) {
            try {
                let res = await registerUser({
                    username,
                    email,
                    password,
                    confirmPassword
                });

                if (res.data.result) {
                    navigate('/confirm');
                } else {
                    errors.emailEnter = res.data.error_message === 'Email is already in use' ? 'That email already exists' : res.data.error_message;
                    msgErrorHeader = 'An error occurred while registering your account. Kindly review the provided information for accuracy';
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            msgErrorHeader = 'Please check the errors marked in red and click the Sign Up button again after fixing them.';
        }
        console.log(username, email, password, confirmPassword);
        setErrorHeader(msgErrorHeader);
        setError(errors)
    }

    // const handleRecaptchaChange = (value) => {
    //     setReCaptcha(value);
    //     // You can use the reCAPTCHA value as needed in your form submission logic.
    // };

    const onChangeName = (e) => {
        setUsername(e.target.value);
        delete error.usernameEnter;
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        if (e.target.value && e.target.value.match(emailRegex))
            error.emailEnter = 'Please do not add special characters';
        else
            delete error.emailEnter;
    }

    const onChangePassword = (e) => {
        let passwordErrors = {};
        delete error.passwordEnter;
        delete error.passwordMatch;
        if (e.target.value.length < 8) {
            passwordErrors.errorMinimum = true;
        } else {
            passwordErrors.errorMinimum = false;
        }
        if (e.target.value && !e.target.value.match(passwordRegex)) {
            passwordErrors.errorSpecial = true;
        } else {
            passwordErrors.errorSpecial = false;
        }
        if (e.target.value && !e.target.value.match(/[0-9]/g)) {
            passwordErrors.errorNumber = true;
        } else {
            passwordErrors.errorNumber = false;
        }
        if (e.target.name === 'password') {
            setPassword(e.target.value);
        } else {
            setConfirmPassword(e.target.value);
        }
        setPasswordError(passwordErrors)
    }
     console.log('e',error)
    return (
        <>
            <Loader loading={loader}/>
            {/* <ResponsiveContainer> */}
            <ContainerRegister>
                <SignUpImage>
                    <img src={sign_banner} alt="form" width={'100%'} style={{height: '100%'}}/>
                </SignUpImage>
                <SignUpForm onSubmit={handleSubmit}>
                    <FormTitle>Sign Up</FormTitle>
                    {errorHeader &&
                        <FormErrorHeader>
                            <ErrorItems style={{ fontWeight: 'bold', fontSize: 12, gap: 5 }}>
                                <div style={{ width: 4, height: 30, backgroundColor: '#FF0000' }} />
                                <AiFillCloseCircle color='FF0000' size={28} />
                                <TextPoppins style={{ gap: 4, color: 'white' }}>ERROR</TextPoppins>
                                <FieldErrorHeaderStatus>{errorHeader}</FieldErrorHeaderStatus>
                            </ErrorItems>
                        </FormErrorHeader>}
                    <FormLabel>Username</FormLabel>
                    <FormControl onInvalid={error.usernameEnter}>
                        <FaUserAlt color='E61383' size={24} />
                        <InputUsername placeholder={" Insert your username (8 characters min)"} onChange={onChangeName}></InputUsername>
                    </FormControl>
                    {error.usernameEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.usernameEnter}</FieldErrorStatus></ErrorItems>}
                    <FormLabel>Email</FormLabel>
                    <FormControl onInvalid={error.emailEnter}>
                        <MdEmail color='E61383' size={24} />
                        <InputEmail placeholder={" Insert your email@example.com"} onChange={onChangeEmail}></InputEmail>
                    </FormControl>
                    {error.emailEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.emailEnter}</FieldErrorStatus></ErrorItems>}
                    <FormLabel>Password</FormLabel>
                    <FormControl onInvalid={error.passwordEnter}>
                        <BiLockAlt color='E61383' size={24} />
                        <InputPassword onChange={onChangePassword} name='password' type={showPassword ? 'text' : 'password'} placeholder={" Insert your password"} />
                        {!error.passwordEnter && passwordError.errorMinimum === false && passwordError.errorSpecial === false && passwordError.errorNumber === false && password &&
                            <PasswordDone position={password.length}><AiFillCheckCircle color='E61383' size={16} /></PasswordDone>}
                        {showPassword ? <AiOutlineEye color='E61383' size={24} onClick={() => setShowPassword(false)} /> : <AiOutlineEyeInvisible color='E61383' size={24} onClick={() => setShowPassword(true)} />}
                    </FormControl>
                    {error.passwordEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.passwordEnter}</FieldErrorStatus></ErrorItems>}
                    <FormLabel>Confirm Password</FormLabel>
                    <FormControl onInvalid={error.confirmPasswordEnter}>
                        <BiLockAlt color='E61383' size={24} />
                        <InputConfirmPassword onChange={onChangePassword} name='confirmPassword' type={showPassword ? 'text' : 'password'} placeholder={" Confirm your password"} />
                        {!error.confirmPasswordEnter && passwordError.errorMinimum === false && passwordError.errorSpecial === false && passwordError.errorNumber === false && (confirmPassword === password) &&
                            <PasswordDone position={confirmPassword.length}><AiFillCheckCircle color='E61383' size={16} /></PasswordDone>}
                        {showPassword ? <AiOutlineEye color='E61383' size={24} onClick={() => setShowPassword(false)} /> : <AiOutlineEyeInvisible color='E61383' size={24} onClick={() => setShowPassword(true)} />}
                    </FormControl>
                    {error.confirmPasswordEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.confirmPasswordEnter}</FieldErrorStatus></ErrorItems>}
                    {error.passwordMatch && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.passwordMatch}</FieldErrorStatus></ErrorItems>}
                    {((password && password.length > 0) || (confirmPassword && confirmPassword.length > 0)) &&
                        <FormPassWordError>
                            <PassWordErrorItems>
                                <AiOutlineCheck color={passwordError.errorMinimum === false ? 'E61383' : '626262'} size={18} />
                                <PasswordErrorStatus checked={passwordError.errorMinimum === false}>{"Minimum 8 characters"}</PasswordErrorStatus>
                            </PassWordErrorItems>
                            <PassWordErrorItems>
                                <AiOutlineCheck color={passwordError.errorSpecial === false ? 'E61383' : '626262'} size={18} />
                                <PasswordErrorStatus checked={passwordError.errorSpecial === false}>{"At least 1 special character"}</PasswordErrorStatus>
                            </PassWordErrorItems>
                            <PassWordErrorItems>
                                <AiOutlineCheck color={passwordError.errorNumber === false ? 'E61383' : '626262'} size={18} />
                                <PasswordErrorStatus checked={passwordError.errorNumber === false}>{"At least 1 number"}</PasswordErrorStatus>
                            </PassWordErrorItems>
                        </FormPassWordError>
                    }
                    <FormCheck>
                        {agree ? <FaRegCheckSquare color='E61383' size={18} onClick={() => setAgree(false)} />
                            : <FaRegSquare color={error.agreeNull ? 'FF0000' : 'E61383'} size={18} onClick={() => setAgree(true)} />}
                        <CheckLabel onClick={() => setAgree(!agree)}>I agree the <a href="https://spacemisfits.com/terms-of-use" target='_blank' rel="noreferrer">Terms of Service</a></CheckLabel>
                    </FormCheck>
                    {error.agreeNull && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.agreeNull}</FieldErrorStatus></ErrorItems>}
                    {/* <ReCAPTCHA
                        sitekey="6Lcgp5snAAAAANrcU8P0sxJdFoD9S3crbHbmjTgf"
                        onChange={handleRecaptchaChange}
                        style={{ width: '100%', marginTop: '5%', justifyContent: 'center', 'alignItems': 'center' }}
                    /> */}
                    {error.captchaInvalid && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.captchaInvalid}</FieldErrorStatus></ErrorItems>}
                    <BtnSignUp type="submit">
                        <TextButtons>Sign up</TextButtons>
                    </BtnSignUp>
                    <FormSignInLink>Have an account? <a href="/sign-in">Sign In</a></FormSignInLink>
                </SignUpForm>
            </ContainerRegister>
            {/* </ResponsiveContainer> */}
            {/* <ResponsiveAlert>
                <ContainerAlert>
                    <RowCore style={{ borderLeft: '5px solid #E71383', alignItems: 'center' }}>
                        <ImgIcon src={help_icon_pink} style={{ width: '15px', height: '15px', marginLeft: '3%' }} />
                        <TextPoppins style={{ fontSize: '11px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%' }}>
                            Fleet Alpha v1 is not optimized for mobile/tablet devices and it will not be supported until the next version following our roadmap, you can play Fleet using the desktop version. We apologize for any inconvenience caused.
                        </TextPoppins>
                    </RowCore>
                </ContainerAlert>
            </ResponsiveAlert > */}
        </>
    );
}