import '../containers/hooks/styles.css'
import GridSpecs from './GridSpecs';

import {
    CardContainerGeneral,
    CardContainerTitle,
    CardContainerImage,
    CardInfo,
    TextTitle,
    Text,
    TextBold,
    CardButton,
    ContainerImgProfile} from './ReusableCardElement';


//Shipyard - props = name, image, icon1, icon2, icon3, class, buildTime, action.
// props to share = description, forgeAmount.
const ReusableCardForge = (props) => {

    return (
        <>

<CardContainerGeneral>

        <CardContainerTitle><TextTitle>{props.name}</TextTitle></CardContainerTitle>
        
        <ContainerImgProfile>
            <CardContainerImage src={props.image}></CardContainerImage>
        </ContainerImgProfile>

        <CardInfo>
            <div style={{marginTop: '2%'}}>
                <GridSpecs specs={props.specs} spaceBetweenColumns={'10px'} columns={4} width={'200px'}/>
            </div>
            <Text>Class: <TextBold>{props.class}</TextBold></Text>
            <Text>Forge Time: <TextBold>{props.buildTime}</TextBold></Text>
        </CardInfo>

        <CardButton onClick={props.action}>
            <Text style={{fontWeight: '400'}}>Select</Text>
        </CardButton>

</CardContainerGeneral>

        </>
    )
};

export default ReusableCardForge;


