import React from "react";

import { useNavigate } from "react-router-dom";

import { convertTimestampMinutes } from "../../../../../../components/utils/utils";

import {
  ImgIcon,
  RowSpaceBetween,
} from "../../../../../../containers/CoreElement";
import {
  ContainerGeneral,
  ContainerOption,
  ContainerOptionRefresh,
  ContainerOptionShowAll,
  IconOption,
  NumberCoins,
  TextCoins,
  TextTitle,
  TextTitleShowAll,
  DivDownloadMayhem,
  ContainerOptionsCellphone,
  ContainerOptionsDesktop,
} from "../../MissionsElement";
import IconSvg from "../../../../../../hooks/IconSvg";

import info_icon from '../../../../../../assets/images/info_icon.svg'
import icon_difficulty_easy from '../../../../../../assets/images/icon_difficulty_easy.svg'
import icon_difficulty_medium from '../../../../../../assets/images/icon_difficulty_medium.svg'
import icon_difficulty_hard from '../../../../../../assets/images/icon_difficulty_hard.svg'
import mission_mining from '../../../../../../assets/images/mission_mining.png'
import icon_refresh from '../../../../../../assets/images/icon_refresh.svg'
import crown_coin from '../../../../../../assets/images/crown_coin.png'

import ReusableRectangle from "../../../../../../hooks/ReusableRectangle";
import banner_mayhem from "../../../../../../assets/images/banner_download_mayhem_v2.jpg";

export function Mining(props) {
  const navigate = useNavigate();

  const sendRoute = (mission) => {
    console.log(mission);
    navigate("/routes/setup/" + mission.id, { state: mission });
  };

  return (
    <>
      <ContainerOptionsDesktop>
        <RowSpaceBetween>
          {true ? (
            <>
              <ContainerOptionShowAll>
                <IconSvg icon={info_icon} color={"#ffffff"} />
                <TextTitleShowAll>Show All</TextTitleShowAll>
              </ContainerOptionShowAll>
              <ContainerOption>
                <IconOption src={icon_difficulty_easy} />
                <TextTitle>Easy</TextTitle>
              </ContainerOption>
              <ContainerOption>
                <IconOption src={icon_difficulty_medium} />
                <TextTitle>Medium</TextTitle>
              </ContainerOption>
              <ContainerOption>
                <IconOption src={icon_difficulty_hard} />
                <TextTitle>Hard</TextTitle>
              </ContainerOption>
            </>
          ) : null}
          <ContainerOptionRefresh onClick={props.fetchMissionsRefresh}>
            <IconOption src={icon_refresh} />
            <TextTitle>REFRESH</TextTitle>
            <TextCoins>
              <ImgIcon src={crown_coin} />
              <NumberCoins>{props.missionsRefreshValue}</NumberCoins>
            </TextCoins>
          </ContainerOptionRefresh>
        </RowSpaceBetween>
      </ContainerOptionsDesktop>
      <ContainerOptionsCellphone>
        <RowSpaceBetween>
          <ContainerOptionShowAll>
            <IconSvg icon={info_icon} color={"#ffffff"} />
            <TextTitleShowAll>Show All</TextTitleShowAll>
          </ContainerOptionShowAll>
          <ContainerOption>
            <IconOption src={icon_difficulty_easy} />
            <TextTitle>Easy</TextTitle>
          </ContainerOption>
        </RowSpaceBetween>
        <RowSpaceBetween>
          <ContainerOption>
            <IconOption src={icon_difficulty_medium} />
            <TextTitle>Medium</TextTitle>
          </ContainerOption>
          <ContainerOption>
            <IconOption src={icon_difficulty_hard} />
            <TextTitle>Hard</TextTitle>
          </ContainerOption>
        </RowSpaceBetween>
        <ContainerOptionRefresh onClick={props.fetchMissionsRefresh}>
          <IconOption src={icon_refresh} />
          <TextTitle>REFRESH</TextTitle>
          <TextCoins>
            <ImgIcon src={crown_coin} />
            <NumberCoins>{props.missionsRefreshValue}</NumberCoins>
          </TextCoins>
        </ContainerOptionRefresh>
      </ContainerOptionsCellphone>
      <ContainerGeneral>
        {props.missions.map((mission) => (
          <ReusableRectangle
            missions={true}
            image={mission_mining}
            outpost={mission.outpost}
            difficulty={"Easy"}
            type={mission.p_mission_economic.type_of_route_name}
            mission_id={mission.id}
            specs={mission.expected_reward}
            enemy_ships={mission.horde}
            min_spent={convertTimestampMinutes(mission.min_spent * 2)}
            value2={200}
            value1Second="value1"
            value2Second="value2"
            value3Second="value3"
            value4Second="value4"
            buttonText="Send Fleet"
            isSendFleet={false}
            action={() => sendRoute(mission)}
            height="auto%"
            margin="0px 3px 9px 0px"
          ></ReusableRectangle>
        ))}
        <a href="https://spacemisfits.com/download/" target="blank">
          <DivDownloadMayhem src={banner_mayhem}></DivDownloadMayhem>
        </a>
      </ContainerGeneral>
    </>
  );
}
