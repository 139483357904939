import React, { useCallback, useEffect, useState } from 'react'
import { ImgIcon, RowCore, RowSpaceBetween, TextPoppins } from '../../../../../../containers/CoreElement'
import IconSvg from '../../../../../../hooks/IconSvg'
import { BtnOptionExport, ContainerGrid, ContainerGridView, ContainerInfo, ContainerItems, ContainerOptions2, ContainerTable, ContainerTableMyExport, ContainerTextInfo, GridViewCoreItems, NumberCoins, TextCoins, TextCrown, TextTitiliumWebOptions2, TittleBarV1, TittleTextV2 } from '../../ExportImportElement'
import TableExport from '../../../../../../tables/TableExport/TableExport'
import { ColsExportImport } from '../../../../../../hooks/ColsExportImport'
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids'
import Pagination from '../../../../../../hooks/Pagination'
import help_icon_pink from '../../../../../../assets/images/info_icon_pink.svg'
import '../../../../../../components/Settings/hooks/styles.css'
import GenericCard from '../../../../../../hooks/GenericCard'
import PopUp from '../../../../../../hooks/PopUp'
import { HTTP } from '../../../../../http/http-common';
import { Loader } from '../../../../../../hooks/Loader'
import { useNavigate } from 'react-router-dom'
import { globalData, updateNavs } from '../../../../../utils/utils'
import { useMediaQuery } from "@mui/material"

export default function Export() {

    const xs = useMediaQuery('(max-width:600px)')
    const navigate = useNavigate();

    const [popupSyncWallet, setPopupSyncWallet] = useState(false);
    const [popupBuyCrown, setPopupBuyCrown] = useState(false)
    const [popUpExport, setPopUpExport] = useState(false);
    const [crownCost, setCrownCost] = useState(0);
    const [auxItems, setAuxItems] = useState([]);
    const [items, setItems] = useState([]);
    const [choiceShips, setChoiceShips] = useState([]);
    const [choiceCommanders, setChoiceCommanders] = useState([]);
    const [choiceMinerals, setChoiceMinerals] = useState([]);
    const [choiceItems, setChoiceItems] = useState([]);
    const [myExport, setMyExport] = useState([]);
    const [popupAlert, setPopupAlert] = useState(false);
    const [popupServerError, setPopupServerError] = useState(false);
    const [popupResponseReport, setPopupResponseReport] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');
    const [textResponseReport, setTextResponseReport] = useState('Success! </br></br>');
    // const [popupSuccess, setPopupSuccess] = useState(false);
    // const [textSuccess, setTextSuccess] = useState('Success!');

    const [loader, setLoader] = useState(false);

    const fetchItems = useCallback(() => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('items/to_export/', config)
            .then((res) => {
                let commanders = [];
                let ships = [];
                let minerals = [];
                let items = [];
                if (res.data.result){
                    console.log(res.data);
                    commanders = res.data.commanders;
                    ships = res.data.ships;
                    minerals = res.data.minerals;
                    items = res.data.items;
                    setAuxItems(commanders.concat(ships, minerals, items));
                    // console.log(auxItems);
                }else{
                    if(res.data.response.includes('sync your Altura Guard Wallet')){
                        setPopupSyncWallet(true);
                    }else{
                        setPopupServerError(true);
                        setTextServerError(res.data.response);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setPopupServerError(true);
                setTextServerError(err.response.statusText);
            })
            .finally(() => {
                setLoader(false);
            })

    }, []);

    function removeObjectsWithId(array, item, countToRemove){
        const updatedArray = [...array];
        console.log(updatedArray);
        if(item.hasOwnProperty('commander') || item.hasOwnProperty('type') || item.hasOwnProperty('item_type')){
            let removedCount = 0;
            const copyArray = [...updatedArray];
            for (let i = 0; i < copyArray.length; i++) {
                let id = copyArray[i].hasOwnProperty('commander') ? copyArray[i].commander.id : copyArray[i].id;
                let idToRemove = item.hasOwnProperty('commander') ? item.commander.id : item.id; 
                if (id === idToRemove) {
                    updatedArray.splice(updatedArray.indexOf(copyArray[i]), 1);
                    removedCount++; 
                    if(removedCount === countToRemove){
                        break
                    }
                }
            }
        }else{
            for (let i = updatedArray.length - 1; i >= 0; i--) {
                let id = updatedArray[i].name;
                let idToRemove = item.name; 
                if (id === idToRemove) {
                    updatedArray[i].value = updatedArray[i].value - countToRemove;
                }
            }
        }
        return updatedArray;
    }

    function addObjects(array, item, countToAdd){
        const updatedArray = [...array];

        if(!item[0].hasOwnProperty('specs') && !item[0].hasOwnProperty('item_type')){
            for(let i = 0; i < updatedArray.length; i++){
                let id = updatedArray[i].name;
                let idToAdd = item[0].name;
                if(id === idToAdd){
                    console.log(updatedArray[i]);
                    updatedArray[i].value = updatedArray[i].value + countToAdd;
                }
            }


        }else{
            for(let i = 0; i < countToAdd; i++){
                console.log(item[i]);
                updatedArray.push(item[i]);
            }
        }
        return updatedArray;
    }
    
    const addElement = (item, amount) => {

        
        console.log(item[0]);
        if(amount){
            const targeArray = [];
    
            if((item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type')) && (item.length === 1 || item[0].units === 1)){
                amount = 1;
            }
            
        
            if (!item[0].hasOwnProperty('specs') && !item[0].hasOwnProperty('item_type')) {
                console.log('ENrta');
                let materialItem = { ...item[0] };
                amount = amount * 1000;
                materialItem.value = amount / 1000;
            
                let updated = false;
                myExport.map((exportArray) => {
                    return exportArray.map((exportItem) => {
                        if (exportItem.name === materialItem.name) {
                            exportItem.value += materialItem.value;
                            updated = true;
                        }
                        return exportItem;
                    });
                });
            
                if (!updated) {
                    targeArray.push(materialItem);
                }

                if (targeArray.length > 0) {
                    setMyExport((prevState) => [...prevState, targeArray]);
                }

            } else {
                console.log('ENrta 2');
                for (let i = 0; i < item.length && i < amount; i++) {
                    const itemId = item[i].hasOwnProperty('commander') ? item[i].commander.id : item[i].id;
                    let added = false;
                
                    for (let j = 0; j < myExport.length; j++) {
                      if (myExport[j].some((obj) => (obj.hasOwnProperty('commander') ? obj.commander.id : obj.id) === itemId)) {
                        myExport[j].push(item[i]);
                        added = true;
                        break;
                      }
                    }
                
                    if (!added) {
                      targeArray.push(item[i]);
    
                    }
                }

                if (targeArray.length > 0) {
                    setMyExport((prevState) => [...prevState, targeArray]);
                }

            }
            
            console.log(myExport);
            setAuxItems(removeObjectsWithId(auxItems, item[0], amount));
            


        }else{
            setPopupAlert(true);
        }
    }


    //Quitar de tabla
    const removeElement = (item, index, amount) => {

        if(amount){
            console.log(item);
            setAuxItems(addObjects(auxItems, item, amount));

            const targeArray = [...myExport];
            console.log(targeArray);

            if(!item[0].hasOwnProperty('specs') && !item[0].hasOwnProperty('item_type')){

                targeArray[index][0].value = targeArray[index][0].value - amount;
                
                if(targeArray[index][0].value === 0){
                    targeArray.splice(index, 1);
                }

            }else{
                console.log('Entra');
                targeArray[index].splice(0, amount);

                if(targeArray[index].length === 0){
                    targeArray.splice(index, 1);
                }
            }
            console.log(targeArray);
            setMyExport(targeArray);
        }else{
            setPopupAlert(true)
        }
        
    } 
    const onClickExportShips = async () => {

        if(choiceShips.length > 0){
            console.log('ejecuta ships');
            setPopUpExport(false);
            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
                data: {"choice_ships": choiceShips},
                method: "POST"
            };
            await HTTP.request('altura/export/items/ships/', config)
            .then((res) => {
                if(res.data.result && res.data.hasOwnProperty('response')){
                    console.log('entra');
                    let textReport = `${res.data.response}</br>`;
                    setTextResponseReport(prevText => prevText + textReport)
                    // setPopupSuccess(true);
                    // setTextSuccess(textReport);
                }
                if(!res.data.result && res.data.hasOwnProperty('response')){
                    let textReport = `ERROR: ${res.data.response}</br>`;
                    setTextResponseReport(prevText => prevText + textReport)
                    // setPopupServerError(true);
                    // setTextServerError(textReport);
                }
            })
            .catch((err) => {
                console.log(err);
                setPopupServerError(true);
                setTextServerError(err.response.statusText);
            })
        }
    }

    const onClickExportCommanders = async () => {

        if(choiceCommanders.length > 0){

            console.log('ejecuta commanders');
            setPopUpExport(false);
            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
                data: {"choice_commanders": choiceCommanders},
                method: "POST"
            };
            await HTTP.request('altura/export/items/commanders/', config)
            .then((res) => {
                if(res.data.result && res.data.hasOwnProperty('response')){
                    console.log('entra');
                    let textReport = `${res.data.response}</br>`;
                    // setPopupSuccess(true);
                    // setTextSuccess(textReport);
                    setTextResponseReport(prevText => prevText + textReport)
                }
                if(!res.data.result && res.data.hasOwnProperty('response')){
                    let textReport = `ERROR: ${res.data.response}</br>`;
                    // setPopupServerError(true);
                    // setTextServerError(textReport);
                    setTextResponseReport(prevText => prevText + textReport)
                }
            })
            .catch((err) => {
                console.log(err);
                setPopupServerError(true);
                setTextServerError(err.response.statusText);
            })
        }
        
    }

    const onClickExportMinerals = async () => {
        if(Object.keys(choiceMinerals).length > 0){
            console.log('ejecuta minerals');
            setPopUpExport(false);
            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
                data: {"choice_minerals": choiceMinerals},
                method: "POST"
            };
            await HTTP.request('altura/export/items/minerals/', config)
            .then((res) => {
                if(res.data.result && res.data.hasOwnProperty('response')){
                    console.log('entra');
                    let textReport = `${res.data.response}</br>`;
                    // setPopupSuccess(true);
                    // setTextSuccess(textReport);
                    setTextResponseReport(prevText => prevText + textReport)
                }
                if(!res.data.result && res.data.hasOwnProperty('response')){
                    let textReport = `ERROR: ${res.data.response}</br>`;
                    // setPopupServerError(true);
                    // setTextServerError(textReport);
                    setTextResponseReport(prevText => prevText + textReport)
                }
            })
            .catch((err) => {
                console.log(err);
                setPopupServerError(true);
                setTextServerError(err.response.statusText);
            })
        }
        
    }

    const onClickExportItems = async () => {

        if(choiceItems.length > 0){
            console.log('ejecuta Items');
            setPopUpExport(false);
            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
                data: {"choice_items": choiceItems},
                method: "POST"
            };
            await HTTP.request('altura/export/items/items/', config)
            .then((res) => {
                if(res.data.result && res.data.hasOwnProperty('response')){
                    console.log('entra');
                    let textReport = `${res.data.response}</br>`;
                    setTextResponseReport(prevText => prevText + textReport)
                    // setPopupSuccess(true);
                    // setTextSuccess(textReport);
                }
                if(!res.data.result && res.data.hasOwnProperty('response')){
                    let textReport = `ERROR: ${res.data.response}</br>`;
                    setTextResponseReport(prevText => prevText + textReport)
                    // setPopupServerError(true);
                    // setTextServerError(textReport);
                }
            })
            .catch((err) => {
                console.log(err);
                setPopupServerError(true);
                setTextServerError(err.response.statusText);
            })
        }
    }


    const onClickExport = () => {
        setPopUpExport(false);
        setLoader(true);

        onClickExportItems().then(() => {
            console.log("pass0000");
            onClickExportShips().then(() => {
                console.log("pass0001");
                onClickExportCommanders().then(() => {
                    console.log("pass0002");
                    onClickExportMinerals().then(() => {
                        console.log("pass0003");
                        setLoader(false);
                        if(textResponseReport !== ''){
                            setPopupResponseReport(true);
                        }
                    });
                });
            });
        });
    }



    useEffect(() => {
        fetchItems();

    }, [fetchItems])

    useEffect(() => {
        setItems(auxItems);
        console.log(auxItems);
        let auxChoiceShips = []
        let auxChoiceCommanders = []
        let auxChoiceMinerals = {}
        let auxChoiceItems = []
        console.log(myExport);
    
        myExport.forEach((item) => {
            if(item[0].hasOwnProperty('specs')){
                if (item[0].type === 'ship') {
                    item.forEach((ship) => {
                        auxChoiceShips.push(ship.id_ship_x_user);
                    });
                } else if (item[0].specs.includes('is_commander')) {
                    item.forEach((commander) => {
                        auxChoiceCommanders.push(commander.id);
                    });
                }
            }else if(item[0].hasOwnProperty('item_type')){
                item.forEach((itemExport) => {
                    auxChoiceItems.push(itemExport.id);
                });
            }else{
                auxChoiceMinerals[item[0].name] = item[0].value;
            }
        });
    
        setChoiceShips(auxChoiceShips);
        setChoiceCommanders(auxChoiceCommanders);
        setChoiceMinerals(auxChoiceMinerals);
        setChoiceItems(auxChoiceItems)
    }, [auxItems, myExport]);

    const itemsTotalCost = async () => {

        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"choice_minerals": choiceMinerals, "choice_commanders": choiceCommanders, "choice_ships": choiceShips},
            method: "POST"
        };
        await HTTP.request('altura/export/items/total/cost/', config)
        .then((res) => {
            if(res.data.result){
                setCrownCost(res.data.response.total_cost)
                setPopUpExport(true);
            }else{
                setPopupServerError(true);
                setTextServerError(res.data.response);
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
    }

    const exportItemsPre = () => {
        updateNavs().then(() => {
            if(globalData.crownsAvailable > 0){
                console.log(myExport);
                setLoader(true);
                itemsTotalCost().then(() => {
                    setLoader(false);
                });
            }else{
                setPopupBuyCrown(true)
            }
        })
    }

    console.log(items)
  return (
    <>
    <Loader loading={loader}/>
    <ContainerOptions2>
        <RowSpaceBetween>
            <BtnOptionExport onClick={() => exportItemsPre()}>
                <TextCoins>
                    <IconSvg icon={'/icons/icon_type_export-import.svg'} width={'20px'} color={'#FFFFFF'}/>
                    <TextTitiliumWebOptions2>EXPORT</TextTitiliumWebOptions2>
                </TextCoins>
                <TextCoins>
                    <ImgIcon src={'/icons/icon_new_crown.png'}/>
                    <NumberCoins>{parseInt(crownCost, 10)}</NumberCoins>
                </TextCoins>
            </BtnOptionExport>
        </RowSpaceBetween>
    </ContainerOptions2>
    <ContainerTable>
        <ContainerTableMyExport>
            <TableExport data={myExport} cols={ColsExportImport()} onClickRemove={removeElement} setCrownCost={setCrownCost}/>
        </ContainerTableMyExport>
    </ContainerTable>
    <ContainerItems>
        <ContainerGridView>
            <TittleBarV1>
                <TittleTextV2>  
                    Add the items you want to export
                </TittleTextV2>
            </TittleBarV1>
            <FilterForGrids allElements={items} exportItems sortByType>
                { elementsToFilter => (
                    <ContainerGrid>
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not crew commanders">
                            { elementsToRender => (
                            <GridViewCoreItems>
                                    { elementsToRender.map((item, index) => (
                                        <>
                                        <GenericCard item={item} setItems={setItems} onClickAdd={addElement}/>
                                        </>
                                    ))
                                    }
                            </GridViewCoreItems>
                            )
                            }
                        </Pagination>
                    </ContainerGrid>
                )
                }
            </FilterForGrids>
        </ContainerGridView>
    </ContainerItems>

    <PopUp show={popupAlert} onClose={() => setPopupAlert(false)} onlyConfirm>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            Enter a numeric value
        </TextPoppins>
    </PopUp>

    <PopUp show={popUpExport} onClose={() => setPopUpExport(false)} onConfirm={onClickExport}>
        <TextPoppins style={{letterSpacing: '0', lineHeight: '25px', fontSize: xs ? '12px' : ''}}>
        Are you sure that you want to <br/>
        export everything to your account for:
        </TextPoppins>
        <TextCoins>
            <ImgIcon src='/icons/icon_new_crown.png'/>
            <TextCrown style={{fontSize: xs ? '14px' : ''}}>CROWN:</TextCrown>
            <NumberCoins style={{fontSize: xs ? '13px' : ''}}>{crownCost}</NumberCoins>
        </TextCoins>
        <ContainerInfo style={{width: xs ? '90%' : ''}}>
        <RowCore style={{borderLeft: '5px solid #E71383', alignItems: 'center'}}>
            <ImgIcon src={help_icon_pink} style={{width: '15px', height: '15px', marginLeft: '3%'}}/>
            <ContainerTextInfo>
            <TextPoppins style={{fontSize: '10px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%'}}>
                Exported items will be added (minted) to your linked wallet
            </TextPoppins>
            </ContainerTextInfo>
        </RowCore>
        </ContainerInfo>
    </PopUp>

    {/* <PopUp show={popupSuccess} onClose={() => setPopupSuccess(false)} onConfirm={() => window.location.reload()}>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}} dangerouslySetInnerHTML={{ __html: textSuccess }}>
        </TextPoppins>
    </PopUp> */}

    <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onConfirm={() => setPopupServerError(false)}>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}} dangerouslySetInnerHTML={{ __html: textServerError }}>
        </TextPoppins>
    </PopUp>

    <PopUp onlyConfirm={true} show={popupResponseReport} onClose={() => window.location.reload()} onConfirm={() => window.location.reload()}>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}} dangerouslySetInnerHTML={{ __html: textResponseReport }}>
        </TextPoppins>
    </PopUp>

    <PopUp show={popupSyncWallet} onClose={() => setPopupSyncWallet(false)} onConfirm={() => setPopupSyncWallet(false)}>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            Please, link your wallet in your <span style={{color: '#13CBE7', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/settings')}>account settings</span> using <br/>
            Altura Guard code, you can also check the guides <a style={{color: '#13CBE7', textDecoration: 'underline', cursor: 'pointer'}} target='_blank' rel='noreferrer' href='https://space-misfits.gitbook.io/space-misfits-fleet-guides/fleet-guides/wallet-linking'>here</a>.
        </TextPoppins>
    </PopUp>

    <PopUp show={popupBuyCrown} onClose={() => setPopupBuyCrown(false)} onConfirm={() => window.open('https://spacemisfits.com/signin/', '_blank')} textBtnNo="Dismiss" textBtnYes="Buy CROWN">
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            Oops, it seems like you're short on CROWN. <br/>
            No worries! You can easily purchase more to unlock this cool feature.
        </TextPoppins>
    </PopUp>
    </>
  )
}
