import React, { useState, useEffect } from "react";
import { HTTP } from '../../../../../../http/http-common';
import { useNavigate } from 'react-router-dom';

import icon_medical from '../../../../../../../assets/images/icon_medical.svg'
import icon_heal_all from '../../../../../../../assets/images/icon_heal_all.svg'
import icon_stats from '../../../../../../../assets/images/icon_stats.svg'
import icon_commander_damage from '../../../../../../../assets/images/icon_commander_damage.svg';

import { ContainerPolygon, PolygonLeft, PolygonRight, RowCore, RowSpaceBetween, TextPoppins } from "../../../../../../../containers/CoreElement";
import { BtnCancelMedBayCard, BtnCancelTextMedBayCard, BtnInstantHealCoinsCard, BtnOptionInstantHealAll, CardCommander, ColStats, ContainerCommanders, ContainerGrid, ContainerImgProfile, ContainerOptions2, ContainerStatsProfile, ContainerTextIconButton, DamagedCard, GridViewCoreCommanders, ImgCoin, ImgIconCancel, ImgIconMedical2, ImgInfoProfile, ImgRepair, NumberCoins, RowCoreBonus, RowRevive, StatsBold, TextCoins, TextPoppinsInfo, TextPoppinsInfoBold, TextRevive, TextTextTitiliumWebInfo, TextTitiliumWebOptions2, TextTitleCard, TitleCardCommander, TitleStatsCard } from "../../../CommanderElement";
import { FilterForGrids } from "../../../../../../../hooks/FilterForGrids";
import GridSpecs from "../../../../../../../hooks/GridSpecs";
import { DropdownStats } from "../../../../../../../hooks/DropdownStats";
import HealingBar from "../../../../../../../hooks/HealingBar";

import { convertProgressToPercentege, globalData, updateNavs } from '../../../../../../../components/utils/utils';
import Pagination from "../../../../../../../hooks/Pagination";
import PopUp from "../../../../../../../hooks/PopUp";
import { Loader } from "../../../../../../../hooks/Loader";

export function Healing(props){

    const [popupBuyCrown, setPopupBuyCrown] = useState(false)
    const [commanders, setCommanders] = useState([]);
    const [commanderId, setCommanderId] = useState(-1);
    const [count, setCount] = useState(0);
    const [valueInstantHealAll, setValueInstantHealAll] = useState(0);

    const [loader, setLoader] = useState(false);

    const [popUpInstantHealAll, setPopUpInstantHealAll] = useState(false);
    const [popUpInstantHeal, setPopUpInstantHeal] = useState(false);
    const [popUpCancelHeal, setPopUpCancelHeal] = useState(false);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');

    let navigate = useNavigate();
    useEffect(() => {
        updateNavs();
        fetchCommandersHealAllValuesProcess();
        fetchCommandersHealing();
    }, [])

    useEffect(() => {
        props.setActiveRoute(props.activeRoute)
        setInterval(() => {
            setCount(prevCount => prevCount + 0.5);
        }, 1000);
    }, [props])
    

    const instantHealCommander = (healCode) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"heal_code": healCode},
            method: "POST"
        };
        HTTP.request('commander/intant/heal/process/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/commanders/med-bay/pick-up')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    
    }

    const instantAllHealCommander = () => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            method: "POST"
        };
        HTTP.request('commander/intant/all/heal/process/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/commanders/med-bay/pick-up')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    
    }

    const fetchCommandersHealing= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('commander/heal/process/', config)
          .then((res) => {
            if (res.data.result){
                setCommanders(res.data.heals)
            }
          })
          .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
          })
          .finally(() => {
            setLoader(false);
          })
    
    };

    const fetchCommandersHealAllValuesProcess= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('commander/heal/all/values/process/', config)
          .then((res) => {
            if (res.data.result){
                setValueInstantHealAll(res.data.values["cost_heal_crowns"]);
            }
          })
          .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
          })
          .finally(() => {
            setLoader(false);
          })
    
    };

    const cancelHeal = (healCode) => {
        setLoader(true);
        console.log(healCode)
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"heal_code": healCode},
            method: "POST"
        };
        HTTP.request('commander/heal/cancel/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/commanders/med-bay/injured')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    
    }

    const handleInstantHealAll = () => {
        updateNavs().then(() => {
            if(globalData.crownsAvailable > 0){
                setPopUpInstantHealAll(true)
            }else{
                setPopupBuyCrown(true)
            }
        })
    }

    const handleInstantHeal = (commander) => {
        updateNavs().then(() => {
            if(globalData.crownsAvailable > 0){
                setCommanderId(commander);
                setPopUpInstantHeal(true);
            }else{
                setPopupBuyCrown(true)
            }
        })
    }   

    const handleCancelHeal = (commander) => {
        setCommanderId(commander);
        setPopUpCancelHeal(true);
    }


    return(
        <>
        <Loader loading={loader}/>
        {valueInstantHealAll > 0 ?
            <ContainerOptions2 onClick={() => handleInstantHealAll()}>
                <RowSpaceBetween>
                    <BtnOptionInstantHealAll>
                    <ImgIconMedical2 src={icon_heal_all}/>
                    <TextTitiliumWebOptions2>INSTANT HEAL ALL</TextTitiliumWebOptions2>
                    <TextCoins>
                        <ImgCoin src={'/icons/icon_type_crown_coin_grey.png'} />
                        <NumberCoins>{valueInstantHealAll}</NumberCoins>
                    </TextCoins>
                    </BtnOptionInstantHealAll>
                </RowSpaceBetween>
            </ContainerOptions2>
            :
            null
        }

        <ContainerCommanders style={{height: commanders.length !== 0 ? '690px' : '180px'}}>
            <FilterForGrids allElements={commanders} commanders sortByClass>
                { elementsToFilter => (
                    <ContainerGrid>
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not commanders in heals">
                            { elementsToRender => (
                                <GridViewCoreCommanders>
                                    {elementsToRender.map((com) => (
                                    <CardCommander className='card-commander' key={com.id} >
                                        <TitleCardCommander>
                                        <TextTitleCard>
                                            {com.commanderxuser.commander.name}
                                        </TextTitleCard>
                                        </TitleCardCommander>
                                        <ContainerImgProfile>
                                        { com.commanderxuser.is_destroyed ?
                                            <DamagedCard>
                                                <ImgRepair src={icon_commander_damage}/>
                                            </DamagedCard>
                                            :
                                            null
                                        }
                                        <ImgInfoProfile src={com.commanderxuser.commander.image}/>
                                        <ContainerPolygon>
                                            <PolygonLeft>
                                                <TextPoppinsInfo>FP:<TextPoppinsInfoBold>+{com.commanderxuser.commander.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                                            </PolygonLeft>
                                        </ContainerPolygon>
                                        <ContainerPolygon style={{right: '0'}}>
                                            <PolygonRight>
                                                <TextTextTitiliumWebInfo>Lvl {com.commanderxuser.commander.level}</TextTextTitiliumWebInfo>
                                            </PolygonRight>
                                        </ContainerPolygon>
                                        </ContainerImgProfile>
                                        <ContainerStatsProfile>
                                        <GridSpecs specs={com.specs} spaceBetweenColumns={'7px'} marginTop={'2%'}/>
                                        <ColStats>
                                            <RowCore style={{marginTop:"3px", marginBottom:"9px"}}>
                                            <TitleStatsCard>Class: <StatsBold>{com.commanderxuser.commander.class}</StatsBold></TitleStatsCard>
                                            </RowCore>
                                            <RowCoreBonus>
                                            <TitleStatsCard>Bonus: <StatsBold>{com.commanderxuser.commander.bonus.name}</StatsBold></TitleStatsCard>
                                            </RowCoreBonus>
                                        </ColStats>

                                        </ContainerStatsProfile>
                                        <DropdownStats textButton='Stats' iconButton={icon_stats} jsonObj={com.commanderxuser.commander.bonus}/>

                                        <HealingBar percent={convertProgressToPercentege(com, count)}/>
                                        <BtnInstantHealCoinsCard onClick={() => handleInstantHeal(com.id)}>
                                            <ContainerTextIconButton>
                                            <RowRevive>
                                                <ImgIconMedical2 src={icon_medical}/>
                                                <TextRevive>
                                                INSTANT HEAL
                                                </TextRevive>
                                                <TextCoins>
                                                <ImgCoin src={'/icons/icon_type_crown_coin_grey.png'} />
                                                <NumberCoins>{com.commanderxuser.commander.cost_heal_crowns}</NumberCoins>
                                                </TextCoins>
                                            </RowRevive>
                                            </ContainerTextIconButton>
                                        </BtnInstantHealCoinsCard>
                                        <BtnCancelMedBayCard onClick={() => handleCancelHeal(com.id)}>
                                            <ImgIconCancel src={'/icons/icon_btn_cancel.svg'}/>
                                            <BtnCancelTextMedBayCard>
                                            Cancel
                                            </BtnCancelTextMedBayCard>
                                        </BtnCancelMedBayCard>
                                    </CardCommander>
                                    ))}
                                </GridViewCoreCommanders>
                            )

                            }
                        </Pagination>
                    </ContainerGrid>
                )
                }
            </FilterForGrids>
        </ContainerCommanders>

        <PopUp show={popUpInstantHealAll} onClose={() => setPopUpInstantHealAll(false)} onConfirm={() => instantAllHealCommander()}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to heal instant all?
            </TextPoppins>
        </PopUp>

        <PopUp show={popUpInstantHeal} onClose={() => setPopUpInstantHeal(false)} onConfirm={() => instantHealCommander(commanderId)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to heal instant?
            </TextPoppins>
        </PopUp>

        <PopUp show={popUpCancelHeal} onClose={() => setPopUpCancelHeal(false)} onConfirm={() => cancelHeal(commanderId)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to cancel heal?
            </TextPoppins>
        </PopUp>

        <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
            <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            {textServerError}
            </TextPoppins>
        </PopUp>

        <PopUp show={popupBuyCrown} onClose={() => setPopupBuyCrown(false)} onConfirm={() => window.open('https://spacemisfits.com/signin/', '_blank')} textBtnNo="Dismiss" textBtnYes="Buy CROWN">
            <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                Oops, it seems like you're short on CROWN. <br/>
                No worries! You can easily purchase more to unlock this cool feature.
            </TextPoppins>
        </PopUp>
        </>
    );
}