import React, { useState } from 'react'
import { BottomNavAction, BottomNavActionAnimation, FabCustom, PaperBottomNav } from './BottomNavElement';
import { BottomNavigation, ThemeProvider } from '@mui/material';
import IconSvg from '../../hooks/IconSvg';
import commanders_icon from '../../assets/images/icon_commanders.svg'
import dashboard_icon from '../../assets/images/dashboard_icon.svg'
import facilities_icon from '../../assets/images/facilities_icon.svg'
import icon_message from '../../assets/images/icon_messages.svg'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import theme from '../../theme/theme';
import { Link, useLocation } from 'react-router-dom';

export default function BottomNav() {

  const location = useLocation();

    const [value, setValue] = useState(null)

    const [open, setOpen] = useState(false)
    
    const isActive = (path) => {
      return location.pathname.includes(path) ? '#E71383' : 'white'
    }

  return (
    <>
    <ThemeProvider theme={theme}>
      <PaperBottomNav elevation={3}>
        <FabCustom aria-label='expand' size='small' color='primary' onClick={() => setOpen(!open)}> 
          { open ?
            <ExpandMore sx={{color: 'black'}}/>
            :
            <ExpandLess sx={{color: 'black'}}/>
          }
        </FabCustom>
        <BottomNavigation
          sx={{background: 'rgb(65, 6, 37)', borderTop: '1px solid #E71383'}}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavAction label="Dashboard" icon={<IconSvg icon={dashboard_icon} color={isActive('dashboard')}/>} sx={{color: `${isActive('dashboard')} !important`}} component={Link} to="/dashboard"/>
          <BottomNavAction label="Commanders" icon={<IconSvg icon={commanders_icon} color={isActive('commanders')}/>} sx={{color: `${isActive('commanders')} !important`}} component={Link} to="/commanders/crew"/>
          <BottomNavAction label="Missions" icon={<IconSvg icon={'/icons/icon_type_header_routes.svg'} width={'18px'} color={isActive('routes/')}/>} sx={{color: `${isActive('routes/')} !important`}} component={Link} to="/routes/expeditions"/>
          <BottomNavAction label="Messages" icon={<IconSvg icon={icon_message} color={isActive('messages')}/>} sx={{color: `${isActive('messages')} !important`}} component={Link} to="/messages/all"/>
        </BottomNavigation>
        { open ?
            <>
            <BottomNavigation
              sx={{background: 'rgb(65, 6, 37)'}}
              showLabels
              value={value}
              onChange={(event, newValue) => {
                  setValue(newValue);
              }}
            >
              <BottomNavAction label="Shipyard" icon={<IconSvg icon={'/icons/icon_shipyard.svg'} color={isActive('shipyard')}/>} sx={{color: `${isActive('shipyard')} !important`}} component={Link} to="/shipyard/hangar"/>
              <BottomNavActionAnimation label="Facilities" icon={<IconSvg icon={facilities_icon} color={isActive('facilities')}/>} sx={{color: `${isActive('facilities')} !important`}} component={Link} to="/facilities"/>
              <BottomNavActionAnimation label="Leaderboard" icon={<IconSvg icon={'/icons/icon_leaderboard.svg'} color={isActive('leaderboard')}/>} sx={{color: `${isActive('leaderboard')} !important`}} component={Link} to="/leaderboard/current-week"/>
              <BottomNavActionAnimation label="Player Market" icon={<IconSvg icon={'/icons/icon_type_player_market.svg'} width={'18px'} color={'white'}/>} sx={{color: `white !important`}} component={Link} to="https://marketplace.spacemisfits.com/"/>
      
            </BottomNavigation>
            <BottomNavigation
              sx={{background: 'rgb(65, 6, 37)'}}
              showLabels
              value={value}
              onChange={(event, newValue) => {
                  setValue(newValue);
              }}
            >
              <BottomNavActionAnimation label="NFT Gateway" icon={<IconSvg icon={'/icons/icon_type_nft_gateway.svg'} color={isActive('altura')}/>} sx={{color: `${isActive('altura')} !important`}} component={Link} to="/altura/export"/>
              <BottomNavActionAnimation label="CROWN Shop" icon={<IconSvg icon={'/icons/icon_type_crown_shop.svg'} color={'white'}/>} sx={{color: `white !important`}} component={Link} to="https://spacemisfits.com/user/crown/"/>
              <BottomNavActionAnimation label="Pledge Pool" icon={<IconSvg icon={'/icons/icon_type_pledge_pool.svg'} color={'white'}/>} sx={{color: `white !important`}} component={Link} to="https://stake.spacemisfits.com/pledge"/>
            </BottomNavigation>
            </>
   
          :
          null
        }
      </PaperBottomNav>
    </ThemeProvider>
    </>
  )
}
