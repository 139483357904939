//Librerias INIT
import React from 'react';

//Elements
  
import { 
    RouteMessageContainerGeneral,
    RouteMessageElement,
    RouteMessageElementData,
    RouteMessageContainerGap,
    MessageContainerGeneral,
    MessageContainerData,
    MessageContainerRow,
    MessageContainerRow1,
    MessageIcon,
    MessageText,
    MessageText1,
    ContainerNewTag,
    ContainerNewTag1,
    Trapecio,
    TextTagNew
} from "../MessagesElement"


export function SystemMessage(props) {

    const toDateUTC = (date) => {
        const dateMsg = new Date(date);

        const dateFormat = dateMsg.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const hourFormat = dateMsg.toLocaleTimeString('en-US', { hour12: false , hour: '2-digit', minute:'2-digit'});        
        const dateHourUTC = `${dateFormat} ${hourFormat} UTC`;

        return dateHourUTC;
    }

    return (
        <>

                <RouteMessageContainerGeneral>
                    <RouteMessageContainerGap>


                            <RouteMessageElement style={{ padding:"0px", border:"0px", background:"none", display:"block"}}>
                                    { !props.isOpen  ?
                                        <ContainerNewTag1>
                                            <Trapecio/>
                                            <TextTagNew>
                                                NEW
                                            </TextTagNew>
                                        </ContainerNewTag1>
                                        : 
                                        <ContainerNewTag/>
                                    }
                                
                                <RouteMessageElementData style={{ padding:"0px" }}>

                                    <MessageContainerGeneral style={{background:"rgba(76, 10, 44, 0.737)"}}>
                                        <MessageContainerData style={{padding:"0px", width:"100%",  border:"0px"}}>
                                            <MessageContainerRow>
                                                <div style={{display:"flex", width:"100%"}}>
                                                    <MessageContainerRow1 style={{width:"49%", }}>
                                                        <div style={{display:"flex", alignItems:"center", gap:"9px"}}>{ props.isRoute === false ? <div style={{display:"flex" , gap:"9px"}}>  <MessageIcon src='/icons/icon_type_system_message.svg'></MessageIcon><MessageText>System Message</MessageText> </div> : <div style={{display:"flex", gap:"9px"}}><MessageIcon src='/icons/icon_type_expedition_completion.svg'></MessageIcon><MessageText>Expedition completion</MessageText></div> }</div>
                                                        <div style={{display:"flex", alignItems:"center", gap:"9px"}}><MessageIcon src='/icons/icon_type_calendar_missions.svg'></MessageIcon><MessageText> {toDateUTC(props.date)}</MessageText></div>
                                                    </MessageContainerRow1>
                                                </div>
                                                <div style={{justifyContent:"flexEnd",display:"flex", alignItems:"center", gap:"9px", marginRight:"6px"}}><MessageIcon src='/icons/icon_type_white_star_messages.svg'></MessageIcon><MessageIcon src='/icons/icon_type_message_messages.svg'></MessageIcon></div>
                                            </MessageContainerRow>
                                            <div style={{background:'#3C0009', padding:"6px", borderRadius:"5px"}}>
                                                <MessageContainerRow style={{width:"100%"}}>
                                                    <div style={{display:"flex", width:"100%"}}>
                                                    <MessageText1>{props.textPurple}</MessageText1> 
                                                    </div>
                                                </MessageContainerRow>
                                                <MessageContainerRow ><MessageText>{props.text}</MessageText></MessageContainerRow>
                                            </div>
                                        </MessageContainerData>
                                    </MessageContainerGeneral>  

                                </RouteMessageElementData>

                            </RouteMessageElement>

                    </RouteMessageContainerGap>
                </RouteMessageContainerGeneral>

        </>
    )
  }