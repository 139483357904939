import styled from "styled-components";
import { ColCore, ContainerBasic, ContainerBasicBG, ContainerInput, ContainerVoid, GridViewCore, ImgIcon, RowCore, RowSpaceBetween, TextPoppins, TextPoppinsBold, TextTitiliumWeb } from "../../../../containers/CoreElement";

export const BtnSelect = styled(ContainerBasic)`
    background: #E71383;
`


export const ContainerCommanders = styled.div`
    margin-top: 1.5%;
    padding: 4px;
    width: 100%;
    height: 610px;
    border: 1px solid #E71383;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2F041A;

    @media(max-width: 960px) {
        margin-bottom: 10%;
    }

    @media(max-width: 600px) {
        margin-bottom: 25%;
        padding: 1px;
    }
`

export const DamagedCard = styled.div`
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.4), rgba(255, 0, 0, 0.4)), url(LadyBug-01.png), url(greenColorized-pattern-01.png), linear-gradient(180deg, #531330 0%, #991558 100%);
    background-blend-mode: normal, normal, difference, normal;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`   
export const ImgRepair = styled(ImgIcon)`
    margin-right: 0%;
    width: 60%;
    height: max-content;
`   

export const CommanderElement = styled.div`
    border: 1px solid white;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
`

export const ContainerInfoCommanders = styled.div`
    // flex-direction: column;
    // align-items: center;
    padding: 0px;
    gap: 14px;
    width: 100%;
    height: auto;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgba(231, 19, 131, 0.3);
    border: 1px solid #E71383;
    margin-top: 1.5%;
`

export const ContainerImgInfoProfile = styled.div`
    width: auto;
    height: auto;
    float: left;
    position: relative;

    /* @media(max-width: 1160px) {
        display: none;
    } */
`

export const ImgInfoProfile = styled.img`
    width: 100%;
    height: 100%;
`

export const BackgroundImgInfoProfile = styled.div`
    width: 218px;
    height: 236px;
    // background: url(image.png), 
    // linear-gradient(180deg, rgba(255, 0, 0, 0) 3.39%, rgba(255, 0, 0, 0.242917) 22.31%, rgba(255, 0, 0, 0.4) 100%), url('Codex.png');
`

export const TitleInfoCommanders = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 2%;

    @media (max-width: 600px) {
        font-size: 25px;
    }
`

export const DescriptionInfoCommanders = styled.div`
    height: 120px;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
`

export const ContainerInfoStats = styled.div`
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    height: 79.94px;
    background: rgba(47, 4, 26, 0.7);
    border: 1.33091px solid #E71383;
    display: flex;

    align-items: center;
    margin-top: 2%;

    @media (max-width: 600px) {
        padding: 7px;
    }

`


export const Row = styled.div`
    display: flex;
    flex-direction: row;
    // background-color: rgba(47, 4, 26, 0.7);
`

export const ColInfo = styled.div`
    // width: 679px;
    // background-color: rgba(47, 4, 26, 0.7);
`

export const IconArrow = styled.div`

    // padding: 5.5px 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    background: #E71383;
    /* margin-right: 4%; */
    // background-color: rgba(47, 4, 26, 0.7);
`

export const IconType = styled.div`

    // padding: 5.5px 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    background: #E71383;
    margin-right: 4%;
    // background-color: rgba(47, 4, 26, 0.7);
`

export const IconNft = styled.div`

    // padding: 5.5px 11px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* margin-left: 4%; */
    width: 40px;
    height: 30px;
    /* background: #E71383; */
    // background-color: rgba(47, 4, 26, 0.7);
`

export const RowStats = styled.div`
    width: 600px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    // background-color: rgba(47, 4, 26, 0.7);
`

export const BtnInstantHealBits = styled.div`
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    padding-left: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    gap: 10px;
    width: 50%;
    background-color: #E71383;
    cursor: pointer;
`
export const BtnClone = styled(BtnInstantHealBits)`
    width: 100%;
`

export const ContainerInputCardSelected = styled(ContainerInput)`
    width: 100%;
`

export const Icon = styled.img`
`

export const ContainerSection2Element = styled.div  `
    border: 1px solid #E61382;
    background: rgba(47, 4, 26, 0.7);
    width:100%;
    display: flex;
`

export const ContainerSection2 = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    margin-top: 9px;
`

export const ContainerSection2ElementIcon = styled.div  `
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const InputSetAmount = styled.input`
    border: none;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    height: 50px;
    width: 90%;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        display: none;
    }
`

export const BtnInstantHealCoins = styled(BtnInstantHealBits)`
    background-color: #AA68D8;
`
export const BtnInstantHealCoinsCard = styled(BtnInstantHealBits)`
    background-color: #AA68D8;
    width: 100%;
`

export const BtnCancelTextMedBayCard = styled(TextTitiliumWeb)`
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    margin-right: 5%;
    margin-left: 2%;
`


export const BtnCancelMedBayCard = styled(BtnInstantHealBits)`
    background: #CF1839;
    width: 100%;
    justify-content: center;
`
export const ContainerIconMedical = styled.div`
    background-color: #2F041A; 
    width: 48px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ImgCoin = styled.img`
`

export const ImgMedical = styled.img`
    width: 20px;
`

export const ImgUnion = styled.img`

`

export const ImgNft = styled.img`
    height: 30px;
`

export const ContainerMinerals = styled.div`
    display: flex;
    gap: 10px;
`

export const TextRevive = styled.span`

    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-right: 2%;
    margin-left: 5px;
`

export const Col = styled.div`
    background-color: #ffffff;
    height: 280px;
    margin-left: 20px;
`

export const TextCoins = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NumberCoins = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5%;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    color: #FFFFFF;
`

export const RowRevive = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #FFFFFF;
`
export const ContainerSelectCommander = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px 20px;
    gap: 16px;
    width: 920px;
    height: 997.44px;
    background: rgba(94, 8, 53, 0.1);
`
export const ContainerPolygonRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),linear-gradient(0deg, rgba(94, 8, 53, 0.5), rgba(94, 8, 53, 0.5));
    clip-path: polygon(0 8px, 8px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    position: relative;

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px;
        border-color: #E71383 transparent transparent #E71383;
    }

`

export const ContainerOptions1 = styled(ContainerBasicBG)`
    width: 100%;
    background: rgba(141, 12, 80, 0.7);
    border: 0.5px solid #E71383;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    padding: 0;
`
export const ContainerOptions2 = styled(ContainerOptions1)`
    box-shadow: inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5)
`

export const ContainerFlexEnd = styled(ContainerVoid)`
    height: 100%;
    width: 100%; 
    justify-content: flex-end;
`

export const BtnOptions1 = styled(ContainerVoid)`
    height: 100%;
    width: 100%;
    cursor: pointer;
`

export const ContainerIcon = styled.div`
    
    @media (max-width: 600px) {
        display: none;
    }
`

export const BtnOptionHealAll = styled(BtnOptions1)`
    background-color: #7C0242;
`
export const BtnOptionInstantHealAll = styled(BtnOptions1)`
    background-color: #AA68D8;
`

export const BtnOptionPickUpAll = styled(BtnOptions1)`
    background-color: #7C0242;
`


export const RowSpaceBetweenBtnElements = styled(RowSpaceBetween)`
    align-items: center;
    padding: 0px 20px 0px 20px;
`

export const ImgIconMedical = styled(ImgIcon)`
    width: 20px;
    height: 21px;
    margin-right: 3%;
`
export const ImgIconClone = styled(ImgIconMedical)`
    width: 18px;
    height: 21px;
`
export const ImgIconCrew = styled(ImgIconMedical)`
    width: 23px;
    height: 21px;
    margin-right: 3%;
`
export const ImgIconMedBay = styled(ImgIconMedical)`
    width: 21px;
    height: 21px;
    margin-right: 3%;
`
export const ImgIconPickUp = styled(ImgIconMedical)`
    width: 23px;
    height: 21px;
    margin-right: 3%;
`
export const ImgIconMedical2 = styled(ImgIcon)`
    width: 17px;
    height: 21px;
    margin-right: 1.5%;
`
export const ImgIconCancel = styled(ImgIcon)`
    width: 17px;
    height: 21px;
    margin-right: 0%;
`


export const TextTitiliumWebOptions1 = styled(TextTitiliumWeb)`
    font-weight: 400;
    line-height: 35px;
    margin-left: 3%;
    
`
export const TextTitiliumWebOptions2 = styled(TextTitiliumWebOptions1)`
    margin-right: 2%;

    @media (max-width: 600px) {
        font-size: 12px;
    }
`
export const ContainerStats = styled(ContainerInfoStats)`
    margin-top: 2%;
    background: rgba(141, 12, 80, 0.7);
    cursor: pointer;
`
export const ContainerTextIconButton = styled(ContainerVoid)`
    width: 100%;
    margin-right: 2%;
`

export const ContainerButtons = styled(ContainerStats)`
    
`

export const RowSpaceBetweenCenter = styled(RowSpaceBetween)`
    align-items: center;
`

export const RowSpaceBetweenDesktop = styled(RowSpaceBetweenCenter)`
    @media (max-width: 600px) {
        display: none;
    }
`

export const ColCellphone = styled(ColCore)`
    gap: 5px;
    @media (min-width: 601px) {
        display: none;
    }
`
export const RowCoreCenter = styled(RowCore)`
    align-items: center;
`
export const ImgIconStats = styled(ImgIcon)`
    width: 25px;
    height: 25px;
`
export const GridViewCoreCommanders = styled(GridViewCore)`
    height: 480px;
    /* margin-right: 1%;
    margin-left: 1%; */
    grid-template-columns: repeat(3, 1fr);

    @media(max-width: 1140px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
export const TextPoppinsInfo = styled(TextPoppins)`
    font-size: 11px;
`
export const TextPoppinsInfoBold = styled(TextPoppinsBold)`
    font-size: 11px;
`

export const TextTextTitiliumWebInfo = styled(TextTitiliumWeb)`
    font-size: 11px;
`

export const RowCoreBonus = styled(RowCore)`

`
export const ImgIconStats2 = styled(ImgIcon)`
    width: 20px;
    margin-right: 3%;
`

export const TextTitiliumWebStats = styled(TextTitiliumWeb)`
    line-height: 21px;
    letter-spacing: 0;
`


export const ContainerPolygonLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),linear-gradient(0deg, rgba(94, 8, 53, 0.5), rgba(94, 8, 53, 0.5));
    clip-path: polygon(0 100%, 0 0, calc(100% - 8px) 0, 100% 8px, 100% 100%, 0 100%);
    position: relative;

    &::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px;
        border-color: #E71383 transparent transparent #E71383;
    }
`

export const LifeBarContainer = styled.div`
    height: 30px;
    width: 100%;
    background: #56585C;
    margin-right: 5%;
`
export const LifeBarContent = styled.div`
    height: 100%;
    background: #9dff94;
    display: flex;
    align-items: center;
    /* transition: width 0.25s; */
`


export const TitleStats = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: white;
   line-height: 18px;
    
`

export const StatsBold = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
`

export const ContainerTitleCommanders = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 12px 10px;
    gap: 10px;
    width: 100%;
    height: 30px;
    background: rgba(141, 12, 80, 0.5);
`

export const TitleCommanders = styled.span`

    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
`

export const ContainerGrid = styled.div`
    padding-top: 7px;
    width: 96%;
    margin: 1% 15px;
    background: rgba(94, 8, 53, 0.1);

`

export const GridView = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    mix-blend-mode: lighten;
    height: 500px;
    margin-right: 1%;
`

export const CardCommander = styled.div`
    height: fit-content;
    /* margin-left: 15px; */
    margin-right: 12px;
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: column;
    background: rgba(231, 19, 131, 0.2);
    border: 1.33091px solid #E71383;
    
`

export const TitleCardCommander = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextTitleCard = styled.span`
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
`

export const ContainerImgProfile = styled.div`
    width: auto;
    height: auto;
    position: relative;

`

export const ContainerStatsProfile = styled.div`
    width: 92%;
    padding: 5px;
    margin-left: 2%;
`

export const ColStats = styled.div`
`

export const TitleStatsCard = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    /* line-height: 24px; */
    line-height: 18px;
    color: #FFFFFF;
`
export const BtnSelectCard = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E71383;
`

export const TextSelectCard = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
`

export const IconHelpInfo = styled.img`
    width: 20px;
`

export const TextInfoNoSelected = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 1%;
`

export const DropDownStatsContainer = styled.div`
    width: 100%;
    background: #2F041A;
    border: 1px solid #E71383;
    border-top: 0px;
    padding: 9px;
`

export const DropDownStatsIcon = styled.img`
    width: 18px;
`
export const DropDownStatsRow = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;   

    margin-bottom: 5px;
`