import '../containers/hooks/styles.css'
import {

RectangleContainerGeneral,
RectangleContainerGeneral2,
RectangleImage,
RectangleContainerComplete,
RectangleRowContainerFirst,
RectangleRowContainerSecond,
RectangleButtonContainer,
RectangleRowsContainer,
RectangleRowText,
RectangleRowTextPurple,
RectangleRowSecondContainerStats,
Icon,
Button,
TextButton,
TextStats,
IconButton,
RectangleElementFirst,
RectangleRowFirstContainer1Outposts
} from './ReusableRectangleElement';


const ReusableRectangleOutposts = (props) =>  {
    
     return(
        <>  
  <RectangleContainerGeneral>
        <RectangleContainerGeneral2>
            <RectangleImage src={props.image}/>
            <RectangleContainerComplete style={{  backgroundColor: props.rectangleBackground}} >

                <RectangleRowsContainer style={{padding:"10px"}}>
                    <RectangleRowContainerFirst>
                        <RectangleRowFirstContainer1Outposts>


                            <RectangleElementFirst>
                                <RectangleRowText> Outpost: </RectangleRowText>
                                <RectangleRowTextPurple> {props.value1} </RectangleRowTextPurple>

                                <RectangleRowText> Max Routes: </RectangleRowText>
                                <RectangleRowTextPurple> {props.value2} </RectangleRowTextPurple>
                            </RectangleElementFirst>
                            
                            <RectangleElementFirst style={{  display:"flex", alignItems:"center"}}>
                                <Icon style={{marginRight:"3px", width:"fit-content"}}src="/icons/icon_type_clock_outposts.svg"></Icon>
                                <RectangleRowText style={{marginRight:"3px"}}>Rental Duration: </RectangleRowText>
                                <RectangleRowTextPurple style={{marginRight:"3px"}}> {props.value3} </RectangleRowTextPurple>
                            <Icon style={{marginRight:"3px", marginLeft:"21px", width:"fit-content"}}src="/icons/icon_type_coin.svg"></Icon>
                                <RectangleRowText style={{marginRight:"3px"}}> {props.value4}</RectangleRowText>
                            </RectangleElementFirst>


                        </RectangleRowFirstContainer1Outposts>


                    </RectangleRowContainerFirst>
                    <RectangleRowSecondContainerStats><TextStats> Stats </TextStats></RectangleRowSecondContainerStats>
                    <RectangleRowContainerSecond>
                        {/*<IconButton src={props.icon1Second}></IconButton>*/}
                        <RectangleElementFirst>
                            <RectangleRowText> Max Routes: </RectangleRowText>
                            <RectangleRowTextPurple> {props.value1Second} </RectangleRowTextPurple>
                        </RectangleElementFirst>
                        <RectangleElementFirst>
                            <RectangleRowText> Level: </RectangleRowText>
                            <RectangleRowTextPurple> {props.value2Second} </RectangleRowTextPurple>
                        </RectangleElementFirst>
                        <RectangleElementFirst>
                            <RectangleRowText>Example</RectangleRowText>
                            <RectangleRowTextPurple> {props.value3Second} </RectangleRowTextPurple>
                        </RectangleElementFirst>
                        <RectangleElementFirst>
                            <RectangleRowText>Example</RectangleRowText>
                            <RectangleRowTextPurple> {props.value4Second} </RectangleRowTextPurple>
                        </RectangleElementFirst>
                            

                    </RectangleRowContainerSecond>
                </RectangleRowsContainer>
                

                <RectangleButtonContainer>
                    <Button onClick={props.action} style={{width: "130px", height: "60px", backgroundColor: "rgba(231, 19, 131, 1)",  clipPath: "polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)", WebkitClipPath : "polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)"}}>
                        <IconButton src={props.buttonIcon}></IconButton>
                        <TextButton>Rent Outpost</TextButton>
                    </Button>
                </RectangleButtonContainer>
                
            </RectangleContainerComplete>
            
        </RectangleContainerGeneral2>
    </RectangleContainerGeneral>
    

    
    </>
    ) 
};
export default ReusableRectangleOutposts;