import React, { useEffect, useState } from "react";
import { HTTP } from '../../../../http/http-common';

import { ContainerRow, ImgCoin, ImgProfile, NumberCoins, RowCore50, RowCoreCenter, RowCoreEnd, RowCoreLeft, RowCoreRight, RowCoreUsername, RowSpaceBetweenRewards, TextTitiliumWebHeaders, TextTitiliumWebNormal, TextTitiliumWebPosition } from "../LeaderboardElement";

import photo_profile from '../../../../../assets/images/photo_profile.png'
import icon_mineral from '../../../../../assets/images/icon_mineral.svg'
import { RowCore, TextPoppins } from "../../../../../containers/CoreElement";
import Pagination from "../../../../../hooks/Pagination";

import { numberToThousandSeparatorLeaderboardRanking, numberToThousandSeparator } from '../../../../utils/utils';
import { Loader } from "../../../../../hooks/Loader";
import PopUp from "../../../../../hooks/PopUp";

export function CurrentWeek(){


    const [leaderboard, setLeaderboard] = useState([]);
    const [rewardPool, setRewardPool] = useState([]);
    const [loader, setLoader] = useState(false);
    const [totalCollectedAurum, setTotalCollectedAurum] = useState(false);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');

    const fetchLeaderboardCurrentWeek = () => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"week": "current"},
            method: "POST"
        };
        HTTP.request('leader/list/', config)
        .then((res) => {
            if (res.data.result){
                setLeaderboard(res.data.response);
                setTotalCollectedAurum(res.data.total_collected_aurum);
                setRewardPool(res.data.reward_pool);
            }
        })
        .catch((err) => {
        console.log(err);
        setPopupServerError(true);
        setTextServerError(err.response.statusText);
    
        })
        .finally(() => {
            setLoader(false);
          })
    };


    useEffect(() => {
        fetchLeaderboardCurrentWeek();
    }, [])
    
    

    function decodePodium(index){
        const images = {
            1: '/icons/icon_top_1.png',
            2: '/icons/icon_top_2.png',
            3: '/icons/icon_top_3.png',
          };
        
        return images[index] || null;
    }




    return(
        <>
        <Loader loading={loader}/>
        <ContainerRow>
            <RowSpaceBetweenRewards>
                <RowCoreCenter>
                    <TextTitiliumWebNormal>
                        Reward Pool:
                    </TextTitiliumWebNormal>
                    <ImgCoin src='/icons/icon_type_coin.svg'/>
                    <NumberCoins>{numberToThousandSeparator(rewardPool)}</NumberCoins>
                </RowCoreCenter>
                <RowCoreEnd>
                    <TextTitiliumWebNormal>
                        Total Collected Aurum:
                    </TextTitiliumWebNormal>
                    <ImgCoin src={icon_mineral}/>
                    <NumberCoins>{numberToThousandSeparatorLeaderboardRanking(totalCollectedAurum)}</NumberCoins>
                </RowCoreEnd>
            </RowSpaceBetweenRewards>
        </ContainerRow>
        <ContainerRow>
            <RowCore>
                <RowCoreLeft>
                    <RowCore50>
                        <TextTitiliumWebHeaders>
                            Position
                        </TextTitiliumWebHeaders>
                    </RowCore50>
                    <RowCore50>
                        <TextTitiliumWebHeaders>
                            Username
                        </TextTitiliumWebHeaders>
                    </RowCore50>
                </RowCoreLeft>
                <RowCoreRight>
                    <TextTitiliumWebHeaders>
                        Collected Aurum
                    </TextTitiliumWebHeaders>
                </RowCoreRight>
            </RowCore>
        </ContainerRow>
        <Pagination elements={leaderboard} elementsPerPage={10}>
            {elementsToRender => (
                <>
                    {elementsToRender.map((player) => (
                        <ContainerRow>
                            <RowCore>
                                <RowCoreLeft>
                                    <RowCoreUsername>
                                        {decodePodium(player.top) == null ?
                                            <TextTitiliumWebPosition>
                                                {player.top}
                                            </TextTitiliumWebPosition>
                                            :
                                            <ImgProfile src={decodePodium(player.top)} />
                                        }
                                    </RowCoreUsername>
                                    <RowCoreUsername>
                                        <ImgProfile src={photo_profile} />
                                        <TextTitiliumWebHeaders style={{ color: player.is_you ? '#13CBE7' : '#FFFFFF' }}>
                                            {player.username} {player.is_you ? '(you)' : null}
                                        </TextTitiliumWebHeaders>
                                    </RowCoreUsername>
                                </RowCoreLeft>
                                <RowCoreRight>
                                    <TextTitiliumWebHeaders>
                                        {player.collected_aurum}
                                    </TextTitiliumWebHeaders>
                                </RowCoreRight>
                            </RowCore>
                        </ContainerRow>
                    ))
                    }
                </>
            )}
        </Pagination>

        
        <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
            <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            {textServerError}
            </TextPoppins>
        </PopUp>
        </>
    )
}

