//Librerias INIT
import React, { useEffect, useState } from "react";

//Icons
import leaderboard_header from '../../../../assets/images/leaderboard_header.png';
import icon_current_week from '../../../../assets/images/icon_current_week.svg';
import icon_last_week from '../../../../assets/images/icon_last_week.svg';


//Elements
import { BtnOptions1, ContainerLeaderboard, ContainerMain, ContainerOptions1, TextTitiliumWebOptions1 } from "./LeaderboardElement";
import HeaderScreen from "../../../../hooks/HeaderScreen";
import { RowSpaceBetween} from "../../../../containers/CoreElement";


import {
    Routes,
    Route,
    useNavigate,
    useLocation
  } from "react-router-dom";
import { CurrentWeek } from "./CurrentWeek/CurrentWeek";
import { LastWeek } from "./LastWeek/LastWeek";
import {updateNavs } from "../../../utils/utils";
import IconSvg from "../../../../hooks/IconSvg";


export function Leaderboard(){
    const location = useLocation();
    const navigate = useNavigate();
    const [activeRoute, setActiveRoute] = useState(location.pathname);

    const handleClick = (path) => {
        setActiveRoute(path);
        navigate(path);
    };
      
    
    useEffect(() => {
        updateNavs();

    }, [])

    return(
        <>
        <ContainerMain>
            <HeaderScreen extended={true} imgHeader={leaderboard_header} leaderBoard={true} icon={'/icons/icon_leaderboard.svg'} title={'LEADERBOARD'} widthIcon="30px">
                Earn Points by collecting Aurum, the leaderboard will reset every week.
            </HeaderScreen>
            <ContainerOptions1>
                <RowSpaceBetween>
                    <BtnOptions1 onClick={() => handleClick('/leaderboard/current-week')} style={{backgroundColor: activeRoute === '/leaderboard/current-week' ? '#FF0087' : 'transparent'}}>
                        <IconSvg icon={icon_current_week} width={'25px'} color={activeRoute === '/leaderboard/current-week' ? '#FFFFFF' : '#d2b8cd'}/>
                        <TextTitiliumWebOptions1 style={{color: activeRoute === '/leaderboard/current-week' ? '#FFFFFF' : '#d2b8cd'}}>
                            Current Week
                        </TextTitiliumWebOptions1>
                    </BtnOptions1>
                    <BtnOptions1 onClick={() => handleClick('/leaderboard/last-week')} style={{backgroundColor: activeRoute === '/leaderboard/last-week' ? '#FF0087' : 'transparent'}}>
                        <IconSvg icon={icon_last_week} width={'25px'} color={activeRoute === '/leaderboard/last-week' ? '#FFFFFF' : '#d2b8cd'}/>
                        <TextTitiliumWebOptions1 style={{color: activeRoute === '/leaderboard/last-week' ? '#FFFFFF' : '#d2b8cd'}}>
                            Last Week
                        </TextTitiliumWebOptions1>
                    </BtnOptions1>
                </RowSpaceBetween>
            </ContainerOptions1>
            <ContainerLeaderboard>
                <Routes>
                    <Route path="current-week" element={<CurrentWeek/>}/>
                    <Route path="last-week" element={<LastWeek/>}/>
                </Routes>
            </ContainerLeaderboard>
        </ContainerMain>
        </>
    );
}