import React, {useState, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/App.css';

import {NavbarComponent} from '../components/Header/NavbarPrimary/Navbar'
import {Home} from '../components/Home/Home'
import {News} from '../components/News/News'
import {Staking} from '../components/Staking/Staking'
import {Tutorial} from '../components/Tutorial/Tutorial'
import {Shop} from '../components/Shop/Shop'
import {Myaccount} from '../components/Myaccount/Myaccount'
import {Playnow} from '../components/Playnow/Playnow'
import {Signflow} from '../components/Signflow/Signflow'
import {ResetPassword} from '../components/Signflow/ResetPassword'
import {NewPassword} from '../components/Signflow/NewPassword'
import { Confirmation } from '../components/Register/Confirmation'
import { VerifyPage } from '../components/Register/Verify'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Register } from '../components/Register/Register'
import { Settings } from '../components/Settings/Settings'

import useToken from './useToken';

const PageView = () => {
  const location = useLocation();
  useEffect(()=> {
    window.dataLayer.push(
      {
        event: "page_view",
      }
    )
  }, [location])

  return null;
}

function App() {
  const [ token, setToken ] = useState(useToken());
  const [ updateNav, setUpdateNav ] = useState(false);

  useEffect(() => {
    console.log(token);
    if (token) {
      setUpdateNav(true)
    }
  }, [token]);





  // if(!token) {
  //   return (
  //     <Router>
  //       <header>
  //         <NavbarComponent />
  //       </header>
  //       <main>
  //         <Routes>

  //         </Routes>
  //       </main>
  //     </Router>
  //     <Navigate to={"/sign-in"}/>
  //   )
  // }

  return (
    <>

      <Router>
        <PageView />
        <header>
          <NavbarComponent setUpdateNav={setUpdateNav} updateNav={updateNav} setToken={setToken}/>
        </header>
        <main>
          <Routes>
            { !token ?
              <>
                <Route path='/sign-in' element={<Signflow setToken={setToken} setUpdateNav={setUpdateNav}/>}/>
                <Route path="/register" element={<Register/>}/>
                <Route path="/reset-password" element={<ResetPassword/>}/>
                <Route path="/confirm" element={<Confirmation/>}/>
                <Route path="/activation-succeed" element={<VerifyPage/>}/>
                <Route path="/verify/:token" element={<VerifyPage/>}/>
                <Route path="/new-password/:token" element={<NewPassword/>}/>
              </>
             :
             <>
              <Route path="/" element={<Home/>}>
                <Route path="dashboard"/>
                <Route path="commanders">
                  <Route path="crew"/>
                  <Route path="clone-lab">
                    <Route path="clone"/>
                    <Route path="pick-up"/>
                  </Route>
                  <Route path="med-bay">
                    <Route path="injured"/>
                    <Route path="healing"/>
                    <Route path="pick-up"/>
                  </Route>
                </Route>
                <Route path="outposts"/>
                <Route path="routes">
                  <Route path='expeditions'/>
                  <Route path='mining'/>
                </Route>
                <Route path="routes/active"/>
                <Route path="shipyard">
                  <Route path="hangar"/>
                  <Route path="forge">
                    <Route path="blueprints"/>
                    <Route path="in-process"/>
                    <Route path="pick-up"/>
                  </Route>
                  <Route path="repair-bay">
                    <Route path="damaged"/>
                    <Route path="repairing"/>
                    <Route path="pick-up"/>
                  </Route>
                </Route>
                <Route path='shipyard/hangar'/>
                <Route path='shipyard/forge'/>
                <Route path='shipyard/repair-bay'/>
                <Route path="routes/setup/:id"/>
                <Route path="forge"/>
                <Route path="messages">
                  <Route path="all"/>
                  <Route path="routes"/>
                  <Route path="system"/>
                  <Route path="favorites"/>
                  <Route path="others"/>
                  <Route path="trash"/>
                </Route>
                <Route path="leaderboard">
                  <Route path="current-week"/>
                  <Route path="last-week"/>
                </Route>
                <Route path="altura">
                  <Route path="export"/>
                  <Route path="import"/>
                </Route>
                <Route path="facilities"/>
              </Route>
              <Route path="/news" element={<News/>}/>
              <Route path="/staking" element={<Staking/>}/>
              <Route path="/tutorial" element={<Tutorial/>}/>
              <Route path="/shop" element={<Shop/>}/>
              <Route path="/myaccount" element={<Myaccount/>}/>
              <Route path="/playnow" element={<Playnow/>}/>
              <Route path="/settings" element={<Settings/>}/>
             </>
            }
            { token ?
              <Route path="*" element={<Navigate replace to="/dashboard" />} />
              :
              <Route path="*" element={<Navigate replace to="/sign-in" />} />
            }
          </Routes>
        </main>
      </Router>
    </>
  )
}
export default App
