import axios from 'axios';
import { BACKEND_API } from '../utils/config';
axios.defaults.withCredentials = true;
export const HTTP = axios.create({
  
    baseURL: BACKEND_API,
    headers: {
      Authorization: 'Bearer {token}'
    }
  })
