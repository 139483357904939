import styled from "styled-components";
import { ContainerBasic, ContainerVoid, TextTitiliumWeb } from "../containers/CoreElement";

export const ContainerFleetStrength = styled(ContainerBasic)`
    padding: 0;
    border: 1.33091px solid #ffffff;
    height: 40px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), rgba(78, 6, 44, 0.25);
    margin-bottom: 2%;
`

export const ContainerStrength = styled(ContainerVoid)`
    width: 100%;
`
export const TextTitiliumLineHeight = styled(TextTitiliumWeb)`
    line-height: 0px;
`