//Libraries INIT
import React, { useState, useEffect } from 'react';

//CSS

import './hooks/dashboard.css'
import './hooks/dashboard.css'
import {DropDownDashboard} from './hooks/DropDownDashboard.jsx'
//Components
import { HTTP } from '../../../http/http-common';
import HeaderScreen from '../../../../hooks/HeaderScreen';
import IconSvg from '../../../../hooks/IconSvg';
import { convertTimestamp, convertProgress, updateNavs, updateCountMessages } from '../../../../components/utils/utils';

//Icons

import icon_dashboard from '../../../../assets/images/icon_dashboard.svg'
import dashboard_header from '../../../../assets/images/dashboard_header.png'
import mission_expedition from '../../../../assets/images/mission_expedition.png'
import mission_mining from '../../../../assets/images/mission_mining.png'
import help_icon_pink from '../../../../assets/images/info_icon_pink.svg'

//Elements

import { 
  ContainerMain, TextPoppins,
} from '../../../../containers/CoreElement';

import { 
  WebSocketComponent,
} from '../../../../websocket/WebSocketComponent';

import { 
  ContainerGeneral,
  RectangleButtonContainerV2,
  ButtonV2,
  RectangleRowTextPurple,
  RectangleRowText,
  RectangleRowFirstContainerIconAndText,
  RectangleRowFirstContainer2,
  RectangleContainerGeneral,
  RectangleContainerGeneral2,
  RectangleImage,
  RectangleContainerComplete,
  RectangleRowsContainer,
  RectangleRowFirstContainer1,
  RectangleRowContainerFirst,
  ContainerIconClock,
  ContainerPercentComplete,
  ContainerAlert,
  ContainerMsgAlert,
  RowMsg,
  ImgInfo,
  TextInfo,
  RowCoreCellphone,
  RectangleButtonContainerV2Desktop,
  RectangleButtonContainer,
  ButtonContainer,
  TextButton,

} from './DashboardElement'
import { RectangleElementFirst } from '../../../../hooks/ReusableRectangleElement';
import MissionBarProgress from '../../../../hooks/MissionBarProgress';
import { Loader } from '../../../../hooks/Loader';
import icon_expeditions from '../../../../assets/images/icon_expeditions.svg'
import icon_mining from '../../../../assets/images/icon_mining.svg'
import PopUp from '../../../../hooks/PopUp';
import { useMediaQuery } from '@mui/material'

export function Dashboard() {

  const xs = useMediaQuery('(max-width:600px)')

  const [loader, setLoader] = useState(false);

  const [extended, setExtended] = useState(true);

  const [walletStatusValue, setWalletStatusValue] = useState(false);

  const [firstTime, setFirstTime] = useState(true);

  const [routesActive, setRoutesActive] = useState([]);

  const [routesReturning, setRoutesReturning] = useState([]);
  const [count, setCount] = useState(0);

  const [popupServerError, setPopupServerError] = useState(false);
  const [textServerError, setTextServerError] = useState('Server Error');

  const [dropDown, setDropDown] = useState(false);


  const btnClickBack = () => {
    setExtended(true);
  }

  

  useEffect(() => {
    updateCountMessages();
    updateNavs();
    fetchRoutesActive();
    fetchRoutesReturning();
    fetchNotifications();
    setInterval(() => {
      setCount(prevCount => prevCount + 0.5);
    }, 1000);
    
  }, []);

  useEffect(() => {
    console.log("RESET ws_open")
    sessionStorage.setItem('ws_open', false);
  }, [])

  const fetchRoutesActive = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };

    console.log("fetchRoutesActive")

    HTTP.request('expeditions/active/', config)
      .then((res) => {
        console.log(res.data)
        setRoutesActive(res.data);
      })
      .catch((err) => {
        console.log(err);
        setPopupServerError(true);
        setTextServerError(err.response.statusText);
      })
      .finally(() => {
        setLoader(false);
      })
  };

  const fetchRoutesReturning = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };

    console.log("fetchRoutesReturning")

    HTTP.request('expeditions/returnings/', config)
      .then((res) => {
        console.log(res.data)
        setRoutesReturning(res.data);
      })
      .catch((err) => {
        console.log(err);
        setPopupServerError(true);
        setTextServerError(err.response.statusText);
      })
      .finally(() => {
        setLoader(false);
      })
  };

  const fetchNotifications = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
        method: "POST"
    };
    HTTP.request('notifications/', config)
    .then((res) => {
        if (res.data.result){
          setWalletStatusValue(res.data.noti_status_wallet);
        }
    })
    .catch((err) => {
    console.log(err);
    setPopupServerError(true);
    setTextServerError(err.response.statusText);
    })
    .finally(() => {
        setLoader(false);
      })
};

  const handleButton = (routeId) => {
    setDropDown(prevState => ({
      ...prevState,
      [routeId]: !prevState[routeId]
    }));
  };

  return (
    <>
    <Loader loading={loader}/>
    <ContainerMain>
      <HeaderScreen dashboard={true} activeFleets={routesActive.length + routesReturning.length} extended={extended} onClickBack={btnClickBack} title={'DASHBOARD'} icon={icon_dashboard} imgHeader={dashboard_header} walletNotifications={walletStatusValue}>
        Here is your dashboard, view your current Routes and other stats.
      </HeaderScreen>

      {firstTime?
        <>
        { routesActive.length !== 0 || routesReturning.length !== 0 ?
          <WebSocketComponent fetchRoutesActive={fetchRoutesActive} fetchRoutesReturning={fetchRoutesReturning} setFirstTime={setFirstTime}/>
          :
          null
        }
        </>
        :
        null
      }

      { routesActive.length !== 0 || routesReturning.length !== 0 ?
          <ContainerGeneral>
           { routesActive.map(route => (
               <RectangleContainerGeneral key={route.id}>
                 <RectangleContainerGeneral2>
                     <RectangleImage src={route.p_mission_economic.type_of_route_name.includes('mining') ? mission_mining : mission_expedition}/>
                     <RectangleContainerComplete>
                         <RectangleRowsContainer>
                             <RectangleRowContainerFirst>
                                 <RectangleRowFirstContainer1>
                                    {route.p_mission_economic.type_of_route_name === "mining_mission"?
                                        <>
                                          <RectangleElementFirst>
                                              <IconSvg icon={icon_mining} color={'#ffffff'} width={'20px'}/>
                                              <RectangleRowText> Mining </RectangleRowText>
                                          </RectangleElementFirst>
                                        </>
                                        :
                                        <>
                                          <RectangleElementFirst>
                                              <IconSvg icon={icon_expeditions} color={'#ffffff'} width={'15px'}/>
                                              <RectangleRowText> Expedition </RectangleRowText>
                                          </RectangleElementFirst>
                                        </>
                                    }
                                 </RectangleRowFirstContainer1>
                                 <RectangleRowFirstContainer2 >
                                     <RectangleRowFirstContainerIconAndText>
                                         <ContainerIconClock>
                                           <IconSvg icon="/icons/icon_type_clock_outposts.svg" color={'#ffff'}/>
                                         </ContainerIconClock>
                                         { convertProgress(route, count) < 99?  
                                           <RectangleRowTextPurple> {convertTimestamp(route.time_to_finish, count, false)} </RectangleRowTextPurple>
                                           :
                                           <RectangleRowTextPurple> {'0s'} </RectangleRowTextPurple>
                                         }
                                     </RectangleRowFirstContainerIconAndText>
                                 </RectangleRowFirstContainer2>
                             </RectangleRowContainerFirst>
                             
                             <ContainerPercentComplete>
                               { convertProgress(route, count) < 99?  
                                 <MissionBarProgress progress={convertProgress(route, count)} returnedFleet={false}/>
                                 :
                                 <MissionBarProgress progress={100} returnedFleet={false}/>
                               }
                             </ContainerPercentComplete>
                             <RowCoreCellphone>
                               <RectangleButtonContainerV2  onClick={() => handleButton(route.id)}>
                                { dropDown[route.id] ?
                                  <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_hidden.svg')"}} ></ButtonV2>
                                  :
                                  <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_show.svg')"}} ></ButtonV2>
                                }
                              </RectangleButtonContainerV2>
                             </RowCoreCellphone>
                         </RectangleRowsContainer>
                         <RectangleButtonContainerV2Desktop  onClick={() => handleButton(route.id)}>
                          { dropDown[route.id] ?
                             <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_hidden.svg')"}} ></ButtonV2>
                             :
                             <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_show.svg')"}} ></ButtonV2>
                          }
                         </RectangleButtonContainerV2Desktop>
                     </RectangleContainerComplete>
                 </RectangleContainerGeneral2>
                 { dropDown[route.id]  === true ? 
                   <DropDownDashboard
                   
                   route={route}
                   
                   ></DropDownDashboard>
                 :
                   null
                 }
               </RectangleContainerGeneral>
             ))
           }
           { routesReturning.map(route => (
             <div>
               <RectangleContainerGeneral key={route.id}>
                 <RectangleContainerGeneral2>
                     <RectangleImage src={route.p_mission_economic.type_of_route_name.includes('mining') ? mission_mining : mission_expedition}/>
                     <RectangleContainerComplete>
                         <RectangleRowsContainer>
                             <RectangleRowContainerFirst>
                                 <RectangleRowFirstContainer1>
                                  {route.p_mission_economic.type_of_route_name === "mining_mission"?
                                    <>
                                      <RectangleElementFirst>
                                          <IconSvg icon={icon_mining} color={'#ffffff'} width={'20px'}/>
                                          <RectangleRowText> Mining </RectangleRowText>
                                      </RectangleElementFirst>
                                    </>
                                    :
                                    <>
                                      <RectangleElementFirst>
                                          <IconSvg icon={icon_expeditions} color={'#ffffff'} width={'15px'}/>
                                          <RectangleRowText> Expedition </RectangleRowText>
                                      </RectangleElementFirst>
                                    </>
                                    }
                                 </RectangleRowFirstContainer1>
                                 <RectangleRowFirstContainer2 >
                                     <RectangleRowFirstContainerIconAndText>
                                         <ContainerIconClock>
                                           <IconSvg icon="/icons/icon_type_clock_outposts.svg" color={'#ffff'}/>
                                         </ContainerIconClock>
                                         <RectangleRowTextPurple> {convertTimestamp(route.time_to_finish, count, false)} </RectangleRowTextPurple>
                                     </RectangleRowFirstContainerIconAndText>
                                 </RectangleRowFirstContainer2>
                             </RectangleRowContainerFirst>
                             <ContainerPercentComplete>
                               <MissionBarProgress progress={convertProgress(route, count)} returnedFleet={true}/>
                               {/* <hr style={{border: 'none', borderTop: '2px solid green', margin: '10px 0'}}/> */}
                               {/* <Img src={route_complete}/> */}
                             </ContainerPercentComplete>
                             <RowCoreCellphone>
                                <RectangleButtonContainerV2 onClick={() => handleButton(route.id)}>
                                { dropDown[route.id] ?
                                  <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_hidden.svg')"}} ></ButtonV2>
                                  :
                                  <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_show.svg')"}} ></ButtonV2>
                                }
                              </RectangleButtonContainerV2>
                             </RowCoreCellphone>
                         </RectangleRowsContainer>
                         <RectangleButtonContainerV2Desktop onClick={() => handleButton(route.id)}>
                          { dropDown[route.id] ?
                             <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_hidden.svg')"}} ></ButtonV2>
                             :
                             <ButtonV2 style={{backgroundImage: "url('/icons/icon_special_arrow_show.svg')"}} ></ButtonV2>
                          }
                         </RectangleButtonContainerV2Desktop>
                     </RectangleContainerComplete>
                 </RectangleContainerGeneral2>
                 { dropDown[route.id] === true ? 
                   <DropDownDashboard
                   
   
                   route={route}
                   
                   ></DropDownDashboard>
                 :
                   null
                 }
               </RectangleContainerGeneral>
   
             </div>
             ))
           }
         </ContainerGeneral>
         :
        <ContainerAlert style={{marginBottom: '20%'}}>
          <ContainerMsgAlert style={{height:"fit-content", flexDirection: xs ? 'column' : '', gap: xs ? '10px' : ''}}>
            <RowMsg>
              <ImgInfo src={help_icon_pink}/>
              <TextInfo>
                You do not have any active fleet! Send out a fleet to earn resources now!
              </TextInfo>
            </RowMsg>
            <div>
                <RectangleButtonContainer>
                      <ButtonContainer to={"/routes/mining"}>
                          <TextButton>{"Send Fleet"}</TextButton>
                      </ButtonContainer>
                </RectangleButtonContainer>
            </div>
          </ContainerMsgAlert>
        </ContainerAlert>
      }
    </ContainerMain>

    <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
          {textServerError}
        </TextPoppins>
    </PopUp>
    </>
  )
}