import React, { useState, useEffect, useCallback } from 'react';

export default function IconSvg(props) {

  const [svgString, setSvgString] = useState(null);
  const [modifiedSvgString, setModifiedSvgString] = useState(null);
  const [imgUrl, setImgUrl] = useState('');
  const [colorDefault, setColorDefault] = useState('');

  const fetchSvgString = useCallback( async () => {
    const response = await fetch(props.icon);
    const text = await response.text();
    setSvgString(text);
  }, [props.icon]);

  const svgToImg = (svgString) => {
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svgBlob);
    setImgUrl(url);
    return () => URL.revokeObjectURL(url);
  };

  const replaceFillValue = useCallback((svgText, newFillValue) => {
    const currentFillValueRegex = /fill="([^"]*)"/g;
    let currentFillValue, lastFillValue;
    while ((currentFillValue = currentFillValueRegex.exec(svgText))) {
      lastFillValue = currentFillValue[1];
    }
    setColorDefault(lastFillValue);
    let newSvgPath;
    let color;
    const firstFillRegex = /fill="[^"]*"/;
    const firstFillMatch = svgText.match(firstFillRegex);
    const firstFillPosition = firstFillMatch ? firstFillMatch.index : -1;
    
    newFillValue ? color = newFillValue : color = colorDefault;

    newSvgPath = svgText.replace(/(fill|stroke)="[^"]*"/g, (match, p1, offset) => {
      if (offset > firstFillPosition) {
        return `${p1}="${color}"`;
      } else {
        return match;
      }
    });


    setModifiedSvgString(newSvgPath);

  }, [colorDefault]);

  
  useEffect(() => {
    fetchSvgString();

  }, [fetchSvgString]);
  
  useEffect(() => {
    if (svgString) {
      replaceFillValue(svgString, props.color);
      svgToImg(modifiedSvgString);
    }
  }, [props.color, svgString, modifiedSvgString, replaceFillValue]);
  

  return (
    <>
      { modifiedSvgString ?
        <img src={imgUrl} alt='SVG converted to IMG' style={{width: props.width, height: props.height}}/>
        :
        svgString ?
          <img src={imgUrl} alt='SVG converted to IMG' style={{width: props.width, height: props.height}}/> 
          :
          null
      }
    </>
  );
};