//Librerias INIT
import React, { useState, useEffect} from 'react';

//CSS

//Components

//Icons

//Elements
  
import { 
    MessageContainerGeneral,
    MessageContainerData,
    MessageContainerRow,
    MessageIcon,
    MessageText,
    MessageText1,
    MessageContainerButton,
    BtnViewMsg1,
    ContainerNewTag,
    Trapecio,
    TextTagNew,
} from "../MessagesElement"
import { HTTP } from '../../../../http/http-common';
import IconSvg from '../../../../../hooks/IconSvg';
import { useMediaQuery } from '@mui/material'


export function Message(props) {

    const xs = useMediaQuery('(max-width:600px)')


    const [isNew , setIsNew] = useState([false]);
    const [typeMessage , setTypeMessage] = useState('System Message');
    // const [loader, setLoader] = useState(false);

    const toDateUTC = (date) => {
        const dateMsg = new Date(date);

        const dateFormat = dateMsg.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const hourFormat = dateMsg.toLocaleTimeString('en-US', { hour12: false , hour: '2-digit', minute:'2-digit'});        
        const dateHourUTC = `${dateFormat} ${hourFormat} UTC`;

        return dateHourUTC;
    }

    
    useEffect(() => {
        setIsNew(props.item.is_open);
        if (props.item.is_route_message){
            setTypeMessage(props.item.route.p_mission_economic.type_of_route_name)
        }
    }, [props])

    const favoriteMessage = (message) => {
        // setLoader(true);
        var value = null;

        if (message.is_favorite){
            value = false;
        }else{
            value = true;
        }

        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"message": message.id, "value": value},
            method: "POST"
        };
        HTTP.request('message/is/favorite/', config)
        .then(() => {
            window.location.reload();
        })
        .catch((err) => {
            console.log(err);
        })
        // .finally(() => {
        //   setLoader(false);
        // })
    }

    const trashMessage = (message) => {
        // setLoader(true);
        var value = null;

        if (message.trash){
            value = false;
        }else{
            value = true;
        }

        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"message": message.id, "value": value},
            method: "POST"
        };
        HTTP.request('message/is/trash/', config)
        .then(() => {
            window.location.reload();
        })
        .catch((err) => {
            console.log(err);
        })
        // .finally(() => {
        //   setLoader(false);
        // })
    }

    return (
        <>
            <div style={{position:" relative"}}>
                { !isNew  ?
                    <ContainerNewTag>
                        <Trapecio/>
                        <TextTagNew>
                            NEW
                        </TextTagNew>
                    </ContainerNewTag>
                    : 
                    <ContainerNewTag/>
                }
                <MessageContainerGeneral style={{marginRight:"0px"}}>
                    <MessageContainerData>
                        <MessageContainerRow style={{justifyContent: 'space-between'}}>
                            <div style={{display:"flex", alignItems:"center", gap:"5px"}}>
                                { props.item.is_system_message === true ? 
                                    <div style={{display:"flex" , gap: xs ? "5px" : "9px"}}>  
                                        <MessageIcon src='/icons/icon_type_system_message.svg'></MessageIcon>
                                        <MessageText>System Message</MessageText> 
                                    </div> 
                                    : 
                                    <>
                                    { props.item.is_route_message ?
                                        <div style={{display:"flex", gap: xs ? "5px" : "9px"}}>
                                        <MessageIcon src={typeMessage.includes('expedition') ? '/icons/icon_type_expedition_completion.svg' : '/icons/icon_mining.svg'}></MessageIcon>
                                        <MessageText>{typeMessage.includes('expedition') ? 'Expedition Completed' : 'Mining Completed'}</MessageText>
                                        </div> 
                                        :
                                        <div style={{display:"flex" , gap: xs ? "5px" : "9px"}}>  
                                        <MessageIcon src='/icons/icon_type_system_message.svg'></MessageIcon>
                                        <MessageText>Message</MessageText> 
                                        </div> 
                                    }
                                    </>
                                }
                            </div>
                            <div style={{display:"flex", alignItems:"center", gap: xs ? "5px" : "9px"}}>
                                <MessageIcon src='/icons/icon_type_calendar_missions.svg'></MessageIcon>
                                <MessageText> {toDateUTC(props.item.created_at)}</MessageText>
                            </div>
                            <div style={{display:"flex", alignItems:"center", gap: xs ? "5px" : "9px"}}>
                                { !props.item.trash ?
                                    <>
                                    {
                                        props.item.is_favorite ?
                                        <IconSvg icon='/icons/icon_type_white_star_messages.svg' width={'20px'} color={'#EABE3F'}/>
                                        :
                                        <MessageIcon style={{cursor: 'pointer'}} onClick={() => favoriteMessage(props.item)} src='/icons/icon_type_white_star_messages.svg'></MessageIcon>
                                    }
                                    <MessageIcon style={{cursor: 'pointer'}} onClick={() => trashMessage(props.item)} src='/icons/icon_type_message_messages.svg'></MessageIcon>
                                    </>
                                    :
                                    null
                                }
                            </div>
                            {/* <div style={{display:"flex", width:"100%"}}>
                                <MessageContainerRow1 style={{width:"70%", }}>
                                    <div style={{display:"flex", alignItems:"center", gap:"9px"}}>
                                        { props.item.is_system_message === true ? 
                                            <div style={{display:"flex" , gap:"9px"}}>  
                                                <MessageIcon src='/icons/icon_type_system_message.svg'></MessageIcon>
                                                <MessageText>System Message</MessageText> 
                                            </div> 
                                            : 
                                            <>
                                            { props.item.is_route_message ?
                                                <div style={{display:"flex", gap:"9px"}}>
                                                <MessageIcon src={typeMessage.includes('expedition') ? '/icons/icon_type_expedition_completion.svg' : '/icons/icon_mining.svg'}></MessageIcon>
                                                <MessageText>{typeMessage.includes('expedition') ? 'Expedition Completed' : 'Mining Completed'}</MessageText>
                                                </div> 
                                                :
                                                <div style={{display:"flex" , gap:"9px"}}>  
                                                <MessageIcon src='/icons/icon_type_system_message.svg'></MessageIcon>
                                                <MessageText>Message</MessageText> 
                                                </div> 
                                            }
                                            </>
                                        }
                                    </div>
                                    <div style={{display:"flex", alignItems:"center", gap:"9px"}}>
                                        <MessageIcon src='/icons/icon_type_calendar_missions.svg'></MessageIcon>
                                        <MessageText> {toDateUTC(props.item.created_at)}</MessageText>
                                    </div>
                                </MessageContainerRow1>
                            </div>
                            <div style={{justifyContent:"flexEnd",display:"flex", alignItems:"center", gap:"9px"}}>
                                { !props.item.trash ?
                                    <>
                                    {
                                        props.item.is_favorite ?
                                        <IconSvg icon='/icons/icon_type_white_star_messages.svg' width={'20px'} color={'#EABE3F'}/>
                                        :
                                        <MessageIcon style={{cursor: 'pointer'}} onClick={() => favoriteMessage(props.item)} src='/icons/icon_type_white_star_messages.svg'></MessageIcon>
                                    }
                                    <MessageIcon style={{cursor: 'pointer'}} onClick={() => trashMessage(props.item)} src='/icons/icon_type_message_messages.svg'></MessageIcon>
                                    </>
                                    :
                                    null
                                }
                            </div> */}
                        </MessageContainerRow>
                        <MessageContainerRow>
                            <div style={{display:"flex", width:"100%"}}>
                            <MessageText1>{props.item.title}</MessageText1> 
                            </div>
                            <div style={{justifyContent:"flexEnd",display:"flex", alignItems:"center", gap:"9px"}}></div>
                        </MessageContainerRow>
                        {/* <MessageContainerRow><MessageText>{props.item.text}</MessageText></MessageContainerRow> */}
                    </MessageContainerData>
                    <MessageContainerButton>
                            <BtnViewMsg1 onClick={props.action}>
                                <MessageText>Read</MessageText>
                            </BtnViewMsg1>
                    </MessageContainerButton>
                    
                </MessageContainerGeneral>      
  
            </div>
        </>
    )
  }