import { useEffect, useState } from "react";


import '../../styles/core.css'

import { 
  TdData,
  TrData,
  ThData,
  TdDataFoot,
  TextV1,
  FleetData,
  IconFleet,
  TableBodyMain,
  TableHeaderMain,
  TableFootMain,
  ContainerTable,
  ContainerTitle,
  TextTitle,
  TableBase
 } from './TableMyFleet2Element';

export default function TableMyFleet2(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
      setData(props.data);
    }, [props.data]);


    let amount = 0;
    let cargo = 0;
    let shield = 0;
    let armor = 0;
    let damage = 0;
    let fp = 0;

    return (
      <>
        { props.text ? 
          <ContainerTitle><TextTitle>Ships</TextTitle></ContainerTitle>
        :
          null
        }
        <ContainerTable>
          <TableBase className="table-main" >
            <TableHeaderMain>
            {
              <TrData>
                  {
                  props.cols.map((column) => (
                      <ThData style={{width:column.width}}>
                      {
                          <div>
                            {column.Header}
                          </div>
                      }
                      </ThData>
                  ))
                  }
              </TrData>
            }
            </TableHeaderMain>
            
          <TableBodyMain>
                {
                  Object.entries(data).map(([, objects]) => {
                    if (objects.length > 0 ) {
                      amount += objects.length
                      fp += objects[0].ship.fp * objects.length
                      cargo += objects[0].ship.load * objects.length
                      shield += objects[0].ship.shield * objects.length 
                      armor += objects[0].ship.armor * objects.length
                      damage += objects[0].ship.damage * objects.length
                      return (<>
                      <TrData id={objects[0].id + "_row"}>
                        {/* <TdData>
                          <IconActionX onClick={() => removeElement(row)} src={action_x}/>
                        </TdData> */}
                        <TdData>
                          <FleetData>
                            <IconFleet src={objects[0].ship.image}/>
                            <TextV1>
                              {objects[0].ship.name}
                            </TextV1>
                          </FleetData>
                        </TdData>
                        <TdData>
                          {objects.length}
                        </TdData>
                        <TdData>
                          {objects[0].ship.fp> 0 ? (objects[0].ship.fp * objects.length) : objects[0].ship.fp}
                        </TdData>
                        <TdData>
                          {objects[0].ship.load > 0 ? (objects[0].ship.load * objects.length) : objects[0].ship.load}
                        </TdData>
                        <TdData>
                          {objects[0].ship.shield > 0 ? (objects[0].ship.shield * objects.length) : objects[0].ship.shield}
                        </TdData>
                        <TdData>
                          {objects[0].ship.armor > 0 ? (objects[0].ship.armor * objects.length) : objects[0].ship.armor}
                        </TdData>
                        <TdData>
                          {objects[0].ship.damage > 0 ? (objects[0].ship.damage * objects.length) : objects[0].ship.damage}
                        </TdData>
                      </TrData>
                  </>)
                  }else{
                    return null
                  }
                })
              }
          </TableBodyMain>
        </TableBase>
      </ContainerTable >
      <TableFootMain style={{color:"white", gridTemplateColumns: props.gridTemplateColumns}}>
        <TdDataFoot colSpan={2} >
          <strong>Totals</strong>
        </TdDataFoot>
        <TdDataFoot colSpan={2} >
          <strong> {amount} </strong>
        </TdDataFoot>
        <TdDataFoot >
          <strong> {fp} </strong>
        </TdDataFoot>
        <TdDataFoot >
          <strong> {cargo} </strong>
        </TdDataFoot>
        <TdDataFoot>
          <strong> {shield} </strong>
        </TdDataFoot>
        <TdDataFoot>
          <strong> {armor} </strong>
        </TdDataFoot>
        <TdDataFoot style={{width:"30px"}} >
          <strong>{damage} </strong>
        </TdDataFoot>
      </TableFootMain>
    </>

    )

}