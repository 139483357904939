import React, { useState } from 'react'
import { Card, ColButtons, ContainerImgProfile, ContainerInput, ContainerStatsProfile, IconArrow, ImgInfoProfile, ImgInfoProfileBg, InputSetAmount, StatsBold, TextTitle, TitleCard, TitleStatsCard } from './GenericCardElement'
import { ContainerPolygon, PolygonLeft, PolygonRight, RowCore } from '../containers/CoreElement'
import { ButtonAdd, TextPoppinsInfo, TextPoppinsInfoBold, TextTextTitiliumWebInfo, TittleTextV7 } from './ReusableElementComposeYourFleetElement'
import GridSpecs from './GridSpecs'
import { RowCoreBonus } from '../components/Home/Pages/Commander/CommanderElement'
import { DropdownStats } from './DropdownStats'
import '../styles/core.css'

import icon_stats from '../assets/images/icon_stats.svg'
import { mineralToIcon } from '../components/utils/utils'

export default function GenericCard({item=[], onClickAdd, importItems=false}) {

            
    const [inputAmountAdd, setInputAmountAdd] = useState('');

    console.log(item);

    const addElement = (item, count) => {
        let maxUnits = 0;
        if(!importItems){
            maxUnits = item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type') ? item.length : parseInt(item[0].value / 1000);
        }else{
            maxUnits = item.userBalance;
        }
        if(count > maxUnits){
            setInputAmountAdd(maxUnits)
        
        }else if(count < 0){
            setInputAmountAdd(Math.abs(count))
        
        }else{
            onClickAdd(item, count);
            setInputAmountAdd('');
        }

    };

    const handleInputAmountAddChange = (event) => {
        setInputAmountAdd(event.target.value);
    };

    const handleIncrement = (item) => {

        let numMax = 0;

        if(!importItems){
            numMax = item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type') ? item.length :  parseInt(item[0].value / 1000)
        }else{
            numMax = item.userBalance;
        }

        if(inputAmountAdd.length === 0){
            setInputAmountAdd(1)
        }else if(inputAmountAdd < numMax){
            setInputAmountAdd(parseInt(inputAmountAdd) + 1);
        }
    }

    const handleDecrement = () => {
        if(inputAmountAdd >= 1){
            setInputAmountAdd(parseInt(inputAmountAdd) - 1);
        }
    }


  return (
    <>
        <Card>
            { !importItems ? 
                <>
                <TitleCard>
                    <TextTitle>
                        {item[0].hasOwnProperty('commander') ? item[0].commander.name : (item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type') ? item[0].name : "1K " + item[0].name + " INGOT")}
                    </TextTitle>
                </TitleCard>
                <ContainerImgProfile>
                { item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type') ?
                    <ImgInfoProfile src={item[0].hasOwnProperty('commander') ? item[0].commander.image : item[0].image} />
                    :
                    <ImgInfoProfileBg src={mineralToIcon(item[0].name, true)} />
                }

                { item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') ?
                    <>
                    <ContainerPolygon>
                        <PolygonLeft>
                            { item[0].hasOwnProperty('commander') ?
                                <TextPoppinsInfo>ID:<TextPoppinsInfoBold>#{item[0].commander.id}</TextPoppinsInfoBold></TextPoppinsInfo>
                                :
                                <TextPoppinsInfo>FP:<TextPoppinsInfoBold>{item[0].fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                            }
                        </PolygonLeft>
                    </ContainerPolygon>
                    <ContainerPolygon style={{right: '0'}}>
                        <PolygonRight>
                            <TextTextTitiliumWebInfo>Lvl {item[0].hasOwnProperty('commander') ? item[0].commander.level : 1}</TextTextTitiliumWebInfo>
                        </PolygonRight>
                    </ContainerPolygon>
                    </>
                    :
                    null
                }
                </ContainerImgProfile>
                <ContainerStatsProfile>
                    <GridSpecs specs={item[0].specs} spaceBetweenColumns={'7px'} marginTop={'2%'} />
                    <>
                        <RowCore style={{ marginTop: "3px", marginBottom: "9px" }}>
                            { item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type') ?
                                <TitleStatsCard>Class: <StatsBold>{item[0].hasOwnProperty('commander') ? item[0].commander.class : item[0].hasOwnProperty('item_type') ? item[0]._class : item[0].class}</StatsBold></TitleStatsCard>
                                :
                                <TitleStatsCard>Class: <StatsBold>{'Mineral'}</StatsBold></TitleStatsCard>
                            }
                        </RowCore>
                        { item[0].hasOwnProperty('commander') ?
                            <RowCoreBonus style={{marginBottom: "9px" }}>
                                <TitleStatsCard>Bonus: <StatsBold>{item[0].commander.bonus.name}</StatsBold></TitleStatsCard>
                            </RowCoreBonus>
                            :
                            null
                        }
                        <RowCoreBonus>
                            <TitleStatsCard>Units: <StatsBold>{item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type') ? item.length : parseInt(item[0].value / 1000)}</StatsBold></TitleStatsCard>
                        </RowCoreBonus>
                    </>

                </ContainerStatsProfile>
                { item[0].hasOwnProperty('commander') ?
                    <DropdownStats textButton='Stats' iconButton={icon_stats} jsonObj={item[0].commander.bonus}/>
                    :
                    null
                }
                { item.length > 1 ||  parseInt(item[0].value / 1000) > 1 ?
                    <RowCore>
                        <ContainerInput>
                            <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='Set amount' min={1} max={item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') ? item.length :  parseInt(item[0].value / 1000)} value={inputAmountAdd} onChange={handleInputAmountAddChange}/>
                            <ColButtons>
                                <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrement(item)}/>
                                <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrement()}/>
                            </ColButtons>
                        </ContainerInput>
                        <ButtonAdd onClick={() => addElement(item, parseInt(inputAmountAdd))} style={{width: '30%'}}>
                            <TittleTextV7>
                            Add
                            </TittleTextV7>
                        </ButtonAdd>

                    </RowCore>
                    :
                    <ButtonAdd onClick={() => addElement(item, 1)} style={{width: '100%'}}>
                        <TittleTextV7>
                        Add
                        </TittleTextV7>
                    </ButtonAdd>
                }
                </>
                :
                <>
                <TitleCard>
                    <TextTitle>
                        {item.name}
                    </TextTitle>
                </TitleCard>
                <ContainerImgProfile>
                    <ImgInfoProfile src={item.image} />  
                </ContainerImgProfile>
                <ContainerStatsProfile>
                    <>
                        <RowCoreBonus>
                            <TitleStatsCard>Units: <StatsBold>{item.userBalance}</StatsBold></TitleStatsCard>
                        </RowCoreBonus>
                    </>
                </ContainerStatsProfile>
                { item.userBalance > 1 ?
                    <RowCore>
                        <ContainerInput>
                            <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='Set amount' min={1} max={item.userBalance} value={inputAmountAdd} onChange={handleInputAmountAddChange}/>
                            <ColButtons>
                                <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrement(item)}/>
                                <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrement()}/>
                            </ColButtons>
                        </ContainerInput>
                        <ButtonAdd onClick={() => addElement(item, parseInt(inputAmountAdd))} style={{width: '30%'}}>
                            <TittleTextV7>
                            Add
                            </TittleTextV7>
                        </ButtonAdd>
                    </RowCore>
                    :
                    <ButtonAdd onClick={() => addElement(item, 1)} style={{width: '100%'}}>
                        <TittleTextV7>
                        Add
                        </TittleTextV7>
                    </ButtonAdd>
                }
                </>
            }
       
    </Card>
    </>

  )
}
