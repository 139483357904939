import styled from "styled-components";
import Dropdown from 'react-bootstrap/Dropdown';
import { GridViewCoreWithoutScroll, RowSpaceBetween, TextTitiliumWeb } from "../containers/CoreElement";

export const InputPlaceholder = styled.input`
    ::placeholder {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: rgba(255, 255, 255, 0.6);
    }
`
export const InputSearch = styled(InputPlaceholder)`
    flex: 1; 
    border: none; 
    padding: 5px;
    outline: none; 
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), rgba(78, 6, 44, 0.25); 
    color: rgba(255, 255, 255, 0.6);

    &::placeholder{
        font-size: 13px;
    }
`

export const DropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    align-items: center;
    border: 1.5px solid #5E0835; 
    border-radius: 1px; 
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 5px 0px;
    
    &&& {
        background-color: transparent;
        border: 1.5px solid #5E0835; 
    }

    &::after {
        display: none;
    }
`

export const DropdownMenu = styled(Dropdown.Menu)`
    border-radius: 1px; 
    border: 1px solid #E71383;
    background: rgb(46, 4, 27);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    z-index: 99999999;
`
export const DropdownItem = styled(Dropdown.Item)`
    color: white;
    width: 100%;

    &:hover{
        background-color: #E71383;
        color: white;
    }
`

export const TextOption = styled(TextTitiliumWeb)`
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    margin-left: 4%;
`

export const GridFilters = styled(GridViewCoreWithoutScroll)`
    margin-top: 2%;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 10px;
    mix-blend-mode: normal;
    @media (min-width: 601px) {
        display: none;
    }
`

export const RowSpacebetweenDesktop = styled(RowSpaceBetween)`
    
    @media (max-width: 600px) {
        display: none;
    }
`