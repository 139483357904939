import styled from "styled-components";
import { BtnPolygon, ColCore, ImgIcon, TextTitiliumWeb, TextOrbitron } from "../containers/CoreElement";
import { Link } from "react-router-dom";

export const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`

export const ContainerPopUp = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #370E23;
    border: 1px solid #E71383;
    color: #fff;
    padding: 15px;
    height: auto;
    min-width: 600px;
    z-index: 10;

    @media(max-width: 600px) {
        min-width: 300px;
        width: 350px;
    }
`
export const BtnClosePopUpMsg = styled(BtnPolygon)`
    position: absolute;
    top: -14px;
    right: -14px;
    cursor: pointer;
    width: 42px;
    height: 42px;
`
export const ImgIconCloseMsg = styled(ImgIcon)`
    width: 15px;
    height: 15px;
`
export const Icon = styled(ImgIcon)`
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const SectionDiv = styled.div`
    width: 100%;
    padding: 10px 0px 10px 0px;
`
export const Title = styled.div`
    font-family: "Poppins"; 
`
export const BuyButton = styled.button`
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;    
    background-color: rgb(231, 19, 131);
    clip-path: polygon(0px 9px, 9px 0px, 100% 0px, 100% calc(100% - 9px), calc(100% - 9px) 100%, 0px 100%);
    border: none;
    margin-left: 2%;
`
export const TextButton = styled.div`
    font-family: "Poppins"; 
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: white;
    display: flex;
    gap: 3px;
`
export const ContainerResource = styled.div`
    font-family: "Poppins"; 
    font-size: 14px;
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
`

export const ContainerResourceCellphone = styled(ContainerResource)`
    @media (min-width: 601px) {
        display: none;
    }
`
export const TextValue = styled(TextTitiliumWeb)`
    font-family: "Poppins"; 
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const MenuLink = styled(Link)`
    color: #fff;
    display: flex;
    cursor: pointer;
    align-items: center;
`

export const IconAdd = styled(Icon)`
    width: 10px;
    height: 10px;
`

export const ColDesktop = styled(ColCore)`

    @media (max-width: 600px) {
        display: none;
    }
`

export const ContainerWithdrawContent = styled.div`
    gap: 20px;
    display: flex;
    align-items: center;
`

export const ContainerSMCWBalance = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
`

export const TitleSMCWBalance = styled.div`
    font-family: "Poppins"; 
    font-size: 14px;
    justify-content: center;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
`

export const SMCWBalance = styled.div`
    font-family: "Poppins"; 
    font-size: 14px;
    color: rgb(255, 255, 255);
    display: flex;
    gap: 5px;
    padding: 10px;
    background: #370E23;
    border: 1px solid #E71383;

`

export const IconCoin = styled(ImgIcon)`
    width: 20px;
    height: 20px;
    margin-right: 5%;
`

export const WithdrawButton = styled.button`
    height: 30px;
    width: 100%;
    border: none;
    background-color: #E71383;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const TextWithdraw = styled(TextOrbitron)`
    letter-spacing: 0.02em;
    text-transform: none;
    font-size: 10px;
    font-weight: 700;
`

export const TextSMCWBalance = styled.span`
    align-items: center;
    display: flex;
    gap: 5px;
`