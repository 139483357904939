import styled from "styled-components";
import { BtnPolygon, ImgIcon, TextOrbitron } from "../containers/CoreElement";

export const ImgIconRotate = styled(ImgIcon)`
    transform: rotate(180deg);
`
export const BtnNumPage = styled(BtnPolygon)`
    width: 33px;
    height: 30px;
    background: rgba(231, 19, 131, 0.5);
`

export const BtnNumPageSquare = styled.div`
    width: 30px;
    height: 30px;
    background: #E71383;
    cursor: pointer;
`
export const TextBtnNumPage = styled(TextOrbitron)`
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
`