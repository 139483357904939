import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../../../http/http-common';
import { useNavigate } from 'react-router-dom'


import { 
    ContainerElements, ContainerForge, Text
} from './ForgeElement'


import {ReusableCardProcess} from "../../../../../../hooks/ReusableCardProcess";


import PopUp from '../../../../../../hooks/PopUp';
import Pagination from '../../../../../../hooks/Pagination';
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids';
import { Loader } from '../../../../../../hooks/Loader';
import { FilterBar } from '../../../../../../hooks/FilterBar';
import { TextPoppins } from '../../../../../../containers/CoreElement';
import { globalData, updateNavs } from '../../../../../utils/utils';







export function ForgeInProcess() {
    const [elementsForgeInProcess, setElementsForgeInProcess] = useState([]);
    const [popUpInstantAll, setPopUpInstantAll] = useState(false);

    const fetchShipsForgeAllValues= () => {

        const clickInstantAll = () => {
          updateNavs().then(() => {
            if(globalData.crownsAvailable > 0){
              setPopUpInstantAll(true)
            }else{
              setPopupBuyCrown(true)
            }
          })
        }

        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/forge/all/values/', config)
          .then((res) => {
            if (res.data.result){
              var elements_forge_in_process = [
                {'icon' : '/icons/icon_type_instant_forge.svg', 'text' :'INSTANT FORGE ALL', 'iconValue':'/icons/icon_new_crown.png','value' : res.data.values["cost_instant_forge"] ,'action' : () => clickInstantAll(), 'backgroundItem' : "#AA68D8"},
              ]
              setElementsForgeInProcess(elements_forge_in_process);
            }else{
              setElementsForgeInProcess([]);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      };

      const fetchShipsForgeAll = () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          method: "POST"
        };
        HTTP.request('ships/intant/all/forge/process/', config)
          .then((res) => {
            if (res.data.result){
              console.log("All forged Ships successfullly!");
              navigate('/shipyard/forge/pick-up')
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      };

    const [cardsForgeInProcess, setCardsForgeInProcess] = useState([]);
    const [loader, setLoader] = useState(false);
    const [popUpInstant, setPopUpInstant] = useState(false);
    const [popUpCancel, setPopUpCancel] = useState(false)

    const [cardSelected, setCardSelected] = useState({});

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');

    const [popupBuyCrown, setPopupBuyCrown] = useState(false)


    let navigate = useNavigate();

    useEffect(() => {
        updateNavs();
        fetchShipsForgeAllValues();
        fetchShipsForgeInProgress();
    }, []);

    const fetchShipsForgeInProgress = () => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/forge/process/', config)
            .then((res) => {
            if (res.data.result){
                setCardsForgeInProcess(res.data.forges)
            }
            })
            .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
            })
            .finally(() => {
                setLoader(false);
              })
    };

    const instantForgeShip = (forgeCode) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"forge_code": forgeCode, "instant_forge": true},
            method: "POST"
        };
        HTTP.request('ships/intant/forge/process/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/shipyard/forge/pick-up')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    }

    const cancelForge = (forgeCode) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"forge_code": forgeCode},
            method: "POST"
        };
        HTTP.request('ships/forge/cancel/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/shipyard/forge/blueprints')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    }

    const selectPopUpInstant = (card) => {

      updateNavs().then(() => {
        if(globalData.crownsAvailable > 0){
          setCardSelected(card);
          setPopUpInstant(true);
        }else{
          setPopupBuyCrown(true)
        }
      })
    }

    const selectCancelForge = (card) => {
        setCardSelected(card);
        setPopUpCancel(true);
    }


    return (

        <>
            <Loader loading={loader}/>

            {elementsForgeInProcess.length > 0 ?
              <FilterBar
                elements = {elementsForgeInProcess}
                columns = "repeat(1,1fr)"
              ></FilterBar>  
              :
              null
            }
            <ContainerForge style={{height: cardsForgeInProcess.length !== 0 ? '650px' : '170px'}}>
                <FilterForGrids allElements={cardsForgeInProcess} sortByClass>
                    { elementsToFilter => (
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData='There are not ships in process'>
                            { elementsToRender => (
                                <ContainerElements>
                                    {elementsToRender.map((card) => (
                                    <ReusableCardProcess
                                        specs={card.ship.specs}
                                        item={card}
                                        actionInstantForge={() => selectPopUpInstant(card)}
                                        actionCancel={() => selectCancelForge(card)}
                                    ></ReusableCardProcess>
                                    ))}
                                </ContainerElements>
                            )
                            }
                        </Pagination>
                    )
                    }
                </FilterForGrids>
            </ContainerForge>

            <PopUp show={popUpInstant} onClose={() => setPopUpInstant(false)} onConfirm={() => instantForgeShip(cardSelected.forge_code)}>
                <Text>Are you sure you want to perform an instant forge?</Text>
            </PopUp>

            <PopUp show={popUpCancel} onClose={() => setPopUpCancel(false)} onConfirm={() => cancelForge(cardSelected.forge_code)}>
                <Text>Are you sure you want to cancel the forge?</Text>
            </PopUp>

            <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
                <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                    {textServerError}
                </TextPoppins>
            </PopUp>

            <PopUp show={popUpInstantAll} onClose={() => setPopUpInstantAll(false)} onConfirm={() => fetchShipsForgeAll()}>
                <Text>Are you sure you want to forge all instant?</Text>
            </PopUp>

            <PopUp show={popupBuyCrown} onClose={() => setPopupBuyCrown(false)} onConfirm={() => window.open('https://spacemisfits.com/signin/', '_blank')} textBtnNo="Dismiss" textBtnYes="Buy CROWN">
              <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                  Oops, it seems like you're short on CROWN. <br/>
                  No worries! You can easily purchase more to unlock this cool feature.
              </TextPoppins>
            </PopUp>
        </>

 


  )

}