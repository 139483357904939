import React, { useEffect, useState } from 'react';
import { HTTP } from '../../../../../http/http-common';

import { 
    ContainerElements,
    ContainerForge,

} from './ForgeElement'


import { convertTimestampMinutes, updateNavs } from '../../../../../../components/utils/utils';

import ReusableCardForge from "../../../../../../hooks/ReusableCardForge";

import CardForgeSelected from "../../../../../../hooks/CardForgeSelected";
import Pagination from '../../../../../../hooks/Pagination';
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids';
import { Loader } from '../../../../../../hooks/Loader';
import PopUp from '../../../../../../hooks/PopUp';
import { TextPoppins } from '../../../../../../containers/CoreElement';





export function ForgeBlueprints(props) {


  const [selectedInfoForge, setSelectedInfoForge] = useState({"specs":["",""],});
  const [, setIndex] = useState(-1);
  const [materials, setMaterials] = useState([]);

  const [loader, setLoader] = useState(false);

  const [popupServerError, setPopupServerError] = useState(false);
  const [textServerError, setTextServerError] = useState('Server Error');
  const [cardsForge, setCardsForge] = useState([]);
  

  
  const handleExtendedForge = (card, index) => {
    setLoader(true);
    
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('minerals/', config)
      .then((res) => {
        if (res.data.result){
          setMaterials([
            {'name': 'Peroxium', 'has': res.data.minerals.peroxium, 'need': card.peroxium},
            {'name': 'Trantium', 'has': res.data.minerals.trantium, 'need': card.trantium},
            {'name': 'Obscurian', 'has': res.data.minerals.obscurian, 'need': card.obscurian},
            {'name': 'Mercoxium', 'has': res.data.minerals.mercoxium, 'need': card.mercoxium},
            {'name': 'Crelyte', 'has': res.data.minerals.crelyte, 'need': card.crelyte},
            {'name': 'Bits', 'has': res.data.minerals.bits, 'need': card.bits}
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
        setPopupServerError(true);
        setTextServerError(err.response.statusText);
      })
      .finally(() => {
        setLoader(false);
      })
    setSelectedInfoForge(card);
    setIndex(index);
    props.closeHeader(true);
    props.closeCardForgeSelected(true);

  }

  const fetchShipsForge = () => {
    setLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('ships/base/', config)
      .then((res) => {
        if (res.data.result){
            setCardsForge(res.data.ships)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };

  useEffect(() => {
    fetchShipsForge();
    updateNavs();
}, []);
    
  return (
    <>
      <Loader loading={loader}/>
      <>
        {props.isOpenBlueprints ?
          <CardForgeSelected
            item = {selectedInfoForge}
            materials={materials}
            traits={selectedInfoForge}
          ></CardForgeSelected>
          :
          null
        }

        <ContainerForge style={{height: cardsForge.length !== 0 ? '650px' : '170px'}}>
          <FilterForGrids allElements={cardsForge} sortByType sortByClass>
            { elementsToFilter => (
              <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData='There are not ships in blueprints'>
                { elementsToRender => (
                  <ContainerElements>
                    {elementsToRender.map((card, index) => (
                      <ReusableCardForge
                        image={card.image_blueprint}
                        name={card.name}
                        icon1={card.icon1}
                        class={card.class}
                        specs={card.specs}
                        buildTime={convertTimestampMinutes(card.forge_time)}
                        description={card.description}
                        forgeAmount ={card.forgeAmount}
                        action={() => handleExtendedForge(card, index)}
                      ></ReusableCardForge>
                    ))}
                  </ContainerElements>
                )
                }
              </Pagination>
            )
            }
          </FilterForGrids>
        </ContainerForge>
      </>

      <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
          <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            {textServerError}
          </TextPoppins>
      </PopUp>
      
    </>


  )

}