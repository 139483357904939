import { TextTitiliumWeb } from '../../../../containers/CoreElement';
import styled from "styled-components";

export const Text = styled(TextTitiliumWeb)`
    font-weight: 100;
    letter-spacing:0px;
    
`

export const StatsContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 9px;
`

export const Icon = styled.img`
`

export const TextBold = styled(TextTitiliumWeb)`
    font-weight:bold;
    letter-spacing:0px;
`
export const TextCoins = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NumberCoins = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5%;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    color: #FFFFFF;
`
