import React from 'react';

import { YourOutposts } from './Pages/YourOutposts/YourOutposts';
import {HeaderOutposts} from './Pages/HeaderOutposts/HeaderOutposts';
export function Outposts() {


  return (
    <>
      <div style={{width:"990px"}}>
      <HeaderOutposts>
      </HeaderOutposts>
      <YourOutposts>
      </YourOutposts>
      </div>
    </>
  )

}