import styled from "styled-components";

import { 
    GridViewCore,
    TextTitiliumWeb
  } from '../../../../../../containers/CoreElement'


export const ContainerElements = styled(GridViewCore)`
    grid-template-columns: repeat(3, 1fr);
    height: 510px;
    overflow-y: scroll;
    margin-left: 1.5%;
    margin-right: 1.5%;
    margin-top: 15px;
    gap: 15px;

`

export const Text = styled(TextTitiliumWeb)`
    font-weight: 100;
    letter-spacing:0px;
    
`

export const ContainerRepairBay = styled.div`
    margin-top: 1.5%;
    padding: 8px;
    width: 100%;
    height: 650px;
    border: 1px solid #E71383;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2F041A;
    background-size: 100% 100%;
`