import React, { useState, useEffect } from 'react';

import { HTTP } from '../../../http/http-common';

import {
  useNavigate,
  useParams,
  useLocation
} from "react-router-dom";

import { ContainerNoBorder, TextPoppins} from '../../../../containers/CoreElement'

import {ColsMyFleet} from "../../../../hooks/ColsMyFleet";
import {ColsMyCommander} from "../../../../hooks/ColsMyCommander";
import { convertTimestampMinutes, updateNavs } from '../../../../components/utils/utils';

import button_send_fleet from '../../../../assets/images/button_send_fleet.png'
import messages_header from '../../../../assets/images/messages_header.png'
import icon_back from '../../../../assets/images/icon_back.svg'
import mission_expedition from '../../../../assets/images/mission_expedition.png'
import mission_mining from '../../../../assets/images/mission_mining.png'
import icon_available_slots from '../../../../assets/images/icon_available_slots.svg'
import icon_commnaders from '../../../../assets/images/icon_commanders.svg'
import icon_fleet_points from '../../../../assets/images/icon_fleet_points.svg'
import icon_fuel from '../../../../assets/images/icon_fuel.svg'
import icon_ship from '../../../../assets/images/icon_ship.svg'
import ReusableRectangle from '../../../../hooks/ReusableRectangle'

import '../MissionsSetup/hooks/styles.css'

import { 
  ContainerMission,
} from '../Missions/MissionsElement';

import { 
  ContainerMyFleet,
  TittleV1,
  TittleBarV1,
  IconTittle,
  TittleTextV1,
  TittleTextV2,
  ContainerTableMyFleet,
  ContainerFleetSetup,
  ComposeYourFleetLayer1,
  ComposeYourFleetLayer2,
  ComposeYourFleetLayer3,
  GridViewComposeYourFleet,
  ContainerGridView,
  ContainerBtnBack,
  RowBtnBack,
  BtnBack,
  ImgBtnBack,
  TextBtnBack,
  TittleFleetSetup,
  ContainerAvailableSlots,
  IconsAvailableSlots,
  TextTitlesAvailableSlots,
  TextTitiliumLineHeight,
  IconsAvailableSlotsMargin,
  ContainerOptions,
  RowCenter,
  ContainerIconCommanders,
  RowSpaceBetween100,
  GridViewAvailableSlots,
  RowJustifyCenter,
  GridMain,

} from './MissionsSetupElement';


import ReusableElementComposeYourFleet from '../../../../hooks/ReusableElementComposeYourFleet'

import TableMyFleet from '../../../../tables/TableMyFleet/TableMyFleet';
import TableMyCommander from '../../../../tables/TableMyCommander/TableMyCommander';
import HeaderScreen from '../../../../hooks/HeaderScreen';
import IconSvg from '../../../../hooks/IconSvg';
import PopUp from '../../../../hooks/PopUp';
import FleetStrength from '../../../../hooks/FleetStrength';
import { Loader } from '../../../../hooks/Loader';

export function MissionsSetup() {

  const location = useLocation();
  const params = location.state;


  const style_class = {
    active : {backgroundColor: '#FF0087', color: '#ffffff'}, 
    inactive: {backgroundColor: 'rgba(141, 12, 80, 0.5)', color: 'rgba(255, 255, 255, 0.7)'}
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const [composeYourFleetShips, setComposeYourFleetShips] = useState([]);
  const [composeYourFleetCommander, setComposeYourFleetCommander] = useState([]);
 
  const [showPopUpInfo, setShowPopUpInfo] = useState(false);

  const [loader, setLoader] = useState(false);

  const [textPopUp, setTextPopUp] = useState('');

  const [myFleet, setMyFleet] = useState({});
  const [myCommander, setMyCommander] = useState({});

  const [optionShips, setOptionShips] = useState(true);

  const [commanderSelected, setCommanderSelected] = useState(true);
  const [commanderLimit] = useState(1);
  const [commanderFleetPointsLimit, setCommanderFleetPointsLimit] = useState(0);
  const [fleetStrengthStatus, setFleetStrengthStatus] = useState("Weak");
  const [fuelConsumption, setFuelConsumption] = useState(0);

  const sendFleet = (data) => {
    if ( countShipsFleetPoints() <= commanderFleetPointsLimit){
      console.log(data["commander_id"])
      if (data["choice_ships"].length > 0 && data["commander_id"].length > 0){
        if (params.p_mission_economic.type_of_route_name.includes('expedition')){
          if (params.route_time_code){
            data["route_time_code"] = params.route_time_code
          }
        }
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          data: data,
          method: "POST"
        };
        HTTP.request('start_route/', config)
          .then((res) => {
            if (res.data.result){
              setShowPopUpInfo(true);
              setTextPopUp('Fleet successfully shipped!!')
              console.log("Fleet successfully shipped!!");
              navigate("/dashboard");
            }else{
              if (res.data.response){
                setShowPopUpInfo(true);
                setTextPopUp(res.data.response)
                console.log(res.data.response);
              }else{
                setShowPopUpInfo(true);
                setTextPopUp('An error occurred while sending the fleet!')
                console.log("An error occurred while sending the fleet!");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      }else{
        setShowPopUpInfo(true);
        setTextPopUp('Your fleet has no ships added! or Your fleet has no commanders added!')
        console.log("Your fleet has no ships added! or Your fleet has no commanders added!");
      }
    }else{
      setShowPopUpInfo(true);
      setTextPopUp('Your fleet has more Fleet point than the commander Fleet Points limit!')
      console.log("Your fleet has more Fleet point than the commander Fleet Points limit!");
    }
  };
  

  useEffect(() => {
    updateNavs();
    fetchComposeYourFleet();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groupElements = (jsonList) => {

    console.log(jsonList)

    let groupedData = jsonList.reduce((grouped, item) => {
        if (!grouped[item.id]) {
          grouped[item.id] = [item];
        } else {
          grouped[item.id].push(item);
        }
        return grouped;
    }, {});
    
    console.log(groupedData);
    return groupedData;
  }

  const groupElementsCommander = (jsonList) => {

    console.log(jsonList)

    let groupedData = jsonList.reduce((grouped, item) => {
        if (!grouped[item.commander.id]) {
          grouped[item.commander.id] = [item];
        } else {
          grouped[item.commander.id].push(item);
        }
        return grouped;
    }, {});
    
    console.log(groupedData);
    return groupedData;
  }

  const fetchComposeYourFleet = () => {
    setLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('get_compose_your_fleet', config)
      .then((res) => {
        if (res.data.result){
          var count = 0;
          var compose_your_fleet_ships = [];
          var compose_your_fleet_commander = [];

          var commanders = res.data.commanders;
          for (let index = 0; index < commanders.length; index++) {
            commanders[index]["enumerate"] = count;
            commanders[index]["type_enumerate"] = "commander";
            compose_your_fleet_commander.push(commanders[index]);
            count++;
          }

          console.log(res.data)

          var ships = res.data.ships;
          for (let index = 0; index < ships.length; index++) {
            ships[index]["enumerate"] = count;
            ships[index]["type_enumerate"] = "ship";
            compose_your_fleet_ships.push(ships[index]);
            count++;
          }

          compose_your_fleet_ships = groupElements(compose_your_fleet_ships);
          compose_your_fleet_commander = groupElementsCommander(compose_your_fleet_commander);
          console.log(compose_your_fleet_ships)
          setComposeYourFleetShips(compose_your_fleet_ships);
          setComposeYourFleetCommander(compose_your_fleet_commander);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };

  const deleteElements = (list, count) => {
    var elementsRemoved = [];
    for (let index = 0; index < count; index++) {
      elementsRemoved.push(list.shift());
    }
    return [list, elementsRemoved];
  }

  const elmentsRemovedToAddAction = (elmentsRemovedToAdd, name, updateElements) => {
    if (!elmentsRemovedToAdd[name]) {
      elmentsRemovedToAdd[name] = [];
      for (let index = 0; index < updateElements[1].length; index++) {
        const element = updateElements[1][index];
        elmentsRemovedToAdd[name].push(element);
      }
    } else {
      for (let index = 0; index < updateElements[1].length; index++) {
        const element = updateElements[1][index];
        elmentsRemovedToAdd[name].push(element);
      }
    }
    return elmentsRemovedToAdd;
  }

  const countCommanders = () => {
    var count = 0;
    Object.entries(myCommander).forEach(([name, objects]) => {
      count += objects.length;
    })
    return count;
  }

  const countTotalCommandersFleetPoints = () => {
    var count = 0;
    Object.entries(myCommander).forEach(([name, objects]) => {
      console.log(objects)
      for (let index = 0; index < objects.length; index++) {
        count += objects[index].commander.fp;
        console.log(objects[index].commander.fp)
      }
    })
    setCommanderFleetPointsLimit(count);
  }

  const countShipsFleetPoints = () => {
    var count = 0;
    Object.entries(myFleet).forEach(([, objects]) => {
      console.log(objects)
      for (let index = 0; index < objects.length; index++) {
        count += objects[index].fp;
      }
    })
    return count;
  }

  const calculateFleetStrength = () => {
    var choice_ships = [];
    var choice_commanders = [];
    Object.entries(myFleet).forEach(([, objects]) => {
      for (let index = 0; index < objects.length; index++) {
        choice_ships.push(objects[index].id_ship_x_user);
      }
    })

    Object.entries(myCommander).forEach(([name, objects]) => {
      for (let index = 0; index < objects.length; index++) {
        choice_commanders.push(objects[index].id);
      }
    })
    if (choice_ships.length > 0){
      if (choice_commanders.length > 0){
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          data: {"route": id, "choice_ships": choice_ships, "commander_id": choice_commanders},
          method: "POST"
        };
        HTTP.request('fleet/power/balance/', config)
          .then((res) => {
            setFuelConsumption(res.data.total_fuel_consumption)
            setFleetStrengthStatus(res.data.capacity)
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      }
    }else{
      setFleetStrengthStatus("Weak")
    }
    return {"route": id, "choice_ships": choice_ships, "commander_id": choice_commanders};
  }

  const addElement = (item, count) => {
    var name = null;
    var elmentsRemovedToAdd = null;
    if (item.type_enumerate === "ship"){
      name = item.id;
      if (composeYourFleetShips[name].length >= count){
        const updateElements = deleteElements(composeYourFleetShips[name], count);
        setComposeYourFleetShips(prevData => ({
          ...prevData,
          [name]: updateElements[0]
        }));
        elmentsRemovedToAdd = myFleet;
        elmentsRemovedToAdd = elmentsRemovedToAddAction(elmentsRemovedToAdd, name, updateElements)
        console.log(myFleet);

        setMyFleet(prevData => ({
          ...prevData,
          [name]: elmentsRemovedToAdd[name]
        }));
      }else{
        setShowPopUpInfo(true);
        setTextPopUp('The amount entered is greater than the available limit');
        console.log("The amount entered is greater than the available limit");
      }

    }else{
      name = item.commander.id;
      if (commanderLimit >= (countCommanders() + count)){
        if (composeYourFleetCommander[name].length >= count){
          const updateElements = deleteElements(composeYourFleetCommander[name], count);
          setComposeYourFleetCommander(prevData => ({
            ...prevData,
            [name]: updateElements[0]
          }));

          elmentsRemovedToAdd = myCommander;

          elmentsRemovedToAdd = elmentsRemovedToAddAction(elmentsRemovedToAdd, name, updateElements)

          setMyCommander(prevData => ({
            ...prevData,
            [name]: elmentsRemovedToAdd[name]
          }));
          if (commanderLimit <= countCommanders()){
            setCommanderSelected(false);
          }else{
            setCommanderSelected(true);
          }
          countTotalCommandersFleetPoints();
        }else{
          setShowPopUpInfo(true);
          setTextPopUp('The amount entered is greater than the available limit');
          console.log("The amount entered is greater than the available limit");
        }
      }else{
        setShowPopUpInfo(true);
        setTextPopUp('The amount entered is greater than the available commanders limit');
        console.log("The amount entered is greater than the available commanders limit");
      }
    }
    calculateFleetStrength();
  };

  const removeElement = (item, count) => {
    var name = null;
    var elmentsRemovedToAdd = null;
    if (item.type_enumerate === "ship"){
      name = item.id;
      if (myFleet[name].length >= count){
        const updateElements = deleteElements(myFleet[name], count);
        setMyFleet(prevData => ({
          ...prevData,
          [name]: updateElements[0]
        }));
        elmentsRemovedToAdd = composeYourFleetShips;
        elmentsRemovedToAdd = elmentsRemovedToAddAction(elmentsRemovedToAdd, name, updateElements)

        setComposeYourFleetShips(prevData => ({
          ...prevData,
          [name]: elmentsRemovedToAdd[name]
        }));
      }else{
        setShowPopUpInfo(true);
        setTextPopUp('The amount entered is greater than the available limit');
        console.log("The amount entered is greater than the available limit");
      }

    }else{
      name = item.commander.id;
      if ((countCommanders() - count) >= 0){
        if (myCommander[name].length >= count){
          const updateElements = deleteElements(myCommander[name], count);
          setMyCommander(prevData => ({
            ...prevData,
            [name]: updateElements[0]
          }));
          elmentsRemovedToAdd = composeYourFleetCommander;

          elmentsRemovedToAdd = elmentsRemovedToAddAction(elmentsRemovedToAdd, name, updateElements)

          setComposeYourFleetCommander(prevData => ({
            ...prevData,
            [name]: elmentsRemovedToAdd[name]
          }));
          if (commanderLimit <= countCommanders()){
            setCommanderSelected(false);
          }else{
            setCommanderSelected(true);
          }
        }else{
          setShowPopUpInfo(true);
          setTextPopUp('The amount entered is greater than the available limit');
          console.log("The amount entered is greater than the available limit");
        }
      }else{
        setShowPopUpInfo(true);
        setTextPopUp('The amount entered is greater than the available limit');
        console.log("The amount entered is greater than the available limit");
      }
    }
    calculateFleetStrength();
  };

  return (
    <>
    <Loader loading={loader}/>
    <ContainerNoBorder>
      <HeaderScreen onClickBack={() => navigate("/routes/expeditions")} extended={false} imgHeader={messages_header} title={'ROUTE SETUP'} icon={'/icons/icon_type_header_routes.svg'} />
      <ContainerMission>
          <ReusableRectangle
            missions = {true}
            image = {params.p_mission_economic.type_of_route_name.includes('expedition') ? mission_expedition : mission_mining}
            outpost = {params.outpost}
            difficulty = {'Easy'}
            type = {params.p_mission_economic.type_of_route_name}
            mission_id = {params.id}
            specs = {params.expected_reward}
            enemy_ships = {params.horde}
            min_spent = {convertTimestampMinutes(params.min_spent*2)}
            value1Second = {params.commander_enemy_class}
            value2Second = "value2"
            value3Second = "value3"
            value4Second = "value4"
            buttonText = "Send Fleet"
            //buttonColor = "rgba(231, 19, 131, 1)"
            isSendFleet = {true}
            action = {() => sendFleet(calculateFleetStrength())} src={button_send_fleet}
            >
            </ReusableRectangle>
      </ContainerMission>
      <ContainerMyFleet>
        <TittleV1>
          <IconTittle src={'/icons/icon_fleet_resume.svg'} />
          <TittleTextV1>  
            Fleet Summary
          </TittleTextV1>
        </TittleV1>
        <ContainerAvailableSlots>
          <GridViewAvailableSlots>
            <RowJustifyCenter>
              <IconsAvailableSlots src={icon_available_slots}/>
              <TextTitlesAvailableSlots>
                Available slots:
              </TextTitlesAvailableSlots>
              <IconSvg icon={icon_commnaders} color={'#ffffff'}/>
              <TextTitiliumLineHeight>
                {countCommanders()}/{commanderLimit}
              </TextTitiliumLineHeight>
            </RowJustifyCenter>
            <RowJustifyCenter>
              <IconsAvailableSlotsMargin src={icon_fleet_points}/>
              <TextTitlesAvailableSlots>
                Fleet points:
              </TextTitlesAvailableSlots>
              <TextTitiliumLineHeight>
                {countShipsFleetPoints()}/{commanderFleetPointsLimit}
              </TextTitiliumLineHeight>
            </RowJustifyCenter>
            <RowJustifyCenter>
              <IconsAvailableSlots src={icon_fuel}/>
              <TextTitlesAvailableSlots>
                Fuel cost: 
              </TextTitlesAvailableSlots>
              <IconsAvailableSlots src={'/icons/icon_type_blue_coin_inventory.svg'}/>
              <TextTitiliumLineHeight>
                {fuelConsumption}
              </TextTitiliumLineHeight>
            </RowJustifyCenter>
          </GridViewAvailableSlots>
        </ContainerAvailableSlots>
        <FleetStrength fleetStrength={fleetStrengthStatus}/>
        <ContainerOptions>
          <RowSpaceBetween100>
            <RowCenter style={{backgroundColor: optionShips ? style_class.active.backgroundColor : style_class.inactive.backgroundColor}} onClick={() => setOptionShips(true)}>
              <ContainerIconCommanders>
                <IconSvg icon={icon_ship} color={optionShips ? style_class.active.color : style_class.inactive.color} width={'25px'}/>
              </ContainerIconCommanders>
              <TextTitiliumLineHeight style={{color: optionShips ? style_class.active.color : style_class.inactive.color}}>
                Ships
              </TextTitiliumLineHeight>
            </RowCenter>
            <RowCenter style={{backgroundColor: !optionShips ? style_class.active.backgroundColor : style_class.inactive.backgroundColor}} onClick={() => setOptionShips(false)}>
              <ContainerIconCommanders>
                <IconSvg icon={icon_commnaders} color={!optionShips ? style_class.active.color : style_class.inactive.color}/>
              </ContainerIconCommanders>
              <TextTitiliumLineHeight style={{color: !optionShips ? style_class.active.color : style_class.inactive.color}}>
                Commanders
              </TextTitiliumLineHeight>
            </RowCenter>
          </RowSpaceBetween100>
        </ContainerOptions>
        <ContainerTableMyFleet>
          { optionShips ?
            <TableMyFleet remove_element={removeElement} data={myFleet} cols={ColsMyFleet()} text='Ships'/>
            :
            <TableMyCommander remove_element={removeElement} data={myCommander} cols={ColsMyCommander()} text='Commanders'/>
          }
        </ContainerTableMyFleet>
      </ContainerMyFleet>
      <ContainerFleetSetup>
        <TittleFleetSetup>
          <IconTittle src={'/icons/icon_fleet_setup.svg'} />
          <TittleTextV1>  
            Fleet Setup
          </TittleTextV1>
        </TittleFleetSetup>
        <ContainerBtnBack>
          <RowBtnBack>
              <BtnBack>
                  <ImgBtnBack src={icon_back}/>
                  <TextBtnBack>
                      Back
                  </TextBtnBack>
              </BtnBack>
          </RowBtnBack>
        </ContainerBtnBack>
        <ComposeYourFleetLayer1>
          <ComposeYourFleetLayer2>
            <ComposeYourFleetLayer3>
              <GridMain>
                <ContainerGridView>
                  <TittleBarV1>
                    <TittleTextV2>  
                      Add items to your Fleet
                    </TittleTextV2>
                  </TittleBarV1>
                  <GridViewComposeYourFleet>
                    {commanderSelected ? Object.entries(composeYourFleetCommander).map(([, objects]) => {
                      return (
                        <>
                        {objects.length > 0 ?
                          <ReusableElementComposeYourFleet item={objects[0]} count={objects.length} addElement={addElement} removeElement={removeElement} isRemove={false}/>
                          :
                          null
                        }
                        </>
                      )
                    })
                    : null
                    }
                    {true ? Object.entries(composeYourFleetShips).map(([, objects]) => {
                      return (
                        <>
                        {objects.length > 0 ?
                          <ReusableElementComposeYourFleet item={objects[0]} count={objects.length} addElement={addElement} removeElement={removeElement} isRemove={false}/>
                          :
                          null
                        }
                        </>
                      )
                    })
                    : null
                    }
                  </GridViewComposeYourFleet>
                </ContainerGridView>
                <ContainerGridView>
                  <TittleBarV1>
                    <TittleTextV2>
                      Remove items from your Fleet
                    </TittleTextV2>
                  </TittleBarV1>
                  <GridViewComposeYourFleet>
                    {true ? Object.entries(myCommander).map(([name, objects]) => {
                      return (
                        <>
                        {objects.length > 0 ?
                          <ReusableElementComposeYourFleet item={objects[0]} count={objects.length} addElement={addElement} removeElement={removeElement} isRemove={true}/>
                          :
                          null
                        }
                        </>
                      )
                    })
                    : null
                    }
                    {true ? Object.entries(myFleet).map(([, objects]) => {
                      return (
                        <>
                        {objects.length > 0 ?
                          <ReusableElementComposeYourFleet item={objects[0]} count={objects.length} addElement={addElement} removeElement={removeElement} isRemove={true}/>
                          :
                          null
                        }
                        </>
                      )
                    })
                    : null
                    }
                    
                  </GridViewComposeYourFleet>
                </ContainerGridView>
              </GridMain>
            </ComposeYourFleetLayer3>
          </ComposeYourFleetLayer2>
        </ComposeYourFleetLayer1>
      </ContainerFleetSetup>
    </ContainerNoBorder>
    
    <PopUp show={showPopUpInfo} onClose={() => setShowPopUpInfo(false)} onlyConfirm={true}>
      <TextPoppins style={{letterSpacing: '0'}}>
        {textPopUp}
      </TextPoppins>
    </PopUp>


    </>
    
  )
}