import React, { useState } from 'react'
import { ContainerSign, SignInForm, RowItems, ForgotLink, BtnSignIn, TextButtons } from './SignflowElement'
import { FormControl, FormLabel, SignUpImage, InputPassword, InputUsername, FormCheck, CheckLabel, FormErrorHeader, ErrorItems, FormSignInLink, FieldErrorStatus, FieldErrorHeaderStatus, FormTitle } from '../Register/RegisterElement';
import { FaUserAlt } from 'react-icons/fa';
import { BiLockAlt } from 'react-icons/bi';
import { AiOutlineEyeInvisible, AiOutlineEye, AiFillCloseCircle } from 'react-icons/ai';
import { FaRegSquare, FaRegCheckSquare } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import sign_banner from '../../assets/images/new-sign-banner.png'

import '../../styles/Signflow.css'

import PropTypes from 'prop-types';

import { TextPoppins } from '../../containers/CoreElement'
import { BACKEND_API } from '../utils/config';

import { SMSITE_API } from '../utils/config';

async function loginSSO(credentials){
  
  var formdata = new FormData();
  formdata.append("user", credentials.username);
  formdata.append("pass", credentials.password);

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
    credentials: 'include'
  };

  return fetch(SMSITE_API + "signin-sso/", requestOptions)
    .then(data => data.json())
}


async function loginUser(credentials) {
  loginSSO(credentials);

  var formdata = new FormData();
  formdata.append("username", credentials.username);
  formdata.append("password", credentials.password);

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
    credentials: 'include'
  };

  return fetch(BACKEND_API + "signin/", requestOptions)
    .then(data => data.json())
}

export function Signflow({ setToken, setUpdateNav }) {

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [errorHeader, setErrorHeader] = useState('');


  const handleSubmit = async e => {
    e.preventDefault();

    let errors = {};
    let msgErrorHeader = '';
    if (!username) {
      errors.usernameEnter = 'Please enter your username or email';
    } else {
      delete errors.usernameEnter;
    }
    //  else if (username && !emailValidation(username)) {
    //   errors.usernameEnter = 'Please enter a valid email';
    // }
    if (!password) {
      errors.passwordEnter = 'Please enter your password';
    } else {
      delete errors.passwordEnter;
    }

    if (username && password && Object.keys(errors).length === 0) {
      try {
        const token = await loginUser({
          username,
          password,
        });

        if ("result" in token && token["result"]) {
          sessionStorage.setItem('token', JSON.stringify(token))
          setToken(token);
          setUpdateNav(true);
        } else {
          if ("response" in token) {
            msgErrorHeader = token["response"];
          } else {
            if ("non_field_errors" in token) {
              msgErrorHeader = token["non_field_errors"][0];
            } else {
              msgErrorHeader = 'Server error!';
            }
          }
        }
      }
      catch (error) {
        console.log(error);
        msgErrorHeader = 'Server error!';
      }
    } else {
      msgErrorHeader = 'Please check the errors marked in red and click the Sign In button again after fixing them.';
    }
    setErrorHeader(msgErrorHeader);
    setError(errors);
  }

  const onChangeName = (e) => {
    setUsername(e.target.value);
    delete error.usernameEnter;
  }

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    delete error.passwordEnter;
  }

  return (
    <>
      {/* <ResponsiveContainer> */}
        <ContainerSign>
          <SignUpImage>
            <img src={sign_banner} alt="form" width={'100%'} />
          </SignUpImage>
          <SignInForm onSubmit={handleSubmit}>
            <FormTitle>Sign In</FormTitle>
            {errorHeader &&
              <FormErrorHeader>
                <ErrorItems style={{ fontWeight: 'bold', fontSize: 12, gap: 5 }}>
                  <div style={{ width: 4, height: 30, backgroundColor: '#FF0000' }} />
                  <AiFillCloseCircle color='FF0000' size={28} />
                  <TextPoppins style={{ gap: 4, color: 'white' }}>ERROR</TextPoppins>
                  <FieldErrorHeaderStatus>{errorHeader}</FieldErrorHeaderStatus>
                </ErrorItems>
              </FormErrorHeader>}
            <FormLabel>Email</FormLabel>
            <FormControl onInvalid={error.usernameEnter}>
              <FaUserAlt color='E61383' size={24} />
              <InputUsername placeholder={"Insert your username or email"} onChange={onChangeName}></InputUsername>
            </FormControl>
            {error.usernameEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.usernameEnter}</FieldErrorStatus></ErrorItems>}
            <FormLabel>Password</FormLabel>
            <FormControl onInvalid={error.passwordEnter}>
              <BiLockAlt color='E61383' size={24} />
              <InputPassword onChange={onChangePassword} name='password' type={showPassword ? 'text' : 'password'} placeholder={" Insert your password"} />
              {showPassword ? <AiOutlineEye color='E61383' size={24} onClick={() => setShowPassword(false)} /> : <AiOutlineEyeInvisible color='E61383' size={24} onClick={() => setShowPassword(true)} />}
            </FormControl>
            {error.passwordEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.passwordEnter}</FieldErrorStatus></ErrorItems>}
            <RowItems style={{ marginTop: 15 }}>
              <FormCheck>
                {remember ? <FaRegCheckSquare color='E61383' size={18} onClick={() => setRemember(false)} />
                  : <FaRegSquare color='E61383' size={18} onClick={() => setRemember(true)} />}
                <CheckLabel onClick={() => setRemember(!remember)}>Remember me</CheckLabel>
              </FormCheck>
              <ForgotLink><a href="/reset-password">Forgot password?</a></ForgotLink>
            </RowItems>
            <BtnSignIn type="submit">
              <TextButtons>Sign in</TextButtons>
            </BtnSignIn>
            <FormSignInLink>First time on Space Misfist? <a href="/register">Sign Up</a></FormSignInLink>
          </SignInForm>
        </ContainerSign>
      {/* </ResponsiveContainer> */}
      {/* <ResponsiveAlert>
        <ContainerAlert>
          <RowCore style={{ borderLeft: '5px solid #E71383', alignItems: 'center' }}>
            <ImgIcon src={help_icon_pink} style={{ width: '15px', height: '15px', marginLeft: '3%' }} />
            <TextPoppins style={{ fontSize: '11px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%' }}>
              Fleet Alpha v1 is not optimized for mobile/tablet devices and it will not be supported until the next version following our roadmap, you can play Fleet using the desktop version. We apologize for any inconvenience caused.
            </TextPoppins>
          </RowCore>
        </ContainerAlert>
      </ResponsiveAlert> */}
    </>
  )
}

Signflow.propTypes = {
  setToken: PropTypes.func.isRequired
}
