import styled from "styled-components";
import { ContainerVoid } from "../../containers/CoreElement";

export const ContainerHome = styled.div`
    justify-content: space-between;
    display: flex;
    width: 1160px;
    margin: 20px calc((90vw - 1000px) / 2);

    @media (max-width: 1160px) {
        width: inherit;
        margin-right: 5%;
        margin-left: 5%;
    }

    @media (max-width: 600px) {
        margin-right: 1%;
        margin-left: 2%;
    }
`

export const ContainerHeader = styled.div`
    width: 200px;

    @media(max-width: 960px) {
        display: none;
    }
`

export const ContainerMain = styled.div`
    width: 930px;

    /* @media (max-width: 600px) {
        margin-left: -4px;
    } */

`
export const ResponsiveAlert = styled.div`
    @media (min-width: 1201px) {
        display: none;
    }
`

export const ContainerAlert = styled(ContainerVoid)`
    width: 80%;
    background: rgba(141, 12, 80, 0.7);
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

