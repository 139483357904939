import React, { useState } from 'react';
import { HTTP } from '../components/http/http-common';
import { useNavigate } from 'react-router-dom'

import '../containers/hooks/styles.css'

import {
    ContainerGeneral,
    Element,
    Container1,
    Image,
    Container1Text,
    TextTitle,
    Text,
    ContainerSection1,
    ContainerSection2,
    Icon,
    TextBold,
    ContainerSection2Element,
    ContainerSection2ElementIcon,
    ContainerSection2ElementButton,
    ContainerImgProfile,
    ContainerPolygonLeft,
    TextPoppinsInfo,
    ContainerPolygonRight,
    TextPoppinsInfoBold,
    TextTextTitiliumWebInfo,
    DamagedCard,
    ImgRepair,
    ContainerSectionDesktop,
    ContainerSectionCellphone,
    ContainerSectionStat
} from './CardSelectedElement.jsx';
import icon_repair from '../assets/images/icon_repair.svg'
import GridSpecs from './GridSpecs';

import { TextPoppins } from '../containers/CoreElement';
import PopUp from './PopUp';
import GridMaterial from './GridMaterials.jsx';

// props - image, shipLVL, fleetPoints, name, description, icon1, icon2, icon3, class, action1, repair, action2, instantRepair
const CardHangarSelected = (props) => {

    const [showPopUp, setShowPopUp] = useState(false);
    const [showPopUpRepair, setShowPopUpRepair] = useState(false);
    const [showPopUpInstantRepair, setShowPopUpInstantRepair] = useState(false);    
    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');
    let navigate = useNavigate();


    const repairShip = (shipId, instantRepair) => {

        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"ship_id": shipId, "instant_repair": instantRepair},
            method: "POST"
        };
        HTTP.request('ships/repair/', config)
        .then((res) => {
            if (res.data.result){
                if (instantRepair) {
                    navigate('/shipyard/repair-bay/pick-up')
                }else{
                    navigate('/shipyard/repair-bay/repairing')
                }
            }
        })
        .catch((err) => {
            console.log(err);
        });

    }

    const recycleShip = (shipId) => {
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"ship_id": shipId},
            method: "POST"
        };
        HTTP.request('ships/recycle/', config)
        .then((res) => {
            if (res.data.result){
                window.location.reload();
            }else{
                setPopupServerError(true);
                setTextServerError(res.data.response);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    console.log(props.item);

    return (
        <>

            <ContainerGeneral>
                <Element>
                    <Container1>
                        <div style={{display:"flex", position:"relative"}}>
                        <ContainerImgProfile>
                            { props.item.is_destroyed ?
                                <DamagedCard>
                                    <ImgRepair src={icon_repair}/>
                                </DamagedCard>
                                :
                                null
                            }
                            <Image src={props.item.image}/>
                            <ContainerPolygonLeft>
                                <TextPoppinsInfo>FP:<TextPoppinsInfoBold>{props.item.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                            </ContainerPolygonLeft>
                            <ContainerPolygonRight>
                                <TextTextTitiliumWebInfo>LVL {1}</TextTextTitiliumWebInfo>
                            </ContainerPolygonRight>
                        </ContainerImgProfile>
                        </div>
                        <Container1Text>
                            <div>
                            <TextTitle>{props.item.name}</TextTitle>
                            </div>
                            <Text>{props.item.name}</Text>
                        </Container1Text>
                    </Container1>
                    <ContainerSectionDesktop>
                        <ContainerSection1>
                            <GridSpecs specs={props.item.specs} spaceBetweenColumns={'10px'} columns={1}/>
                            <div><Text>Class: </Text><TextBold>{props.item.class}</TextBold> </div>
                        </ContainerSection1>
                        <ContainerSectionStat>
                            <Text>Fleet Points:</Text><TextBold>{props.item.fp}</TextBold>
                        </ContainerSectionStat>
                        <ContainerSectionStat>
                            <Text>Level: </Text><TextBold>{1}</TextBold>
                        </ContainerSectionStat>
                    </ContainerSectionDesktop>
                    <ContainerSectionCellphone>
                        <ContainerSection1 style={{flexDirection: 'column', justifyContent: 'left', alignItems: 'start', gap: '20px'}}>
                            <GridSpecs specs={props.item.specs} spaceBetweenColumns={'10px'} columns={2}/>
                            <div><Text>Class: </Text><TextBold>{props.item.class}</TextBold> </div>
                        </ContainerSection1>
                        <ContainerSectionStat>
                            <Text>Fleet Points:</Text><TextBold>{props.item.fp}</TextBold>
                        </ContainerSectionStat>
                        <ContainerSectionStat>
                            <Text>Level: </Text><TextBold>{1}</TextBold>
                        </ContainerSectionStat>
                    </ContainerSectionCellphone>

                    <ContainerSection2>
                        { props.item.is_destroyed && !props.item.in_repair ?
                        <ContainerSection2Element>
                            <ContainerSection2ElementIcon>
                            <Icon src="/icons/icon_type_repair_1.svg"></Icon>
                            </ContainerSection2ElementIcon>
                            <ContainerSection2ElementButton onClick={() => setShowPopUpRepair(true)}>
                                <Text>REPAIR</Text>
                                <Icon src="/icons/icon_type_blue_coin_inventory.svg"></Icon>
                                <Text>{props.item.cost_repair_bits}</Text>
                            </ContainerSection2ElementButton>
                        </ContainerSection2Element>
                        :
                        null
                        }
                        { !props.item.is_destroyed && !props.item.in_repair && props.item.can_be_recycle? 
                        <ContainerSection2Element onClick={() => setShowPopUp(true)}>
                            <ContainerSection2ElementIcon>
                            <Icon src="/icons/icon_recycle.svg"></Icon>
                            </ContainerSection2ElementIcon>
                            <ContainerSection2ElementButton>
                                <Text>RECYCLE</Text>
                            </ContainerSection2ElementButton>
                        </ContainerSection2Element>
                        :
                        null
                        }
                        { props.item.is_destroyed && !props.item.in_repair ?
                        <ContainerSection2Element>
                            <ContainerSection2ElementIcon>
                            <Icon src="/icons/icon_type_instant_repair.svg"></Icon>
                            </ContainerSection2ElementIcon>
                            <ContainerSection2ElementButton onClick={() => setShowPopUpInstantRepair(true)} style={{ background:"#AA68D8"}}>
                                <Text>INSTANT REPAIR</Text>
                                <Icon src="/icons/icon_type_crown_coin_grey.png"></Icon>
                                <Text>{props.item.cost_repair_crowns}</Text>
                            </ContainerSection2ElementButton>
                        </ContainerSection2Element>
                        :
                        null
                        }

                    </ContainerSection2>

                </Element>
            </ContainerGeneral>

            <PopUp show={showPopUp} onClose={() => setShowPopUp(false)} onConfirm={() => recycleShip(props.item.id_ship_x_user)}>
                <TextPoppins style={{letterSpacing: '0'}}>
                    {'Are you sure that you want to proceed with recycling?'}
                </TextPoppins>
                <TextPoppins style={{letterSpacing: '0'}}>
                    {'You will recover 50% of the cost:'}
                </TextPoppins>
                <div>
                    <GridMaterial propsInItem={props.item} quantity={1} justifyContent={"left"} columns={1} spaceBetweenRows={'16px'} withHas={false}/>
                </div>
            </PopUp>

            <PopUp show={showPopUpRepair} onClose={() => setShowPopUpRepair(false)} onConfirm={() => repairShip(props.item.id_ship_x_user, false)}>
                <Text>Are you sure you want to repair?</Text>
            </PopUp>

            <PopUp show={showPopUpInstantRepair} onClose={() => setShowPopUpInstantRepair(false)} onConfirm={() => repairShip(props.item.id_ship_x_user, true)}>
                <Text>Are you sure you want to instant repair?</Text>
            </PopUp>

            <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
                <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                {textServerError}
                </TextPoppins>
            </PopUp>           

        </>
    )
};

export default CardHangarSelected;