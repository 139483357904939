import React, { useEffect, useState } from 'react';
import { HTTP } from '../components/http/http-common';
import { useNavigate } from 'react-router-dom'

import icon_amount from '../assets/images/icon_available_slots.svg'
import icon_nft_transparent from '../assets/images/icon_nft_transparent.svg'
import icon_instant_forge from '../assets/images/icon_instant_forge.svg'

import '../containers/hooks/styles.css'

import {
    ContainerGeneral,
    Element,
    Container1,
    Image,
    Container1Text,
    TextTitle,
    Text,
    ContainerSection1,
    ContainerSection2,
    Icon,
    TextBold,
    ContainerSection2Element,
    ContainerSection2ElementIcon,
    ContainerSection2ElementButton,
    ContainerSection2Stats,
    ContainerStats,
    ContainerStatsGold,
    ContainerImgProfile,
    ContainerPolygonLeft,
    TextPoppinsInfo,
    TextPoppinsInfoBold,
    ContainerPolygonRight,
    TextTextTitiliumWebInfo,
    InputSetAmount,
    ContainerInputCardSelected,
    ContainerSectionDesktop,
    ContainerSectionCellphone,

} from './CardSelectedElement.jsx';
import GridMaterial from './GridMaterials';
import GridSpecs from './GridSpecs';
import GridTraits from './GridTraits';
import PopUp from "./PopUp";
import { ColButtons, IconArrow, TextPoppins } from '../containers/CoreElement';
import { globalData, updateNavs } from '../components/utils/utils.js';


  
// props - image, name, description, icon1, icon2, class, forgeTime, rows1 (row1.icon, row1.text, row1.value), rows2 (row2.icon, row2.text, row2.value), action1, action2, forgeAmount.
const CardForgeSelected = (props) => {

    const [popupBuyCrown, setPopupBuyCrown] = useState(false)
    console.log(props.item);

    const [forgeV1, setForgeV1] = useState(false);
    const [forgeV2, setForgeV2] = useState(false);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');
    const [inputAmountAdd, setInputAmountAdd] = useState(0);
    const [disabledForge, setDisabledForge] = useState(true);

    const handleInputAmountAddChange = (event) => {
        var integerNumber = Math.round(event.target.value);
        integerNumber = parseInt(integerNumber, 10);
        setInputAmountAdd(integerNumber);
        console.log(integerNumber)
        if (integerNumber > 0){
            document.getElementById('idInputAmountAdd').value = integerNumber;
            setDisabledForge(false)
        }else{
            document.getElementById('idInputAmountAdd').value = '';
            setDisabledForge(true)
        }
    };

    let navigate = useNavigate();

    const handleForgeV1 = () => {

        setForgeV1(true);
        setForgeV2(false);

    }
    
    const handleForgeV2 = () => {
        updateNavs().then(() => {
            if(globalData.crownsAvailable > 0){
                setForgeV1(false);
                setForgeV2(true);
            }else{
                setPopupBuyCrown(true);
            }
        })
    }

    const forgeShip = (shipId, instantForge) => {
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"ship_id": shipId, "instant_forge": instantForge, "quantity": inputAmountAdd},
            method: "POST"
        };
        HTTP.request('ships/forge/', config)
        .then((res) => {
            if (res.data.result){
                console.log(res.data.instant_forge)
                if (instantForge || res.data.instant_forge) {
                    navigate('/shipyard/forge/pick-up')
                }else{
                    navigate('/shipyard/forge/in-process')
                }
            }else{
                setPopupServerError(true);
                setForgeV1(false);
                setForgeV2(false);
                setTextServerError(res.data.response);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    const handleIncrement = (item) => {

        setInputAmountAdd(inputAmountAdd + 1)
    }

    const handleDecrement = () => {
        if(inputAmountAdd >= 1){
            setInputAmountAdd(inputAmountAdd - 1)
        }
    }

    useEffect(() => {
        inputAmountAdd > 0 ? setDisabledForge(false) : setDisabledForge(true)
    }, [inputAmountAdd])
    

    return (
    <>
        <ContainerGeneral>
            <Element>
                <Container1>
                    <div style={{display:"flex", position:"relative"}}>
                    <ContainerImgProfile>
                        <Image src={props.item.image}/>
                        <ContainerPolygonLeft>
                            <TextPoppinsInfo>FP:<TextPoppinsInfoBold>{props.item.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                        </ContainerPolygonLeft>
                        <ContainerPolygonRight>
                            <TextTextTitiliumWebInfo>LVL {1}</TextTextTitiliumWebInfo>
                        </ContainerPolygonRight>
                    </ContainerImgProfile>
                    </div>
                    <Container1Text>
                        <div>
                        <TextTitle>{props.item.name}</TextTitle>
                        </div>
                        <Text>{props.item.description}</Text>
                    </Container1Text>
                </Container1>
                <ContainerSectionDesktop>
                    <ContainerSection1 style={{justifyContent:"unset" , gap:"9px"}}>
                        <GridSpecs specs={props.item.specs} spaceBetweenColumns={'10px'} columns={props.item.specs.length}/>
                        <Text>Class:</Text>
                        <TextBold>{props.item.class}</TextBold> 
                    </ContainerSection1>
                    <ContainerSection1 style={{display:"inlineFlex", justifyContent:"flex-end"}}>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"9px"}}> <Icon src="/icons/icon_type_forge_time.svg"></Icon><Text>Forge Time: </Text><TextBold>{props.item.forge_time}</TextBold><Text style={{lineHeight: '0px'}}>min</Text></div>
                    </ContainerSection1>
                </ContainerSectionDesktop>
                <ContainerSectionCellphone>
                    <ContainerSection1 style={{justifyContent:"unset" , gap:"9px"}}>
                        <GridSpecs specs={props.item.specs} spaceBetweenColumns={'10px'} columns={props.item.specs.length}/>
                        <Text>Class:</Text>
                        <TextBold>{props.item.class}</TextBold> 
                    </ContainerSection1>
                    <ContainerSection1 style={{display:"inlineFlex", justifyContent:"flex-end"}}>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"9px"}}> <Icon src="/icons/icon_type_forge_time.svg"></Icon><Text>Forge Time: </Text><TextBold>{props.item.forge_time}</TextBold><Text style={{lineHeight: '0px'}}>min</Text></div>
                    </ContainerSection1>
                </ContainerSectionCellphone>
                <ContainerSection2>
                    <div style={{width:"100%"}}>
                        <ContainerSection2Stats>
                            <Icon src="/icons/icon_type_materials.svg"></Icon>
                            <TextBold style={{letterSpacing:"1px", fontFamily:"poppins"}}>Materials</TextBold>
                        </ContainerSection2Stats>
                        <ContainerStatsGold>
                            <GridMaterial materials={props.materials} quantity={inputAmountAdd} justifyContent={"left"} columns={1} spaceBetweenRows={'7px'}/>
                        </ContainerStatsGold>
                    </div>     
                    <div style={{width:"100%"}}>
                        <ContainerSection2Stats>
                            <Icon src="/icons/icon_type_stars.svg"></Icon>
                            <TextBold style={{letterSpacing:"1px", fontFamily:"poppins"}}>Traits / Unique Traits</TextBold>
                        </ContainerSection2Stats>
                        <ContainerStats>
                            <GridTraits  
                                shield={props.traits.shield}
                                armor={props.traits.armor}
                                damage={props.traits.damage}
                                mining={props.traits.mining}
                                cargo={props.traits.load}
                                columns={1} 
                                spaceBetweenRows={'15px'}
                            />
                        </ContainerStats>
                    </div>
                </ContainerSection2>
                <ContainerSection2>
                    <ContainerSection2Element>
                        <ContainerSection2ElementIcon>
                            <Icon src={icon_amount}></Icon>
                        </ContainerSection2ElementIcon>
                        <ContainerInputCardSelected>
                            <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='Set amount' id='idInputAmountAdd' step="1" min={1} max={props.count} value={inputAmountAdd} onChange={handleInputAmountAddChange}/>
                            <ColButtons>
                                <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrement(props.item)}/>
                                <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrement()}/>
                            </ColButtons>
                        </ContainerInputCardSelected>
                        {/* <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='Set amount' id='idInputAmountAdd' step="1" min={1} max={props.count} value={inputAmountAdd} onChange={handleInputAmountAddChange}/> */}
                    </ContainerSection2Element>
                    <ContainerSection2Element style={{opacity: disabledForge ? 0.5 : 1}}>
                        <ContainerSection2ElementIcon>
                            <Icon src="/icons/icon_type_forge.svg"></Icon>
                        </ContainerSection2ElementIcon>
                        <ContainerSection2ElementButton disabled={disabledForge} onClick={() => handleForgeV1()}>
                            {props.item.active_bp ?
                            <Text>INSTANT FORGE</Text>
                            :
                            <Text>FORGE</Text>
                            }
                        </ContainerSection2ElementButton>
                    </ContainerSection2Element>
                    {props.item.active_bp ?
                    null
                    :
                    <ContainerSection2Element style={{opacity: disabledForge ? 0.5 : 1}}>
                        <ContainerSection2ElementIcon>
                            <Icon src={icon_instant_forge}></Icon>
                        </ContainerSection2ElementIcon>
                        <ContainerSection2ElementButton disabled={disabledForge} style={{background:"#AA68D8"}} onClick={() => handleForgeV2()}>
                            <Text>INSTANT FORGE</Text>
                            <Icon src="/icons/icon_new_crown.png"></Icon>
                            <Text>{props.item.cost_instant_forge * inputAmountAdd}</Text>
                        </ContainerSection2ElementButton>
                    </ContainerSection2Element>
                    }
                </ContainerSection2>
            </Element>
        </ContainerGeneral>

        <PopUp show={forgeV1} onClose={() => setForgeV1(false)} onConfirm={() => forgeShip(props.item.id, false)} >
            <Text style={{marginBottom: '5%'}}>Are you sure that you want to Forge this ship for:</Text>
            <div>
                <div style={{gap:"3px",display:"flex",alignItems:'center', marginBottom: '16px'}}><Icon src={icon_amount}></Icon><Text style={{lineHeight: '0px'}}>Amount: </Text><TextBold style={{lineHeight: '0px'}}>{inputAmountAdd}</TextBold></div>
                <GridMaterial materials={props.materials} quantity={inputAmountAdd} justifyContent={"left"} columns={1} spaceBetweenRows={'16px'} withHas={false} discount={props.item.active_bp? 0.70 : 1}/>
                <div style={{gap:"3px",display:"flex",alignItems:'center', marginTop: '16px'}}><Icon src={icon_nft_transparent}></Icon><Text style={{lineHeight: '0px'}}>Blueprint discount: </Text><TextBold style={{lineHeight: '30px', color:'#E71383'}}>{props.item.active_bp? 30 : 0}% (BITS)</TextBold></div>
                <div style={{gap:"3px",display:"flex",alignItems:'center', marginTop: '16px'}}><Icon src="/icons/icon_type_instant_forge.svg"></Icon><Text style={{lineHeight: '0px'}}>Forge Time: {props.item.active_bp? <TextBold>Instant</TextBold> : <TextBold style={{lineHeight: '0px'}}>{props.item.forge_time}min</TextBold>} </Text></div>
            </div>
        </PopUp>

        <PopUp show={forgeV2} onClose={() => setForgeV2(false)} onConfirm={() => forgeShip(props.item.id, true)}>
            <Text>Are you sure that you want to buy this ship for:</Text>
            <div>
                <div style={{gap:"3px",display:"flex",alignItems:'center', marginTop: '16px'}}><Icon src={icon_amount}></Icon><Text style={{lineHeight: '0px'}}>Amount: </Text><TextBold>{inputAmountAdd}</TextBold></div>
                <GridMaterial materials={props.materials} quantity={inputAmountAdd} justifyContent={"left"} columns={1} spaceBetweenRows={'16px'} withHas={false} discount={props.item.active_bp? 0.70 : 1}/>
                <div style={{gap:"3px",display:"flex",alignItems:'center', marginTop: '16px'}}><Icon src="/icons/icon_new_crown.png"></Icon><TextBold style={{lineHeight: '0px'}}>{props.item.cost_instant_forge}</TextBold></div>
                <div style={{gap:"3px",display:"flex",alignItems:'center', marginTop: '16px'}}><Icon src="/icons/icon_type_instant_forge.svg"></Icon><Text style={{lineHeight: '0px'}}>Forge Time: </Text><TextBold style={{lineHeight: '0px'}}>INSTANT</TextBold></div>
            </div>
        </PopUp>

        <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
            <Text style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                {textServerError}
            </Text>
        </PopUp>

        <PopUp show={popupBuyCrown} onClose={() => setPopupBuyCrown(false)} onConfirm={() => window.open('https://spacemisfits.com/signin/', '_blank')} textBtnNo="Dismiss" textBtnYes="Buy CROWN">
            <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                Oops, it seems like you're short on CROWN. <br/>
                No worries! You can easily purchase more to unlock this cool feature.
            </TextPoppins>
        </PopUp>
    </>
    )
};

export default CardForgeSelected;