import React, {useEffect, useState} from 'react';
import {
    ContainerDropDownDashboard,
    ContainerTableMyFleet,
 } from '../DashboardElement';

 import ReusableRectangle from '../../../../../hooks/ReusableRectangle';

 import TableMyFleet2 from '../../../../../tables/TableMyFleet2/TableMyFleet2';


export function DropDownDashboard(props) {

    const [, setHorde] = useState(props.route.horde);

    useEffect(() => {
        setHorde(props.route.horde);
      }, [props.route.horde]);


    const cols = [
        {
        Header: "Fleet",
        width: "21%",
        accessor: "fleet"
        },
        {
        Header: "Amount",
        width: "21%",
        accessor: "amount"
        },
        {
        Header: "FP",
        width: "7%",
        accessor: "fp"
        },
        {
            Header: "Cargo",
            width: "7%",
            accessor: "cargo"
        },
        {
            Header: "Shield",
            width: "7%",
            accessor: "shield"
        },
        {
            Header: "Armor",
            width: "7%",
            accessor: "armor"
        },
        {
            Header: "Damage",
            width: "7%",
            accessor: "damage"
        },
    ];

  return (
    <ContainerDropDownDashboard>    
        <ReusableRectangle
            missions = {true}
            image = {null}
            difficulty = {"Easy"}
            type = {props.route.p_mission_economic.type_of_route_name}
            mission_id = {props.route.id}
            specs = {props.route.expected_reward}
            enemy_ships = {props.route.horde}
            min_spent = {null}
            value1Second = {props.route.commander_enemy_class}
            value2Second = "value2"
            value3Second = "value3"
            value4Second = "value4"
            buttonText = "Send Fleet"
            //buttonColor = "rgba(231, 19, 131, 1)"
            isSendFleet = {true}
            action = {null}

            height="130%"
            margin="0px 3px 54px 0px"
            >
        </ReusableRectangle>
        <ContainerTableMyFleet>
            <TableMyFleet2 remove_element={''} data={props.route.ships} cols={cols}></TableMyFleet2>
        </ContainerTableMyFleet>
    </ContainerDropDownDashboard>
  )
}