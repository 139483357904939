import React, {useEffect, useState} from 'react';
import { ContainerFleetStrength, ContainerStrength, TextTitiliumLineHeight } from './FleetStrengthElement';
import { RowSpaceBetween } from '../containers/CoreElement';

export default function FleetStrength({fleetStrength}){

    const [styles, setStyles] = useState({backgroundColor: '', color: ''});

    useEffect(() => {
        console.log(fleetStrength)
        if(fleetStrength.toLowerCase() === 'weak'){
            setStyles({backgroundColor: '#EF0707', color: 'white'});
        }else if(fleetStrength.toLowerCase() === 'fair'){
            setStyles({backgroundColor: '#FAD000', color: 'black'});
        }else if(fleetStrength.toLowerCase() === 'strong'){
            setStyles({backgroundColor: '#92FA00', color: 'black'});
        }
        
    }, [fleetStrength])
    
    return(
        <>
        <ContainerFleetStrength>
          <RowSpaceBetween>
            <ContainerStrength style={{backgroundColor: styles.backgroundColor}}>
              <TextTitiliumLineHeight style={{color: styles.color}}>
                {fleetStrength}
              </TextTitiliumLineHeight>
            </ContainerStrength>
          </RowSpaceBetween>
        </ContainerFleetStrength>
        </>
    )
}