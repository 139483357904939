import React, { useState } from 'react';
import { HTTP } from '../components/http/http-common';

import '../containers/hooks/styles.css'

import {
    CardContainerGeneral,
    CardContainerTitle,
    CardContainerImage,
    CardInfo,
    TextTitle,
    Text,
    CardButton,
    TextPoppinsInfo,
    TextPoppinsInfoBold,
    TextTextTitiliumWebInfo,
    TextBold,
    ContainerImgProfile
} from './ReusableCardElement';

import GridSpecs from './GridSpecs';
import PopUp from './PopUp';
import { ContainerPolygon, PolygonLeft, PolygonRight } from '../containers/CoreElement';

//Shipyard
const ReusableCardPickUp = (props) => {

    console.log(props.item);

    const [forgeCode, setForgeCode] = useState(-1)
    const [popUpPickupNow, setPopUpPickupNow] = useState(false);

    const pickupShip = (forgeCode) => {

        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"forge_code": forgeCode},
            method: "POST"
        };
        HTTP.request('ships/forge/pickup/', config)
        .then((res) => {
            if (res.data.result){
                window.location.reload()
            }
        })
        .catch((err) => {
            console.log(err);
        });

    }

    const handlePickupNow = (forgeCode) => {
        setForgeCode(forgeCode);
        setPopUpPickupNow(true);
    }


    return (
        <>
        <CardContainerGeneral>

            <CardContainerTitle>
                <TextTitle>{props.item.ship.name}</TextTitle>
            </CardContainerTitle>
            <div style={{display:"flex", position:"relative"}}>
                <ContainerImgProfile>
                    <CardContainerImage src={props.item.ship.image}></CardContainerImage>
                </ContainerImgProfile>
                <ContainerPolygon style={{bottom: '10px'}}>
                    <PolygonLeft>
                        <TextPoppinsInfo>FP:<TextPoppinsInfoBold>{props.item.ship.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                    </PolygonLeft>
                </ContainerPolygon>
                <ContainerPolygon style={{right: '0', bottom: '10px'}}>
                    <PolygonRight>
                        <TextTextTitiliumWebInfo>LVL {1}</TextTextTitiliumWebInfo>
                    </PolygonRight>
                </ContainerPolygon>
            </div>
            <CardInfo>
                <div style={{marginTop: '3%'}}>
                    <GridSpecs specs={props.item.ship.specs} spaceBetweenColumns={'10px'} columns={4} width={'200px'}/>
                </div> 
                <Text>Class: <TextBold>{props.item.ship.class}</TextBold></Text>
                {/* <Text>Units: <TextBold>{props.item.ship.fp}</TextBold></Text> */}
            </CardInfo>
            <CardButton onClick={() => handlePickupNow(props.item.forge_code)}>
                <Text style={{fontWeight: '400', letterSpacing: '0.02em'}}>Pickup Now</Text>
            </CardButton>
        </CardContainerGeneral>

        <PopUp show={popUpPickupNow} onClose={() => setPopUpPickupNow(false)} onConfirm={() => pickupShip(forgeCode)}>
            <Text>Are you sure you want to pick up now?</Text>
        </PopUp>
        </>
    )
};

export default ReusableCardPickUp;


