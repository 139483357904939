import styled from "styled-components";
import { ContainerInput } from "../containers/CoreElement";

export const InputSet = styled.input`
    border: none;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    height: 30px;
    width: 85%;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        display: none;
    }

    &::placeholder{
        @media (max-width: 600px) {
            font-size: 12px;
        }
    }

    @media (max-width: 600px) {
        font-size: 10px;
    }
    
`

export const ButtonAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 10px;
    background: #E71383;
    cursor: pointer;
`

export const TittleTextV7 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 600px) {
        font-size: 10px;
    }
`

export const ContainerInputCustom = styled(ContainerInput)`
    border: 1.5px solid rgba(94, 8, 53, 1);
    width: 70%;
`