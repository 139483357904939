import styled from 'styled-components'

import navbar_home_background from '../../../assets/images/navbar_home_background.png'
import messages_icon from '../../../assets/images/messages_icon.svg'
import messages_icon_ellipse from '../../../assets/images/messages_icon_ellipse.svg'

import {
    Link
} from "react-router-dom";


export const ImgLine = styled.img`
    width: 99%;
    margin-left: 1px;
`

export const PrimaryHomeNav = styled.nav`
    z-index: 14;
    background-image: url(${navbar_home_background});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: block;
    color: #C87DA5;
`

export const NumMessages = styled.span`
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    color: white;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 25px;
`

export const Messages = styled(Link)`
    background-image: url(${messages_icon});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 30%;
    height: 38px;
    position: relative;
`

export const MessagesElipse = styled.div`
    background-image: url(${messages_icon_ellipse});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 25px;
    height: 25px;
    top: -5px;
    right: -5px;
    position: absolute;
`

export const MessagesLink = styled(Link)`

`

export const Li = styled.li`
    padding: 8px;
    justify-content: left;
    align-items: center;
    text-align: left;
    display: flex;
`

export const Icon = styled.img`
    margin-right: 10px;
`
export const IconLeaderboard = styled.img`
    width: 25px;
    margin-right: 10px;
`

export const IconLock = styled.img`
    width: 20px;
    margin-left: 5px;
`
export const IconAccess = styled.img`
    width: 15px;
    margin-left: 10px;
`

export const Version = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.7);
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 5px;
`

export const Hour = styled.h1`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
    width: 150px;
`

export const NavbarSection1 = styled.div`
    justify-content: space-between;
    display: flex;
    padding: 15px;
    align-items: center;
    text-align: center;
    padding-bottom: 0;
`

export const ContainerMenuLink = styled.div`
    /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(0deg, #E71383, #E71383),
    linear-gradient(0deg, rgba(231, 19, 131, 0.2), rgba(231, 19, 131, 0.2)); 
    border: 0.5px solid #E71383; */
    width: 100%; 
    height: 35px;
    justify-content: left;
`

export const MenuLink = styled(Link)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: white;
    display: flex;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    padding: 0 1.2rem;
    height: 100%;
    position: relative;
    //background: radial-gradient(50% 44.05% at 50% 100%, rgba(251, 203, 228, 0.21) 0%, rgba(251, 203, 228, 0) 100%);
    &.active {
        color: #000000;
    }
`
export const Menu = styled.ul`
    align-items: center;
    @media screen and (max-width: 768px) {
        display: none;
    }
    display: block;
    list-style:none;
    margin: 0;
    padding: 0;
`

export const ImagePromo = styled.img`
    margin: 5px 0px 5px 0px;
    cursor: pointer;
`
