import styled from "styled-components";

export const LifeBarContainer = styled.div`
    height: 40px;
    width: 100%;
    background: #FF47A9;
`
export const LifeBarContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    transition: width 0.35s;
`