import React, { useEffect, useState } from "react";

import {
  ContainerGeneral,
  Element,
  ContainerImage,
  Text,
  Icon,
  ContainerContent,
  ElementContainer,
  TextLevel,
  ContainerDarkerLayer,
  ContainerGeneralToUPLevels,
  ContainerSection1,
  ContainerImg,
  ImgToUpLevel,
  ContainerDescription,
  TitleToUpLevel,
  DescriptionToUpLevel,
  ContainerSection2,
  IconSection2,
  InfomationBar,
  ItemBar,
  TitleItemBar,
  ContentItemBar,
  ContainerSection3,
  ContainerUpgrade,
  ContainerImgUpgrade,
  ImgUpgrade,
  ContainerButtonUpgrade,
  TittleButtonUpgrade,
  ContentButtonUpgrade,
  ImgContentButtonUpgrade,
  DescriptionButtonUpgrade,
  Lock,
  LockContainer
} from "../Facilities/FacilitiesElement.jsx";

import HeaderScreen from "../../../../hooks/HeaderScreen";

import image from "../../../../assets/images/image_header_facilities.png";

import ImageHangar from "../../../../assets/images/hangar_facilities.jpg";
import ImageCommanders from "../../../../assets/images/facilities-commanders-1.png";
import ImageOutpost from "../../../../assets/images/HeaderOutposts.jpg";
import ImageShipyard from "../../../../assets/images/shipyard_facilities.png";

import ImageToUpLevelOutpost from "../../../../assets/images/image_outpost_touplevel.png";
import ImageToUpLevelHangar from "../../../../assets/images/image_hangar_touplevel.png";

import IconItemBar from "../../../../assets/images/icon_item_bar.png";
import IconUpgradeLevel from "../../../../assets/images/icon_upgrade_level.png";
import LockIcon from '../../../../assets/images/lock.png';


import {
  ContainerPolygon,
  PolygonRight,
  TextPoppins,
} from "../../../../containers/CoreElement";
import { numberToThousandSeparatorLeaderboardRanking, updateNavs } from "../../../utils/utils.js";
import { Loader } from "../../../../hooks/Loader.jsx";
import { HTTP } from "../../../http/http-common.js";
import PopUp from "../../../../hooks/PopUp.jsx";

function UpLevels(props) {
  
  return (
      <>
          <ContainerGeneralToUPLevels>
              <ContainerSection1>
                <ContainerImg>
                  <ImgToUpLevel src={props.img}/>
                </ContainerImg>
                <ContainerDescription>
                  <TitleToUpLevel>
                    {props.title}
                  </TitleToUpLevel>
                  <DescriptionToUpLevel>
                    {props.description}
                  </DescriptionToUpLevel>
                </ContainerDescription>
              </ContainerSection1>
              <ContainerSection2>
                <IconSection2 src={IconItemBar} />
                <InfomationBar>
                  <ItemBar>
                    <TitleItemBar>
                      LVL:
                    </TitleItemBar>
                    <ContentItemBar>
                      {props.currentLevel}
                    </ContentItemBar>
                  </ItemBar>
                  <ItemBar>
                    <TitleItemBar>
                      {props.titleTypeUpgrade}
                    </TitleItemBar>
                    <ContentItemBar>
                      {props.valueTypeUpgrade}
                    </ContentItemBar>
                  </ItemBar>
                  <ItemBar>
                    <TitleItemBar>
                      Next Level:
                    </TitleItemBar>
                    <ContentItemBar>
                      {props.nextUpgrade}
                    </ContentItemBar>
                  </ItemBar>
                  <ItemBar>
                    <TitleItemBar>
                      Next Level Requirement:
                    </TitleItemBar>
                    <ContentItemBar>
                      {props.requirement}
                    </ContentItemBar>
                  </ItemBar>
                </InfomationBar>
              </ContainerSection2>
              {props.nextUpgrade !== 'MAX' ?
                <ContainerSection3>
                  <ContainerUpgrade style={{border: '1px solid #E71383'}}>
                    <ContainerImgUpgrade>
                      <ImgUpgrade src={IconUpgradeLevel}/>
                    </ContainerImgUpgrade>
                    <ContainerButtonUpgrade onClick={() => props.setPopUpUpgrade(true)} style={{backgroundColor: '#E71383'}}>
                      <TittleButtonUpgrade>
                        UPGRADE
                      </TittleButtonUpgrade>
                      <ContentButtonUpgrade>
                        <ImgContentButtonUpgrade src='/icons/icon_type_blue_coin_inventory.svg'/>
                        <DescriptionButtonUpgrade>
                          {props.valueUpgradeBits}
                        </DescriptionButtonUpgrade>
                      </ContentButtonUpgrade>
                      <ContentButtonUpgrade>
                        <ImgContentButtonUpgrade src='/icons/icon_peroxium.svg'/>
                        <DescriptionButtonUpgrade>
                          {props.valueUpgradePeroxium}
                        </DescriptionButtonUpgrade>
                      </ContentButtonUpgrade>
                      <ContentButtonUpgrade>
                        <ImgContentButtonUpgrade src='/icons/icon_trantium.svg'/>
                        <DescriptionButtonUpgrade>
                          {props.valueUpgradeTrantium}
                        </DescriptionButtonUpgrade>
                      </ContentButtonUpgrade>
                      <ContentButtonUpgrade>
                        <ImgContentButtonUpgrade src='/icons/icon_obscurian.png'/>
                        <DescriptionButtonUpgrade>
                          {props.valueUpgradeObscurian}
                        </DescriptionButtonUpgrade>
                      </ContentButtonUpgrade>
                      <ContentButtonUpgrade>
                        <ImgContentButtonUpgrade src='/icons/icon_type_mercoxium.png'/>
                        <DescriptionButtonUpgrade>
                          {props.valueUpgradeMercoxium}
                        </DescriptionButtonUpgrade>
                      </ContentButtonUpgrade>
                      <ContentButtonUpgrade>
                        <ImgContentButtonUpgrade src='/icons/icon_type_crelyte.svg'/>
                        <DescriptionButtonUpgrade>
                          {props.valueUpgradeCrelyte}
                        </DescriptionButtonUpgrade>
                      </ContentButtonUpgrade>
                      <ContentButtonUpgrade>
                        <ImgContentButtonUpgrade src='/icons/icon_type_crown_coin_grey.png'/>
                        <DescriptionButtonUpgrade>
                          {props.valueInstantUpgrade}
                        </DescriptionButtonUpgrade>
                      </ContentButtonUpgrade>
                    </ContainerButtonUpgrade>
                  </ContainerUpgrade>
                </ContainerSection3>
                :
                null
              }
          </ContainerGeneralToUPLevels>
      </>
  )
}

function ComponentContainer(props){

  return (
    <ElementContainer to={""}>
      {
        props.lockActive ?
        <LockContainer>
          <Lock src={LockIcon} />
        </LockContainer>
        :
        null
      }
      <Element>
        <ContainerDarkerLayer>
          <ContainerImage src={props.image}></ContainerImage>
        </ContainerDarkerLayer>
        <ContainerContent>
          <Icon src={props.icon_type_header}></Icon>
          <Text>{props.title}</Text>
        </ContainerContent>
      </Element>
      <ContainerPolygon style={{ right: "0" }}>
        <PolygonRight>
          <TextLevel>Lvl {props.currentLevel}</TextLevel>
        </PolygonRight>
        </ContainerPolygon>
    </ElementContainer> 
  );
}

export function Facilities() {
  useEffect(() => {
    updateNavs();
  }, []);

  const [currentFacility, setCurrentFacility] = useState('');
  const [levels, setLevels] = useState(null);
  const [loader, setLoader] = useState(false);

  const [popUpUpgrade, setPopUpUpgrade] = useState(false);
  const [popUpUpgradeInstant, setPopUpUpgradeInstant] = useState(false);

  const [popupServerError, setPopupServerError] = useState(false);
  const [textServerError, setTextServerError] = useState("Server Error");

  const [popupServerSuccess, setPopupServerSuccess] = useState(false);
  const [textServerSuccess, setTextServerSuccess] = useState("");

  const fetchGetLevels = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('levels/', config)
    .then((res) => {
        if (res.data.result){
          setLevels(res.data.response);
        }
    })
    .catch((err) => {
    console.log(err);
    })
    .finally(() => {
      setLoader(false);
    })
  };

  useEffect(() =>{
    fetchGetLevels();
  }, []);

  const upgradeLevel = (instantUpgrade) => {
    setLoader(true);
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(sessionStorage.getItem("token")).token
        }`,
      },
      data: { instant_upgrade: instantUpgrade },
      method: "POST",
    };
    HTTP.request(currentFacility==='Outpost'? "upgrade/outpost/level/" : "upgrade/hangar/level/", config)
      .then((res) => {
        if (res.data.result){
          setPopUpUpgrade(false);
          setPopUpUpgradeInstant(false);
          setPopupServerSuccess(true);
          setTextServerSuccess(res.data.response);
        }else{
          setPopUpUpgrade(false);
          setPopUpUpgradeInstant(false);
          setPopupServerError(true);
          setTextServerError(res.data.response);
        }
      })
      .catch((err) => {
        console.log(err);
        setPopupServerError(true);
        setTextServerError(err.response.statusText);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const successUpgrade = () =>{
    fetchGetLevels();
    updateNavs();
    setPopupServerSuccess(false);
  }

  return (
    <div>
      <Loader loading={loader}/>

      <PopUp
        show={popUpUpgrade}
        onClose={() => setPopUpUpgrade(false)}
        onConfirm={() => upgradeLevel(false)}
      >
        <TextPoppins
          style={{ fontWeight: "400", fontSize: "15px", lineHeight: "27px" }}
        >
          {"Are you sure you want to upgrade?"}
        </TextPoppins>
      </PopUp>

      <PopUp
        show={popUpUpgradeInstant}
        onClose={() => setPopUpUpgradeInstant(false)}
        onConfirm={() => upgradeLevel(true)}
      >
        <TextPoppins
          style={{ fontWeight: "400", fontSize: "15px", lineHeight: "27px" }}
        >
          {"Are you sure you want to instant upgrade?"}
        </TextPoppins>
      </PopUp>

      <PopUp
        show={popupServerError}
        onClose={() => setPopupServerError(false)}
        onlyConfirm
      >
        <TextPoppins
          style={{ fontWeight: "400", fontSize: "15px", lineHeight: "27px" }}
        >
          {textServerError}
        </TextPoppins>
      </PopUp>

      <PopUp
        show={popupServerSuccess}
        onClose={() => successUpgrade()}
        onlyConfirm
      >
        <TextPoppins
          style={{ fontWeight: "400", fontSize: "15px", lineHeight: "27px" }}
        >
          {textServerSuccess}
        </TextPoppins>
      </PopUp>

      <HeaderScreen
        widthBar="100%"
        width="100%"
        extended={true}
        imgHeader={image}
        icon="/icons/icon_type_facilities_header.svg"
        title="FACILITIES"
      >
        Upgrade your Facilities level (soon) and improve their functionality.
      </HeaderScreen>

      { currentFacility==='Outpost'?
        <UpLevels 
          title="Outpost" 
          description="Your Outpost serves as the heart of your operations. By upgrading this facility, you'll gain access to more challenging missions, as well as new unlocks for other facilities that require a higher level. Additionally, you'll increase the number of simultaneous mission slots available, allowing for greater flexibility and efficiency in your expeditions. Upgrade your Outpost Facility today and unlock a wealth of new opportunities!"
          img={ImageToUpLevelOutpost}
          currentLevel={levels.outpost.current_level}
          titleTypeUpgrade={"Mission Slots:"}
          valueTypeUpgrade={levels.outpost.value_type_upgrade}
          nextUpgrade={levels.outpost.next_upgrade}
          requirement={levels.outpost.requirement}
          valueUpgradeBits={numberToThousandSeparatorLeaderboardRanking(levels.outpost.cost_bits)}
          valueUpgradePeroxium={numberToThousandSeparatorLeaderboardRanking(levels.outpost.cost_peroxium)}
          valueUpgradeTrantium={numberToThousandSeparatorLeaderboardRanking(levels.outpost.cost_trantium)}
          valueUpgradeObscurian={numberToThousandSeparatorLeaderboardRanking(levels.outpost.cost_obscurian)}
          valueUpgradeMercoxium={numberToThousandSeparatorLeaderboardRanking(levels.outpost.cost_mercoxium)}
          valueUpgradeCrelyte={numberToThousandSeparatorLeaderboardRanking(levels.outpost.cost_crelyte)}
          valueInstantUpgrade={numberToThousandSeparatorLeaderboardRanking(levels.outpost.cost_crowns)}
          setPopUpUpgrade={setPopUpUpgrade}
          setPopUpUpgradeInstant={setPopUpUpgradeInstant}

        />
        :
        null
      }

      { currentFacility==='Hangar'?
        <UpLevels 
          title="Hangar" 
          description="As you navigate the harsh wilderness, your Hangar facility becomes an essential hub for your operations. By upgrading your Hangar, you can increase the number of ships you can have simultaneously, allowing for more extensive exploration and expansion in the vast expanse of the wilderness. Upgrade your Hangar today and unlock new possibilities for your fleet!"
          img={ImageToUpLevelHangar}
          currentLevel={levels.hangar.current_level}
          titleTypeUpgrade={"Ship Slots:"}
          valueTypeUpgrade={levels.hangar.value_type_upgrade}
          nextUpgrade={levels.hangar.next_upgrade}
          requirement={levels.hangar.requirement}
          valueUpgradeBits={numberToThousandSeparatorLeaderboardRanking(levels.hangar.cost_bits)}
          valueUpgradePeroxium={numberToThousandSeparatorLeaderboardRanking(levels.hangar.cost_peroxium)}
          valueUpgradeTrantium={numberToThousandSeparatorLeaderboardRanking(levels.hangar.cost_trantium)}
          valueUpgradeObscurian={numberToThousandSeparatorLeaderboardRanking(levels.hangar.cost_obscurian)}
          valueUpgradeMercoxium={numberToThousandSeparatorLeaderboardRanking(levels.hangar.cost_mercoxium)}
          valueUpgradeCrelyte={numberToThousandSeparatorLeaderboardRanking(levels.hangar.cost_crelyte)}
          valueInstantUpgrade={numberToThousandSeparatorLeaderboardRanking(levels.hangar.cost_crowns)}
          setPopUpUpgrade={setPopUpUpgrade}
          setPopUpUpgradeInstant={setPopUpUpgradeInstant}
        />
        :
        null
      }

      { levels ?
        <ContainerGeneral>

          <div onClick={()=>setCurrentFacility('Hangar')}>
            <ComponentContainer 
              lockActive={false}
              image={ImageHangar}
              icon_type_header={"/icons/icon_type_hangar_header.svg"}
              title={"HANGAR"}
              currentLevel={levels.hangar.current_level}
            />
          </div>
          <div>
            <ComponentContainer 
              lockActive={true}
              image={ImageCommanders}
              icon_type_header={"/icons/icon_type_commanders_header.svg"}
              title={"COMMANDERS"}
              currentLevel={"1"}
            />
          </div>
          <div>
            <ComponentContainer 
              lockActive={true}
              image={ImageShipyard}
              icon_type_header={"/icons/icon_type_header_shipyard.svg"}
              title={"SHIPYARD"}
              currentLevel={"1"}
            />
          </div>
          <div onClick={()=>setCurrentFacility('Outpost')}>
            <ComponentContainer 
              lockActive={false}
              image={ImageOutpost}
              icon_type_header={"/icons/icon_type_header_outposts.svg"}
              title={"OUTPOST"}
              currentLevel={levels.outpost.current_level}
            />
          </div>
        </ContainerGeneral>
        :
        null

      }
    </div>
  );
}
