import React, { useState } from 'react';

import '../containers/hooks/styles.css'

import {
    TittleV3,
    TittleTextV4,
    TittleTextV5,
    ElementsComposeYourFleet,
    NameElementCYF,
    TextNameElementCYF,
    ImageElementCYF,
    ImageElementCYFContainer,
    ContainerDataCYF,
    TittleTextV7,
    ButtonAdd,
    ButtonRemove,
    TextTextTitiliumWebInfo,
} from './ReusableElementComposeYourFleetElement';



import { RowCore , ContainerPolygon , PolygonLeft , PolygonRight, ContainerInput, ColButtons, IconArrow  } from '../containers/CoreElement'


import { DropdownStats } from './DropdownStats';
import { DropdownStatsShips } from './DropDownStatsShips';
import GridSpecs from './GridSpecs';
import { InputSetAmount } from './GenericCardElement';


//Shipyard-Forge Process
const ReusableElementComposeYourFleet = (props) => {

    const removeElement = (item, count) => {
        props.removeElement(item, count)
    };

    const addElement = (item, count) => {
        props.addElement(item, count)
    };

    const [inputAmountAdd, setInputAmountAdd] = useState('');

    const handleInputAmountAddChange = (event) => {
        setInputAmountAdd(event.target.value);
    };

    const [inputAmountRemove, setInputAmountRemove] = useState('');

    const handleInputAmountRemoveChange = (event) => {
        setInputAmountRemove(event.target.value);
    };

    const handleIncrementAdd = (item) => {

        console.log(item);
        const numMax = props.count;

        // alert(`${inputAmountAdd} - ${numMax}`)

        console.log(inputAmountAdd);
        if(inputAmountAdd.length === 0){
            setInputAmountAdd(1)
        }else if(inputAmountAdd < numMax){
            console.log('ENTRAAAA');
            setInputAmountAdd(parseInt(inputAmountAdd) + 1);
        }
    }

    const handleDecrementAdd = () => {
        if(inputAmountAdd >= 1){
            setInputAmountAdd(parseInt(inputAmountAdd) - 1);
        }
    }

    const handleIncrementRemove = (item) => {
        const numMax = props.count;

        console.log(inputAmountRemove);
        if(inputAmountRemove.length === 0){
            setInputAmountRemove(1)
        }else if(inputAmountRemove < numMax){
            setInputAmountRemove(parseInt(inputAmountRemove) + 1);
        }
    }

    const handleDecrementRemove = () => {
        if(inputAmountRemove >= 1){
            setInputAmountRemove(parseInt(inputAmountRemove) - 1);
        }
    }


    return (
        <>

        <ElementsComposeYourFleet>
            <NameElementCYF>
                <TextNameElementCYF>
                {props.item.type === 'ship' ? props.item.name : props.item.commander.name}
                </TextNameElementCYF>
            </NameElementCYF>
            <ImageElementCYFContainer>
                <ImageElementCYF src={props.item.type === 'ship' ? props.item.image : props.item.commander.image}/>
                { props.item.type !== 'ship' ?
                <>
                    <ContainerPolygon>
                        <PolygonLeft><TextTextTitiliumWebInfo>FP: +{props.item.commander.fp}</TextTextTitiliumWebInfo></PolygonLeft>
                    </ContainerPolygon>

                    <ContainerPolygon style={{right: '0'}}>
                        <PolygonRight><TextTextTitiliumWebInfo>LVL {props.item.commander.level}</TextTextTitiliumWebInfo></PolygonRight>
                    </ContainerPolygon>
                </>
                : 
                <>
                    <ContainerPolygon>
                        <PolygonLeft><TextTextTitiliumWebInfo>FP: {props.item.fp}</TextTextTitiliumWebInfo></PolygonLeft>
                    </ContainerPolygon>

                    <ContainerPolygon style={{right: '0'}}>
                        <PolygonRight><TextTextTitiliumWebInfo>LVL 1</TextTextTitiliumWebInfo></PolygonRight>
                    </ContainerPolygon>
                </>
                }
            </ImageElementCYFContainer>
            <ContainerDataCYF>
                <GridSpecs specs={props.item.specs} spaceBetweenColumns={'2px'}/>
                {props.item.type === "ship"?
                    <>
                    <TittleV3>
                        <TittleTextV4>
                            Class:
                        </TittleTextV4>
                        <TittleTextV5>
                            {props.item.class}
                        </TittleTextV5>
                    </TittleV3>
                    <TittleV3>
                        {props.count > 1 ?
                        <>
                            {!props.isRemove ?
                            <>
                                <TittleTextV4>
                                    Units:
                                </TittleTextV4>
                                <TittleTextV5>
                                    {props.count}
                                </TittleTextV5>
                            </>
                            :
                            <>
                                <TittleTextV4>
                                    Amount:
                                </TittleTextV4>
                                <TittleTextV5>
                                    {props.count}
                                </TittleTextV5>
                            </>
                            }
                        </>
                        :
                        null
                        }
                    </TittleV3>
                    </>
                    :
                    <>
                    <TittleV3>
                        <TittleTextV4>
                            Class:
                        </TittleTextV4>
                        <TittleTextV5>
                            {props.item.commander.class}
                        </TittleTextV5>
                    </TittleV3>
                    <TittleV3>
                        <TittleTextV4>
                            Bonus:
                        </TittleTextV4>
                        <TittleTextV5>
                            {props.item.commander.bonus.name}
                        </TittleTextV5>
                    </TittleV3>
                    <TittleV3>
                        {props.count > 1 ?
                        <>
                            <TittleTextV4>
                                Units:
                            </TittleTextV4>
                            <TittleTextV5>
                                {props.count}
                            </TittleTextV5>
                        </>
                        :
                        null
                        }
                    </TittleV3>
                    </>
                }
            </ContainerDataCYF>
            {props.item.type === 'ship' ? <DropdownStatsShips iconButton={"/icons/icon_special_stats.svg"} textButton='Stats' jsonObj={props.item} ships={true}/> 
            : <DropdownStats iconButton={"/icons/icon_special_stats.svg"} textButton='Stats' jsonObj={props.item.commander.bonus} ships={false}/>}
            {!props.isRemove ?
                <>
                    { props.count > 1 ?
                        <RowCore>
                        <ContainerInput>
                            <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='0' min={1} max={props.count} value={inputAmountAdd} onChange={handleInputAmountAddChange}/>
                            <ColButtons>
                                <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrementAdd(props.item)}/>
                                <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrementAdd()}/>
                            </ColButtons>
                        </ContainerInput>
                        <ButtonAdd onClick={() => addElement(props.item, inputAmountAdd)} style={{width: '30%'}}>
                            <TittleTextV7>
                            Add
                            </TittleTextV7>
                        </ButtonAdd>
                        </RowCore>
                        : 
                        <ButtonAdd onClick={() => addElement(props.item, 1)}>
                        <TittleTextV7>
                            Add
                        </TittleTextV7>
                        </ButtonAdd>
                    }
                </>
                :
                <>
                    { props.count > 1 ?
                        <RowCore>
                            <ContainerInput>
                                <InputSetAmount className='input-custom' type='number' name='Set amount' placeholder='0' min={1} max={props.count} value={inputAmountRemove} onChange={handleInputAmountRemoveChange}/>
                                <ColButtons>
                                    <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrementRemove(props.item)}/>
                                    <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrementRemove()}/>
                                </ColButtons>
                            </ContainerInput>
                            <ButtonRemove onClick={() => removeElement(props.item, inputAmountRemove)} style={{width: '30%'}}>
                                <TittleTextV7>
                                Remove
                                </TittleTextV7>
                            </ButtonRemove>
                        </RowCore>
                        : 
                        <ButtonRemove onClick={() => removeElement(props.item, 1)}>
                            <TittleTextV7>
                            Remove
                            </TittleTextV7>
                        </ButtonRemove>
                    }
                </>
            }

    
        </ElementsComposeYourFleet>

        </>
    )
};

export default ReusableElementComposeYourFleet;