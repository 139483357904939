import styled from "styled-components";
import { ContainerBasicBG, ContainerVoid, GridViewCore, TextTitiliumWeb } from "../../../../containers/CoreElement";


export const ContainerOptions1 = styled(ContainerBasicBG)`
    width: 100%;
    background: rgba(141, 12, 80, 0.7);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E71383;
    height: 40px;
    padding: 0;
`

export const BtnOptions1 = styled(ContainerVoid)`
    height: 100%;
    width: 100%;
    cursor: pointer;
`

export const TextTitiliumWebOptions1 = styled(TextTitiliumWeb)`
    font-weight: 400;
    line-height: 35px;
    margin-left: 3%;

    @media (max-width: 600px) {
        font-size: 12px;
    }
`

export const ContainerOptions2 = styled(ContainerOptions1)`
    box-shadow: inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5);
`
export const BtnOptionExport = styled(BtnOptions1)`
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.295) inset;
    background: #AA68D8;
`

export const TextTitiliumWebOptions2 = styled(TextTitiliumWebOptions1)`
    margin-left: 5%;
`

export const TextCoins = styled.div`
    margin-left: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NumberCoins = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 10%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
`
export const ContainerTable = styled.div`
    margin-top: 2%;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
    background: rgba(94, 8, 53, 0.1);
    background-size: 100% 100%;
    border: 1px solid rgba(231, 19, 131, 1);
    position: relative;
    color: white;
`
export const ContainerItems = styled(ContainerTable)`
    
    @media (max-width: 960px) {
        margin-bottom: 20%;
    }
`

export const ContainerTableMyExport = styled.div`
    gap: 13px;
    border-radius: 4px;
    color: white;
    padding-bottom: 0;
    position: relative;
`

export const ContainerGridView = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    color: #ffffff;
    display: flex;
    width: 100%;
    height: 650px;
    flex-direction: column;
    padding: 5px;
`

export const TittleBarV1 = styled.div`
    padding: 1.5%;
    background: rgba(141, 12, 80, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
`

export const TittleTextV2 = styled(TextTitiliumWeb)`
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
`

export const ContainerGrid = styled.div`
    padding-top: 7px;
    width: 96%;
    margin: 1% 15px;
    background: rgba(94, 8, 53, 0.1);

`

export const GridViewCoreItems = styled(GridViewCore)`
    height: 480px;
    /* margin-right: 1%;
    margin-left: 1%; */
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
export const TextCrown = styled(TextTitiliumWeb)`
    margin-left: 6%;
    font-weight: 400;
`
export const ContainerInfo = styled(ContainerVoid)`
    width: auto;
    margin: 0px 60px;
    background: rgba(141, 12, 80, 0.7);
    padding: 10px 20px;
`

export const ContainerTextInfo = styled.div`
    margin-right: 20px; 
    display: flex;
    justify-content: center;
`
