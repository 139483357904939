//Libraries INIT
import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../../../http/http-common';
import { useNavigate } from 'react-router-dom'

import { 
    ContainerElements, ContainerRepairBay, Text
} from './RepairBayElement'

import ReusableCardRegular from "../../../../../../hooks/ReusableCardRegular";
import Pagination from '../../../../../../hooks/Pagination';
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids';

import PopUp from '../../../../../../hooks/PopUp';
import { TextPoppins } from '../../../../../../containers/CoreElement';
import { Loader } from '../../../../../../hooks/Loader';
import { FilterBar } from '../../../../../../hooks/FilterBar';
import { updateNavs } from '../../../../../utils/utils';


export function RepairBayPickUp() {

    const elementsRepairBaPickUp = [

        {'icon' : '/icons/icon_type_pickup_all.svg', 'text' :'PICKUP ALL', 'action' : () => setPopUpPickUpAllRepair(true), 'backgroundItem' : "#7C0242"},
    
      ]

    const [loader, setLoader] = useState(false);
    const [cardsRepairBayPickUp, setCardsRepairBayPickUp] = useState([])
    const [forgeCode, setForgeCode] = useState(-1);
    const [popUpPickupNow, setPopUpPickupNow] = useState(false);
    const [popUpPickUpAllRepair, setPopUpPickUpAllRepair] = useState(false);
    
    let navigate = useNavigate();

    const fetchShipsInPickUp= () => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/repair/pickup/process/', config)
        .then((res) => {
            if (res.data.result){
                setCardsRepairBayPickUp(res.data.pickups)
            }
        })
        .catch((err) => {
        console.log(err);
        })
        .finally(() => {
            setLoader(false);
          })
    
    };

    const fetchShipsPickUp = (repairCode) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"repair_code": repairCode},
            method: "POST"
        };
        HTTP.request('ships/repair/pickup/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/shipyard/hangar')
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoader(false);
          })
    };


    const handleAction = (forgeCode) => {
        setForgeCode(forgeCode);
        setPopUpPickupNow(true);
    }

    const fetchRepairShipsPickUpAll = () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          method: "POST"
        };
        HTTP.request('ships/repair/all/pickup/', config)
          .then((res) => {
            if (res.data.result){
              setPopUpPickUpAllRepair(false)
              navigate('/shipyard/hangar')
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      };


    useEffect(() => {
        updateNavs();
        fetchShipsInPickUp();
    }, []);

    return (

        <>
            <Loader loading={loader}/>
            {elementsRepairBaPickUp.length > 0 ?
              <FilterBar                                  
                elements = {elementsRepairBaPickUp}
                columns = "repeat(1,1fr)"                                                           
              ></FilterBar>
              :
               null
            }
            <ContainerRepairBay style={{height: cardsRepairBayPickUp.length !== 0 ? '650px' : '170px'}}>
                <FilterForGrids allElements={cardsRepairBayPickUp} sortByClass>
                    { elementsToFilter => (
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData='There are not pick up ships'>
                            { elementsToRender => (
                                <ContainerElements>
                                    {elementsToRender.map((card, index) => (
                                        <ReusableCardRegular 
                                            item={card.ship}
                                            buttonText={"Pickup Now"}
                                            action={() => handleAction(card.repair_code)}
                                        ></ReusableCardRegular>
                                    ))}
                                </ContainerElements>
                            )
                            }
                        </Pagination>
                    )
                    }
                </FilterForGrids>
            </ContainerRepairBay>

            <PopUp show={popUpPickupNow} onClose={() => setPopUpPickupNow(false)} onConfirm={() => fetchShipsPickUp(forgeCode)}>
                <TextPoppins>Are you sure you want to pick up now?</TextPoppins>
            </PopUp>

            <PopUp show={popUpPickUpAllRepair} onClose={() => setPopUpPickUpAllRepair(false)} onConfirm={() => fetchRepairShipsPickUpAll()}>
                <Text>Are you sure you want to pick up all?</Text>
            </PopUp>
        </>
    )

}