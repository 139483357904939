import { updateCountMessages, updateNavs } from "../components/utils/utils";

export const WebSocketComponent = (props) => {
    props.setFirstTime(false);
    //ws://127.0.0.1:8000/ws/websocket/
    //wss://fleet-staging-qay26lbj4q-uc.a.run.app/ws/websocket/
    const socket = new WebSocket(`wss://s10985.nyc1.piesocket.com/v3/1?api_key=6hT9TAuJo1BbSwNEBP3eVHhynqoQZydiAGnKjiX5&notify_self=1`);   
    console.log(sessionStorage.getItem('ws_open'))
    if (sessionStorage.getItem('ws_open') !== true && sessionStorage.getItem('ws_open') !== "true"){
      console.log("OPEN")
      sessionStorage.setItem('ws_open', true)
      // Event listener for when the connection is opened
      socket.addEventListener('open', (event) => {
        console.log('WebSocket connection opened', event);
      });

      // Event listener for when a message is received
      socket.addEventListener('message', (event) => {
        console.log("MSG LISTENED")
        var data = JSON.parse(event.data)
        
        if (data.type === "RELOAD"){
          console.log(JSON.parse(sessionStorage.getItem('token')).user_id)
          console.log(data)
          if ( data.user_id === JSON.parse(sessionStorage.getItem('token')).user_id){
            updateCountMessages();
            updateNavs();
            props.fetchRoutesActive();
            props.fetchRoutesReturning();
          }
        }
      });

      // Event listener for when the connection is closed
      socket.addEventListener('close', (event) => {
        console.log('WebSocket connection closed', event);
        sessionStorage.setItem('ws_open', false)
      });
      
    }

    window.addEventListener('beforeunload', function(event) {
      sessionStorage.setItem('ws_open', false)
    });


  };

