import styled from "styled-components";
import { ContainerBasic, ContainerBasicBG, RowCore } from "../containers/CoreElement";
import { TextTitiliumWeb } from '../containers/CoreElement';

export const ContainerHeader = styled(ContainerBasic)`
    width: 100%;
    height: 200px;
    clip-path: polygon(0px 6px, 6px 0px, calc(100% - 6px) 0px, 100% 6px, 100% calc(100% - 6px), calc(100% - 0px) 100%, 6px 100%, 0px calc(100% - 0px));
    color: white;
    padding: 0;
    border: 1px solid #a40f5e;
    border-radius: 2px;
    position: relative;
`

export const ContainerHeaderResourcesBorder = styled(ContainerBasicBG)`
    background: #E71383 ;
    clip-path: polygon(0 3.00px,3.00px 0,calc(100% - 3.00px) 0,100% 3.00px,100% calc(100% - 3.00px),calc(100% - 3.00px) 100%,3.00px 100%,0 calc(100% - 3.00px));
    padding: 0;

    margin-bottom: 3px;
    border: 0px;
`

export const ContainerHeaderBorder = styled(ContainerBasicBG)`
    background: #E71383 ;
    clip-path: polygon(0 3.00px,3.00px 0,calc(100% - 3.00px) 0,100% 3.00px,100% calc(100% - 3.00px),calc(100% - 0px) 100%,3.00px 100%,0 calc(100% - 0px));
    padding: 0;
    position: relative;
    border: 0px;
    flex-flow: column;
`

export const CollateralContainer = styled.div`
    border-right: 1px solid #E71383;
    border-bottom: 1px solid #E71383;
    border-left: 1px solid #E71383;
    background: #0000009c;
    display: flex;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 0;

`

export const WeekResetContainer = styled.div`
    display: flex;
    height: 35px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
`

export const OsmPledgeContaiener = styled.div`
    display: flex;
    height: 35px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #E71383;
`

export const ContainerIconText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
`

export const ContainerMainElements = styled.div`
    padding: 3px 0px 3px 0px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2%;
    line-height: 0px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

`

export const ContainerMainElementsDesktop = styled(ContainerMainElements)`

    @media(max-width: 600px) {
        
        display: none;   
    }
`
export const ContainerMainElementsCellphone = styled(ContainerMainElements)`
    grid-template-columns: repeat(2, 1fr);

    @media(min-width: 601px) {
        display: none;
    }
`

export const ContainerHeaderResources = styled(ContainerBasicBG)`
    clip-path: polygon(0 6.00px,6.00px 0,calc(100% - 6.00px) 0,100% 6.00px,100% calc(100% - 6.00px),calc(100% - 6.00px) 100%,6.00px 100%,0 calc(100% - 6.00px));
    width: 100%;
    color: white;
    padding: 0;
    background: rgb(65, 6, 37);
    background-blend-mode: normal;
`

export const HeaderMinerals = styled(RowCore)`

    @media(max-width: 600px) {
        
        justify-content: space-between;
    }
`

export const ContainerHeaderSquare = styled(ContainerBasic)`
    width: 100%;
    height: 200px;
    color: white;
    padding: 0;
    border: 1px solid #E71383;
    border-radius: 2px;
    position: relative;
`

export const ContainerTextHelp = styled(ContainerBasicBG)`
    background: rgba(141, 12, 80, 0.7);
    border: 0px;
    padding: 10px;
`

export const ContainerElements = styled.div`
    border: 1px solid transparent;
    border-image:linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;
    border-bottom: 0px;
    border-right: 0px;
    border-top: 0px;

    display: grid;
    grid-template-columns: 15px auto 13px;
    align-items:center;

    padding-left: 7px;
    padding-right: 7px;

    margin-top: 3px;
    margin-bottom: 3px;
    min-width: 95px;
    gap: 5px;
`
export const ContainerElements1 = styled(ContainerElements)`
    text-align: center;
    
    display: flex;
    justify-content: center;
`
export const ContainerElementsHover = styled.div`
    padding: 3px;
    width: 90px;
    background: rgb(65, 6, 37);
    border: 0.1px solid rgb(231, 19, 131);
    position: absolute;
    bottom: 102%;

    color: white;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    font-family: 'Poppins';
    align-items: center;

    display: block;
`
export const ContainerElementsPeroxium = styled(ContainerElementsHover)`
    left: 25%;
`
export const ContainerElementsTrantium = styled(ContainerElementsHover)`
    left: 37%;
`
export const ContainerElementsObscurian = styled(ContainerElementsHover)`
    left: 45%;
`
export const ContainerElementsMercoxium = styled(ContainerElementsHover)`
    left: 57%;
`
export const ContainerElementsCrelyte = styled(ContainerElementsHover)`
    left: 72%;
`
export const ContainerElementsBits = styled(ContainerElementsHover)`
    left: 85%;
`
export const Text = styled(TextTitiliumWeb)`
    line-height: 0px ;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: 14px;
`

export const ButtonClose = styled.div`
    clip-path: polygon(0px 8px, 8px 0px, 100% 0px, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0px 100%);
    background:#E71383;
    width: 24px;
    height: 24px;
    margin-right: 9px; 

    right: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
`

export const Icon = styled.img`
    width: 9px;
    height: 9px;
`
export const RowMsg = styled(RowCore) `
    width: 50%;
    border: 0.5px solid #E71383;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    background:#8D0C50B2;

    @media (max-width: 600px) {
        width: 90%;
    }
`
export const TextInfo = styled(TextTitiliumWeb) `
    font-size: 14px; 
    text-align: left;
    line-height: 18px;
    font-weight: 300;
`


