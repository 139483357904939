import styled from "styled-components";
import { BtnPolygon, TextPoppins } from "../../containers/CoreElement";

export const ContainerRegister = styled.div`
    width: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;

    @media (max-width: 1230px) {
        margin-top: 40px;
        width: 80%;
        margin-bottom: 5%;
    }
`

export const SignUpForm = styled.form`
    width: 100%;
    padding: 50px;
    background: #5E0835;
    border: 1px solid #E61383;

`;

export const SignUpDiv = styled.div`
    width: 100%;
    padding: 50px;
    background: #5E0835;
    border: 1px solid #E61383;
`;

export const SignUpImage = styled.div`
    width: 360px;
    border: 1px solid rgb(230, 19, 131);
    border-right-width: 0;
    object-fit: cover;
    @media (max-width: 1230px) {
        display: none;
    }
`;

export const FormTitle = styled.div`
    font-weight: 600;
    color: white;
    font-size: 30px;
    line-height: 36px;
    font-family: 'Poppins';
    margin-bottom: 10px;
`

export const FormControl = styled.div`
    width: 100%;
    height: 44px;
    border: 1px solid ${props => (props.onInvalid ? '#FF0000' : '#E61383')};
    background: #230313;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
`;

export const FormLabel = styled.label`
    color: white;
    margin-top: 15px;
    position: relative;
    font-family: 'Poppins';
    font-size: 14px;
    &:after{
        content: '*';
        color: #E61383;
        position: absolute;
        right: -10px;
        top: 0;
    }
    `;

export const InputUsername = styled.input`
    width: calc(100% - 34px);
    height: 44px;
    color: white;
    background: transparent;
    outline: none;
    border: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    &::placeholder {
        color: rgba(255, 255, 255, 0.70);
        font-family: 'Poppins';
        font-size: 18px;
        line-height: 24px;
        font-style: normal;
        font-weight: 400;
    }
    &:-internal-autofill-selected {
        background-color: transparent !important;
    }
`

export const InputEmail = styled(InputUsername)`

`
export const InputPassword = styled(InputUsername)`
    width: calc(100% - 60px);
`
export const InputConfirmPassword = styled(InputPassword)`

`
export const PasswordDone = styled('div')`
    position: absolute;
    left:  calc(100% - 60px);
`
export const FormCheck = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
    color: white;
    a {
        color: #E61383;
        text-decoration: underline;
    }
`;

export const CheckLabel = styled.label`
   margin-left: 10px;
   color: white;
   font-family: 'Titillium Web';
   font-size: 18px;
   a {
    color: #E61383;
    font-family: 'Poppins';
    font-size: 18px;
    text-decoration: underline;
   }
    `;

export const BtnSignUp = styled(BtnPolygon)`
    margin-top: 30px;
    color: white;
    width: 100%;
    height: 54px;
    background: rgba(141, 12, 80, 0.70);
    /* @media (max-width: 1000px) {
        width: 304px;
    } */
`
export const TextButtons = styled(TextPoppins)`
    font-weight: 600;
    letter-spacing: 0.02em;
    font-size: 18px;
    padding: 12px 0px;
`
export const FormPassWordError = styled.div`
    margin-bottom: 20px;
`;

export const ErrorItems = styled.div`
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PassWordErrorItems = styled(ErrorItems)`

`;

export const FieldErrorStatus = styled.label`
    margin-left: 10px;
    color: white;
    font-family: 'Titillium Web';
    font-size: 16px;
    `;

export const PasswordErrorStatus = styled(FieldErrorStatus)`
    margin-left: 10px;
    color: ${props => (props.checked ? '#E61383' : '#626262')};
`;

export const FormErrorHeader = styled.div`
    padding: 10px 20px 4px 20px;
    margin-top: 10px;
    background: rgba(141, 12, 80, 0.70);
`;
export const FieldErrorHeaderStatus = styled(FieldErrorStatus)`
    margin-left: 5px;
    font-size: 12px;
    font-family: 'Titillium Web';
    `;
export const FormSignInLink = styled.div`
    text-align: center;
    color: white;
    margin-top: 10px;
    font-family: 'Poppins';
    font-size: 18px;
    a {
        color: #E61383;
        font-family: 'Poppins';
        text-decoration: underline;
    }
`;

export const FormSupportLink = styled(FormSignInLink)`
    font-size: 18px;
    line-height: 24px;
`;