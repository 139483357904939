import styled from 'styled-components'
import {
Img,
RowCore,
TextTitiliumWeb,
ContainerBasic,
GridViewCoreScroll2,
GridViewCore
} from'../containers/CoreElement.jsx'
///Rectangle Empty
export const RectangleEmpty = styled.div`
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 765px;
    height: 130px;

   
    
    @media (max-width: 1160px) {
        width: 75%;
        padding: 12px;
    }
`

export const ButtonSelectRouteTime = styled.button`
    background-color: transparent;
    border: 1px solid rgb(231, 19, 131);
`

export const RectangleEmptyContainerGeneral = styled.div`

    margin: 0px 0px 15px 0px;
`

export const RectangleEmptyContainerGeneral2  = styled.div  `
    display: inline-flex;
    margin: 0px 0px 15px 0px;
`
///Rectangle Complete
export const RectangleContainerGeneral = styled.div`
    width: 100%;
    
`

export const RectangleElementFirst = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`

export const RowEnemyFleet = styled(RowCore)`
    
    gap: 20px;
    @media (max-width: 600px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 5px;
    }
`

export const RectangleContainerGeneral2  = styled.div  `
    display: inline-flex;
    margin: 0px 0px 0px 0px;
    width: 100%;
`

export const RectangleImage = styled(Img) `
    border: 1px solid #E71383;
    border-right:0px;
    width: 180px;
    height: 130px;
    height: auto;

    @media (max-width: 600px) {
        display: none;
    }
`

export const RectangleContainerComplete = styled.div`
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    width: 100%;
    justify-content: space-between;
    display: flex;
    color: white;
    
    @media (max-width: 1160px) {
        width: 100%;
        height: auto;
        /* padding: 12px; */
    }
`

export const RectangleContainerCompleteRented = styled.div`
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
    color: white;
    border-left: 0px;
    
    @media (max-width: 1160px) {
        width: 75%;
       
        padding: 12px;
    }
`

export const RectangleRowsContainer = styled.div`
    width: 100%;
    padding: 0px 12px 0px 12px;
    display: flex;
    flex-direction: column;
`

export const RectangleRowsContainerRow = styled.div`
    /* height: 33%;
    width: 100%; */
    border: 1px solid transparent;
    border-image: linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
`

export const RectangleRowContainerFirst = styled(RowCore)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const GridViewEnemyShips = styled(GridViewCore)`
    grid-template-columns: repeat(6, 1fr);
`

export const RectangleRowFirstContainer1 = styled.div`
    display: grid;
    grid-column-gap: 0px;
    grid-template-columns: repeat(4, 1fr);
`

export const RectangleRowFirstContainer1Outposts = styled.div`
    display: grid;
    grid-column-gap: 36px;
    grid-template-columns: repeat(2, 1fr);
`

export const RectangleRowFirstContainer2 = styled.div`
    
    display:inline-flex;
`

export const RectangleRowFirstContainerIconAndText = styled.div`  
    display:inline-flex;
`

export const RectangleRowSecondContainerStats = styled.div`

`

export const RectangleRowContainerSecond = styled.div`
    width: 100%;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2,1fr);
    height: 21px;
`
export const RectangleRowContainerSecondMissions = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`

export const RectangleRowText = styled(TextTitiliumWeb)`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    color: #FFFFFF;
`

export const RectangleRowTextRoutes = styled(TextTitiliumWeb)`
    
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 300;
    background-color:rgba(141, 12, 80, 0.5);
    border: 1px solid rgba(231, 19, 131, 1);
    height: 21px; 
    line-height: 157%;
    padding: 0px 3px 0px 3px;
    margin: 10px 6px 0px 6px; 
`

export const RectangleRowTextPurple = styled(TextTitiliumWeb)`
    color: rgba(231, 19, 131, 1);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
`
export const RectangleRowTextPurpleValueIcon = styled(RectangleRowTextPurple)`
`

export const TextStats = styled(RectangleRowTextPurple)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
`

export const TextButton = styled(TextTitiliumWeb)`
    font-size: 15px;
    font-weight: medium;
    letter-spacing: 0px;
    line-height: 20px;
`
///
export const RectangleButtonContainer = styled.div `
    width: 180px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 1px solid transparent;
    border-image: linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;
`

export const RectangleButtonContainerDesktop = styled(RectangleButtonContainer) `
    @media(max-width: 600px) {
        display: none;
    }
`

export const RectangleButtonContainerCellphone = styled(RectangleButtonContainer) `
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    @media(min-width: 601px) {
        display: none;
    }
`

export const Icon = styled.img`

`

export const IconButton = styled.img`

`

export const Button = styled.button`

    border: 1px solid rgba(231, 19, 131, 1);
    width: 110px;
    height: 70px;
    background-color:rgba(231, 19, 131, 1);
    clip-path: "";
    -webkit-clip-path: "";
    cursor: pointer;


    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
   
`


export const RoutesContainerText = styled(ContainerBasic)`
    padding: 0px;
    height: 30%;
    margin: 0px 3px 0px 3px;
    background : rgba(141, 12, 80, 0.5);
`

export const RoutesContainerText2 = styled(ContainerBasic)`
    padding: 0px 9px 0px 9px;
    margin: 0px 3px 0px 3px;
    background-color : #530830;
    margin: 0px 150px 0px 150px;
`



export const FleetResumeGeneral = styled.div`
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 1168px;
    height: 342px;
    justify-content: space-between;
    display: table-row;
    color: white;
    padding: 3px 15px 15px 15px;
    @media (max-width: 1160px) {
        width: 75%;
       
        padding: 12px;
    }
`

export const FleetResumeRows = styled.div`
    border-bottom: 1px solid #E71383;
    border-radius: 2px;
    width: 98%;
    height: 60px;
    display: flex;
    align-items: center;
`

export const FleetResumeRowTotal = styled.div`
    
    border-radius: 2px;
    width: 96%;
    height: 36px;
    display: flex;
    align-items: center;
`

export const FleetResumeRowTotalContainer = styled.div`
    
    border-radius: 2px;
    width: 99%;
    height: 36px;
    display: flex;
    align-items: center;
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid rgba(141, 12, 80, 0.5);
`

export const FleetResumeRowsFleet = styled.div`
    width: 300px;
    height: 42px;
  
    display: flex;
    align-items: center;
`

export const FleetResumeRowsAmount = styled.div`
    width: 122px;
    height: 42px;
    
`



export const FleetResumeRowsEquipment = styled.div`
    width: 184px;
    height: 42px;
 
    display: flex;
    align-items: center;
`
export const FleetResumeRowsType = styled.div`
    width: 184px;
    height: 42px;
   
    display: flex;
    align-items: center;

`
export const FleetResumeRowsStat = styled.div`
    width: 96px;
    height: 42px;
    
`

export const FleetResumeRowsContainerGeneral = styled.div`
    width: 99%;
    height: 270px;
    display: block;
    
    border: 3px solid rgba(231, 19, 131, 1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
`
export const FleetResumeRowsContainerRows = styled(GridViewCoreScroll2)`
    width: 99%;
    height: 210px;
    display: block;
    overflow-y: scroll;
    
    
   
`

export const FleetResumeTitles = styled(TextTitiliumWeb)`
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: 300;
    color:  #A9A7BB;
`

export const RowIcons = styled.img`
    width: 39x;
    height: 39px;
`

export const FleetResumeContainerTitle = styled.div`
    display: flex;
`


