import React from 'react';


import image from '../../../../../../assets/images/HeaderOutposts.jpg'

import HeaderScreen from '../../../../../../hooks/HeaderScreen'





export function HeaderOutposts() {
return (

    <HeaderScreen  
    widthBar="100%"
    width="100%"
    extended={true}
    imgHeader ={image}
    icon = "/icons/icon_type_header_outposts.svg"
    title = "OUTPOSTS"
    textHelp = "Rent Outposts to access their Routes and send your fleet to earn huge rewards!"
    >
    </HeaderScreen>

)
}