import React from 'react'
import { FilterBar } from '../../../../../../hooks/FilterBar'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ForgeBlueprints } from './ForgeBlueprints'
import { ForgeInProcess } from './ForgeInProcess'
import { ForgePickUp } from './ForgePickUp'

export default function Forge({closeHeader, isOpenBlueprints, closeCardForgeSelected}) {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  const elementsForge = [

    {'icon' : '/icons/icon_type_blueprints.svg', 'text' :'BLUEPRINTS', 'action' : () => handleClick('/shipyard/forge/blueprints'), 'backgroundItem' : location.pathname === '/shipyard/forge/blueprints' ? '#A40A5B' : 'transparent', 'boxShadow':'inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5)', 'color': location.pathname === '/shipyard/forge/blueprints' ? '#FFFFFF' : '#d2b8cd'},
    { 'icon' : '/icons/icon_type_in_process.svg', 'text' :'IN PROCESS', 'action' : () => handleClick('/shipyard/forge/in-process'), 'backgroundItem' : location.pathname === '/shipyard/forge/in-process' ? '#A40A5B' : 'transparent', 'boxShadow':'inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5)', 'color': location.pathname === '/shipyard/forge/in-process' ? '#FFFFFF' : '#d2b8cd'},
    { 'icon' : '/icons/icon_type_pick_up.svg', 'text' :'PICK UP', 'action' : () => handleClick('/shipyard/forge/pick-up'), 'backgroundItem' : location.pathname === '/shipyard/forge/pick-up' ? '#A40A5B' : 'transparent', 'boxShadow':'inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5)', 'color': location.pathname === '/shipyard/forge/pick-up' ? '#FFFFFF' : '#d2b8cd'},

  ]

  return (
    <>
    { elementsForge.length > 0 ?
        <FilterBar
        background = "rgba(255, 0, 136, 0.0)"
        boxShadow = "0px 6px 6px 0px #000000 inset"
        elements = {elementsForge}
        columns = "repeat(3,1fr)"
        ></FilterBar>   
        :
        null
    }
    <Routes>
        <Route path="blueprints" element={<ForgeBlueprints closeHeader = {closeHeader} isOpenBlueprints = {isOpenBlueprints} closeCardForgeSelected = {closeCardForgeSelected} ></ForgeBlueprints>}/>
        <Route path="in-process" element={<ForgeInProcess/>}/>
        <Route path="pick-up" element={<ForgePickUp/>}/>
    </Routes>
    </>
  )
}
