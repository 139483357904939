import React, { useEffect, useState } from 'react';

import { RowSpaceBetween, TextPoppins } from '../containers/CoreElement';
import '../containers/hooks/styles.css'
import {

RectangleContainerGeneral,
RectangleContainerGeneral2,
RectangleImage,
RectangleEmptyContainerGeneral,
RectangleEmpty,
RectangleContainerComplete,
RectangleRowContainerFirst,
RectangleRowContainerSecond,
RectangleRowsContainer,
RectangleEmptyContainerGeneral2,
RectangleRowText,
RectangleRowFirstContainer2,
RectangleRowSecondContainerStats,
Icon,
RectangleRowFirstContainerIconAndText,
TextStats,
RectangleElementFirst,
RectangleRowContainerSecondMissions,
RectangleRowsContainerRow
} from './ReusableRectangleElement';
import GridSpecs from '../hooks/GridSpecs';
import PopUp from './PopUp';
import IconSvg from './IconSvg';



export function ReusableRectangleEmpty() {

    return(
        <>
            { <RectangleEmptyContainerGeneral>
                <RectangleEmptyContainerGeneral2>
                    <RectangleImage />
                    <RectangleEmpty>

                        

                    </RectangleEmpty>
                </RectangleEmptyContainerGeneral2>
            </RectangleEmptyContainerGeneral>
            }

            
        </>
    )
}

const ReusableRectangleInfo = (props) =>  {

    const [showPopUpSendFleet, setShowPopUpSendFleet] = useState(false);
    const [typeMessage , setTypeMessage] = useState('System Message');

    useEffect(() => {
        setTypeMessage(props.typeMessage.p_mission_economic.type_of_route_name)
    }, [props.typeMessage.p_mission_economic.type_of_route_name, props.typeMessage])
    

    
     return(
        <>  
  <RectangleContainerGeneral>
        <RectangleContainerGeneral2>
            <RectangleImage src={props.image}/>
            <RectangleContainerComplete style={{ backgroundColor: props.rectangleBackground}} >
                <RectangleRowsContainer >
                    <RectangleRowsContainerRow style={{display:"flex", alignItems:"center"}}>
                        <RectangleRowContainerFirst>
                            <RowSpaceBetween style={{width: '70%'}}>
                                { props.missions ?
                                    <>
                                        <RectangleElementFirst>
                                            <IconSvg icon={typeMessage.includes('expedition') ? '/icons/icon_type_expedition_completion.svg' : '/icons/icon_mining.svg'} color={'#ffffff'} width={'15px'}/>
                                            <RectangleRowText> {typeMessage.includes('expedition') ? 'Expedition Completed' : 'Mining Completed'} </RectangleRowText>
                                        </RectangleElementFirst>
                                    </>
                                    :
                                    <>
                                        <RectangleElementFirst>
                                            <RectangleRowText> Outpost: </RectangleRowText>
                                            <RectangleRowText> {props.outpost} </RectangleRowText>
                                        </RectangleElementFirst>
                                        <RectangleElementFirst>
                                            <RectangleRowText> Mission: </RectangleRowText>
                                            <RectangleRowText> {props.value2} </RectangleRowText>
                                        </RectangleElementFirst>
                                        <RectangleElementFirst>
                                            <RectangleRowText> Route ID: </RectangleRowText>
                                            <RectangleRowText> {props.mission_id} </RectangleRowText>
                                        </RectangleElementFirst>
                                    </>
                                }
                            </RowSpaceBetween>
                            <RectangleRowFirstContainer2>
                                <RectangleRowFirstContainerIconAndText>
                                    <Icon style={{marginRight:"3px"}}src="/icons/icon_type_clock_white.svg"></Icon>
                                    <RectangleRowText> {props.min_spent} </RectangleRowText>
                                </RectangleRowFirstContainerIconAndText>
                            </RectangleRowFirstContainer2>

                        </RectangleRowContainerFirst>
                    </RectangleRowsContainerRow>
                    <RectangleRowsContainerRow>
                    <RectangleRowSecondContainerStats><TextStats> {props.missions ? 'Enemy Fleet' : 'Stats'} </TextStats></RectangleRowSecondContainerStats>                  
                    { props.missions ?
                        <RectangleRowContainerSecondMissions>
                            { props.enemy_ships.map((ship) => (
                                <RectangleRowText style={{textAlign: 'left'}}> {ship.quantity}x {ship.name} </RectangleRowText>
                            ))
                            }
                        </RectangleRowContainerSecondMissions>
                        :
                        <RectangleRowContainerSecond>
                            <RectangleElementFirst>
                                <RectangleRowText> Difficulty: </RectangleRowText>
                                <RectangleRowText> {props.value1Second} </RectangleRowText>
                            </RectangleElementFirst>
                            <RectangleElementFirst>
                                <RectangleRowText> Reward: </RectangleRowText>
                                <RectangleRowText> {props.value2Second} </RectangleRowText>
                            </RectangleElementFirst>
                            <RectangleElementFirst>
                                <RectangleRowText> Distance: </RectangleRowText>
                                <RectangleRowText> {props.value3Second} </RectangleRowText>
                            </RectangleElementFirst>
                            <RectangleElementFirst>
                                <RectangleRowText> Risk: </RectangleRowText>
                                <RectangleRowText> {props.value4Second} </RectangleRowText>
                            </RectangleElementFirst>
                        </RectangleRowContainerSecond>
                    }
                    </RectangleRowsContainerRow>
                    <RectangleRowsContainerRow style={{border:"0px"}}>
                    { props.missions ? 
                        <>
                        <RectangleRowSecondContainerStats><TextStats> Potential Rewards</TextStats></RectangleRowSecondContainerStats>
                        {
                            typeMessage.includes('expedition') ?
                            <RectangleRowContainerSecond>
                                <GridSpecs style={{height:"18px"}}specs={[{"name": "Aurum", "quantity": "(Cargo based)"}]} rewards={true} columns={3} spaceBetweenColumns={'15px'} width={'300%'}/>
                            </RectangleRowContainerSecond>
                            :
                            <RectangleRowContainerSecond>
                                <GridSpecs style={{height:"10px"}} specs={props.specs} rewards={true} columns={6} spaceBetweenColumns={'15px'} width={'200%'}/>
                            </RectangleRowContainerSecond>
                        }
                        </>
                        :
                        null
                    }
                    </RectangleRowsContainerRow>
                </RectangleRowsContainer>
                
            </RectangleContainerComplete>
            
        </RectangleContainerGeneral2>
    </RectangleContainerGeneral>

    <PopUp show={showPopUpSendFleet} onClose={() => setShowPopUpSendFleet(false)}  onlyConfirm={false} onConfirm={props.action}>
      <TextPoppins style={{letterSpacing: '0'}}>
        Are you sure to send the fleet?
      </TextPoppins>
    </PopUp>


    </>
    ) 
};
export default ReusableRectangleInfo;