import styled from "styled-components";
import {
    TextPoppins
} from '../../../../containers/CoreElement'
import { Link } from "react-router-dom";

export const ContainerSection1 = styled.div`
    width: 866px;
    height: 236px;
    display: flex;
    gap: 20px;
`

export const ContainerImg = styled.div`
    width: 218px;
    height: 236px;
`

export const ImgToUpLevel = styled.img`
    width: 100%;
`

export const ContainerDescription = styled.div`
    width: 628px;
    height: 196px;
    flex-direction: column;
    display: flex;
    gap: 12px;

`
export const TitleToUpLevel = styled.span`
    width: 100%;
    text-align: center;
    color: white;
    font-family: 'Titillium Web';
    font-size: 48px;
    font-weight: bold;
`

export const DescriptionToUpLevel = styled.span`
    width: 100%;
    color: white;
    font-family: 'Titillium Web';
    font-size: 16px;
`

export const ContainerGeneralToUPLevels = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgba(231, 19, 131, 0.3);
    box-shadow: none;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-bottom:0px;
    margin-top: 15px;
    min-height: 100px;
    border: 1px solid rgba(231, 19, 131, 0.3);
    padding: 32px;
`

export const ContainerSection2 = styled.div`
    width: 866px;
    height: 64px;
    background-color: #2f041ab9;
    border: 1px solid #E71383;
    padding: 16px;
    display: flex;
    gap: 15px;
    
`

export const IconSection2 = styled.img`
`

export const InfomationBar = styled.div`
    align-items: center;
    display: flex;
    gap: 20px;
    margin-left: 20px;
`

export const ItemBar = styled.div`
    display: flex;
    gap: 5px;   
    justify-content: center;
    align-items: center;
`

export const TitleItemBar = styled.span`
    color: white;
    font-family: 'Poppins';
    font-size: 14px;
`

export const ContentItemBar = styled.span`
    color: white;
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: bold;
`

export const ContainerSection3 = styled.div`
    width: 866px;
    display: flex;
    gap: 14px;
    flex-direction: column;
`

export const ContainerUpgrade = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
`

export const ContainerImgUpgrade = styled.div`
    width: 48px;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #2F041A;
`

export const ImgUpgrade = styled.img`
    width: 25px;
    height: 25px;
`

export const ContainerButtonUpgrade = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const TittleButtonUpgrade = styled.div`
    color: white;
    font-family: 'Poppins';
    font-size: 16px;   
    font-weight: bold;
`

export const ContentButtonUpgrade = styled.div`
    display: flex;
    gap: 5px;
    padding-inline: 7px;
`

export const ImgContentButtonUpgrade = styled.img`
    width: 18px;
    height: 18px;
`

export const DescriptionButtonUpgrade = styled.span`
    color: white;
    font-family: 'Poppins';
    font-size: 12px;   
    font-weight: bold; 
`

export const ContainerGeneral = styled.div `
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15px;

    @media (max-width: 600px) {
        margin-bottom: 50%;
        grid-template-columns: repeat(1, 1fr);
    }
`

export const ElementContainer = styled(Link) `
    position: relative;
    display: inline-block;
    margin-top: 15px;
`

export const Element = styled.div `
    display: flex;
    border: 1px solid #E71383;
    justify-content: center; 
    align-items: center;
`

export const ContainerDarkerLayer = styled.div `
    position: relative;

    ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* Change the last value (0.5) to adjust the darkness */
        z-index: 1; /* Ensure the overlay is above the image */
    }
`

export const ContainerImage = styled.img `
    width: 100%;
    height:130px;
    display: flex;

`

export const Text = styled(TextPoppins) `
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0.5px;
`

export const Icon = styled.img `
    
`

export const ContainerContent = styled.div `
    position: absolute;
    display: flex; 
    gap: 15px;
    z-index: 50;
`

export const ContainerLevel = styled.div `
    position: absolute;
    display: flex; 
    z-index: 50;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    width: 15%;
    position: absolute;
    bottom: 0%;
    right: 0px;

    align-items:center;
    justify-content: center;
    gap: 3px;

    border: 1px solid #E71383;
    border-bottom: 0px;
    border-right: 0px;
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
`

export const TextLevel = styled(TextPoppins) `
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 27px;
`

export const LockContainer = styled.div `
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 51;
    background-color: #0000008a;
    width: 100%;
    height: 100%;
`

export const Lock = styled.img `
    width: 50px;
    height: 50px;
`
