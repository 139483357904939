import { useEffect, useState } from "react";

import "../../styles/core.css";

import {
  TdData,
  TrData,
  ThData,
  TdDataFoot,
  TextV1,
  FleetData,
  IconFleet,
  TableBodyMain,
  TableHeaderMain,
  TableFootMain,
  ContainerTable,
  TableBase,
  TrDataDesktop,
  TrDataCellphone,
  TdDataSpecs,
} from "./TableMyCommanderElement";
import GridSpecs from "../../hooks/GridSpecs";

export default function TableMyCommander(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log(props.data);
    setData(props.data);
  }, [props.data]);

  let amount = 0;
  let fp = 0;
  let cargo = 0;
  let shield = 0;
  let armor = 0;
  let damage = 0;
  return (
    <>
      <ContainerTable>
        <TableBase className="table-main">
          <TableHeaderMain>
            {
              <>
                <TrDataDesktop>
                  {props.cols.map((column) => (
                    <ThData style={{ width: column.width }}>
                      {<div>{column.Header}</div>}
                    </ThData>
                  ))}
                </TrDataDesktop>
                <TrDataCellphone>
                  {props.cols.map((column, index) => {
                    if (index !== 2) {
                      return (
                        <ThData style={{ width: column.width }}>
                          {<div>{column.Header}</div>}
                        </ThData>
                      );
                    }
                    return null;
                  })}
                </TrDataCellphone>
              </>
            }
          </TableHeaderMain>
          <TableBodyMain>
            {Object.entries(data).map(([, objects]) => {
              if (objects.length > 0) {
                amount += objects.length;
                fp += objects[0].commander.fp;
                cargo += objects[0].commander.bonus.load;
                shield += objects[0].commander.bonus.shield;
                armor += objects[0].commander.bonus.armor;
                damage += objects[0].commander.bonus.damage;
                return (
                  <>
                    <TrData id={objects[0].id + "_row"}>
                      {/* <TdData>
                      <IconActionX onClick={() => removeElement(row)} src={action_x}/>
                    </TdData> */}
                      <TdData>
                        <FleetData>
                          <IconFleet src={objects[0].commander.image} />
                          <TextV1>{objects[0].commander.name}</TextV1>
                        </FleetData>
                      </TdData>
                      <TdData>{objects.length}</TdData>
                      <TdDataSpecs>
                        <GridSpecs
                          specs={objects[0].specs}
                          columns={4}
                          spaceBetweenColumns={"4px"}
                        />
                      </TdDataSpecs>
                      <TdData>
                        +
                        {objects[0].commander.fp > 0
                          ? objects[0].commander.fp * objects.length
                          : objects[0].commander.fp}
                      </TdData>
                      <TdData>
                        +
                        {objects[0].commander.bonus.load > 0
                          ? objects[0].commander.bonus.load * objects.length
                          : objects[0].commander.bonus.load}
                        %
                      </TdData>
                      <TdData>
                        +
                        {objects[0].commander.bonus.shield > 0
                          ? objects[0].commander.bonus.shield * objects.length
                          : objects[0].commander.bonus.shield}
                        %
                      </TdData>
                      <TdData>
                        +
                        {objects[0].commander.bonus.armor > 0
                          ? objects[0].commander.bonus.armor * objects.length
                          : objects[0].commander.bonus.armor}
                        %
                      </TdData>
                      <TdData>
                        +
                        {objects[0].commander.bonus.damage > 0
                          ? objects[0].commander.bonus.damage * objects.length
                          : objects[0].commander.bonus.damage}
                        %
                      </TdData>
                    </TrData>
                  </>
                );
              } else {
                return null;
              }
            })}
            {/* {props.text ? (
              <ContainerTitle>
                <TextTitle>Commander</TextTitle>
              </ContainerTitle>
            ) : null} */}
          </TableBodyMain>
        </TableBase>
      </ContainerTable>
      <TableFootMain style={{gridTemplateColumns: props.gridTemplateColumns}}>
        <TdDataFoot style={{width:"0px"}} colSpan={1} className="text-right">
          <strong>Totals</strong>
        </TdDataFoot>
        <TdDataFoot colSpan={1} className="text-right">
          <strong> {amount} </strong>
        </TdDataFoot>
        <TdDataFoot className="text-right">
          <strong> +{fp} </strong>
        </TdDataFoot>
        <TdDataFoot className="text-right">
          <strong> +{cargo}% </strong>
        </TdDataFoot>
        <TdDataFoot className="text-right">
          <strong> +{shield}% </strong>
        </TdDataFoot>
        <TdDataFoot className="text-right">
          <strong> +{armor}% </strong>
        </TdDataFoot>
        <TdDataFoot className="text-right">
          <strong> +{damage}% </strong>
        </TdDataFoot>
      </TableFootMain>
    </>
  );
}
