import React, { useState, useEffect } from "react";

import icon_back from '../assets/images/icon_back.svg'

import help_icon_pink from '../assets/images/info_icon_pink.svg'
import { ContainerVoid, ImgIcon, RowCore, TextTitiliumWeb } from "../containers/CoreElement";
import { RowSpaceBetweenCenter } from "../components/Home/Pages/Commander/CommanderElement";
import { BtnNumPage, ImgIconRotate, TextBtnNumPage } from "./PaginationElement";


export default function Pagination({elements = [], children, elementsPerPage=1, textNoData='No data'}){

    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(elements.length / elementsPerPage);
    const startIndex = (currentPage - 1) * elementsPerPage;
    const endIndex = startIndex + elementsPerPage;
    const elementsToRender = elements.slice(startIndex, endIndex);

    const goToPreviousPage = () => {
        if(currentPage !== 1){
            setCurrentPage(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if(currentPage !== totalPages){
            setCurrentPage(currentPage + 1);
        }
    };
    
    useEffect(() => {
        if (totalPages === 0){
            setCurrentPage(1);
        }
        else if(currentPage > totalPages){
            setCurrentPage(totalPages);
        }
    }, [currentPage, totalPages]);

    return(
        <>
        { elementsToRender.length !== 0 ?
            <>
             {children(elementsToRender)}
            </>
            :
            <>
            <ContainerVoid style={{backgroundColor: 'rgba(141, 12, 80, 0.7)', padding: '10px', width: '100%'}}>
                <RowCore style={{marginLeft: '2%', borderLeft: '5px solid #E71383', padding: '5px', alignItems: 'center'}}>
                    <ImgIcon src={help_icon_pink} style={{width: '15px', height: '15px', marginLeft: '1%'}}/>
                    <TextTitiliumWeb style={{fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left'}}>
                        {textNoData}
                    </TextTitiliumWeb>
                </RowCore>
            </ContainerVoid>
            </>
        }
        { elementsToRender.length !== 0 ?
            <ContainerVoid style={{marginTop: '2%'}}>
            <RowSpaceBetweenCenter>
                <RowCore style={{justifyContent: 'flex-end', alignItems: 'center', width: '35%', backgroundColor: 'transparent', marginRight: '12%'}}>
                    { currentPage !== 1 ?
                        <>
                            <ContainerVoid style={{cursor: 'pointer', marginRight: '10%'}} onClick={()=>setCurrentPage(1)}>
                                <ImgIcon src={icon_back} style={{width: '20px', height: 'auto'}}/>
                                <ImgIcon src={icon_back} style={{width: '20px', height: 'auto'}}/>
                            </ContainerVoid>
                            <ContainerVoid style={{cursor: 'pointer'}} onClick={goToPreviousPage}>
                                <ImgIcon src={icon_back} style={{width: '20px', height: 'auto'}}/>
                            </ContainerVoid>
                        </>
                        :
                        null
                    }
                </RowCore>
                <RowCore style={{width: '30%', justifyContent: 'center', backgroundColor: 'transparent'}}>
                    {totalPages <= 3 ? (
                        Array.from({ length: totalPages }).map((_, index) => {
                            const pageNumber = index + 1;
                            return (
                            <>
                                <BtnNumPage
                                style={{ 
                                    marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                    backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                    clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                    WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',

                                }}
                                onClick={() => setCurrentPage(pageNumber)}
                                >
                                <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                </BtnNumPage>
                            </>
                            );
                        })
                        ) : (
                            null
                        )
                    }
                    {totalPages === 4 ? (
                        Array.from({ length: totalPages }).map((_, index) => {
                            const pageNumber = index + 1;
            
                            return(
                                <>
                                { pageNumber === 4 && currentPage <= 2 ?
                                    <div style={{marginRight: '5%'}}>
                                        <TextBtnNumPage style={{fontSize: '20px'}}>...</TextBtnNumPage>
                                    </div>
                                    :
                                    null
                                }
                                <BtnNumPage
                                style={{ 
                                    marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                    backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                    clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                    WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',

                                }}
                                onClick={() => setCurrentPage(pageNumber)}
                                >
                                <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                </BtnNumPage>
                                { pageNumber === 1 && currentPage <= 4 && currentPage > 2 ?
                                    <div style={{marginRight: '5%'}}>
                                        <TextBtnNumPage style={{fontSize: '20px'}}>...</TextBtnNumPage>
                                    </div>
                                    :
                                    null
                                }
                                </>
                
                            );
                            
                        })
                        ) : (
                            null
                        )
                    }
                    {totalPages >= 5 ? (
                        Array.from({ length: totalPages }).map((_, index) => {
                            const pageNumber = index + 1;
                            if(currentPage === 1 || currentPage === 2){
                                return(
                                    <>
                                    { pageNumber <= 3 ? (
                                    <BtnNumPage
                                    style={{ 
                                        marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                        backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                        clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                        WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',

                                    }}
                                    onClick={() => setCurrentPage(pageNumber)}
                                    >
                                    <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                    </BtnNumPage>
                                    ) : (pageNumber === totalPages && (
                                    <>
                                    <div style={{marginRight: '5%'}}>
                                        <TextBtnNumPage style={{fontSize: '20px'}}>...</TextBtnNumPage>
                                    </div>
                                    <BtnNumPage
                                    style={{ 
                                        marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                        backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                        clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                        WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',

                                    }}
                                    onClick={() => setCurrentPage(pageNumber)}
                                    >
                                    <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                    </BtnNumPage>
                                    </>
                                    ))
                                }
                                    </>
                                );
                            }else if(currentPage === totalPages || currentPage === totalPages - 1){
                                return(
                                    <>
                                    { pageNumber > totalPages - 3 && pageNumber <= totalPages ? (
                                        <BtnNumPage
                                        style={{ 
                                            marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                            backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                            clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                            WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
        
                                        }}
                                        onClick={() => setCurrentPage(pageNumber)}
                                        >
                                        <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                        </BtnNumPage>
                                        ) : (pageNumber === 1 && (
                                        <>
                                        <BtnNumPage
                                            style={{ 
                                                marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                                backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                                clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                                WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',

                                            }}
                                            onClick={() => setCurrentPage(pageNumber)}
                                            >
                                            <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                        </BtnNumPage>
                                        <div style={{marginRight: '5%'}}>
                                            <TextBtnNumPage style={{fontSize: '20px'}}>...</TextBtnNumPage>
                                        </div>
                                        </>
                                        ))
                                    }
                                    </>
                                );
                            }else{
                                return(
                                    <>
                                    { pageNumber === 1 ? (
                                        <>
                                        <BtnNumPage
                                            style={{ 
                                                marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                                backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                                clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                                WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
            
                                            }}
                                            onClick={() => setCurrentPage(pageNumber)}
                                            >
                                            <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                        </BtnNumPage>
                                        <div style={{marginRight: '5%'}}>
                                            <TextBtnNumPage style={{fontSize: '20px'}}>...</TextBtnNumPage>
                                        </div>
                                        </>
                                        ) : ( pageNumber === totalPages ? (
                                            <>
                                            <div style={{marginRight: '5%'}}>
                                                <TextBtnNumPage style={{fontSize: '20px'}}>...</TextBtnNumPage>
                                            </div>
                                            <BtnNumPage
                                                style={{ 
                                                    marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                                    backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                                    clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                                    WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                
                                                }}
                                                onClick={() => setCurrentPage(pageNumber)}
                                                >
                                                <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                            </BtnNumPage>
                                            </>
                                        ) : ( pageNumber === currentPage ? (
                                            <>
                                            <BtnNumPage
                                                style={{ 
                                                    marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                                    backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                                    clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                                    WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                
                                                }}
                                                onClick={() => setCurrentPage(pageNumber)}
                                                >
                                                <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                            </BtnNumPage>
                                            </>
                                        ) : ( pageNumber === currentPage - 1 ? (
                                            <>
                                            <BtnNumPage
                                                style={{ 
                                                    marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                                    backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                                    clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                                    WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                
                                                }}
                                                onClick={() => setCurrentPage(pageNumber)}
                                                >
                                                <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                            </BtnNumPage>
                                            </>
                                        ) : (pageNumber === currentPage + 1 ? (
                                            <>
                                            <BtnNumPage
                                                style={{ 
                                                    marginRight: pageNumber === totalPages ? '0%' : '5%', 
                                                    backgroundColor: pageNumber === currentPage ? '#E71383' : 'rgba(231, 19, 131, 0.5)',
                                                    clipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                                                    WebkitClipPath: pageNumber === currentPage ? 'none' : 'polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)',
                
                                                }}
                                                onClick={() => setCurrentPage(pageNumber)}
                                                >
                                                <TextBtnNumPage>{pageNumber}</TextBtnNumPage>
                                            </BtnNumPage>
                                            </>
                                        ) : null
                                        )
                                        )
                                        )
                                        )

                                    }
                                    </>
                                );
                            }                          
                        })
                        ) : (
                            null
                        )
                    }
                </RowCore>
                <RowCore style={{justifyContent: 'flex-start', alignItems: 'center', width: '35%', backgroundColor: 'transparent', marginLeft: '12%'}}>
                { currentPage !== totalPages ?
                    <>
                        <ContainerVoid style={{cursor: 'pointer'}} onClick={goToNextPage}>
                            <ImgIconRotate src={icon_back} style={{width: '20px', height: 'auto'}}/>
                        </ContainerVoid>
                        <ContainerVoid style={{cursor: 'pointer', marginLeft: '10%'}} onClick={()=>setCurrentPage(totalPages)}>
                            <ImgIconRotate src={icon_back} style={{width: '20px', height: 'auto'}}/>
                            <ImgIconRotate src={icon_back} style={{width: '20px', height: 'auto'}}/>
                        </ContainerVoid>                
                    </>
                    :
                    null
                }
                </RowCore>
            </RowSpaceBetweenCenter>
        </ContainerVoid>
        :
        null
        }

        </>
    );
}