import React from "react";
import { GridViewSpecs } from "../containers/CoreElement";
import { RowCore } from "../containers/CoreElement";
import { ImgIcon } from "../containers/CoreElement";
import { TextTitiliumWeb } from "../containers/CoreElement";
import { numberToThousandSeparatorLeaderboardRanking } from "../components/utils/utils";
import { 
    BuyButton,
    TextButton,
} 
from "./PopUpDetailsElement";

function RowMaterial({justifyContent, name, img, quantity}) {
    return(
        <>
            <RowCore style={{alignItems: 'center', justifyContent: justifyContent}}>
                <ImgIcon src={img} style={{width: '20px', height: '20px'}}/>
                <TextTitiliumWeb style={{lineHeight: '0px', fontWeight: '400'}}>
                    {name}: 
                </TextTitiliumWeb>
                <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%', color: 0 > quantity ? '#FF0000' : 'white', fontWeight: '1000'}}>
                    {numberToThousandSeparatorLeaderboardRanking(quantity)}
                </TextTitiliumWeb>
            </RowCore>
        </>
    )
}

export default function GridMaterial({materials, quantity, justifyContent, width, columns, spaceBetweenColumns,spaceBetweenRows, marginTop, withHas = true, discount=1, propsInItem=null}){


    const materialToIcon = (reward) => {
        let specIcon = '';

        console.log(reward);

        switch (reward.toLowerCase()){
            case 'peroxium':
                specIcon = "/icons/icon_peroxium.svg";
                break;

            case 'trantium':
                specIcon = "/icons/icon_trantium.svg";
                break;
                
            case 'obscurian':
                specIcon = "/icons/icon_obscurian.png";
                break;
                
            case 'mercoxium':
                specIcon = "/icons/icon_type_mercoxium.png";
                break;
                
            case 'bits':
                specIcon = "/icons/icon_type_blue_coin_inventory.svg";
                break;

            case 'crelyte':
                specIcon = "/icons/icon_type_crelyte.png";
                break;

            case 'aurum':
                specIcon = "/icons/icon_mineral.svg";
                break;

            default:
                specIcon = "";
        }

        return specIcon;
    };

    return(
        <>
        <GridViewSpecs style={{width: width, gridTemplateColumns: `repeat(${columns}, 1fr)`, gridRowGap: `${spaceBetweenRows}`, columnGap: `${spaceBetweenColumns}`,marginTop: marginTop}}>
            { propsInItem ?
            <>
                <RowMaterial justifyContent={justifyContent} name={"Bits"} img={materialToIcon("Bits")} quantity={propsInItem.bits*0.50}/>
                <RowMaterial justifyContent={justifyContent} name={"Peroxium"} img={materialToIcon("Peroxium")} quantity={propsInItem.peroxium*0.50}/>
                <RowMaterial justifyContent={justifyContent} name={"Trantium"} img={materialToIcon("Trantium")} quantity={propsInItem.trantium*0.50}/>
                <RowMaterial justifyContent={justifyContent} name={"Obscurian"} img={materialToIcon("Obscurian")} quantity={propsInItem.obscurian*0.50}/>
                <RowMaterial justifyContent={justifyContent} name={"Mercoxium"} img={materialToIcon("Mercoxium")} quantity={propsInItem.mercoxium*0.50}/>
                <RowMaterial justifyContent={justifyContent} name={"Crelyte"} img={materialToIcon("Crelyte")} quantity={propsInItem.crelyte*0.50}/>
            </>
            :
            <>
                { materials.map((material, index) => (
                    <>
                    {material.need * quantity > 0 ?
                        <RowCore style={{alignItems: 'center', justifyContent: justifyContent}}>
                            <ImgIcon key={index} src={materialToIcon(material.name)} style={{width: '20px', height: '20px'}}/>
                            <TextTitiliumWeb style={{lineHeight: '0px', fontWeight: '400'}}>
                                {material.name}: 
                            </TextTitiliumWeb>
                            { withHas ? 
                                <>
                                {material.name === "Bits" && discount !== 1?
                                <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%', color: ((material.need * quantity) * (discount)) > material.has ? '#FF0000' : 'white', fontWeight: '1000'}}>
                                    {numberToThousandSeparatorLeaderboardRanking(material.has)} /
                                </TextTitiliumWeb>
                                :
                                <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%', color: ((material.need * quantity)) > material.has ? '#FF0000' : 'white', fontWeight: '1000'}}>
                                    {numberToThousandSeparatorLeaderboardRanking(material.has)} /
                                </TextTitiliumWeb>
                                }
                                </>
                                :
                                null
                            }
                            {material.name === "Bits" && discount !== 1?
                                <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%'}}>
                                    {numberToThousandSeparatorLeaderboardRanking(((material.need * quantity) * discount))} <TextTitiliumWeb style={{lineHeight: '0px', color: '#FF0000'}}> (-{numberToThousandSeparatorLeaderboardRanking((material.need * quantity) * 0.30)})</TextTitiliumWeb>
                                </TextTitiliumWeb>
                                :
                                <>
                                <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%'}}>
                                    {numberToThousandSeparatorLeaderboardRanking(((material.need * quantity)))}
                                </TextTitiliumWeb>
                                {  ((material.need * quantity)) > material.has && material.name === "Peroxium"? 
                                    <BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/10', '_blank')}><TextButton>Buy +</TextButton></BuyButton>
                                : 
                                    null
                                }
                                {  ((material.need * quantity)) > material.has && material.name === "Trantium"? 
                                    <BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/11', '_blank')}><TextButton>Buy +</TextButton></BuyButton> 
                                : 
                                    null
                                }
                                {  ((material.need * quantity)) > material.has && material.name === "Obscurian"? 
                                    <BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/9', '_blank')}><TextButton>Buy +</TextButton></BuyButton> 
                                : 
                                    null
                                }
                                {  ((material.need * quantity)) > material.has && material.name === "Mercoxium"? 
                                    <BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/8', '_blank')}><TextButton>Buy +</TextButton></BuyButton> 
                                : 
                                    null
                                }
                                {  ((material.need * quantity)) > material.has && material.name === "Crelyte"? 
                                    <BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/7', '_blank')}><TextButton>Buy +</TextButton></BuyButton>   
                                : 
                                    null
                                }
                                </>
                            }
                        </RowCore>
                        :
                        <RowCore style={{alignItems: 'center', justifyContent: justifyContent}}>
                            <ImgIcon key={index} src={materialToIcon(material.name)} style={{width: '20px', height: '20px'}}/>
                            <TextTitiliumWeb style={{lineHeight: '0px', fontWeight: '400'}}>
                                {material.name}: 
                            </TextTitiliumWeb>
                            <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%', color: 0 > material.has ? '#FF0000' : 'white', fontWeight: '1000'}}>
                                {numberToThousandSeparatorLeaderboardRanking(material.has)} /
                            </TextTitiliumWeb>
                            <TextTitiliumWeb style={{lineHeight: '0px', marginLeft: '2%'}}>
                                {0}
                            </TextTitiliumWeb>
                        </RowCore>
                    }
                    </>
                ))}
            </>
            }

        </GridViewSpecs>
        </>
    );
}