import '../containers/hooks/styles.css'

import {
    CardContainerGeneral,
    CardContainerTitle,
    CardContainerImage,
    CardInfo,
    TextTitle,
    Text,
    TextBold,
    CardButton,
    ContainerImgProfile,
    TextPoppinsInfo,
    TextPoppinsInfoBold,
    TextTextTitiliumWebInfo,
    DamagedCard,
    ImgRepair
} from './ReusableCardElement';

import icon_repair from '../assets/images/icon_repair.svg'

import GridSpecs from './GridSpecs';
import { ContainerPolygon, PolygonLeft, PolygonRight } from '../containers/CoreElement';

//Hangar

//Props - name, image, shipLVL, fleetPoints, specs, class, action.

//Other props to share with CardHangarSelected - description, repair, instantRepair
const ReusableCardRegular = (props) => {

    return (
        <>
            <CardContainerGeneral>
                <CardContainerTitle>
                    <TextTitle>{props.item.name}</TextTitle>
                </CardContainerTitle>

                <ContainerImgProfile>
                    { props.item.is_destroyed ?
                        <DamagedCard>
                            <ImgRepair src={icon_repair}/>
                        </DamagedCard>
                        :
                        null
                    }
                    <CardContainerImage src={props.item.image}/>
                    <ContainerPolygon>
                        <PolygonLeft>
                            <TextPoppinsInfo>FP:<TextPoppinsInfoBold>{props.item.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                        </PolygonLeft>
                    </ContainerPolygon>
                    <ContainerPolygon style={{right: '0'}}>
                        <PolygonRight>
                            <TextTextTitiliumWebInfo>LVL {1}</TextTextTitiliumWebInfo>
                        </PolygonRight>
                    </ContainerPolygon>
                    {/* <ContainerPolygonRight>
                        <TextTextTitiliumWebInfo>LVL {1}</TextTextTitiliumWebInfo>
                    </ContainerPolygonRight> */}
                </ContainerImgProfile>
                <CardInfo>
                    <div style={{marginTop: '2%'}}>
                        <GridSpecs specs={props.item.specs} spaceBetweenColumns={'10px'} columns={4} />
                    </div>
                    <Text>Class: <TextBold>{props.item.class}</TextBold></Text>
                </CardInfo>
                <CardButton onClick={props.action}>
                    <Text>{props.buttonText}</Text>
                </CardButton>
            </CardContainerGeneral>
        </>
    )
};

export default ReusableCardRegular;


