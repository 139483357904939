import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContainerSign, SignInForm, BtnSignIn, TextButtons, FormResetPasswordLink } from './SignflowElement'
import { FormControl, FormLabel, SignUpImage, InputEmail, FormTitle, FormErrorHeader, ErrorItems, FormSignInLink, FieldErrorStatus, FieldErrorHeaderStatus } from '../Register/RegisterElement';
import { MdEmail } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';
import { GoAlert } from 'react-icons/go';
import { emailValidation } from '../utils/utils';

import sign_banner from '../../assets/images/new-sign-banner.png'

import '../../styles/Signflow.css'
import "@fontsource/titillium-web";

import { TextPoppins } from '../../containers/CoreElement'
import { HTTP } from '../http/http-common';
import { Loader } from '../../hooks/Loader';

export function ResetPassword({reset}) {

  const [email, setEmail] = useState();
  const [error, setError] = useState({});
  const [errorHeader, setErrorHeader] = useState('');
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);

  let navigate = useNavigate();

  const handleSubmit = async e => {
    setLoader(true);
    e.preventDefault();

    let errors = {};
    let msgErrorHeader = '';
    if (!email) {
      errors.emailEnter = 'Please enter your email';
    } else if (email && !emailValidation(email)) {
      errors.emailEnter = 'Please enter a valid email';
    } else {
      delete errors.emailEnter;
    }

    if (email && emailValidation(email) && Object.keys(error).length === 0) {
      try {
        var config = {
            data: { "email": email},
            method: "POST"
        };
      
        HTTP.request('password/recovery/', config)
            .then((res) => {
                if (res.data.result) {
                  setSuccess(true);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoader(false);
            });
      }
      catch (error) {
        console.log(error);
      }
    } else {
      msgErrorHeader = 'Please check the errors marked in red and click the Send Instructions button again after fixing them.';
    }
    setErrorHeader(msgErrorHeader);
    setError(errors);
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    delete error.emailEnter;
  }

  return (
    <>
      <Loader loading={loader}/>
      {/* <ResponsiveContainer> */}
        <ContainerSign>
          <SignUpImage>
            <img src={sign_banner} alt="form" width={'100%'} />
          </SignUpImage>
          {!success ? (<SignInForm onSubmit={handleSubmit}>
            <FormTitle>Reset Password</FormTitle>
            <div style={{ marginTop: '15px' }}>
              <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px', marginTop: '15px', marginBottom: '15px' }}>Enter the email associated with your account and we will send you an email with the instructions to reset your password.</TextPoppins>
            </div>
            {errorHeader &&
              <FormErrorHeader>
                <ErrorItems style={{ fontWeight: 'bold', fontSize: 12, gap: 5 }}>
                  <div style={{ width: 4, height: 30, backgroundColor: '#FF0000' }} />
                  <AiFillCloseCircle color='FF0000' size={28} />
                  <TextPoppins style={{ gap: 4, color: 'white' }}>ERROR</TextPoppins>
                  <FieldErrorHeaderStatus>{errorHeader}</FieldErrorHeaderStatus>
                </ErrorItems>
              </FormErrorHeader>}
            <FormLabel>Email</FormLabel>
            <FormControl onInvalid={error.emailEnter}>
              <MdEmail color='E61383' size={24} />
              <InputEmail placeholder={" Insert your email@example.com"} onChange={onChangeEmail}></InputEmail>
            </FormControl>
            {error.emailEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.emailEnter}</FieldErrorStatus></ErrorItems>}
            <BtnSignIn type="submit">
              <TextButtons>Send Instructions</TextButtons>
            </BtnSignIn>
            <FormSignInLink>Have an account? <a href="/sign-in">Sign In</a></FormSignInLink>
          </SignInForm>) : (
            <SignInForm>
              <FormTitle>Check Your Email</FormTitle>
              <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px' }}>
                <img src={"/images/recovery-icon.png"} alt="recovery" width={72} height={79} />
              </div>
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px' }}>
                  We have sent the password reset instructions to the email address linked to your account.
                </TextPoppins>
              </div>
              <FormResetPasswordLink style={{ textAlign: 'left', marginTop: '20px', marginBottom: '15px' }}>If after this process you still can not recover your account, contact us through our <a href="https://discord.gg/space-misfits-560969562753859585" style={{ textDecoration: 'underline' }} target='_blank' rel="noreferrer">Discord Support Tickets.</a></FormResetPasswordLink>
              <BtnSignIn onClick={() => navigate('/sign-in')} style={{ marginTop: '10px' }}>
                <TextButtons>Return to Sign In</TextButtons>
              </BtnSignIn>
            </SignInForm>
          )}
        </ContainerSign>
      {/* </ResponsiveContainer> */}
      {/* <ResponsiveAlert>
        <ContainerAlert>
          <RowCore style={{ borderLeft: '5px solid #E71383', alignItems: 'center' }}>
            <ImgIcon src={help_icon_pink} style={{ width: '15px', height: '15px', marginLeft: '3%' }} />
            <TextPoppins style={{ fontSize: '11px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%' }}>
              Fleet Alpha v1 is not optimized for mobile/tablet devices and it will not be supported until the next version following our roadmap, you can play Fleet using the desktop version. We apologize for any inconvenience caused.
            </TextPoppins>
          </RowCore>
        </ContainerAlert>
      </ResponsiveAlert> */}
    </>
  )
}
