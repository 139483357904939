import React, { useCallback, useEffect, useState } from 'react'
import { ChildrenHeaderCellphone, ChildrenHeaderDesktop, ImgIcon, RowCore, RowSpaceBetween, TextPoppins } from '../../../../../../containers/CoreElement'
import IconSvg from '../../../../../../hooks/IconSvg'
import { BtnOptionExport, ContainerGrid, ContainerGridView, ContainerInfo, ContainerItems, ContainerOptions2, ContainerTable, ContainerTableMyExport, ContainerTextInfo, GridViewCoreItems, NumberCoins, TextCoins, TextCrown, TextTitiliumWebOptions2, TittleBarV1, TittleTextV2 } from '../../ExportImportElement';
import TableImport from '../../../../../../tables/TableExport/TableImport'
import { ColsExportImport } from '../../../../../../hooks/ColsExportImport'
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids'
import Pagination from '../../../../../../hooks/Pagination'
import crown_coin from '../../../../../../assets/images/crown_coin.png'
import help_icon_pink from '../../../../../../assets/images/info_icon_pink.svg'
import '../../../../../../components/Settings/hooks/styles.css'
import GenericCard from '../../../../../../hooks/GenericCard'
import PopUp from '../../../../../../hooks/PopUp';
import { HTTP } from '../../../../../http/http-common';
import { Loader } from '../../../../../../hooks/Loader';
import { useNavigate } from 'react-router-dom';

export default function Import() {

    
    const navigate = useNavigate();

    const [popupSyncWallet, setPopupSyncWallet] = useState(false);

    const [popUpImport, setPopUpImport] = useState(false);
    const [crownCost, setCrownCost] = useState(0);
    const [auxItems, setAuxItems] = useState([]);
    const [items, setItems] = useState([]);
    const [myExport, setMyExport] = useState([]);
    const [choiceItems, setChoiceItems] = useState([]);
    const [popupServerError, setPopupServerError] = useState(false);
    const [popupSuccess, setPopupSuccess] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');
    const [popupAlert, setPopupAlert] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isItemAlert, setIsItemAlert] = useState(false);

    
    const fetchItems = useCallback(() => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('altura/get/items/import/', config)
            .then((res) => {
                if (res.data.result){
                    console.log(res.data);
                    setAuxItems(res.data.response.items);
                }else{
                    if(res.data.response.includes('sync your Altura Guard Wallet')){
                        setPopupSyncWallet(true);
                    }else{
                        setPopupServerError(true);
                        setTextServerError(res.data.response);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
                setPopupServerError(true);
                setTextServerError(err.response.statusText);
            })
            .finally(() => {
                setLoader(false);
            })

    }, []);


    function removeObjectsWithId(array, item, countToRemove){
        const updatedArray = [...array];

        for(let i = 0; i < updatedArray.length; i++){
            let id = updatedArray[i].tokenId;
            let idToRemove = item.tokenId; 
            if(id === idToRemove){
                console.log('entra');
                console.log(updatedArray[i]);
                updatedArray[i].userBalance = updatedArray[i].userBalance - countToRemove;
            }
        }
  
        return updatedArray;
    }

    function addObjects(array, item, countToAdd){
        
        const updatedArray = [...array];

        for(let i = 0; i < updatedArray.length; i++){
            let id = updatedArray[i].tokenId;
            let idToRemove = item.tokenId; 
            console.log(`${id} - ${idToRemove}`);
            if(id === idToRemove){
                updatedArray[i].userBalance = updatedArray[i].userBalance + countToAdd;
            }       
        }
        
        return updatedArray;
    }
    
    const addElement = (item, amount) => {  
        if(amount){
            console.log('var vacia');
            let itemUpdate = {...item}
            itemUpdate.userBalance = amount
            setAuxItems(removeObjectsWithId(auxItems, item, amount));
            setMyExport(prevState => [...prevState, itemUpdate]);
        } else {
            setPopupAlert(true);
        }
    } 
    

    //Quitar de tabla
    const removeElement = (item, index, amount) => {

        if(amount){
            console.log(index);
            setAuxItems(addObjects(auxItems, item, amount));
    
            const targeArray = [...myExport];
    
            targeArray[index].userBalance = targeArray[index].userBalance - amount;
    
            if(targeArray[index].userBalance === 0){
                console.log('ha entrado');
                targeArray.splice(index, 1);
            }
    
            setMyExport(targeArray);

        }else{
            setPopupAlert(true);
        }
    } 
    
    const onClickImport = () => {
        setLoader(true);
        setPopUpImport(false);
        for(let i = 0; i < choiceItems.length; i++){
            if (choiceItems[i].is_osbt) {
                setIsItemAlert(true)
            }  
        }

        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"choice_items": choiceItems},
            method: "POST"
        };
        HTTP.request('altura/import/items/', config)
        .then((res) => {
            let textReport = res.data.response;
            setPopupSuccess(true);
            setTextServerError(textReport);
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        }).finally(()=> {
            setLoader(false);
        })
    }


    useEffect(() => {

        fetchItems();

    }, [fetchItems])
    
    useEffect(() => {

        setItems(auxItems);
        console.log(auxItems);
        let auxChoiceItems = []
        let auxItem = {}

        myExport.forEach((item) => {
            console.log(item);
            auxItem = {'token_id': item.tokenId, 'collection_address': item.collectionAddress, 'quantity': item.userBalance, 'is_osbt': item.is_osbt}
            auxChoiceItems.push(auxItem);
        });

        setChoiceItems(auxChoiceItems);

    }, [auxItems, myExport])
    
  return (
    <>
    <Loader loading={loader}/>
    <ContainerOptions2>
        <RowSpaceBetween>
            <BtnOptionExport onClick={() => setPopUpImport(true)}>
                <TextCoins>
                    <IconSvg icon={'/icons/icon_type_export-import.svg'} width={'20px'} color={'#FFFFFF'}/>
                    <TextTitiliumWebOptions2>IMPORT</TextTitiliumWebOptions2>
                </TextCoins>
                {/* <TextCoins>
                    <ImgIcon src={crown_coin}/>
                    <NumberCoins>{'15'}</NumberCoins>
                </TextCoins> */}
            </BtnOptionExport>
        </RowSpaceBetween>
    </ContainerOptions2>
    <ContainerTable>
        <ContainerTableMyExport>
            <TableImport data={myExport} cols={ColsExportImport()} onClickRemove={removeElement} setCrownCost={setCrownCost} importItems/>
        </ContainerTableMyExport>
    </ContainerTable>
    <ContainerItems>
        <ContainerGridView>
            <TittleBarV1>
                <TittleTextV2>  
                    Add the items you want to import
                </TittleTextV2>
            </TittleBarV1>
            <FilterForGrids allElements={items} sortByType>
                { elementsToFilter => (
                    <ContainerGrid>
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not crew commanders">
                            { elementsToRender => (
                               <GridViewCoreItems>
                                    { elementsToRender.map((item, index) => (
                                        <>
                                        <GenericCard item={item} setItems={setItems} onClickAdd={addElement} importItems/>
                                        </>
                                    ))
                                    }
                               </GridViewCoreItems>
                            )
                            }
                        </Pagination>
                    </ContainerGrid>
                )
                }
            </FilterForGrids>
        </ContainerGridView>
    </ContainerItems>


    <PopUp show={popupAlert} onClose={() => setPopupAlert(false)} onlyConfirm>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            Enter a numeric value
        </TextPoppins>
    </PopUp>

    <PopUp show={popUpImport} onClose={() => setPopUpImport(false)} onConfirm={onClickImport}>
        <TextPoppins style={{letterSpacing: '0', lineHeight: '25px'}}>
        Are you sure that you want to<br/>
        import everything to your account?
        </TextPoppins>
        <TextCoins>
            <ImgIcon src={crown_coin}/>
            <TextCrown>CROWN:</TextCrown>
            <NumberCoins>{crownCost}</NumberCoins>
        </TextCoins>
        <ContainerInfo>
          <RowCore style={{borderLeft: '5px solid #E71383', alignItems: 'center'}}>
            <ImgIcon src={help_icon_pink} style={{width: '15px', height: '15px', marginLeft: '3%'}}/>
            <ContainerTextInfo>
              <TextPoppins style={{fontSize: '10px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%', whiteSpace: 'nowrap'}}>
                <ChildrenHeaderDesktop>
                    Deposited items will be burned (melted) from your linked wallet
                </ChildrenHeaderDesktop>
                <ChildrenHeaderCellphone>
                    Deposited items will <br/>
                    be burned (melted) <br/>
                    from your linked wallet
                </ChildrenHeaderCellphone>
              </TextPoppins>
            </ContainerTextInfo>
          </RowCore>
        </ContainerInfo>
    </PopUp>

    <PopUp onlyConfirm={true} show={popupServerError} onClose={() => setPopupServerError(false)} onConfirm={() => window.location.reload()}>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
        {textServerError}
        </TextPoppins>
    </PopUp>

    <PopUp onlyConfirm={true} show={popupSuccess} onClose={() => window.location.reload()} onConfirm={() => window.location.reload()}>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
        {textServerError}
        </TextPoppins>
        { isItemAlert ?
            <ContainerInfo>
                <RowCore style={{borderLeft: '5px solid #E71383', alignItems: 'center'}}>
                <ImgIcon src={help_icon_pink} style={{width: '15px', height: '15px', marginLeft: '3%'}}/>
                <ContainerTextInfo>
                    <TextPoppins style={{fontSize: '10px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%', whiteSpace: 'nowrap'}}>
                    <ChildrenHeaderDesktop>
                        Your Olympus Syndicate Bounty Ticket has arrived in your Mayhem account
                    </ChildrenHeaderDesktop>
                    <ChildrenHeaderCellphone>
                        Your Olympus Syndicate <br/>
                        Bounty Ticket has arrived <br/>
                        in your Mayhem account
                    </ChildrenHeaderCellphone>
                    </TextPoppins>
                </ContainerTextInfo>
                </RowCore>
            </ContainerInfo>
            :
            null
        }
    </PopUp>

    <PopUp show={popupSyncWallet} onClose={() => setPopupSyncWallet(false)} onConfirm={() => setPopupSyncWallet(false)}>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            Please, link your wallet in your <span style={{color: '#13CBE7', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/settings')}>account settings</span> using <br/>
            Altura Guard code, you can also check the guides <a style={{color: '#13CBE7', textDecoration: 'underline', cursor: 'pointer'}} target='_blank' rel='noreferrer' href='https://space-misfits.gitbook.io/space-misfits-fleet-guides/fleet-guides/wallet-linking'>here</a>.
        </TextPoppins>
    </PopUp>

    </>
  )
}
