import '../containers/hooks/styles.css'

import {
    CardContainerGeneral,
    CardContainerTitle,
    CardContainerImage,
    CardInfo,
    CardInfo2,
    TextTitle,
    Text,
    TextBold,
    Icon,
    ProcessCotainer,
    Process,
    InstantForge,
    Cancel,
    ContainerImgProfile,
    DamagedCard,
    ImgRepair,
    TextPoppinsInfo,
    TextPoppinsInfoBold,
    TextTextTitiliumWebInfo,
    TextCoins,
    NumberCoins
} from './ReusableCardElement';

import { ContainerPolygon, ContainerVoid, ImgIcon, PolygonLeft, PolygonRight, TextTitiliumWeb } from '../containers/CoreElement';

import icon_instant_repair from '../assets/images/icon_instant_repair.svg'

import GridSpecs from './GridSpecs';
import icon_repair from '../assets/images/icon_repair.svg'

import crown_coin from '../assets/images/crown_coin.png'


//Shipyard-Forge Process
const ReusableCardRepairBay = (props) => {

    return (
        <>

<CardContainerGeneral>

        <CardContainerTitle><TextTitle>{props.item.ship.name}</TextTitle></CardContainerTitle>
        
        <ContainerImgProfile>
            { props.item.is_destroyed ?
                <DamagedCard>
                    <ImgRepair src={icon_repair}/>
                </DamagedCard>
                :
                null
            }
            <CardContainerImage src={props.item.ship.image}/>
            <ContainerPolygon>
                <PolygonLeft>
                    <TextPoppinsInfo>FP:<TextPoppinsInfoBold>{props.item.ship.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                </PolygonLeft>
            </ContainerPolygon>
            <ContainerPolygon style={{right: '0'}}>
                <PolygonRight>
                    <TextTextTitiliumWebInfo>LVL {1}</TextTextTitiliumWebInfo>
                </PolygonRight>
            </ContainerPolygon>
        </ContainerImgProfile>

        <CardInfo>
            <div style={{marginTop: '2%'}}><GridSpecs specs={props.item.ship.specs} spaceBetweenColumns={'10px'} columns={4} width={'200px'}/></div> 
            <Text>Class: <TextBold>{props.item.ship.class}</TextBold></Text>
        </CardInfo>
        <CardInfo2>
            <ProcessCotainer><Process style={{width: props.percentage.toString() + '%'}}></Process>
            <ContainerVoid style={{position: 'absolute', bottom: '0px', right: '0px', left:"0px", height: '100%'}}><TextTitiliumWeb style={ {fontSize:"16px", padding:"6px 0px 6px 0px", margin:"0 auto", letterSpacing: '0.02em', fontWeight: '500'}}>{props.percentage}%</TextTitiliumWeb></ContainerVoid>
            </ProcessCotainer>
            <InstantForge onClick={props.actionInstantRepair}>
                <Icon src={icon_instant_repair}></Icon>
                <TextTitiliumWeb style={{fontSize:"16px", fontWeight: '600', letterSpacing: '0.02em'}}>INSTANT REPAIR</TextTitiliumWeb> 
                <TextCoins>
                    <ImgIcon src={crown_coin} />
                    <NumberCoins>{props.item.ship.cost_repair_crowns}</NumberCoins>
                </TextCoins>
            </InstantForge>
            <Cancel onClick={props.actionCancel}>
            <Icon src="/icons/icon_type_cancel.svg"></Icon>
            <TextTitiliumWeb style={{fontSize:"14px", fontWeight: '500', letterSpacing: '0.02em'}}>Cancel</TextTitiliumWeb> 
            </Cancel>
        </CardInfo2>
</CardContainerGeneral>

        </>
    )
};

export default ReusableCardRepairBay;

