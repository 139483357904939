import React from 'react'
import { FilterBar } from '../../../../../../hooks/FilterBar'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { RepairBayDamaged } from './RepairBayDamaged'
import { RepairBay } from './RepairBay'
import { RepairBayPickUp } from './RepairBayPickUp'

export default function Repair({closeHeader, isOpen, closeCardSelect}) {

  const location = useLocation();
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  const elementsRepairBay = [

    {'icon' : '/icons/icon_type_blueprints.svg', 'text' :'DAMAGED', 'action' : () => handleClick('/shipyard/repair-bay/damaged'), 'backgroundItem' : location.pathname === '/shipyard/repair-bay/damaged' ? '#A40A5B' : 'transparent', 'boxShadow':'inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5)', 'color': location.pathname === '/shipyard/repair-bay/damaged' ? '#FFFFFF' : '#d2b8cd'},
    { 'icon' : '/icons/icon_type_in_process.svg', 'text' :'REPAIRING', 'action' : () => handleClick('/shipyard/repair-bay/repairing'), 'backgroundItem' : location.pathname === '/shipyard/repair-bay/repairing' ? '#A40A5B' : 'transparent', 'boxShadow':'inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5)', 'color': location.pathname === '/shipyard/repair-bay/repairing' ? '#FFFFFF' : '#d2b8cd'},
    { 'icon' : '/icons/icon_type_pick_up.svg', 'text' :'PICK UP', 'action' : () => handleClick('/shipyard/repair-bay/pick-up'), 'backgroundItem' : location.pathname === '/shipyard/repair-bay/pick-up' ? '#A40A5B' : 'transparent', 'boxShadow':'inset 0px 8px 5px -5px rgba(0, 0, 0, 0.5)', 'color': location.pathname === '/shipyard/repair-bay/pick-up' ? '#FFFFFF' : '#d2b8cd'},

  ]

  return (
    <>
    {elementsRepairBay.length > 0 ?
        <FilterBar                     
        elements = {elementsRepairBay}
        columns = "repeat(3,1fr)"
        ></FilterBar>
        :
        null
    }
    <Routes>
        <Route path="damaged" element={<RepairBayDamaged closeHeader={closeHeader} isOpen={isOpen} closeCardSelect={closeCardSelect}/>}/>
        <Route path="repairing" element={<RepairBay/>}/>
        <Route path="pick-up" element={<RepairBayPickUp/>}/>
      </Routes>
    </>
  )
}
