import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ResponsiveContainer, ResponsiveAlert, ContainerAlert, BtnSignIn, TextButtons } from '../Signflow/SignflowElement'
import { ContainerRegister, SignUpForm, SignUpImage, FormTitle } from './RegisterElement';
import help_icon_pink from '../../assets/images/info_icon_pink.svg'

import sign_banner from '../../assets/images/new-sign-banner.png'

import '../../styles/Signflow.css'

import { ImgIcon, RowCore, TextPoppins } from '../../containers/CoreElement'
import { HTTP } from '../http/http-common';
import { Loader } from '../../hooks/Loader';

export function VerifyPage() {
  let navigate = useNavigate();
  let { token } = useParams();
  const [loader, setLoader] = useState(false);

  const [ success, setSuccess ] = useState(false);

  useEffect(() => {
    setLoader(true);
    var config = {
      method: "POST"
    };

    HTTP.request('confirm/' + token + '/', config)
        .then((res) => {
            if (res.data.result) {
              setSuccess(true)
            }else{
              setSuccess(false)
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoader(false);
        });

  }, [token])

  return (
    <>
      <Loader loading={loader}/>
      <ResponsiveContainer>
        <ContainerRegister>
          <SignUpImage>
            <img src={sign_banner} alt="form" width={'100%'} />
          </SignUpImage>
          { success ?
          <SignUpForm>
            <FormTitle style={{fontSize: 28, fontWeight: '700'}}>Your account is now activated</FormTitle>
            <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px' }}>
              <img src={"/images/activated-icon.png"} alt="recovery" width={72} height={79} />
            </div>
            <div style={{ marginTop: '15px', marginBottom: '10px' }}>
              <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px' }}>
                {`Thank you, your account has been activated.`}
              </TextPoppins>
            </div>
            <BtnSignIn onClick={() => navigate('/sign-in')} style={{ marginTop: '20px' }}>
              <TextButtons>Return to Sign In</TextButtons>
            </BtnSignIn>
          </SignUpForm>
          :
          <SignUpForm>
            <FormTitle style={{fontSize: 28, fontWeight: '700'}}>Activation failed</FormTitle>
            <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px' }}>
              <img src={"/images/activated-icon.png"} alt="recovery" width={72} height={79} />
            </div>
            <div style={{ marginTop: '15px', marginBottom: '10px' }}>
              <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px' }}>
                {`Failed, your account has not been activated.`}
              </TextPoppins>
            </div>
            <BtnSignIn onClick={() => navigate('/sign-in')} style={{ marginTop: '20px' }}>
              <TextButtons>Return to Sign In</TextButtons>
            </BtnSignIn>
          </SignUpForm>
          }
        </ContainerRegister>
      </ResponsiveContainer>
      <ResponsiveAlert>
        <ContainerAlert>
          <RowCore style={{ borderLeft: '5px solid #E71383', alignItems: 'center' }}>
            <ImgIcon src={help_icon_pink} style={{ width: '15px', height: '15px', marginLeft: '3%' }} />
            <TextPoppins style={{ fontSize: '11px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%' }}>
              Fleet Alpha v1 is not optimized for mobile/tablet devices and it will not be supported until the next version following our roadmap, you can play Fleet using the desktop version. We apologize for any inconvenience caused.
            </TextPoppins>
          </RowCore>
        </ContainerAlert>
      </ResponsiveAlert>
    </>
  )
}