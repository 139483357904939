import React from 'react'
import { ContainerTable, FleetData, IconFleet, TableBase, TableBodyMain, TableFootMain, TableHeaderMain, TdData, TdDataFoot, TextV1, ThData, TrData } from './TableExportElement'
import '../../styles/core.css'
import GridSpecs from '../../hooks/GridSpecs'
import InputSetAmount from '../../hooks/InputSetAmount'
import { mineralToIcon } from '../../components/utils/utils'

export default function TableImport({cols=[], data=[], onClickRemove, setCrownCost, importItems=false}) {


    let amount = 0;
    // let crownCost = 0;

    // useEffect(() => {
    //     setCrownCost(crownCost);
    // }, [crownCost, setCrownCost])


    // const getPriceCommanders = (value) => {
    //     var total_cost = 0
    //     total_cost =+ value * 0.75
    //     return total_cost / 0.01
    // }
    
    // const getPriceMinerals = (value) => {
    //     console.log(value.value)
    //     var total_cost = 0
    //     var cost_per_10 = 0.30
    //     var count = (value)
    //     if (count === 10){
    //         total_cost += cost_per_10
    //     }else if (count < 10){
    //         total_cost += count * (cost_per_10 / 10)
    //     }else{
    //         total_cost += cost_per_10 + ((count-10) * 0.02)
    //     }
    //     return total_cost / 0.01
    // }

    // const getPriceShips = (value) => {
    //     var total_cost = 0;
    //     console.log(value)
    //     if(value === 1){
    //         total_cost += 0.30;
    //     }else{
    //         total_cost += 0.30;
    //         total_cost += (value - 1) * 0.10
    //     }
    //     return total_cost / 0.01
    // }

    const validateExist = (name, properties) => {
        const resultados = properties.filter((jsonObj) => jsonObj.name.includes("code") && jsonObj.value.includes(name));
        if (resultados.length > 0){
            return true
        }else{
            return false
        }
    }

    // eslint-disable-next-line array-callback-return
    data.map((item, index) => { 
        amount += validateExist("CC", item.properties) ? item.userBalance : ( validateExist("SC", item.properties) ? item.userBalance : item.userBalance);
        // crownCost += validateExist("CC", item.properties) ? getPriceCommanders(item.userBalance) : ( validateExist("SC", item.properties) ? getPriceShips(item.userBalance) : getPriceMinerals(item.userBalance));
        // setCrownCost(crownCost);
    });
    

  return (
    <>
        <ContainerTable>
            <TableBase className='table main'>
                <TableHeaderMain >
                    <TrData>
                        {
                        cols.map((column) => (
                            <ThData style={{width: column.width}}>
                            {
                                <div>
                                    {column.Header}
                                </div>
                            }
                            </ThData>
                        ))
                        }
                    </TrData>
                </TableHeaderMain>
                <TableBodyMain>
                    { data.map((item, index) => {
                        return(
                            <TrData id={index + "_row"}>
                            {/* <TdData>
                                <IconActionX onClick={() => removeElement(row)} src={action_x}/>
                            </TdData> */}
                            <TdData>
                                <FleetData>
                                    { !importItems ?
                                        <>
                                        { item.hasOwnProperty('specs') ?
                                            <>
                                            <IconFleet src={item.hasOwnProperty('commander') ? item.commander.image : item.image}/>
                                            <TextV1>
                                                {item.hasOwnProperty('commander') ? item.commander.name : item.name}
                                            </TextV1>
                                            </>
                                            :
                                            <>
                                            <IconFleet src={mineralToIcon(item.name, true)}/>
                                            <TextV1 style={{textTransform: 'capitalize'}}>
                                                {item.name}
                                            </TextV1>
                                            </>
                                        }
                                        </>
                                        :
                                        <>
                                        <IconFleet src={item.image}/>
                                        <TextV1>
                                            {item.name}
                                        </TextV1>
                                        </>
                                    }
                                </FleetData>
                            </TdData>
                            <TdData>
                                {item.userBalance}
                            </TdData>
                            <TdData>
                                { !importItems ?
                                    <>
                                    <GridSpecs specs={item.specs} columns={4} spaceBetweenColumns={'4px'}/>
                                    
                                    </>
                                    :
                                    null
                                }
                            </TdData>
                                <TdData>
                                    {0}
                                </TdData>
                            <TdData>
                                <InputSetAmount item={item} index={index} placeholder={0} onClickRemove={onClickRemove} importItems={importItems}/>
                            </TdData>
                            </TrData>
                        )
                    })
                    }
                </TableBodyMain>
            </TableBase>
        </ContainerTable>
        <TableFootMain>
            <TdDataFoot colSpan={2} >
                <strong>Totals</strong>
            </TdDataFoot>
            <TdDataFoot>
                <strong>{amount}</strong>
            </TdDataFoot>
            <TdDataFoot>
                <strong>{0}</strong>
            </TdDataFoot>

        </TableFootMain>
    </>
  )
}
