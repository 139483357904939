import React from 'react';
import { ContainerVoid, RowCore, TextTitiliumWeb } from '../containers/CoreElement';
import { LifeBarContainer, LifeBarContent } from './HealingBarElement';
 
const HealingBar = (props) => {
 
    return (
        <>
            <RowCore style={{alignItems: 'center', width: props.width, backgroundColor: 'blue'}}>
                <LifeBarContainer style={{width: '100%', position: 'relative'}}>
                    <LifeBarContent style={{width: `${props.percent}%`, background: '#E71383'}}/>
                    <ContainerVoid style={{position: 'absolute', bottom: '0px', right: '0px', height: '100%', left: '50%', transform: 'translateX(-50%)'}}>
                        <TextTitiliumWeb style={{letterSpacing: '0', lineHeight: '30px', fontWeight: '400'}}>{props.percent}%</TextTitiliumWeb>
                    </ContainerVoid>
                </LifeBarContainer>
            </RowCore>
        </>
    )
};

export default HealingBar;