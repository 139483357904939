import styled from "styled-components";

import "@fontsource/poppins";

export const ContainerGeneral = styled.div`
    box-sizing: border-box;
    padding-bottom: 20px;
    width: 100%;
    overflow-y: scroll;
    height: 600px;
`

export const ContainerOutpost = styled.div`

`

export const OutpostSection1 = styled.img`
    height: 130px;
    margin-top: auto;
    border: 1px solid #E71383;
    border-right: 0px solid;
    @media (max-width: 1160px) {
        width: 25%;
        height: auto;
    }

`

export const OutpostSection2 = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 765px;
    justify-content: space-between;
    display: flex;
    color: white;
    
    @media (max-width: 1160px) {
        width: 75%;
        display: block;
        padding: 12px;
    }
`

export const OutpostSectionAlreadyRented = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 765px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-grid;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;

    @media (max-width: 1160px) {
        width: 75%;
        padding: 12px;
    }

`

export const ImgRoutesIcon = styled.img`

    margin-inline: 5px;
`

export const OutpostSection2Data = styled.div`
    width: 100%;
    margin-bottom: 0;
    
    height: 60px;
    @media (max-width: 1160px) {
        margin: 0px;
    }
`

export const OutpostSection2DataContainer1 = styled.div`

    color: white;  
    margin-bottom: 12px;
    width:100%;
    display: flex;
   
`

export const OutpostSection2DataContainer2 = styled.div`
    background-blend-mode: soft-light;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5.5px 10px;
    gap: 6px;
    color: white;  
    height: 30px;
    width: 160px;
`

export const OutpostSection2DataContainer3 = styled.div`
    align-items: center;
    gap: 6px;
    color: white;  
    text-align: left;
    text-align-last: left;
    display:flex;
`

export const OutpostSection2DataContainer3DurationAndCoins = styled.div`
    align-items: center;
    gap: 6px;
    color: white;  
    text-align: left;
    text-align-last: left;
    display:flex;
    padding-left: 72px;
`

export const OutpostSection2DataContainer3Stats = styled.div`

    align-items: center;
    gap: 6px;
    color: white;  
    text-align: left;
    text-align-last: left;
    width: 100%;
    display: grid;
`

export const ContainerSeparatorCotainer3 = styled.div`
    padding: 0px 9px 0px 9px;
`

export const OutpostSection2DataContainerMain = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: center;
`

export const TextValue = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 6px 0px;
`

export const TextValuePurple = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    padding: 6px 0px;
    color: rgba(231, 19, 131, 1);
`

export const TextValuePurpleStats = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    padding: 6px 0px;
    color: rgba(231, 19, 131, 1);
    width: 600px;
`

export const TextValueV2 = styled.h1`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    justify-content: center;
    display: flex;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    justify-content: left;
`

export const TextTitle = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 19px;
    padding: 6px 0px;
`

export const TextTitleRent = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
`

export const TextSubTitle = styled.h1`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    padding: 0;
`

export const TextAlreadyRentSection = styled.h1`
    font-family: 'Poppins';
    font-weight: 100;
    font-size: 13px;
    
    margin: 0;
    padding: 0;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
`

export const TextAlreadyRentRoutesWord = styled.h1`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 157%;
    margin: 0;
    padding: 0px 3px 0px 3px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    background-color:rgba(141, 12, 80, 0.5);
    border: 1px solid rgba(231, 19, 131, 1);
    margin: 0px 4px 0px 4px;
`

export const ContainerCounter = styled.div `
    height:30px;
    background-blend-mode: soft-light;
    border: 0.3px solid #930d54;
    margin:0px auto;
    border-radius: 2px;
    display: flex;
    justify-content:center;
    align-items:center;
    padding: 3px 15px 3px 15px;
`
export const ContainerCounterText = styled.h1 `
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    padding: 0px 3px 0px 3px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
`

export const ContainerCounterTextBold = styled.h1 `
    font-family: 'Poppins';

    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    padding: 0px 3px 0px 3px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
`

export const ContainerPrice = styled.div`

`

export const ImgCoin = styled.img`
    margin-right: 5px;
    
`

export const ImgClock = styled.img`

`

export const ImgIconRented = styled.img`
    margin-bottom: 2px;
    margin-right: 3px;
`

export const OutpostSection2Rent = styled.div`
    width: 149px;
    margin: 12px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ContainerDuration = styled.div`
    padding: 12px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(19, 203, 231, 0.6);
    background-blend-mode: normal, hard-light;
    border: 0.5px solid #13CBE7;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    justify-content: space-between;
`

export const ContainerRent = styled.div`
    width: 100px;
    background-color:rgba(231, 19, 131, 1);
    height: 39px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
    cursor: pointer;
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
    -webkit-clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
`

