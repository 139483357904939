export function ColsMyFleet() {
 const columns = [
    //  {
    //    Header: "Action",
    //    width: "5%",
    //    accessor: "action"
    //  },
     {
      Header: "Fleet",
      width: "15%",
      accessor: "fleet"
     },
     {
       Header: "Amount",
       width: "5%",
       accessor: "amount"
     },
     {
      Header: "Type",
      width: "15%",
      accessor: "type"
    },
    {
      Header: "Cargo",
      width: "5%",
      accessor: "cargo"
    },
    {
      Header: "Shield",
      width: "5%",
      accessor: "shield"
    },
    {
      Header: "Armor",
      width: "5%",
      accessor: "armor"
    },
    {
      Header: "Damage",
      width: "5%",
      accessor: "damage"
    },
  ];

 return columns;
}
