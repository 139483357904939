import styled from "styled-components";
import background_msg from '../../../../assets/images/background_msg.png'
import border_header from '../../../../assets/images/borders_header.svg'
import { BtnPolygon, ColCore, ImgIcon, TextOrbitron, TextPoppins, TextTitiliumWeb, GridViewCore } from "../../../../containers/CoreElement";

export const ContainerMessages = styled.div`
    flex-direction: column;
    /* background-color: aquamarine; */
    width: 2400px;

`
export const ContainerMessagesPopUp = styled.div`
    flex-direction: column;
    /* background-color: aquamarine; */
    width: 950px;

`

export const ContainerOutpost = styled.div`
    display: flex;
    width: 208px;
    height: 180px;
`

export const FrameMessage = styled.img`
    width: 208px;
    height: 200px;
    @media (max-width: 1160px) {
        width: 25%;
        height: auto;
    }
`

export const ContainerImgMessages = styled.div`
    position: absolute;
    width: 208px;
    height: 175px;
    margin-top: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    @media (max-width: 1160px) {
        width: 25%;
        height: auto;
    }

`

export const ImgMessage = styled.img`
    cursor: pointer;
    width: 20px;
    @media (max-width: 1160px) {
        width: 25%;
        height: auto;
    }
`

export const ImgStar = styled.img`
 
    width: 20px;
    margin-right: 1%;
    @media (max-width: 1160px) {
        width: 25%;
        height: auto;
    }
`
export const ImgMessageNew = styled.img`
 
    width: 100px;
    margin-left: 10px;
    @media (max-width: 1160px) {
        width: 25%;
        height: auto;
    }
`

export const RowInfoMsg = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    width: 100%;
        border-right: 1px solid #e7138457;
    /* background-color: aqua; */
    /* background-color: rgba(47, 4, 26, 0.7); */
`

export const RowInfoMsgPopUp = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    width: 100%;
    /* background-color: aqua; */
    /* background-color: rgba(47, 4, 26, 0.7); */
`
export const RowHeadersMsg = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    width: 100%;
        border-right: 1px solid #e7138457;
    /* background-color: rgba(47, 4, 26, 0.7); */
`
export const RowHeadersMsgPopUp = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    width: 100%;
    /* background-color: rgba(47, 4, 26, 0.7); */
`
export const RowMsgBody = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    width: 100%;
    border-right: 1px solid #e7138457;

    /* background-color: brown; */
`
export const RowMsgBodyPopUp = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    width: 100%;

    /* background-color: brown; */
`
export const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    // background-color: rgba(47, 4, 26, 0.7);
`
export const RowNameShip = styled.div`
    display: flex;
    flex-direction: row;
    // background-color: rgba(47, 4, 26, 0.7);
`
export const RowShips = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0px 10px 10px 10px;
    // background-color: rgba(47, 4, 26, 0.7);
`
export const RowCommander = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 10px;
    // background-color: rgba(47, 4, 26, 0.7);
`

export const RowDataInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background-color: rgba(47, 4, 26, 0.7);
`

export const RowMsg = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    /* padding: 16px; */
    /* gap: 12px; */
    background: rgba(33, 11, 37, 0.1);
    /* background: url(${background_msg}), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2); */
    border: 1px solid #E71383;
    border-radius: 2px;
    background-size: contain;
    margin-bottom: 2%;

`
export const MainRow = styled.div`
    width: 995px;
    display: flex;
    flex-direction: row;

`
export const TextMsgBody = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* or 157% */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    /* White */

    color: #FFFFFF;
`

export const TextMsgBodyPopUp = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* or 157% */


    /* White */

    color: #FFFFFF;
`


export const MessageSection1DataContainer = styled.div`

    width: 310px;
    justify-content: space-between;
    display: flex;
    margin: 12px;
`

export const ContainerInfoMessage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 80%;
    height: 30px;
    /* background-color: aquamarine; */
    /* @media (max-width: 1160px) {
        width: 75%;
        display: block;
        padding: 12px;
    } */
`

export const SectionDataMsg = styled.div`
    width: 90%;
    /* background-color: aquamarine; */
    margin-bottom: 0;

    @media (max-width: 1160px) {
        margin: 0px;
    }
`

export const SectionDataContainerMsg = styled.div`
    background: #520830;
    border: 1px solid #A20E5C;
    color: white;  
    margin-bottom: 12px;
`

export const SectionDataContainerMainMsg = styled.div`

    width: 500px;
    justify-content: space-between;
    display: flex;
    margin: 12px;
`

export const ContainerMsg = styled.div`

    width: auto;
    justify-content: space-between;
    display: flex;
    margin: 12px;
`

export const ContainerDateMsg = styled.div`
 
    padding: 5.5px 10px;
    gap: 6px;
    color: white;  
    width: 180px;
    display: flex;
    align-items: center;
    float: inline-end;
`

export const ContainerDateInfoMsg = styled.div`
 
    background: #3c0623;
    background-blend-mode: soft-light;
    border: 0.5px solid #E71383;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5.5px 10px;
    gap: 6px;
    color: white;  
    width: 190px;
`

export const ContainerAutorMsg = styled.div`
 
    padding: 5.5px 10px;
    gap: 6px;
    color: white;  
    width: 180px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: right;
     width: 80%;
    height: 30px;
`

export const ContainerTypeMsg = styled.div`
    padding: 5.5px 0px;
    gap: 6px;
    color: white;  
    width: 180px;
    display: flex;
    align-items: center;
`

export const TextTitle = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    padding: 6px 0px;
`

export const TextValue = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #E71383;
    padding: 6px 0px;
`

export const TextValueInfoDate = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 6px 0px;
`

export const TextAutorMsg = styled.span`
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 11px;
    line-height: 19px;
    /* identical to box height, or 171% */


    /* White */

    color: #FFFFFF;
`

export const TextValueDate = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 6px 0px;
`


export const SectionDataContainerInfoMsg = styled.div`
    flex-direction: row;
    align-items: center;
    gap: 6px;
    color: white;  
    text-align: left;
    text-align-last: left;
`

export const TextSubTitle = styled.h1`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    margin: 0;
    padding: 0;
`

export const TextValueV2 = styled.h1`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    justify-content: center;
    display: flex;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    justify-content: left;
`

export const ImgCoin = styled.img`
    margin-right: 5px;
`
export const SectionBtnViewMsg = styled.div`
    flex-direction: column;
    width: 100%;
    height: 140px;
    padding-top: 40px;
    margin-left: 2%;
    justify-content: center;
    align-items: center;
    /* background-color: aquamarine; */
`

export const ImgBtnViewMsg = styled.img`
    width: 120px;
    height: 170px;
    cursor: pointer;
    object-fit: contain;
`

export const TextTitleRent = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    position: absolute;
    text-align: center;
`
export const ContainerTitleBtnView = styled.div`
    width: 100px;
`

// export const ContainerPopUp = styled.div`
//     width: 985px;
//     top: -50px; /* posición vertical */
//     left: -50px; /* posición horizontal */
//     height: auto;
//     background-color: white;
//     position: absolute;
// `


export const Contenedor = styled.div`
    position: relative;
`

export const ContainerMessagesMain = styled.div`
    /* background-color: #eee; */
    margin-top: 2%;
    height: auto;
`
export const ContainerMessagesScroll = styled(GridViewCore)`
    height: 480px;
    display: block;
    overflow-y: scroll;
`
export const ContainerPopUp = styled.div`
    position: fixed;
    top: 50px;
    left: 480px; 
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), rgba(94, 8, 53, 0.8);
    border: 1px solid #E71383;
    color: #fff;
    padding: 39px 15px 24px;
    height: auto;
`
export const ContainerPopUpDelMsg = styled(ContainerPopUp)`
    width: 600px;
    top: 250px;
    left: 750px;
`
export const BtnViewMsg = styled(BtnPolygon)`
    width: 30%;
    cursor: pointer;
`
export const TextBtnViewMsg = styled(TextTitiliumWeb)`
    font-weight: 500;
    letter-spacing: 0.02em;
`
export const BtnClosePopUpMsg = styled(BtnPolygon)`
    position: absolute;
    margin: -55px 0 0 985px;
    cursor: pointer;
    width: 42px;
    height: 42px;
`
export const BtnClosePopUpMsg2 = styled(BtnPolygon)`
    position: absolute;
    right:0;
    top:0;
    cursor: pointer;
    width: 42px;
    height: 42px;
`
export const BtnClosePopUpDelMsg = styled(BtnClosePopUpMsg)`
    margin: -55px 0 0 590px;
`
export const ImgIconCloseMsg = styled(ImgIcon)`
    width: 15px;
    height: 15px;
`

export const ColInfoPopUp = styled(ColCore)`
    margin: 0px 10% 5% 10%;
`

export const BtnDialogNo = styled(BtnPolygon)`
    cursor: pointer;
    width: 50%;
    height: 42px;
    margin-right: 5%;
`
export const TextDialogNo = styled(TextOrbitron)`
    text-transform: none;
    font-weight: 700;
    font-size: 16px;
`

export const BtnDialogYes = styled(BtnDialogNo)`
    margin-right: 0;
`

export const TextDialogYes = styled(TextDialogNo)`
`

export const TextInfoPopUp = styled(TextPoppins)`
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: normal;
`

export const ContainerInfoMsg = styled.div`
    width: 995px;
    background: url(greenColorized-pattern-02.png), url(DeepFrontier-v02.png), #520830;
    border: 1px solid #980D57;
`


export const ContainerFleetMsg = styled.div`
    margin-top: 2%;
    width: 995px;
    background: url(greenColorized-pattern-02.png), url(DeepFrontier-v02.png), #520830;
    border: 1px solid #980D57;
`

export const ContainerRewardsMsg = styled.div`
    margin-top: 2%;
   width: 995px;
    background: url(greenColorized-pattern-02.png), url(DeepFrontier-v02.png), #520830;
    border: 1px solid #980D57;
`

export const ContainerTitleInfoMsg = styled.div`
    width: 100%;
    background: #520830;
    border: 1px solid #980D57;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 6px 0;
`

export const ContainerTitleFleetMsg = styled.div`
    width: 100%;
    background: #520830;
    border: 1px solid #980D57;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 6px 0;
`

export const ContainerTitleRewardsMsg = styled.div`
    width: 100%;;
    background: #520830;
    border: 1px solid #980D57;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 0 6px 0;
`

export const ImgInfo = styled.img`
    margin-right: 1%;

`

export const TextInfoTitle = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
`

export const TextTitleCommander = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #F0EBFF;
`

export const ImgBtnClose = styled.img`
    position: absolute;
    margin: -55px 0 0 985px;
    cursor: pointer;
`

export const ContainerDataInfoMsg = styled.div`
    width: 60%;
    margin: 30px 2px 10px 10px;

    /* background-color: aquamarine; */
`

export const ElementDuration = styled.div`
    display: flex;
    justify-content: space-between;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(19, 203, 231, 0.6);
    background-blend-mode: normal, hard-light;
    border: 0.5px solid #13CBE7;
    border-radius: 2px;
    padding: 10px 8px;
    gap: 6px;
    width: 150px;

`

export const IconClock = styled.img`
`

export const TextV1 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 19px;
    text-transform: uppercase;
    color: #FFFFFF;
`

export const ContainerTitleCommander = styled.div`
    margin-top: 2%;
    margin-left: 1%;

`

export const IconCommander = styled.img`
    

`

export const ContainerTitleShips = styled.div`
    margin-top: 5%;
    margin-left: 1%;
    margin-bottom: 2%;
`

export const IconShip = styled.img`
    
    
`

export const TextTitleCommanderFleet = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #F0EBFF;
    margin-left: 1%; 
`
export const TextNameCommander = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #F0EBFF;
    margin-top: 20%;
    margin-left: 20%;
`

export const TextNameShip= styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #F0EBFF;
    margin-top: 5%;
    margin-left: 10%;
`

export const ContainerNumberRoute = styled.div`
    background: #3c0623;
    background-blend-mode: soft-light;
    border: 0.5px solid #E71383;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5.5px 10px;
    gap: 6px;
    color: white;  
    width: 120px;
`
export const ContainerPortada = styled.div`
    width: 996px;
    height: 200px;
    background-image: url(${border_header});
    background-size: cover;
    clip-path: polygon(0 13px, 9px 0, calc(100% - 9px) 0, 100% 13px, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 13px, 9px 0, calc(100% - 9px) 0, 100% 13px, 100% 100%, 0 100%);
    color: white;
    
`

export const ContainerOptions = styled.div`
    width: 100%;
    background: rgba(141, 12, 80, 0.7);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E71383;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`


export const ContainerOptionsResp = styled(ContainerOptions)`
    
    @media(min-width: 961px) {
        display: none;
    }
`

export const ContainerOptionsDesktop = styled.div`
    
    @media(max-width: 600px) {
        display: none;
    }
`

export const ContainerOptionsCellphone = styled.div`
    
    @media(min-width: 601px) {
        display: none;
    }
`
export const OptionShowAll = styled.div`
    width: 110px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 6px;
    gap: 10px;
    
    cursor: pointer;

    @media (max-width: 600px) {
        width: 50%;
    }
`

export const TextOptionMessages = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 6px 0px;

    color: #FFFFFF;
`

export const OptionsCenter = styled.div`
    width: 130px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;

    cursor: pointer;

    @media (max-width: 600px) {
        width: 50%;
    }
`

export const OptionsCenterResp = styled(OptionsCenter)`
    
    @media(max-width: 960px) {
        display: none;
    }
`

export const OptionDeleteAll = styled.div`
    width: 130px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 3px 6px;
    gap: 10px;
    background: #E71383;
    cursor: pointer;
`

export const OptionDeleteAllResp = styled(OptionDeleteAll)`


    @media(max-width: 960px) {
        display: none;
    }
`

export const Trapecio = styled.div`
    height: 100px;
    width: 80px;
    border-top: 20px solid #E71383;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`

export const ContainerNewTag = styled.div`
    height: 20px;
    position: absolute;
    width: 990px;
    display: flex;
    justify-content: center;
    /* background-color: blue; */
`

export const ContainerNewTag1 = styled.div`
    height: 20px;
    position: absolute;
    width: -webkit-fill-available;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
`


export const TextTagNew = styled.span`  
    position: absolute;
    /* margin-bottom: 10px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    /* identical to box height, or 0% */
    text-align: center;
    color: #FFFFFF;
    margin-top: 2px;
`
export const ContainerMoreOptions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: right;
    width: 80%;
    height: 30px;
`

export const ContainerTitleMsg = styled.div`
    padding: 10px;
    width: 80%;
    height: 30px;
`

export const ContainerCommander = styled.div`
    width: 200px;
    height: auto;
    background: #3C0009;
    border: 1px solid #A20E5C;
    /* background-color: aquamarine; */
`

export const ContainerShip = styled.div`
    width: 310px;
    height: auto;
    background: #3C0009;
    border: 1px solid #A20E5C;
    margin-left: 1%;
    margin-bottom: 2%;
    /* background-color: aquamarine; */
`

export const ImgInfoProfile = styled.img`
    width: 100px;
    border: 1px solid #5E0835;
`
export const ContainerInfoCommander = styled.div`
    display: flex;
    flex-direction: column;
`

export const ContainerInjuredCommander = styled.div`
    margin-top: 20%;
    margin-left: 20%;
`
export const ContainerLostShip = styled.div`
    margin-left: 1%;
`

export const ContainerFoundReward = styled.div`
    margin-left: 1%;
`
export const ContainerBonusReward = styled.div`
    margin-left: 1%;
`
export const ContainerTotalReward = styled.div`
    margin-left: 1%;
`

export const ContainerInjuredShip = styled.div`
    margin-left: 10%;
`

export const ContainerAliveShip = styled.div`
    margin-left: 10%;
`
export const ContainerStatsShip = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 18%;
`
export const ContainerStatsReward = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 13%;
    width: 190px;
`

export const TextInjuredCommander = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #F0EBFF;
`
export const ContainerQuantityInjured = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #F0EBFF;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px;
    background: #BE940B;
    border: 1px solid #5E0835;
`
export const ContainerQuantityLost = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #F0EBFF;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px;

    background: #D9534F;
    border: 1px solid #5E0835;
`

export const ContainerQuantityFound = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #F0EBFF;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px;

    background: #143C4A;
    border: 1px solid #5E0835;
`

export const ContainerQuantityBonus = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #F0EBFF;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px;

    background: #9019E6;
    border: 1px solid #5E0835;
`
export const ContainerQuantityTotal = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #F0EBFF;
    margin-top: 9%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px;

    background: #E71383;
    border: 1px solid #5E0835;
`
export const ContainerQuantityAlive= styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #F0EBFF;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 3px 1px;
    background: #E71383;
    border: 1px solid #5E0835;
`

export const GridView = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
`

export const ContainerGrid = styled.div`
    padding-top: 20px;
    width: 985px;
    height: 220px;
    background: rgba(94, 8, 53, 0.1);

`
export const ContainerGridRewards = styled.div`
    padding-top: 20px;
    width: 985px;
    height: 150px;
    background: rgba(94, 8, 53, 0.1);

`
// RouteMessage
export const RouteMessageContainerGeneral = styled.div`
    margin-top: 20px;
    /* position: fixed;
    margin-top: 2%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    padding: 18px; */

    @media(max-width: 960px) {
        margin-bottom: 10%;
    }

    @media(max-width: 600px) {
        margin-bottom: 20%;
    }

`

export const StatsBold = styled.span`
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const StatsBoldV2 = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const TitleStatsCard = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const RouteMessageContainerCommander = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`

export const CommanderImgContainer = styled.div`
    position: relative;
`

export const CommanderContainerData = styled.div`
    display: flex;
    padding: 15.971px;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex: 1 0 0;
    align-self: stretch;
    border: 1.331px solid #E71383;
    background: rgba(47, 4, 26, 0.70);

    @media (max-width: 600px) {
        flex-direction: column;
        gap: 5px;
    }

`

export const ImgRepair = styled(ImgIcon)`
    margin-right: 0%;
    width: 60%;
    height: max-content;
`   

export const DamagedCard = styled.div`
    background: linear-gradient(rgba(255, 0, 0, 0) 0%, rgb(255 0 0 / 38%) 19.58%, rgb(255 0 0 / 55%) 100%);
    background-blend-mode: normal, normal, difference, normal;
    width: 100%;
    height: 90%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`   

export const CommanderImg = styled.img`
    width: 76px;
    height: 82.28px;
    
`

export const RouteMessageContainerGap = styled.div`

    padding: 9px;
    background-color: rgb(84 12 50 / 50%);
    border: 1px solid rgba(231, 19, 131, 1);
`

export const RouteMessageContainerElements = styled.div`
    margin-top: 15px;
    max-height: 720px;
    display: block;
    mix-blend-mode: lighten;
    grid-row-gap: 0px;
`

export const RouteMessageElement = styled.div`
    width: 100%;
    background-color: rgba(76, 10, 44, 0.737);
    border: 1px solid rgba(231, 19, 131, 1);
    margin-bottom: 15px;
`

export const RouteMessageElementTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(231, 19, 131, 1);
`

export const RouteMessageTextTitle = styled.span`
    display: flex;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: white;
`

export const RouteMessageElementData = styled.div`
    padding: 9px;
`

export const RouteMessageRewardsContainerGeneral = styled.div`
    display: grid;
    padding: 6px;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

export const RouteMessageRewardsElement = styled.div`
    display: flex;
    padding: 6px;
    background: #3C0009;
    border: 1px solid #A20E5C;
    width: 100%;
    height: 1000x;
`

export const RouteMessageRewardsElementImage = styled.img`
    
`

export const RouteMessageRewardsElementDiv1 = styled.div`
    margin-left: 5px;
`

export const RouteMessageRewardsElementSubDiv1 = styled.div`
    height: 50%;
`

export const RouteMessageRewardsElementSubDiv2 = styled.div`
    height: 50%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 9px;
`

export const RouteMessageRewardsElementText = styled.span`
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    color: white;
    text-transform: uppercase;
`
export const RouteMessageRewardsElementText2 = styled(RouteMessageRewardsElementText)`
    text-transform: none;
`

//Message
export const MessageContainerGeneral = styled.div`
    display: flex;
    background:  0% 0% / 100% 100% rgba(94, 8, 53, 0.1);
    border: 1px solid #E71383;
    border-radius: 2px;
    margin-bottom: 2%;
    padding: 9px;

    @media (max-width: 600px) {
        margin-bottom: 10%;
        flex-direction: column;
    }
`

export const MessageContainerButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`

export const MessageContainerData = styled.div`
    width: 84%;
    padding-right: 15px;

    border-right: 1px solid transparent;
    border-image: linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;

    @media (max-width: 600px) {
        width: 100%;
        border: 0px;
    }
`

export const MessageContainerRow = styled.div`
    width: 100%;
    display: flex;
    padding: 10px 0px 10px 0px;
`

export const MessageContainerRow1 = styled.div`
    width: 100%;
    display: grid;    
    grid-template-columns: repeat(2, 1fr);
`

export const MessageIcon = styled.img`
    width: 20px;

    @media (max-width: 600px) {
        width: 15px;
    }
`

export const MessageText = styled.span`
    font-family: 'Titillium Web';
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: white;

    @media (max-width: 600px) {
        font-size: 12px;
    }
`

export const MessageText1 = styled.span`
    font-family: 'Titillium Web';
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: rgb(231, 19, 131);
`

export const BtnViewMsg1 = styled.div`
    width: 90px;
    height: 39px;

    background-color: #E71383;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
`

export const MessageTextCommander = styled.span`
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px;
    font-style: oblique;
    line-height: 14px;
    color: white;
`

//Button Close

export const ButtonCloseContainer = styled.div`
    padding: 15px 0px 15px 0px;
    background-color: #E71383;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const ButtonCloseText = styled.span`
    font-family: 'Titillium Web';
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: white;
`

export const ResponsiveOptions = styled.div`
    
    @media(max-width: 960px) {
        display: none;
    }
`