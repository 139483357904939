import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import { 
  PrimaryNav,
  MenuLink,
  MenuLink2,
  Optional,
  MenuMain,
  IconSM, 
  IconU, 
  NavDivi,
  TextUsername, 
  BtnPlayFree,
  TextPlayFree,
  IconCoin, 
  MainRowInfo,
  FirstRowInfo,
  SecondRowInfo,
  TextCurrency,
  IconStatusCoin,
  TextCurrencyValue,
  IconContract,
  TextContract,
  IconWallet,
  TextCurrencyQuantity,
  TextSuscribed,
  ContainerBackground,
  DropDownGeneral,
  DropDownContainer,
  DropDownRow,
  Icon,
  IconRotate,
  BtnContract,
  LogoSM,
  MenuLink2Responsive,
  } from './NavElement'

import icon_space_misfits from '../../../assets/images/icon_space_misfits.svg'
import icon_space_misfits_small from '../../../assets/images/icon_space_misfits_small.svg'

import icon_news from '../../../assets/images/icon_news.svg'
import icon_staking from '../../../assets/images/icon_staking.svg'
import icon_apps from '../../../assets/images/icon_apps.svg'
import icon_user from '../../../assets/images/icon_user.svg'
import icon_link_wallet_on from '../../../assets/images/icon_link_wallet_on.svg'
import icon_access from '../../../assets/images/icon_access.svg'

import data_charac_element_divi from '../../../assets/images/data_charac_element_divi.svg'
import crown_coin from '../../../assets/images/crown_coin.png'
import icon_contract from '../../../assets/images/icon_contract.svg'
import icon_currency_up from '../../../assets/images/icon_currency_up.svg'



import './hooks/styles.css'
import '../../../styles/core.css'

import { HTTP } from '../../http/http-common';

import { RowCore, RowCoreCenter } from '../../../containers/CoreElement';
import { copyToClipboard } from '../../utils/utils';
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles'

import { numberToThousandSeparator } from '../../../components/utils/utils';
import PopUpDetails from '../../../hooks/PopUpDetails'


const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} 
  sx={{
    '& .MuiTooltip-arrow': {
      color: 'pink',
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'pink',
    },
  }}
  arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ffffff',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#8d0c50',
    border: '1px solid #f5eaf0',
    fontFamily: 'Poppins',
    fontSize: '12px',
    width: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '50px'
  },
}));



function DropDownApps(){


  return(
    <> 
      <DropDownGeneral>
        <DropDownContainer>
          <DropDownRow to={"https://marketplace.spacemisfits.com/"}>
            <Icon src='/icons/icon_type_nav_marketplace.svg'></Icon>
                Player Market
          </DropDownRow>
          <DropDownRow to={"https://spacemisfits.com/user/crown/"}>
              <Icon src='/icons/icon_type_nav-crown-wallet.svg'></Icon>
                CROWN Shop
          </DropDownRow>
        </DropDownContainer>
      </DropDownGeneral>
    </>
  );
}

function DropDownStaking(){


  return(
    <> 
      <DropDownGeneral>
        <DropDownContainer>
          <DropDownRow to={"https://stake.spacemisfits.com/"} style={{position:"relative"}}>
              <Icon src='/icons/icon_type_settings.svg'></Icon>
              dApp v2
              <Icon src={icon_access} style={{position:"absolute", right:"0", marginRight:"12px"}}></Icon>
          </DropDownRow>
        </DropDownContainer>
      </DropDownGeneral>
    </>
  );
}

function DropDownGames(){


  return(
    <> 
      <DropDownGeneral>
        <DropDownContainer>
          <DropDownRow to={"https://spacemisfits.com/download"}  style={{position:"relative"}}>
              <Icon src='/icons/icon_type_mayhem_nav.svg'></Icon>
              Mayhem
              <Icon src={icon_access} style={{position:"absolute", right:"0", marginRight:"12px"}}></Icon>
          </DropDownRow>
          <DropDownRow to={"https://fleet.spacemisfits.com/register"} style={{position:"relative"}}>
              <Icon src='/icons/icon_type_fleet_icon_nav.svg'></Icon>
              Fleet
              <Icon src={icon_access} style={{position:"absolute", right:"0", marginRight:"12px"}}></Icon>
          </DropDownRow>
        </DropDownContainer>
      </DropDownGeneral>
    </>
  );
}

function DropDownGuides(){


  return(
    <> 
      <DropDownGeneral>
        <DropDownContainer>
          <DropDownRow to={"https://space-misfits.gitbook.io/space-misfits-mayhem-guides/"}  style={{position:"relative"}}>
              <Icon src='/icons/icon_type_guides_nav.svg'></Icon>
              Mayhem
              <Icon src={icon_access} style={{position:"absolute", right:"0", marginRight:"12px"}}></Icon>
          </DropDownRow>
          <DropDownRow to={"https://space-misfits.gitbook.io/space-misfits-fleet-guides/"} style={{position:"relative"}}>
              <Icon src='/icons/icon_type_guides_nav.svg'></Icon>
              Fleet
              <Icon src={icon_access} style={{position:"absolute", right:"0", marginRight:"12px"}}></Icon>
          </DropDownRow>
        </DropDownContainer>
      </DropDownGeneral>
    </>
  );
}

function DropDownUser(props){


  return(
    <> 
      <DropDownGeneral>
        <DropDownContainer>
          <DropDownRow to={"/settings"}>
            <Icon src="/icons/icon_type_settings.svg"></Icon>
                Settings
          </DropDownRow>
          <DropDownRow to={"/sign-in"} onClick={props.action}>
            <Icon src='/icons/icon_type_log_out.svg'></Icon>
                Log Out
          </DropDownRow>
        </DropDownContainer>
      </DropDownGeneral>
    </>
  );
}


export function NavbarComponent(props){

  const [isOpenApps, setIsOpenApps] = useState(false);
  const [isOpenStaking, setIsOpenStaking] = useState(false);
  const [isOpenGames, setIsOpenGames] = useState(false);
  const [isOpenGuides, setIsOpenGuides] = useState(false);
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [isLogedUser, setIsLogedUser] = useState(false);
  const [username, setUsername] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [price, setPrice] = useState(0);
  let navigate = useNavigate();

  const [isPopUpDetails, setIsPopUpDetails] = useState(false);


  const signOut = () => {
    const tokenObject = JSON.parse(sessionStorage.getItem('token'));
    const token = tokenObject.token;
    console.log(token);

    var config = {
      data: {"token":token},
      method: "POST"
    };
  
    HTTP.request('logout/', config)
      .then(() => {
        sessionStorage.setItem('token', null)
        props.setUpdateNav(false);
        props.setToken(null);
        navigate('/sign-in')
        
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const fetchPrice = () => {

    var config = {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
      },
    };
    HTTP.request('price/', config)
      .then((res) => {
        console.log(res);
        if (res.data){
          if(res.data.result){
            setPrice(res.data.price);
          }else{
            setPrice('...');
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(sessionStorage.getItem('token'));
    const intervalId = setInterval(() => {
      fetchPrice();
    }, 60000);
  
    if(sessionStorage.getItem('token') && sessionStorage.getItem('token') !== 'null'){
      setIsLogedUser(true);
      setUsername(JSON.parse(sessionStorage.getItem('token')).username)
    }else{
      setIsLogedUser(false);
    }

    return () => {
      clearInterval(intervalId);
      fetchPrice();

    };
  }, [props]);

  const btnClickSignOut = async e =>{
    e.preventDefault();
    await signOut();
  }


  const btnClickContract = (text) => {
    copyToClipboard(text);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);
  };



  return (
    <>
    {/* <Responsive> */}
    <MainRowInfo>
        <FirstRowInfo>
          <RowCoreCenter style={{marginRight: '10px'}}>
            <IconCoin src={"/icons/icon_type_coin.svg"}/>
            <TextCurrency>SMCW</TextCurrency>
            <IconStatusCoin src={icon_currency_up}/>
            <TextCurrencyValue>${price >= 0 ? price.toFixed(4) : '...'}</TextCurrencyValue>
          </RowCoreCenter>
          <RowCoreCenter>
            <BootstrapTooltip title={'Copied to clipboard'} placement='top' open={tooltipOpen} disableHoverListener>
              <BtnContract onClick={() => btnClickContract('0xb2ea51BAa12C461327d12A2069d47b30e680b69D')}>
                <IconContract src={icon_contract}/>
                <TextContract>Contract</TextContract>
              </BtnContract>
            </BootstrapTooltip>
          </RowCoreCenter>
        </FirstRowInfo>
        { props.updateNav ?
          <SecondRowInfo style={{width: '30%', justifyContent: 'end'}}>
            { false ?
              <RowCoreCenter>
                <IconCoin src={icon_space_misfits_small}/>
                <ContainerBackground style={{backgroundColor: '#E71383'}}>
                  <TextSuscribed>Suscribed</TextSuscribed>
                </ContainerBackground>
              </RowCoreCenter>
              :
              null
            }
            <RowCore style={{width: '100%', justifyContent: 'end'}}>
              { false ?
                <IconWallet src={icon_link_wallet_on}/>
                :
                null
              }
              <div style={{display:"flex", gap:"9px",alignItems: "center", justifyContent: 'end'}}>
                <a style={{display:"flex",cursor:"pointer"}}href='https://spacemisfits.com/user/crown'>
                  <IconCoin src={'/icons/icon_type_nav-crown-wallet-greencheck.svg'}/>
                </a>
                <ContainerBackground style={{backgroundColor: '#840E4E'}}>
                  <IconCoin src={crown_coin}/>
                  <TextCurrencyQuantity id='crowns-availables'>{numberToThousandSeparator(0)}</TextCurrencyQuantity>
                  <IconCoin onClick={() => setIsPopUpDetails(true)} style={{marginLeft:"5px", width:"15px", height:"15px", cursor:"pointer"}} src={'/icons/icon_type_more_info_purple.svg'}/>
                </ContainerBackground>
              </div>
            </RowCore>
          </SecondRowInfo>
          :
          null
        }
      </MainRowInfo>
      <PrimaryNav>
        <LogoSM href='/dashboard'>
          <IconSM src={icon_space_misfits} />
        </LogoSM>
        <MenuMain>
          <Optional>
            <MenuLink to={"https://spacemisfits.com/news/" }>
              <IconU src={icon_news} />
              News
            </MenuLink>
            <NavDivi src={data_charac_element_divi} />
            <div onMouseLeave={() => setIsOpenGames(false)}>
              <MenuLink onMouseEnter={() => setIsOpenGames(true)}>
                <IconU src={'/icons/icon_type_games_navbar.svg'} />
                Games  {isOpenGames ? <IconRotate src="/icons/icon_type_arrow_navbar.svg" style={{transform:"rotate(180deg)", transition:" all 0.3s ease-in-out"}}/>  : <IconRotate src="/icons/icon_type_arrow_navbar.svg" />}
              </MenuLink>
              { isOpenGames ?
                  <DropDownGames></DropDownGames>
                :
                  null
              }
            </div>
            <NavDivi src={data_charac_element_divi} />
            <div onMouseLeave={() => setIsOpenStaking(false)}>
              <MenuLink onMouseEnter={() => setIsOpenStaking(true)}>
                <IconU src={icon_staking} />
                Staking  {isOpenStaking ? <IconRotate src="/icons/icon_type_arrow_navbar.svg" style={{transform:"rotate(180deg)", transition:" all 0.3s ease-in-out"}}/>  : <IconRotate src="/icons/icon_type_arrow_navbar.svg" />}
              </MenuLink>
              { isOpenStaking ?
                  <DropDownStaking></DropDownStaking>
                :
                  null
              }
            </div>
            <NavDivi src={data_charac_element_divi} />
            <div onMouseLeave={() => setIsOpenApps(false)}>
              <MenuLink to={false ? "/apps" : '#'} onMouseEnter={() => setIsOpenApps(true)} >
                <IconU src={icon_apps} />
                Apps  {isOpenApps ? <IconRotate src="/icons/icon_type_arrow_navbar.svg" style={{transform:"rotate(180deg)", transition:" all 0.3s ease-in-out"}}/>  : <IconRotate src="/icons/icon_type_arrow_navbar.svg" />}
              </MenuLink>
              { isOpenApps   ?
                <DropDownApps></DropDownApps>
              :
                null
              }
            </div>
            <NavDivi src={data_charac_element_divi} />
            <div onMouseLeave={() => setIsOpenGuides(false)}>
              <MenuLink onMouseEnter={() => setIsOpenGuides(true)}>
                <IconU src={"/icons/icon_type_guides_nav.svg"} />
                Guides  {isOpenGuides ? <IconRotate src="/icons/icon_type_arrow_navbar.svg" style={{transform:"rotate(180deg)", transition:" all 0.3s ease-in-out"}}/>  : <IconRotate src="/icons/icon_type_arrow_navbar.svg" />}
              </MenuLink>
              { isOpenGuides ?
                  <DropDownGuides></DropDownGuides>
                :
                  null
              }
            </div>
          </Optional>
          
          { isLogedUser ?
            <MenuLink2Responsive>
              <BtnPlayFree style={{height: '40px'}}>
                <TextPlayFree>
                  Play free
                </TextPlayFree>
              </BtnPlayFree>
            </MenuLink2Responsive>
            :
            <MenuLink2 to={'https://spacemisfits.com/download/'}>
              <BtnPlayFree style={{height: '40px'}}>
                <TextPlayFree>
                  Play free
                </TextPlayFree>
              </BtnPlayFree>
            </MenuLink2>
          }

          <div style={{ alignItems:"center", display:"flex"}}>
          { isLogedUser && props.updateNav?
          <div onMouseLeave={() => setIsOpenUser(false)} >
          <MenuLink2 style={{padding:"0px 1.2rem"}} to={false ? "/myaccount" : '#' } onMouseEnter={() => setIsOpenUser(true)}>
            <IconU src={icon_user} />
            <TextUsername>
              {username} {isOpenUser ? <IconRotate src="/icons/icon_type_small_arrow.svg" style={{transform:"rotate(180deg)", transition:" all 0.3s ease-in-out"}}/>  : <IconRotate src="/icons/icon_type_small_arrow.svg" />}
            </TextUsername>
          </MenuLink2>
          { isOpenUser  ?
            <DropDownUser action={btnClickSignOut}></DropDownUser>
            :
            null
          }
          </div>
            :
            null
          }
          </div>
        </MenuMain>
      </PrimaryNav>
    {/* </Responsive> */}
    { props.updateNav ? 
        <PopUpDetails show={isPopUpDetails} onClose={() => setIsPopUpDetails(false)} ></PopUpDetails>
      :
        null
    }
    </>
  )
}