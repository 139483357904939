import React, { useState } from 'react'
import { ColButtons, IconArrow, RowCore } from '../containers/CoreElement'
import { ButtonAdd, ContainerInputCustom, InputSet, TittleTextV7 } from './InputSetAmountElement'
import '../styles/core.css'

export default function InputSetAmount({item, index, placeholder, onClickRemove, importItems}) {

    
    const [inputAmountRemove, setInputAmountRemove] = useState('');

    const handleInputAmountRemoveChange = (event) => {
        setInputAmountRemove(event.target.value);
    };

    const removeElement = (item, index, count) => {

        let maxUnits = 0;
        if(!importItems){
            maxUnits = item[0].hasOwnProperty('specs') || item[0].hasOwnProperty('item_type') ? item.length : item[0].value;
        }else{
            maxUnits = item.userBalance;
        }
        if(count > maxUnits){
            setInputAmountRemove(maxUnits)
        
        }else if(count < 0){
            setInputAmountRemove(Math.abs(count))
        
        }else{
            onClickRemove(item, index, count);
            setInputAmountRemove('');
        }

    };


    const handleIncrement = (item, importItems) => {
        let numMax = 0 

        if(!importItems){
            numMax = item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') || item[0].hasOwnProperty('item_type') ? item.length : item[0].value
        }else{
            numMax = item.userBalance
        }

        if(inputAmountRemove.length === 0){
            setInputAmountRemove(1)
        }else if(inputAmountRemove < numMax){
            setInputAmountRemove(parseInt(inputAmountRemove) + 1);
        }
    }

    const handleDecrement = () => {
        if(inputAmountRemove >= 1){
            setInputAmountRemove(parseInt(inputAmountRemove) - 1);
        }
    }

    

  return (
    <RowCore>
        <ContainerInputCustom>
        { !importItems ?
            <>
            <InputSet className='input-custom' type='number' name={placeholder} placeholder={placeholder} min={1} max={item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('type') ? item.length : item.value } value={inputAmountRemove} onChange={handleInputAmountRemoveChange}/>
            <ColButtons>
                <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrement(item, importItems)}/>
                <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrement()}/>
            </ColButtons>
            </>
            :
            <>
            <InputSet className='input-custom' type='number' name={placeholder} placeholder={placeholder} min={1} max={item.userBalance} value={inputAmountRemove} onChange={handleInputAmountRemoveChange}/>
            <ColButtons>
                <IconArrow src='/icons/icon_special_stats_arrow_hidden.svg' onClick={() => handleIncrement(item, importItems)}/>
                <IconArrow src='/icons/icon_special_stats_arrow_show.svg' onClick={() => handleDecrement()}/>
            </ColButtons>
            </>
        }
        </ContainerInputCustom>
        <ButtonAdd onClick={() => removeElement(item, index, parseInt(inputAmountRemove))} style={{width: '45%', height: '30px'}}>
            <TittleTextV7>
            Remove
            </TittleTextV7>
        </ButtonAdd>
    </RowCore>
  )
}
