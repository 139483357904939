import React, { useState, useEffect } from 'react';
import { HTTP } from '../components/http/http-common';
import { 
    ContainerHeader,
    ContainerHeaderResources,
    ContainerHeaderSquare,
    ContainerTextHelp,
    ContainerElements,
    Text,
    ContainerHeaderResourcesBorder,
    ContainerHeaderBorder,
    ButtonClose,
    Icon,
    RowMsg,
    TextInfo,
    ContainerElementsPeroxium,
    ContainerElementsTrantium,
    ContainerElementsObscurian,
    ContainerElementsMercoxium,
    ContainerElementsCrelyte,
    ContainerElementsBits,
    ContainerElementsHover,
    CollateralContainer,
    WeekResetContainer,
    OsmPledgeContaiener,
    ContainerIconText,
    HeaderMinerals,
    ContainerMainElementsCellphone,
    ContainerMainElementsDesktop,
} from './HeaderScreenElement';

import PopUpDetails from './PopUpDetails'
import { ColCore, ContainerIconSvg, ContainerVoid, Img, ImgIcon, RowCore, TextPoppins, TextTitiliumWeb } from '../containers/CoreElement';
import help_icon_pink from '../assets/images/info_icon_pink.svg'
import help_icon_yellow from '../assets/images/info_icon_yellow.svg'
import next_week_reset from '../assets/images/next_week_reset.svg'
import icon_back from '../assets/images/icon_back.svg'
import icon_ship_progress from '../assets/images/icon_ship_progress.svg'

import { useNavigate } from "react-router-dom";
import { convertTimestamp, numberToThousandSeparator, numberToThousandSeparatorLeaderboardRanking } from '../components/utils/utils';
import icon_ship_slots from '../assets/images/icon_ship_slots.svg'

// props = imgHeader, icon, title, onClickBack, width, widthBar, extended, textHelp.
const HeaderScreen = ({ children, extended, imgHeader, icon, title, width, onClickBack, dashboard = false, linked = false, leaderBoard = false, activeFleets = 0, countShips=false, shipyard=false, walletNotifications=false}) => {

    // let iconWidth = props.extended ? iconWidth = []
    const [minerals, setMinerals] = useState({});

    const [activeFleet, setActiveFleet] = useState(0);
    const [isTextHelp, setIsTextHelp] = useState(true);
    const [rank, setRank] = useState(false);
    const [countRanking, setCountRanking] = useState("...");
    const [collectedAurum, setCollectedAurum] = useState("...");
    const [nextWeekReset, setNextWeekReset] = useState("...");
    const [collateralValues, setCollateralValues] = useState({});
    const [count, setCount] = useState(0);
    let navigate = useNavigate();

    const [isRankShow, setIsRankShow] = useState(false);
    const [isPeroxium, setIsPeroxium] = useState(false);
    const [isTrantium, setIsTrantium] = useState(false);
    const [isObscurian, setIsObscurian] = useState(false);
    const [isMercoxium, setIsMercoxium] = useState(false);
    const [isCrelyte, setIsCrelyte] = useState(false);
    const [isBits, setIsBits] = useState(false);

    const [isPopUpDetails, setIsPopUpDetails] = useState(false);

    const handleHover = (x) => {
        
        if (x === 1){
            setIsPeroxium(true)
        }else if (x === 2){
            setIsTrantium(true)
        }else if (x === 3){
            setIsObscurian(true)
        }else if (x === 4){
            setIsMercoxium(true)
        }else if (x === 5){
            setIsCrelyte(true)
        }else if (x === 6){
            setIsBits(true)
        }else if (x === 0){
            setIsRankShow(true)
        }
    };
    const handleCloseHover = (x) => {
        if (x === 1){
            setIsPeroxium(false)
        }else if (x === 2){
            setIsTrantium(false)
        }else if (x === 3){
            setIsObscurian(false)
        }else if (x === 4){
            setIsMercoxium(false)
        }else if (x === 5){
            setIsCrelyte(false)
        }else if (x === 6){
            setIsBits(false)
        }else if (x === 0){
            setIsRankShow(false)
        }

    };

    const handleButtonClose = () => {
        setIsTextHelp(false);
    };

    const getCollateralLockValues = () => {
        var config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            method: "GET"
        };

        HTTP.request('collateral/values/', config)
            .then((res) => {
                console.log(res);
                if (res.data.result) {
                    setNextWeekReset(res.data.values.time_to_finish)
                    setCollateralValues(res.data.values)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const fetchMinerals = () => {
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('minerals/', config)
          .then((res) => {
            if (res.data.result){
                setMinerals(res.data.minerals)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };


    useEffect(() => {

        console.log(Math.floor(1.5));
        fetchMinerals();
        fetchRanking();
        fetchParameterActiveFleet();
        if (leaderBoard || dashboard) {
            getCollateralLockValues();
        }
        
        setInterval(() => {
            setCount(prevCount => prevCount + 0.5);
        }, 1000);
    }, [leaderBoard, dashboard]);

    const fetchParameterActiveFleet = () => {
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            method: "GET"
        };
        HTTP.request('parameter/active/fleet/', config)
            .then((res) => {
                if (res.data.result) {
                    setActiveFleet(res.data.value)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchRanking = () => {
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('leader/ranking/', config)
            .then((res) => {
                if (res.data.result) {
                    console.log(res.data.response.rank)
                    setRank(res.data.response.rank)
                    setCollectedAurum(res.data.response.collected_aurum)
                    setCountRanking(res.data.response.count_ranking)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <div style={{position:"relative"}}>
                { isRankShow ? 
                <ContainerElementsHover style={{width:"210px"}}>
                    Olympus Syndicate Membership
                </ContainerElementsHover>
                :
                    null
                }
                { isPeroxium ? 
                <ContainerElementsPeroxium>
                    Peroxium
                </ContainerElementsPeroxium>
                :
                    null
                }
                { isTrantium ?
                <ContainerElementsTrantium>
                    Trantium
                </ContainerElementsTrantium>
                :
                    null
                }
                { isObscurian ?
                <ContainerElementsObscurian>
                    Obscurian
                </ContainerElementsObscurian>
                :
                    null
                }
                { isMercoxium ? 
                <ContainerElementsMercoxium>
                    Mercoxium
                </ContainerElementsMercoxium>
                :
                    null
                }
                { isCrelyte ? 
                <ContainerElementsCrelyte>
                    Crelyte
                </ContainerElementsCrelyte>
                :
                    null
                }
                { isBits ? 
                <ContainerElementsBits style={{width:"45px"}}>
                    BITS
                </ContainerElementsBits>
                :
                    null
                }

                <ContainerHeaderResourcesBorder>
                    <ContainerHeaderResources style={{  width: width, padding: '0' }}>
                        <HeaderMinerals>
                            <RowCore style={{ marginLeft: '2%', alignItems: 'center', lineHeight: '0px' }}>
                                {rank ? 
                                    <>
                                    <ImgIcon onMouseEnter={() => handleHover(0)} onMouseLeave={() => handleCloseHover(0)}  src='/icons/icon_type_header_bar.svg' style={{ width: '18px', height: '18px' }} /><TextTitiliumWeb onClick={() => navigate("/leaderboard/current-week")} style={{ cursor: "pointer", fontSize: "14px", letterSpacing: '0.05em', lineHeight: '0px', textDecoration: "underline" }}>{numberToThousandSeparatorLeaderboardRanking(collectedAurum)} (Ranking <TextTitiliumWeb style={{ fontSize: "14px", letterSpacing: '0em', lineHeight: '0px', fontWeight: '100' }}> {rank} of {countRanking}</TextTitiliumWeb>)</TextTitiliumWeb>
                                    </>
                                    :
                                    <>
                                    <ImgIcon onMouseEnter={() => handleHover(0)} onMouseLeave={() => handleCloseHover(0)} src='/icons/icon_type_header_bar_off.svg' style={{ width: '18px', height: '18px' }} /><TextTitiliumWeb onClick={() => navigate("/leaderboard/current-week")} style={{ cursor: "pointer", fontSize: "14px", letterSpacing: '0.05em', lineHeight: '0px', textDecoration: "underline" }}>Unranked</TextTitiliumWeb>
                                    </>
                                }
                            </RowCore>
                            <ContainerMainElementsDesktop>
                                <ContainerElements onMouseEnter={() => handleHover(1)} onMouseLeave={() => handleCloseHover(1)}>
                                    <ImgIcon src='/icons/icon_type_peroxium.svg' />
                                    <Text id='current-peroxium'></Text>
                                    <ImgIcon onClick={() => setIsPopUpDetails(true)} src={'/icons/icon_type_more_info_purple.svg'} style={{ width: '12px', height: '12px', cursor:'pointer' }} />
                                </ContainerElements>
                                <ContainerElements onMouseEnter={() => handleHover(2)} onMouseLeave={() => handleCloseHover(2)}>
                                    <ImgIcon src='/icons/icon_type_trantium.svg' style={{ width: '15px', height: '15px' }} />
                                    <Text id='current-trantium'></Text>
                                    <ImgIcon onClick={() => setIsPopUpDetails(true)} src={'/icons/icon_type_more_info_purple.svg'} style={{ width: '12px', height: '12px', cursor:'pointer' }} />
                                </ContainerElements>
                                <ContainerElements  onMouseEnter={() => handleHover(3)} onMouseLeave={() => handleCloseHover(3)}>
                                    <ImgIcon src='/icons/icon_type_crystal.png' style={{ width: '15px', height: '15px' }} />
                                    <Text id='current-obscurian'></Text>
                                    <ImgIcon onClick={() => setIsPopUpDetails(true)} src={'/icons/icon_type_more_info_purple.svg'} style={{ width: '12px', height: '12px', cursor:'pointer' }} />
                                </ContainerElements>
                                <ContainerElements  onMouseEnter={() => handleHover(4)} onMouseLeave={() => handleCloseHover(4)}>
                                    <ImgIcon src='/icons/icon_type_mercoxium.png' style={{ width: '15px', height: '15px' }} />
                                    <Text id='current-mercoxium'></Text>
                                    <ImgIcon onClick={() => setIsPopUpDetails(true)} src={'/icons/icon_type_more_info_purple.svg'} style={{ width: '12px', height: '12px', cursor:'pointer' }} />
                                </ContainerElements>
                                <ContainerElements  onMouseEnter={() => handleHover(5)} onMouseLeave={() => handleCloseHover(5)}>
                                    <ImgIcon src='/icons/icon_type_crelyte.png' style={{ width: '15px', height: '15px' }} />
                                    <Text id='current-crelyte'></Text>
                                    <ImgIcon onClick={() => setIsPopUpDetails(true)} src={'/icons/icon_type_more_info_purple.svg'} style={{ width: '12px', height: '12px', cursor:'pointer' }} />
                                </ContainerElements>
                                <ContainerElements style={{gridTemplateColumns: '15px auto'}} onMouseEnter={() => handleHover(6)} onMouseLeave={() => handleCloseHover(6)}>
                                    <ImgIcon src='/icons/icon_type_blue_coin_header.svg' style={{ width: '15px', height: '15px' }} />
                                    <Text id='current-bits'></Text>
                                </ContainerElements>
                                <ContainerElements style={{width:"30px", minWidth: "30px"}}>
                                    <ImgIcon onClick={() => setIsPopUpDetails(true)} src={help_icon_pink} style={{ width: '15px', height: '15px', cursor:'pointer' }} />
                                </ContainerElements>
                            </ContainerMainElementsDesktop>
                            <ContainerMainElementsCellphone>
                                <ContainerElements style={{gridTemplateColumns: '15px auto'}} onMouseEnter={() => handleHover(6)} onMouseLeave={() => handleCloseHover(6)}>
                                    <ImgIcon src='/icons/icon_type_blue_coin_header.svg' style={{ width: '15px', height: '15px' }} />
                                    <Text id='current-bits-resp'>{minerals.bits}</Text>
                                </ContainerElements>
                                <ContainerElements style={{width:"30px", minWidth: "30px"}}>
                                    <ImgIcon onClick={() => setIsPopUpDetails(true)} src={help_icon_pink} style={{ width: '15px', height: '15px', cursor:'pointer' }} />
                                </ContainerElements>
                            </ContainerMainElementsCellphone>
                        </HeaderMinerals>
                    </ContainerHeaderResources>
                </ContainerHeaderResourcesBorder>
                {extended ?
                    <ContainerHeaderBorder>
                        <ContainerHeader style={{ width: "100%", height: '200px', backgroundColor: '#ffffff' }}>
                            <Img src={imgHeader} style={{ position: 'absolute', zIndex: '1' }} />
                            <ColCore style={{ position: 'absolute', zIndex: '2', width: '95%', height: '100%' }}>
                                {leaderBoard ?
                                    <RowCore style={{ marginTop: '2%' }}>
                                        <RowCore style={{ padding: '5px', alignItems: 'center', width: '50%' }}>
                                            <div style={{ border: '0.5px solid rgba(231, 19, 131, 1)', background: 'rgba(141, 12, 80, 0.7)', padding: '5px', alignItems: 'center', width: '245px' }}>
                                                <ImgIcon src={next_week_reset} style={{ width: '15px', height: '15px', marginLeft: '1%' }} />
                                                <TextTitiliumWeb style={{ fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left' }}>
                                                    { nextWeekReset > 0 && (nextWeekReset - Math.floor(new Date().getTime() / 1000)) > 0?
                                                        <>
                                                        Next week reset: {convertTimestamp(nextWeekReset, count)}
                                                        </>
                                                        :
                                                        "Week restarting..."
                                                    }
                                                </TextTitiliumWeb>
                                            </div>

                                        </RowCore>
                                        <RowCore style={{ justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
                                            <ImgIcon src={icon} style={{ width: '20px', height: 'auto', marginRight: '1%' }} />
                                            <TextPoppins style={{ fontSize: '20px' }}>
                                                {title}
                                            </TextPoppins>
                                        </RowCore>
                                    </RowCore>
                                    :
                                    <>
                                        { !countShips ?
                                        <>
                                        {!dashboard ?
                                            <RowCore style={{ justifyContent: 'flex-end', alignItems: 'center', marginTop: '2%' }}>
                                                <ImgIcon src={icon} style={{ width: '20px', height: 'auto', marginRight: '1%' }} />
                                                <TextPoppins style={{ fontSize: '20px' }}>
                                                    {title}
                                                </TextPoppins>
                                            </RowCore>
                                            :
                                            <RowCore style={{ marginTop: '2%'}}>
                                                <RowCore style={{ justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                                                    <RowMsg>
                                                        <ContainerIconSvg style={{width: '20px', marginRight: '7%'}}>
                                                            <ImgIcon src={icon_ship_progress} />
                                                        </ContainerIconSvg>
                                                        <TextInfo> Active Fleets: </TextInfo>
                                                        <TextInfo style={{fontWeight: 'bold', marginLeft: '10%'}}>{activeFleets} / {activeFleet}</TextInfo>
                                                    </RowMsg>
                                                </RowCore>
                                                <RowCore style={{ justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
                                                    <ImgIcon src={icon} style={{ width: '20px', height: 'auto', marginRight: '1%' }} />
                                                    <TextPoppins style={{ fontSize: '20px' }}>
                                                        {title}
                                                    </TextPoppins>
                                                </RowCore>
                                            </RowCore>
                                        }
                                        </>
                                        :
                                        <RowCore style={{ marginTop: '2%' }}>
                                            <RowCore style={{ justifyContent: 'flex-start', alignItems: 'center', width: '70%' }}>
                                                <RowMsg>
                                                    <ImgIcon src={icon_ship_slots} style={{width: '20px', marginRight: '7%'}}/>
                                                    <TextInfo> Ship Slots: </TextInfo>
                                                    <TextInfo style={{fontWeight: 'bold', marginLeft: '5%'}}>{countShips.count} / {countShips.limit}</TextInfo>
                                                </RowMsg>
                                            </RowCore>
                                            <RowCore style={{ justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
                                                <ImgIcon src={icon} style={{ width: '20px', height: 'auto', marginRight: '1%' }} />
                                                <TextPoppins style={{ fontSize: '20px' }}>
                                                    {title}
                                                </TextPoppins>
                                            </RowCore>
                                        </RowCore>
                                        }
                                    </>
                                }
                                <ColCore style={{ position: 'relative', width: '98%', height: '100%', marginBottom: '2%', justifyContent: 'end', gap: '10px' }}>
                                    { dashboard && walletNotifications ?
                                        <ContainerTextHelp style={{ height: '30%' }}>
                                            <RowCore style={{ marginLeft: '2%', borderLeft: '5px solid #FFB800', padding: '5px', alignItems: 'center' }}>
                                                <ImgIcon src={help_icon_yellow} style={{ width: '15px', height: '15px', marginLeft: '1%' }} />
                                                <TextTitiliumWeb style={{ fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left' }}>
                                                    In order to receive blockchain rewards you need to link your wallet, {<a href='settings' style={{color: '#13CBE7'}}>link it now</a>}.
                                                </TextTitiliumWeb>

                                                <ButtonClose onClick={handleButtonClose}><Icon src="/icons/icon_type_x.svg"></Icon></ButtonClose>

                                            </RowCore>
                                        </ContainerTextHelp>
                                        :
                                        null
                                    }
                                    {isTextHelp ?
                                        <ContainerTextHelp style={{ position: 'absolute', bottom: '0px', right: '0px', left: '0px', height: '30%' }}>
                                            <RowCore style={{ marginLeft: '2%', borderLeft: '5px solid #E71383', padding: '5px', alignItems: 'center'}}>
                                                <ImgIcon src={help_icon_pink} style={{ width: '15px', height: '15px', marginLeft: '1%' }} />
                                                <TextTitiliumWeb style={{ fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left'}}>
                                                    {children}
                                                </TextTitiliumWeb>

                                                <ButtonClose onClick={handleButtonClose}><Icon src="/icons/icon_type_x.svg"></Icon></ButtonClose>

                                            </RowCore>
                                        </ContainerTextHelp>
                                        :
                                        null
                                    }
                                </ColCore>
                            </ColCore>
                        </ContainerHeader>
                        {dashboard ?
                            <CollateralContainer>
                                <WeekResetContainer>
                                    <ContainerIconText>
                                        <ImgIcon src={"/icons/icon_week_reset.svg"} style={{ width: '15px', height: '15px', marginLeft: '1%' }} />
                                        <TextTitiliumWeb style={{ fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left', whiteSpace: 'nowrap'}}>
                                            Week reset:
                                        </TextTitiliumWeb>
                                        <TextTitiliumWeb style={{ fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left', whiteSpace: 'nowrap', color: 'var(--main-pink, #E71383)'}}>
                                            { nextWeekReset > 0 && (nextWeekReset - Math.floor(new Date().getTime() / 1000)) > 0?
                                                <>
                                                {convertTimestamp(nextWeekReset, count)}
                                                </>
                                                :
                                                "Week restarting..."
                                            }
                                        </TextTitiliumWeb>
                                    </ContainerIconText>
                                </WeekResetContainer>
                                {collateralValues.smnft ?
                                    <OsmPledgeContaiener onClick={() => window.open('https://stake.spacemisfits.com/pledge', '_blank')} style={collateralValues.osm_pledge ? {border: '3px solid #13CBE7', cursor: 'pointer'}  : {border: '3px solid #F00;', cursor: 'pointer'}}>
                                        <ContainerIconText>
                                            {collateralValues.osm_pledge ?
                                                <ImgIcon src={"/icons/icon_OSM_active.svg"} style={{ width: '15px', height: '15px', marginLeft: '1%' }} />
                                                :
                                                <ImgIcon src={"/icons/icon_OSM_inactive.svg"} style={{ width: '15px', height: '15px', marginLeft: '1%' }} />
                                            }
                                            <TextTitiliumWeb style={{ fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left', whiteSpace: 'nowrap'}}>
                                                OSM Min. Pledge:
                                            </TextTitiliumWeb>
                                        </ContainerIconText>
                                        <ContainerIconText>
                                            <ImgIcon src={"/icons/icon_crown.svg"} style={{ width: '15px', height: '15px', marginLeft: '1%' }} />
                                            <TextTitiliumWeb style={{ fontWeight: 'normal', letterSpacing: '0px', fontSize: '13px', lineHeight: '15px', textAlign: 'left', whiteSpace: 'nowrap'}}>
                                                {numberToThousandSeparator(collateralValues.pledge_amount)} / {numberToThousandSeparator(collateralValues.pledge_min)}
                                            </TextTitiliumWeb>
                                        </ContainerIconText>
                                        <ContainerIconText>
                                            <ImgIcon src={"/icons/icon_plus_pledge_link.svg"} style={{ width: '15px', height: '15px', marginLeft: '1%'}}/>
                                        </ContainerIconText>
                                    </OsmPledgeContaiener>
                                    :
                                    null
                                }
                            </CollateralContainer>
                            :
                            null
                        }
                    </ContainerHeaderBorder>
                    :
                    <ContainerHeaderSquare style={{ width: width, height: '40px', clipPath: '0', WebkitClipPath: '0' }}>
                        <Img src={imgHeader} style={{ position: 'absolute', zIndex: '1', objectFit: 'none' }} />
                        <ColCore style={{ position: 'absolute', zIndex: '2', width: '95%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                            <RowCore>
                                { shipyard ?
                                <>
                                <RowCore style={{ justifyContent: 'flex-start', alignItems: 'center', width: '10%' }}>
                                    <ContainerVoid style={{ cursor: 'pointer' }} onClick={onClickBack}>
                                        <ImgIcon src={icon_back} style={{ width: '15px', height: 'auto', marginRight: '20%' }} />
                                        <TextPoppins style={{ fontSize: '18px' }}>
                                            Back
                                        </TextPoppins>
                                    </ContainerVoid>
                                </RowCore>
                                <RowCore style={{ justifyContent: 'flex-start', alignItems: 'center', width: '70%' }}>
                                    <RowMsg style={{padding: '6px 15px'}}>
                                        <ImgIcon src={icon_ship_slots} style={{width: '20px', marginRight: '7%'}}/>
                                        <TextInfo> Ship Slots: </TextInfo>
                                        <TextInfo style={{fontWeight: 'bold', marginLeft: '5%'}}>{countShips.count} / {countShips.limit}</TextInfo>
                                    </RowMsg>
                                </RowCore>
                                </>
                                :
                                <RowCore style={{ justifyContent: 'flex-start', alignItems: 'center', width: '50%' }}>
                                    <ContainerVoid style={{ cursor: 'pointer' }} onClick={onClickBack}>
                                        <ImgIcon src={icon_back} style={{ width: '15px', height: 'auto', marginRight: '20%' }} />
                                        <TextPoppins style={{ fontSize: '18px' }}>
                                            Back
                                        </TextPoppins>
                                    </ContainerVoid>
                                </RowCore>
                                }
                                <RowCore style={{ justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
                                    <ImgIcon src={icon} style={{ width: '20px', height: 'auto', marginRight: '1%' }} />
                                    <TextPoppins style={{ fontSize: '20px' }}>
                                        {title}
                                    </TextPoppins>
                                </RowCore>
                            </RowCore>
                        </ColCore>
                    </ContainerHeaderSquare>
                }
            </div>
            
            <PopUpDetails show={isPopUpDetails} onClose={() => setIsPopUpDetails(false)} peroxium={0} trantium={0} obscurian={0} mercoxium={0} crelyte={0} bits={0}></PopUpDetails>
        </>
    )
};

export default HeaderScreen;