//Libraries INIT
import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';

//Components
import { HTTP } from './../http/http-common';
import PopUp from './../../hooks/PopUp';

//CSS
import '../Settings/hooks/styles.css'

//Icons

import icon_link_wallet_off from './../../assets/images/icon_link_wallet_off.svg'
import icon_link_wallet_on from './../../assets/images/icon_link_wallet_on.svg'

import icon_danger from './../../assets/images/icon_danger.svg'
import icon_file_copy from '../../assets/images/icon_file_copy.svg'

//Elements

import { 
  ColCore,
  ContainerMain, ImgIcon, RowCore, RowSpaceBetween, TextOrbitron, TextPoppins, TextTitiliumWeb,
} from './../../containers/CoreElement';

import { 
  ContainerValidations,
  ContainerSectionMain,
  Header,
  IconOff,
  TextPoppinsCustom1,
  TextPoppinsCustom2,
  TextPoppinsCustom3,
  SubElementValidations,
  ContainerQrPopup,
  ContainerValidationsMain,
  ContainerSection,
  TitleMain,
  Separator,
  SubTitle,
  TitleSetting,
  TextSetting,
  TextWarning,
  ContainerWarning,
  RowCenter,
  IconWarning,
  InputForm,
  BtnInput,
  RowInput,
  RowMain2FA,
  Row2FA,
  RowDisableNotifications,
  BtnUpdateProfileData,
  ContainerBtn,
  ContainerTextHelp,
  InputFormCodeWallet,
  RowDesktop,
  ColCellphone,
  TextPoppinsCustom8

} from './SettingsElement'

import help_icon_pink from '../../assets/images/info_icon_pink.svg'
import icon_back_settings from '../../assets/images/icon_back_settings.svg'

import { Loader } from '../../hooks/Loader';

import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles'
import { convertTimestamp, copyToClipboard } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material'

export function Settings() {


  const xs = useMediaQuery('(max-width:600px)')


  const navigate = useNavigate()

  const [isChecked, setIsChecked] = useState(true);
  const [showPopUpInfo, setShowPopUpInfo] = useState(false);
  const [showPopUpInfoConnections, setShowPopUpInfoConnections] = useState(false);
  const [textPopUp, setTextPopUp] = useState('');
  const [textConnections, setTextConnections] = useState('');

  const handleSwitchChange = (checked) => {
    setIsChecked(checked);
  };
  
  const [loader, setLoader] = useState(false);
  const [userData, setUserData] = useState({});
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    handleUserDataChange(name, value);
    
  };

  const handleUserDataChange = (fieldName, fieldValue) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [fieldName]: fieldValue
    }));
  };

  const [newPassword, setNewPassword] = useState('');

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleCodeWalletChange = (event) => {
    setCodeWallet(event.target.value);
  };

  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const clickBtnRefresh = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
        data: {"twitter": userData.twitter_username},
        method: "POST"
    };
    HTTP.request('user/refresh/twitter/', config)
    .then((res) => {
      if (res.data.result){
        window.location.reload();
      }else{
        console.log(res.data.response);
      }
    })
    .catch((err) => {
    console.log(err);
    })
    .finally(() => {
        setLoader(false);
      })
  }

  const clickBtnLink = (discord_value_status, url) => {
    if (discord_value_status) {
      window.open(url, '_blank');
    }else{

      setLoader(true);
      const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          method: "POST"
      };
      HTTP.request('user/unlink/discord/', config)
      .then((res) => {
        if (res.data.result){
          window.location.reload();
        }else{
          console.log(res.data.response);
        }
      })
      .catch((err) => {
      console.log(err);
      })
      .finally(() => {
          setLoader(false);
        })

    }
  }

  const clickBtnUpdateProfileData = () => {
    const msg = newPassword +  ' ' + confirmNewPassword;
    alert(msg);
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
        data: {"password": newPassword, "password_confirmation": confirmNewPassword},
        method: "POST"
    };
    HTTP.request('user/update/password/', config)
    .then((res) => {
      if (res.data.result){
        window.location.reload();
      }else{
        console.log(res.data.response);
      }
    })
    .catch((err) => {
    console.log(err);
    })
    .finally(() => {
        setLoader(false);
      })
  }

  const [codePopup, setCodePopup] = useState(false);
  const [codeProcessDone, setCodeProcessDone] = useState(false);
  const [walletLocked, setWalletLocked] = useState(false);
  const [popupAlturaLinked, setPopupAlturaLinked] = useState(false);
  const [popupAlturaUnlinked, setPopupAlturaUnlinked] = useState(false);
  const [count, setCount] = useState(0);
  

  const [codeWallet, setCodeWallet] = useState('');
  const [codeWalletLinked, setCodeWalletLinked] = useState('');

  const checkCodeProcess = () => {
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      method: "POST"
    };

    HTTP.request('altura/status/wallet/', config)
      .then((res) => {
        console.log(res);
        console.log(res.data.wallet_locked)
        setWalletLocked(res.data.wallet_locked)

        if (res.data.result){
          setCodeProcessDone(true)
        }else{
          setCodeProcessDone(false)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const unlinkWallet = () => {
    setLoader(true)
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      method: "POST"
    };

    HTTP.request('altura/revoke/connection/', config)
      .then((res) => {
        console.log(res);
        if (res.data.result){
          setPopupAlturaUnlinked(true)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false)
      })
  }

  const linkWallet = () => {
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      data: {"code": codeWallet},
      method: "POST"
    };

    HTTP.request('altura/link/wallet/', config)
      .then((res) => {
        console.log(res);
        if (res.data.result){
          setPopupAlturaLinked(true)
        }else if (res.data.wallet_locked){
          setShowPopUpInfo(true);
          setTextPopUp("You must wait " + convertTimestamp(res.data.wallet_locked) + " to be able to link a new wallet")
        }else{
          if (res.data.response.includes("Duplicate connection")){
            setShowPopUpInfoConnections(true);
            setTextPopUp(res.data.response)
            setTextConnections(res.data.url_connections)
          }else{
            setShowPopUpInfo(true);
            setTextPopUp(res.data.response)
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleResend = async (email) => {
    if (email != null){
      var config = {
        data: {"email": email},
        method: "POST"
      };
      if (config) {
        HTTP.request('resend/confirm/', config)
          .then((res) => {
              if (res.data.result) {
                  window.location.reload();
              }
          })
          .catch((err) => {
              console.log(err);
          });
      }
    }
  }

  const fetchUser = () => {
    setLoader(true);
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      method: "GET"
    };

    HTTP.request('user/', config)
      .then((res) => {
        console.log(res);
        if (!res.data.result){
          setUserData(res.data.user)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  }

  const fetchWalletCode = () => {
    setLoader(true);
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      method: "GET"
    };

    HTTP.request('wallet/', config)
      .then((res) => {
        console.log(res);
        setCodeWalletLinked(res.data.wallet)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  }

  useEffect(() => {
    fetchWalletCode();
    fetchUser();
    checkCodeProcess();
  }, []);

  const btnClickCopy = (text) => {
    copyToClipboard(text);
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 3000);
  };

  useEffect(()=>{
    setInterval(() => {
      setCount(prevCount => prevCount + 0.5);
  }, 1000);
  }, [count])



  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} 
    sx={{
      '& .MuiTooltip-arrow': {
        color: 'pink',
      },
      '& .MuiTooltip-tooltip': {
        backgroundColor: 'pink',
      },
    }}
    arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#ffffff',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#8d0c50',
      border: '1px solid #f5eaf0',
      fontFamily: 'Poppins',
      fontSize: '13px',
      width: '250px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: '50px'
    },
  }));

  return (
    <>
    <Loader loading={loader}/>
    <ContainerMain>
      <BtnInput onClick={() => navigate('/dashboard')} style={{margin: '2% 0'}}>
        <ImgIcon src={icon_back_settings} style={{width: '15px', marginRight: '5%'}}/>
        <TextOrbitron style={{textTransform: 'none'}}>
          Back to Fleet
        </TextOrbitron>
      </BtnInput>
      <TitleMain>Settings</TitleMain>
      <Separator/>
      <SubTitle>ACCOUNT INFO</SubTitle>
      <TitleSetting>Nickname</TitleSetting>
      <TextSetting>{userData.nick_name}</TextSetting>
      <TitleSetting>E-mail address</TitleSetting>
      { userData.email && userData.email_confirmation ?
        <div>
          <IconWarning src={'/icons/check_email.svg'}/>
          <TextSetting>{userData.email ? userData.email : 'vacio'}</TextSetting>
        </div>
        :
        <TextSetting>{userData.email ? userData.email : 'vacio'}</TextSetting>
      }
      { userData.email && !userData.email_confirmation ?
        <>
        <ContainerWarning>
          <RowCenter>
            <IconWarning src={icon_danger}/>
            <ColCore>
              <TextWarning>Your email address has not been validated yet.</TextWarning>
              <TextWarning>Please check your email inbox and click the activation link or contact support for assistance.</TextWarning>
            </ColCore>
          </RowCenter>
        </ContainerWarning>
        <BtnInput onClick={() => handleResend(userData.email ? userData.email : null)} style={{ marginTop: '10px', width: '250px'}}>
          <TextOrbitron style={{textTransform: 'none'}}>
          Resend Activation Link
          </TextOrbitron>
        </BtnInput>
        </>
        :
        null
      }

      <Separator/>
      <SubTitle>WALLET LINKING</SubTitle>

      <ContainerValidationsMain>
        <ContainerValidations>
          <SubElementValidations>
            <ContainerSectionMain style={{height: "100%"}}>
              { codeProcessDone ? 
                <>
                <Header style={{height: "50px"}}>
                  <ContainerSection>
                    <IconOff src={icon_link_wallet_on}/>
                    <TextPoppinsCustom1>
                      ALTURA WALLET:
                    </TextPoppinsCustom1>
                  </ContainerSection>
                  <TextPoppinsCustom2>
                    LINKED
                  </TextPoppinsCustom2>
                </Header>
                <RowSpaceBetween style={{gap: '10px', alignItems: 'center'}}>
                  <RowCore style={{width: '100%'}}>
                    <BootstrapTooltip title={'Copied to clipboard'} placement='bottom' open={tooltipOpen} disableHoverListener>
                      <ImgIcon src={icon_file_copy} style={{width: '35px', height: '35px', cursor: 'pointer'}} onClick={() => btnClickCopy(codeWalletLinked)}/>
                    </BootstrapTooltip>
                    <InputFormCodeWallet style={{fontSize: xs ? '11px' : ''}} className='input-custom' name='code_wallet' placeholder='Insert your Altura code here' type='text' value={codeWalletLinked} readOnly/>
                  </RowCore>
                  { !xs ?
                    <ContainerBtn>
                      <BtnInput onClick={() => unlinkWallet()} style={{width: "100%"}}>
                        <TextOrbitron>
                          Unlink
                        </TextOrbitron>
                      </BtnInput>
                    </ContainerBtn>
                    : null
                  }
                </RowSpaceBetween>
                { xs ?
                  <RowCore style={{padding: '0px 10px', marginTop: '3%', justifyContent: 'center'}}>
                    <ContainerBtn>
                      <BtnInput onClick={() => unlinkWallet()} style={{width: "100%"}}>
                        <TextOrbitron>
                          Unlink
                        </TextOrbitron>
                      </BtnInput>
                    </ContainerBtn>
                  </RowCore>
                  : null
                }
                </>
                :
                <>
                <Header style={{height: "50px"}}>
                  <ContainerSection>
                    <IconOff src={icon_link_wallet_off}/>
                    <TextPoppinsCustom1>
                      ALTURA WALLET:
                    </TextPoppinsCustom1>
                  </ContainerSection>
                  <TextPoppinsCustom2 style={{color: '#FF0000'}}>
                    UNLINKED
                  </TextPoppinsCustom2>
                  <TextPoppinsCustom8>
                    { walletLocked ?
                      <>
                        <div>
                        WALLET AVAILABLE IN:
                        </div>
                        <div style={{color: '#FF0000'}}>
                        {convertTimestamp(walletLocked, count)}
                        </div>
                      </>
                      :
                      null
                    }
                  </TextPoppinsCustom8>
                </Header>

                <RowDesktop style={{gap: '30px'}}>
                  <InputFormCodeWallet style={{width: '70%'}} className='input-custom' name='code_wallet' placeholder='Insert your Altura code here' type='text' value={codeWallet} onChange={handleCodeWalletChange}/>
                  <ContainerBtn>
                    <BtnInput onClick={() => linkWallet()} style={{width: "100%"}}>
                      <TextOrbitron>
                        Link Altura Wallet
                      </TextOrbitron>
                    </BtnInput>
                  </ContainerBtn>
                </RowDesktop>

                <ColCellphone style={{padding: '0px 10px'}}>
                  <InputFormCodeWallet style={{width: '100%', fontSize: '10px'}} className='input-custom' name='code_wallet' placeholder='Insert your Altura code here' type='text' value={codeWallet} onChange={handleCodeWalletChange}/>
                  <RowCore style={{justifyContent: 'center', marginTop: '5%'}}>
                    <ContainerBtn style={{width: '100%'}}>
                      <BtnInput onClick={() => linkWallet()} style={{width: "100%"}}>
                        <TextOrbitron>
                          Link Altura Wallet
                        </TextOrbitron>
                      </BtnInput>
                    </ContainerBtn>
                  </RowCore>
                </ColCellphone>
                </>
              }
            </ContainerSectionMain>
          </SubElementValidations>
        </ContainerValidations>
        {/* <ColCore style={{width: '100%',  marginTop: '10px'}}>
          <ContainerTextHelp>
              <RowCore style={{marginLeft: '2%', borderLeft: '5px solid #E71383', alignItems: 'center'}}>
                  <ImgIcon src={help_icon_pink} style={{width: '15px', height: '15px', marginLeft: '1%'}}/>
                  <TextTitiliumWeb style={{fontWeight: '400', letterSpacing: '0px', fontSize: '12px', lineHeight: '19px', textAlign: 'left', alignItems: 'center', display: 'flex'}}>
                    You need an active Collateral Lock to earn CROWN from your collected Aurum, you can lock it every week during the first 24h only if you are going to participate.
                  </TextTitiliumWeb>
              </RowCore>
          </ContainerTextHelp>
        </ColCore> */}
        { !codeProcessDone ?
          <ColCore style={{width: '100%',  marginTop: '10px'}}>
            <ContainerTextHelp>
                <RowCore style={{marginLeft: '2%', borderLeft: '5px solid #E71383', alignItems: 'center', width: '95%'}}>
                    <ImgIcon src={help_icon_pink} style={{width: '15px', height: '15px', marginLeft: '1%'}}/>
                    <ColCore>
                      <TextTitiliumWeb style={{fontWeight: '400', fontSize: '12px', lineHeight: '19px', textAlign: 'left'}}>
                      To register with Altura, you can either use your cryptocurrency wallet or opt for social login. To do this, head to the Player Marketplace at <a style={{color: '#13CBE7', textDecoration: 'underline'}} href='https://marketplace.spacemisfits.com/'>https://marketplace.spacemisfits.com/</a> and follow <a style={{color: '#13CBE7', textDecoration: 'underline'}} href=' https://space-misfits.gitbook.io/space-misfits-fleet-guides/fleet-guides/'>these guides</a> if you need. Once you have linked your Altura code through your settings, Space Misfits will be able to identify your NFT collectibles such as Syndicate Memberships, Commanders, and other assets.
                      </TextTitiliumWeb>
                    </ColCore>
                </RowCore>
            </ContainerTextHelp>
          </ColCore>
          :
          null
        }
      </ContainerValidationsMain>
      <Separator/>
      <SubTitle>SOCIAL MEDIA</SubTitle>
      <TitleSetting>Twitter</TitleSetting>
      <RowInput>
        <InputForm className='input-custom' name='twitter_username' type='text' value={userData.twitter_username} onChange={handleChange}/>
        <BtnInput onClick={() => clickBtnRefresh()}>
          <TextOrbitron>
            Refresh
          </TextOrbitron>
        </BtnInput>
      </RowInput>
      <TitleSetting>Discord</TitleSetting>
      <RowInput>
        <InputForm className='input-custom' name='discord_tag' value={userData.discord_tag} onChange={handleChange} readOnly/>
        <BtnInput onClick={() => clickBtnLink(userData.discord_value_status, userData.discord_auth_url)}>
          <TextOrbitron>
            {userData.discord_value_update}
          </TextOrbitron>
        </BtnInput>
      </RowInput>
      {!userData.discord_value_status ?
      <TextWarning>Your Discord is now unlinked, you can link it by pressing “Link” button.</TextWarning>
      :
      null
      }
      <Separator/>
      { false ? 
      <>
      <SubTitle>2FA</SubTitle>
      <RowMain2FA>
          <Row2FA>
            <BtnInput>
              <TextOrbitron>
                Activate
              </TextOrbitron>
            </BtnInput>
            <ContainerWarning>
              <RowCenter>
                <IconWarning src={icon_danger}/>
                <ColCore>
                  <TextWarning>We recommend enabling this feature for added security.</TextWarning>
                </ColCore>
              </RowCenter>
            </ContainerWarning>
          </Row2FA>
      </RowMain2FA>
      <Separator/>
      <SubTitle>NOTIFICATIONS</SubTitle>
      <RowDisableNotifications>
        <TextSetting>Disable notifications</TextSetting>
        <Switch
          checked={isChecked}
          onChange={handleSwitchChange}
          onColor="#E71383" // Color del switch cuando está activado
          offColor="#5a5a5c" // Color del switch cuando está desactivado
          onHandleColor="#FFFFFF" // Color del botón del switch cuando está activado
          offHandleColor="#FFFFFF" // Color del botón del switch cuando está desactivado
          checkedIcon={false}
          uncheckedIcon={false}
          height={20} // Altura del switch
          width={35} // Ancho del switch
          activeBoxShadow="0 0 2px 3px #007AFF80" // Sombra del switch cuando está activado
        />
      </RowDisableNotifications>
      <Separator/>
      <SubTitle>PROFILE DATA</SubTitle>
      <TextSetting style={{marginTop: '2%'}}>Update Password</TextSetting>
      <TitleSetting>New password</TitleSetting>
      <RowInput>
        <InputForm className='input-custom' type='password' value={newPassword} onChange={handleNewPasswordChange}/>
      </RowInput>
      <TitleSetting>Confirm new password</TitleSetting>
      <RowInput>
        <InputForm className='input-custom' type='password' value={confirmNewPassword} onChange={handleConfirmNewPasswordChange}/>
      </RowInput>
      <BtnUpdateProfileData onClick={() => clickBtnUpdateProfileData()}>
        <TextOrbitron>
          Update Profile Data
        </TextOrbitron>
      </BtnUpdateProfileData>
      <Separator/>
      </>
      :
      null
      }
    </ContainerMain>

    <PopUp show={codePopup} onClose={() => setCodePopup(false)} onConfirm={() => linkWallet()}>
      <ContainerQrPopup>
      <TextPoppinsCustom3>
        <span>Set your Altura Guard Code</span>
        <br />
        <span>You can find this code in this URL </span>
        <br />
        <span style={{textDecoration: 'underline'}}>https://app.alturanft.com/user/YOUR_WALLET/alturaguard</span>
      </TextPoppinsCustom3>

        <div style={{justifyContent: 'center'}}>
          <InputForm style={{width: '90%'}} className='input-custom' type='text' value={codeWallet} onChange={handleCodeWalletChange}/>
        </div>
      </ContainerQrPopup>
    </PopUp>

    <PopUp show={popupAlturaLinked} onClose={() => window.location.reload()} onlyConfirm>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
          You successfully linked your Altura Wallet.
        </TextPoppins>
    </PopUp>

    <PopUp show={popupAlturaUnlinked} onClose={() => window.location.reload()} onlyConfirm>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
          You successfully unlinked your Altura Wallet.
        </TextPoppins>
    </PopUp>
    <PopUp show={showPopUpInfo} onClose={() => setShowPopUpInfo(false)} onlyConfirm={true}>
      <TextPoppins style={{letterSpacing: '0'}}>
        Error!
        <br />
        {textPopUp}
      </TextPoppins>
    </PopUp>
    <PopUp show={showPopUpInfoConnections} onClose={() => setShowPopUpInfoConnections(false)} onlyConfirm={true}>
      <TextPoppins style={{letterSpacing: '0'}}>
        Error!
        <br />
        {textPopUp}
        <br />
        <TextPoppins style={{color: '#13CBE7'}}>{textConnections}</TextPoppins>
      </TextPoppins>
    </PopUp>
    </>
  )
}