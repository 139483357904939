import { useEffect, useState } from "react";

import "../../styles/core.css";

import {
  TdData,
  TrData,
  ThData,
  TdDataFoot,
  TextV1,
  FleetData,
  IconFleet,
  TableBodyMain,
  TableHeaderMain,
  TableFootMain,
  ContainerTable,
  TableBase,
  TdDataSpecs,
  TrDataCellphone,
  TrDataDesktop,
} from "./TableMyFleetElement";
import GridSpecs from "../../hooks/GridSpecs";

export default function TableMyFleet(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  let amount = 0;
  let cargo = 0;
  let shield = 0;
  let armor = 0;
  let damage = 0;

  return (
    <>
      <ContainerTable>
        <TableBase className="table-main">
          <TableHeaderMain>
            {
              <>
                <TrDataDesktop>
                  {props.cols.map((column) => (
                    <ThData style={{ width: column.width }}>
                      {<div>{column.Header}</div>}
                    </ThData>
                  ))}
                </TrDataDesktop>
                <TrDataCellphone>
                  {props.cols.map((column, index) => {
                    if (index !== 2) {
                      return (
                        <ThData style={{ width: column.width }}>
                          {<div>{column.Header}</div>}
                        </ThData>
                      );
                    }
                    return null;
                  })}
                </TrDataCellphone>
              </>
            }
          </TableHeaderMain>
          <TableBodyMain>
            {Object.entries(data).map(([index, objects]) => {
              if (objects.length > 0) {
                amount += objects.length;
                cargo += objects[0].load * objects.length;
                shield += objects[0].shield * objects.length;
                armor += objects[0].armor * objects.length;
                damage += objects[0].damage * objects.length;

                return (
                  <>
                    <TrData id={objects[0].id + "_row"}>
                      <TdData>
                        <FleetData>
                          <IconFleet src={objects[0].image} />
                          <TextV1>{objects[0].name}</TextV1>
                        </FleetData>
                      </TdData>
                      <TdData>{objects.length}</TdData>
                      <TdDataSpecs>
                        <GridSpecs
                          specs={objects[0].specs}
                          columns={4}
                          spaceBetweenColumns={"4px"}
                        />
                      </TdDataSpecs>
                      <TdData>
                        {objects[0].load > 0
                          ? objects[0].load * objects.length
                          : objects[0].load}
                      </TdData>
                      <TdData>
                        {objects[0].shield > 0
                          ? objects[0].shield * objects.length
                          : objects[0].shield}
                      </TdData>
                      <TdData>
                        {objects[0].armor > 0
                          ? objects[0].armor * objects.length
                          : objects[0].armor}
                      </TdData>
                      <TdData>
                        {objects[0].damage > 0
                          ? objects[0].damage * objects.length
                          : objects[0].damage}
                      </TdData>
                    </TrData>
                  </>
                );
              } else {
                return null;
              }
            })}
          </TableBodyMain>
        </TableBase>
      </ContainerTable>
      <TableFootMain style={{gridTemplateColumns:props.gridTemplateColumns}} >
        <TdDataFoot colSpan={2} >
          <strong>Totals</strong>
        </TdDataFoot>
        <TdDataFoot colSpan={2} >
          <strong> {amount} </strong>
        </TdDataFoot>
        <TdDataFoot >
          <strong> {cargo} </strong>
        </TdDataFoot>
        <TdDataFoot>
          <strong> {shield} </strong>
        </TdDataFoot>
        <TdDataFoot>
          <strong> {armor} </strong>
        </TdDataFoot>
        <TdDataFoot >
          <strong> {damage} </strong>
        </TdDataFoot>
      </TableFootMain>
    </>
  );
}
