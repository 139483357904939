import React from 'react'

import {NavbarHomeComponent} from '../Header/NavbarSecondary/NavbarHome'

import {
  Routes,
  Route
} from "react-router-dom";

import { Missions } from './Pages/Missions/Missions';
import { MissionsActive } from './Pages/MissionsActive/MissionsActive';
import { Commander } from './Pages/Commander/Commander';
import { Outposts } from './Pages/Outposts/Outposts';
import { Dashboard } from './Pages/Dashboard/Dashboard';
import { Messages } from './Pages/Messages/Messages';
import { MissionsSetup } from './Pages/MissionsSetup/MissionsSetup';
import { Facilities } from './Pages/Facilities/Facilities';
import { Shipyard } from './Pages/Shipyard/Shipyard';
import { 
  ContainerHome,
  ContainerHeader,
  ContainerMain,
} from './HomeElement';
import { Leaderboard } from './Pages/Leaderboard/Leaderboard';
import ExportImport from './Pages/Export-Import/ExportImport';
import BottomNav from '../BottomNav/BottomNav';


export function Home() {

  return (
    <>
    <BottomNav/>
    <ContainerHome>
      <ContainerHeader>
            <NavbarHomeComponent />
      </ContainerHeader>
      <ContainerMain>
        <Routes>
          <Route path="dashboard" element={<Dashboard/>}/>
          <Route path="commanders" element={<Commander/>}>
            <Route path="crew"/>
            <Route path="clone-lab">
              <Route path="clone"/>
              <Route path="pick-up"/>
            </Route>
            <Route path="med-bay">
              <Route path="injured"/>
              <Route path="healing"/>
              <Route path="pick-up"/>
            </Route>
          </Route>
          <Route path="commanders" element={<Commander/>}/>
          <Route path="outposts" element={<Outposts/>}/>
          <Route path="routes" element={<Missions/>}>
            <Route path="expeditions"/>
            <Route path="mining"/>
          </Route>
          <Route path="routes/active" element={<MissionsActive/>}/>
          <Route path="routes/setup/:id" element={<MissionsSetup/>}/>
          <Route path="messages" element={<Messages/>}>
            <Route path="all"/>
            <Route path="routes"/>
            <Route path="system"/>
            <Route path="favorites"/>
            <Route path="others"/>
            <Route path="trash"/>
          </Route>
          <Route path="leaderboard" element={<Leaderboard/>}>
            <Route path="current-week"/>
            <Route path="last-week"/>
          </Route>
          <Route path="shipyard" element={<Shipyard/>}>
            <Route path="hangar"/>
            <Route path="forge">
              <Route path="blueprints"/>
              <Route path="in-process"/>
              <Route path="pick-up"/>
            </Route>
            <Route path="repair-bay">
              <Route path="damaged"/>
              <Route path="repairing"/>
              <Route path="pick-up"/>
            </Route>
          </Route>
          <Route path="facilities" element={<Facilities/>}/>
          <Route path="altura" element={<ExportImport/>}>
            <Route path="export"/>
            <Route path="import"/>
          </Route>
        </Routes>
      </ContainerMain>
    </ContainerHome>
    {/* <ResponsiveAlert>
      <ContainerAlert>
        <RowCore style={{borderLeft: '5px solid #E71383', alignItems: 'center'}}>
          <ImgIcon src={help_icon_pink} style={{width: '15px', height: '15px', marginLeft: '3%'}}/>
          <TextPoppins style={{fontSize: '11px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%'}}>
            Fleet Alpha v1 is not optimized for mobile/tablet devices and it will not be supported until the next version following our roadmap, you can play Fleet using the desktop version. We apologize for any inconvenience caused.
          </TextPoppins>
        </RowCore>
      </ContainerAlert>
    </ResponsiveAlert> */}
    </>
  )
}