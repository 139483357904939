import React, { useState, useEffect } from "react";
import { HTTP } from '../../../../../../http/http-common';
import { useNavigate } from 'react-router-dom'

import icon_pickup_all from '../../../../../../../assets/images/icon_pickup_all.svg'
import icon_stats from '../../../../../../../assets/images/icon_stats.svg'

import { ContainerPolygon, PolygonLeft, PolygonRight, RowCore, RowSpaceBetween, TextPoppins} from "../../../../../../../containers/CoreElement";
import { BtnOptionPickUpAll, BtnSelectCard, CardCommander, ColStats, ContainerCommanders, ContainerGrid, ContainerImgProfile, ContainerOptions2, ContainerStatsProfile, GridViewCoreCommanders, ImgIconMedical2, ImgInfoProfile, RowCoreBonus, StatsBold, TextPoppinsInfo, TextPoppinsInfoBold, TextSelectCard, TextTextTitiliumWebInfo, TextTitiliumWebOptions2, TextTitleCard, TitleCardCommander, TitleStatsCard } from "../../../CommanderElement";
import GridSpecs from "../../../../../../../hooks/GridSpecs";
import { FilterForGrids } from "../../../../../../../hooks/FilterForGrids";
import { DropdownStats } from "../../../../../../../hooks/DropdownStats";
import Pagination from "../../../../../../../hooks/Pagination";
import PopUp from "../../../../../../../hooks/PopUp";
import { Loader } from "../../../../../../../hooks/Loader";
import { updateNavs } from "../../../../../../utils/utils";

export function PickUp(props){

    const [commanderHealCode, setCommanderHealCode] = useState(-1);
    const [commanders, setCommanders] = useState([]);

    const [loader, setLoader] = useState(false);

    const [popUpPickupAll, setPopUpPickupAll] = useState(false);
    const [popUpPickupNow, setPopUpPickupNow] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        updateNavs();
        fetchCommandersPickup();
    }, [])

    useEffect(() => {
        props.setActiveRoute(props.activeRoute)
    }, [props])
    

    const fetchCommanderPickUp = (healCode) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"heal_code": healCode},
            method: "POST"
        };
        HTTP.request('commander/heal/pickup/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/commanders/crew')
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoader(false);
          })
    };


    const fetchCommandersPickup= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('commander/heal/pickup/process/', config)
          .then((res) => {
            if (res.data.result){
                setCommanders(res.data.pickups)
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
    };


    const fetchCommanderPickUpAll = () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          method: "POST"
        };
        HTTP.request('commander/heal/all/pickup/', config)
          .then((res) => {
            if (res.data.result){
                navigate('/commanders/crew')
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      };

    const handlePickUpNow = (healCode) => {
        setCommanderHealCode(healCode);
        setPopUpPickupNow(true);
    }


    return(
        <>
        <Loader loading={loader}/>
        <ContainerOptions2 onClick={() => setPopUpPickupAll(true)}>
            <RowSpaceBetween>
                <BtnOptionPickUpAll>
                <ImgIconMedical2 src={icon_pickup_all}/>
                <TextTitiliumWebOptions2>PICKUP ALL</TextTitiliumWebOptions2>
                </BtnOptionPickUpAll>
            </RowSpaceBetween>
        </ContainerOptions2>

        <ContainerCommanders style={{height: commanders.length !== 0 ? '690px' : '180px'}}>
            <FilterForGrids allElements={commanders} commanders sortByType sortByClass>
                { elementsToFilter => (
                    <ContainerGrid>
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not pick up commanders">
                            { elementsToRender => (
                                <GridViewCoreCommanders>
                                    {elementsToRender.map((com, index) => (
                                    <CardCommander className='card-commander' key={com.id} >
                                        <TitleCardCommander>
                                        <TextTitleCard>
                                            {com.commander.name}
                                        </TextTitleCard>
                                        </TitleCardCommander>
                                        <ContainerImgProfile>
                                        <ImgInfoProfile src={com.commander.image}/>
                                        <ContainerPolygon>
                                            <PolygonLeft>
                                                <TextPoppinsInfo>FP:<TextPoppinsInfoBold>+{com.commander.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                                            </PolygonLeft>
                                        </ContainerPolygon>
                                        <ContainerPolygon style={{right: '0'}}>
                                            <PolygonRight>
                                                <TextTextTitiliumWebInfo>Lvl {com.commanderxuser.commander.level}</TextTextTitiliumWebInfo>
                                            </PolygonRight>
                                        </ContainerPolygon>
                                        </ContainerImgProfile>
                                        <ContainerStatsProfile>
                                        <GridSpecs specs={com.commander.specs} spaceBetweenColumns={'7px'} marginTop={'2%'}/>
                                        <ColStats>
                                            <RowCore style={{marginTop:"3px", marginBottom:"9px"}}>
                                            <TitleStatsCard>Class: <StatsBold>{com.commander.class}</StatsBold></TitleStatsCard>
                                            </RowCore>
                                            <RowCoreBonus>
                                            <TitleStatsCard>Bonus: <StatsBold>{com.commander.bonus.name}</StatsBold></TitleStatsCard>
                                            </RowCoreBonus>
                                        </ColStats>
                    
                                        </ContainerStatsProfile>
                                        <DropdownStats textButton='Stats' iconButton={icon_stats} jsonObj={com.commander.bonus}/>
                                        <BtnSelectCard className='btn-select' onClick={() => handlePickUpNow(com.heal_code)}>
                                            <TextSelectCard>Pickup Now</TextSelectCard>
                                        </BtnSelectCard>
                                    </CardCommander>
                                    ))}
                                </GridViewCoreCommanders>
                            )
                            }
                        </Pagination>
                    </ContainerGrid>
                )
                }
            </FilterForGrids>
        </ContainerCommanders>
        
        <PopUp show={popUpPickupAll} onClose={() => setPopUpPickupAll(false)} onConfirm={() => fetchCommanderPickUpAll()}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to pickup all?
            </TextPoppins>
        </PopUp>

        <PopUp show={popUpPickupNow} onClose={() => setPopUpPickupNow(false)} onConfirm={() => fetchCommanderPickUp(commanderHealCode)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to pickup now?
            </TextPoppins>
        </PopUp>
        </>
    );
}