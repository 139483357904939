import React, { useEffect, useState } from "react";

import { calculateSimilarity } from '../components/utils/utils';

import { 
    ContainerVoid,
    RowSpaceBetween,
    RowCoreCenter,
    ImgIcon,
    RowCore
} from "../containers/CoreElement";

import icon_clear from '../assets/images/icon_clear.svg';
import icon_inventory from '../assets/images/inventory_icon.svg';
import icon_active_ship from '../assets/images/icon_active_ship.svg';
import icon_damaged_ship from '../assets/images/icon_damaged_ship.svg';
import icon_light_fighter from '../assets/images/icon_light_fighter.svg';
import icon_miner from '../assets/images/icon_miner.svg';
import icon_nft_ownership from '../assets/images/icon_nft_ownership.svg';
import icon_clone from '../assets/images/icon_clone.svg';
import icon_mineral from '../assets/images/icon_type_mineral.svg';
import icon_ship from '../assets/images//icon_type_ship.svg';

import icon_status from '../assets/images/icon_status.svg';
import icon_search from '../assets/images/icon_search.svg';
import { TextTitiliumWeb } from "../containers/CoreElement";
import { DropdownItem, DropdownMenu, DropdownToggle, GridFilters, InputSearch, RowSpacebetweenDesktop, TextOption } from "./FilterForGridsElement";
import IconSvg from "./IconSvg";

import Dropdown from 'react-bootstrap/Dropdown';
import '../styles/core.css'



const typeOptionsExportImport = [
    {'name':'All', 'key':'all', 'icon': icon_inventory},
    {'name':'Ship', 'key':'ship', 'icon': icon_ship},
    {'name':'Mineral', 'key':'value', 'icon': icon_mineral},
    {'name':'Commander Clone', 'key':'commander', 'icon': icon_clone},
];

const statusOptionsCommanders = [
    {'name':'All', 'key':'all', 'icon': icon_inventory},
    {'name':'Active', 'key':'active', 'icon': icon_clone},
    {'name':'Injured', 'key':'is_destroyed', 'icon': icon_clone},
];

const typeOptionsCommanders = [
    {'name':'All', 'key':'all', 'icon': icon_inventory},
    {'name':'NFT Ownership', 'key':'is_nft', 'icon': icon_nft_ownership},
    {'name':'Clone', 'key':'not_nft', 'icon': icon_clone},
];

const classOptionsCommanders = [
    {'name':'All', 'key':'all', 'icon': icon_inventory},
    {'name':'Berserk', 'key':'Berserk', 'icon': '/icons/icon_cargo.svg'},
    {'name':'Cargo', 'key':'Cargo', 'icon': '/icons/icon_cargo.svg'},
    {'name':'Cargo Assault', 'key':'cargo_assault', 'icon': '/icons/icon_cargo_assault.svg'},
    {'name':'Defensive', 'key':'Defensive', 'icon': '/icons/icon_defensive.svg'},
    {'name':'Logistics', 'key':'Logistics', 'icon': '/icons/icon_logistics.svg'},
    {'name':'Tactical', 'key':'Tactical', 'icon': '/icons/icon_tactical_expertise.svg'},
    
];

const statusOptionsShips = [
    {'name':'All', 'key':'all', 'icon': icon_inventory},
    {'name':'Active', 'key':'active', 'icon': icon_active_ship},
    {'name':'Damaged', 'key':'is_destroyed', 'icon': icon_damaged_ship},
];

const typeOptionsShips = [
    {'name':'All', 'key':'all', 'icon': icon_inventory},
    {'name':'NFT Ownership', 'key':'is_nft', 'icon': icon_nft_ownership},
];

const classOptionsShips = [
    {'name':'All', 'key':'all', 'icon': icon_inventory},
    {'name':'Light Fighter', 'key':'Light Fighter', 'icon': icon_light_fighter},
    {'name':'Miner', 'key':'Miner', 'icon': icon_miner},
    {'name':'Entry', 'key':'Entry', 'icon': icon_miner},
];

export function FilterForGrids({children, allElements=[], commanders=false, exportItems=false, importItems=false, sortByStatus=false, sortByType=false, sortByClass=false}){


    const countTrueParameters = (sortByStatus, sortByType, sortByClass) => {
        const trueParams = [sortByStatus, sortByType, sortByClass].reduce((count, param) => {
            return count + (param ? 1 : 0);
        }, 0);
        
        return trueParams;
    }

    const [inputSearch, setInputSearch] = useState('');
    const [searchResults, setSearchResults] = useState(allElements);

    const [statusOptions, setStatusOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);

    const [statusSelected, setStatusSelected] = useState('all');
    const [typeSelected, setTypeSelected] = useState('all');
    const [classSelected, setClassSelected] = useState('all');

    const [statusNameSelected, setStatusNameSelected] = useState('All');
    const [typeNameSelected, setTypeNameSelected] = useState('All');
    const [classNameSelected, setClassNameSelected] = useState('All');
    
    const all = allElements;
    const countParameters = countTrueParameters(sortByStatus, sortByType, sortByClass);

    const handleInputSearch = (event) => {
        const newValue = event.target.value;
        console.log(newValue);
        setInputSearch(newValue);
    }

    const handleBtnClear = () => {
        setStatusSelected('all')
        setTypeSelected('all')
        setClassSelected('all')
        setStatusNameSelected('All')
        setTypeNameSelected('All')
        setClassNameSelected('All')
        setInputSearch('')

        if(exportItems){
            const groupedData = {};
            all.forEach(item => {
                const id = item.hasOwnProperty('commander') ? item.commander.id : item.id;
                if(id !== undefined){
                    if (!groupedData[id]) {
                        groupedData[id] = [];
                    }
                    
                    groupedData[id].push(item);
                }else{
                    const name = item.name;
                    if (!groupedData[name]) {
                        groupedData[name] = [];
                    }

                    groupedData[name].push(item);
                }
            });
            
            const groupedArray = Object.values(groupedData);
    
            const results = groupedArray.filter((item) => {
                let name = item[0].hasOwnProperty('commander') ? item[0].commander.name : item[0].name;
                const similarity = calculateSimilarity(inputSearch.toLowerCase(), name.toLowerCase());
                const similarityThreshold = 0.49;
                return similarity >= similarityThreshold || name.toLowerCase().includes(inputSearch.toLowerCase());
            });
            setSearchResults(results);
        }else{
            setSearchResults(all);
        }
    }

    const searchItems = () => {
        
        const results = allElements.filter((item) => {
            let name = commanders ? item.commander?.name || item.commanderxuser?.commander?.name : item.name;
            if(!name){
                name = item.name ?? item.ship?.name ?? item.shipxuser?.ship?.name;
            }
            const similarity = calculateSimilarity(inputSearch.toLowerCase(), name.toLowerCase());
            const similarityThreshold = 0.49;
            return similarity >= similarityThreshold || name.toLowerCase().includes(inputSearch.toLowerCase());
        });
        setSearchResults(results);
    }

    const searchItemsEI = () => {
        const groupedData = {};
        allElements.forEach(item => {
            if(item.hasOwnProperty('specs')){

                const id = item.hasOwnProperty('commander') ? item.commander.id : item.id;
    
                if (!groupedData[id]) {
                    groupedData[id] = [];
                }
                
                groupedData[id].push(item);
            }else{
                const name = item.name;
    
                if (!groupedData[name]) {
                    groupedData[name] = [];
                }
                
                groupedData[name].push(item);
            }

        });

        const groupedArray = Object.values(groupedData);

        const results = groupedArray.filter((item) => {
            let name = item[0].hasOwnProperty('commander') ? item[0].commander.name : item[0].name;
            const similarity = calculateSimilarity(inputSearch.toLowerCase(), name.toLowerCase());
            const similarityThreshold = 0.49;
            return similarity >= similarityThreshold || name.toLowerCase().includes(inputSearch.toLowerCase());
        });
        setSearchResults(results);
    }

    useEffect(() => {
        
        if(commanders){
            setStatusOptions(statusOptionsCommanders)
            setTypeOptions(typeOptionsCommanders)
            setClassOptions(classOptionsCommanders)
        }else{
            setStatusOptions(statusOptionsShips)
            setTypeOptions(typeOptionsShips)
            setClassOptions(classOptionsShips)
        }
        if(exportItems){
            searchItemsEI();
            setTypeOptions(typeOptionsExportImport);
        }else{
            searchItems();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputSearch, allElements, commanders])

    
    const filterByStatus = (option) => {

        const key = option.key

        setStatusNameSelected(option.name)
        setStatusSelected(key);
        
        const filteredByStatus = key !== 'all'
            ? allElements.filter((item) => commanders ? (item[key] || item.commander[key]) : item[key])
            : allElements;
        
        const filteredByType = typeSelected !== 'all'
            ? filteredByStatus.filter((item) => commanders ? (item['type'] === typeSelected || item.commander['type'] === typeSelected) : item.specs.includes(typeSelected))
            : filteredByStatus;
        
        const filteredByClass = classSelected !== 'all'
            ? filteredByType.filter((item) => commanders ? (item.commanderxuser?.commander?.class === classSelected || item.commander?.class === classSelected) : (item?.class || item.ship?.class || item.shipxuser.ship.class) === classSelected)
            : filteredByType;
        
        setSearchResults(filteredByClass);
    };

    const filterByType = (option) => {

        const key = option.key

        setTypeNameSelected(option.name)
        setTypeSelected(key);

        if(!exportItems){
            const filteredByType = key !== 'all'
            ? allElements.filter((item) => commanders ? (item['specs']?.includes(key) || item.commander['specs']?.includes(key)) : item.specs.includes(key))
            : allElements;
        
            const filteredByStatus = statusSelected !== 'all'
                ? filteredByType.filter((item) => commanders ? (item[statusSelected] || item.commander[statusSelected]) : item[statusSelected])
                : filteredByType;
            
            const filteredByClass = classSelected !== 'all'
                ? filteredByStatus.filter((item) => commanders ? (item.commanderxuser?.commander?.class === classSelected || item.commander?.class === classSelected) : (item?.class || item.ship?.class || item.shipxuser.ship.class) === classSelected)
                : filteredByStatus;
            
            setSearchResults(filteredByClass);
        }else{
            const filteredByType = key !== 'all'
            ? allElements.filter((item) => (item.hasOwnProperty('commander') || item.hasOwnProperty('value')) ? item.hasOwnProperty(key) : item.type === key)
            : allElements;

            const groupedData = {};
            console.log(filteredByType);
            filteredByType.forEach(item => {
                const id = item.hasOwnProperty('commander') ? item.commander.id : item.id;
                console.log(id);

                if(id !== undefined){
                    if (!groupedData[id]) {
                        groupedData[id] = [];
                    }
                    
                    groupedData[id].push(item);
                }else{
                    const name = item.name;
                    if (!groupedData[name]) {
                        groupedData[name] = [];
                    }

                    groupedData[name].push(item);
                }
            });

            console.log(groupedData);
            const groupedArray = Object.values(groupedData);

            console.log(groupedArray);
            setSearchResults(groupedArray);
        }
        
    
    };
      
    const filterByClass = (option) => {

        const key = option.key

        setClassNameSelected(option.name)
        setClassSelected(key);
        
        const filteredByClass = key !== 'all'
            ? allElements.filter((item) => commanders ? (item.commanderxuser?.commander?.class === key || item.commander?.class === key) : (item?.class || item.ship?.class || item.shipxuser.ship.class) === key) 
            : allElements;
        
        const filteredByStatus = statusSelected !== 'all'
            ? filteredByClass.filter((item) => commanders ? (item[statusSelected] || item.commander[statusSelected]) : item[statusSelected])
            : filteredByClass;
        
        const filteredByType = typeSelected !== 'all'
            ? filteredByStatus.filter((item) => commanders ? (item['specs']?.includes(typeSelected) || item.commander['specs']?.includes(typeSelected)) : item.specs.includes(typeSelected))
            : filteredByStatus;
        
        setSearchResults(filteredByType);
    };
      

    return(
        <>
            <ContainerVoid style={{margin: '1% 15px'}}>
                <RowSpacebetweenDesktop style={{color: 'black', gap: '2.5%'}}>
                    <div style={{display: 'flex', alignItems: 'center', border: '1.5px solid #5E0835', borderRadius: '1px', padding: '2px', width: countParameters === 3 ? '22.5%' : countParameters === 2 ? '30%' : '60%'}}>
                        <InputSearch type="text" placeholder="Search here..." value={inputSearch} onChange={handleInputSearch} style={{width: countParameters === 3 ? '22.5%' : countParameters === 2 ? '30%' : '60%'}}/>
                        <div style={{width: '20px', marginRight: '1%'}}>
                            <ImgIcon src={icon_search}/>
                        </div>
                    </div>
                    { sortByStatus ?
                        <Dropdown style={{width: countParameters === 3 ? '22.5%' : '30%'}}>
                            <DropdownToggle variant="success" id="dropdown-basic">
                                <RowSpaceBetween>
                                    <RowCoreCenter style={{padding: '0 2% 0 2%', width: '90%'}}>
                                        <IconSvg icon={icon_status} color={'white'}/>
                                        <TextTitiliumWeb style={{fontWeight: '400', lineHeight: '20px', marginLeft: '5%'}}>Status <span style={{color: '#E71383'}}>({statusNameSelected})</span></TextTitiliumWeb>
                                    </RowCoreCenter>
                                    <RowCoreCenter style={{width: '10%', justifyContent: 'flex-end', marginRight: '2%'}}>
                                        <IconSvg icon={'/icons/icon_type_arrow_navbar.svg'} color={'white'} width={'15px'}/>
                                    </RowCoreCenter>
                                </RowSpaceBetween>
                            </DropdownToggle>

                            <DropdownMenu>
                                { statusOptions.map((option, index) => (
                                    <DropdownItem onClick={() => filterByStatus(option)} style={{backgroundColor: statusSelected === option.key ? '#E71383' : 'transparent'}}>
                                        <RowCoreCenter>
                                            <IconSvg icon={option.icon} color={'#ffffff'}/>
                                            <TextOption>{option.name}</TextOption>
                                        </RowCoreCenter>
                                    </DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                        :
                        null
                    }
                    { sortByType ?
                        <Dropdown style={{width: countParameters === 3 ? '22.5%' : '30%'}}>
                            <DropdownToggle variant="success" id="dropdown-basic">
                                <RowSpaceBetween>
                                    <RowCoreCenter style={{padding: '0 2% 0 2%', width: '90%'}}>
                                        <IconSvg icon={icon_inventory} color={'white'}/>
                                        <TextTitiliumWeb style={{fontWeight: '400', lineHeight: '20px', marginLeft: '5%'}}>Type <span style={{color: '#E71383'}}>({typeNameSelected})</span></TextTitiliumWeb>
                                    </RowCoreCenter>
                                    <RowCoreCenter style={{width: '10%', justifyContent: 'flex-end', marginRight: '2%'}}>
                                        <IconSvg icon={'/icons/icon_type_arrow_navbar.svg'} color={'white'} width={'15px'}/>
                                    </RowCoreCenter>
                                </RowSpaceBetween>
                            </DropdownToggle>

                            <DropdownMenu>
                                { typeOptions.map((option, index) => (
                                    <DropdownItem onClick={() => filterByType(option)} style={{backgroundColor: typeSelected === option.key ? '#E71383' : 'transparent'}}>
                                        <RowCoreCenter>
                                            <IconSvg icon={option.icon} color={'#ffffff'}/>
                                            <TextOption>{option.name}</TextOption>
                                        </RowCoreCenter>
                                    </DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                        :
                        null
                    }
                    { sortByClass ?
                        <Dropdown style={{width: countParameters === 3 ? '22.5%' : '30%'}}>
                            <DropdownToggle variant="success" id="dropdown-basic">
                                <RowSpaceBetween>
                                    <RowCoreCenter style={{padding: '0 2% 0 2%', width: '90%'}}>
                                        <IconSvg icon={icon_inventory} color={'white'}/>
                                        <TextTitiliumWeb style={{fontWeight: '400', lineHeight: '20px', marginLeft: '5%'}}>Class{commanders ? '/bonus' : ''}<span style={{color: '#E71383'}}> ({classNameSelected})</span></TextTitiliumWeb>
                                    </RowCoreCenter>
                                    <RowCoreCenter style={{width: '10%', justifyContent: 'flex-end', marginRight: '2%'}}>
                                        <IconSvg icon={'/icons/icon_type_arrow_navbar.svg'} color={'white'} width={'15px'}/>
                                    </RowCoreCenter>
                                </RowSpaceBetween>
                            </DropdownToggle>
                            <DropdownMenu style={{width: countParameters === 3 ? '110%' : '100%'}}>
                                { classOptions.map((option, index) => (
                                    <DropdownItem onClick={() => filterByClass(option)} style={{backgroundColor: classSelected === option.key ? '#E71383' : 'transparent'}}>
                                        <RowCoreCenter>
                                            <IconSvg icon={option.icon} color={'#ffffff'}/>
                                            <TextOption>{option.name}</TextOption>
                                        </RowCoreCenter>
                                    </DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                        : 
                        null
                    }
                    <RowCoreCenter style={{width: '10%', justifyContent: 'flex-end', cursor: 'pointer'}} onClick={handleBtnClear}>
                        <ImgIcon src={icon_clear} style={{width: '20px', height: '23px', marginRight: '10%'}}/>
                        <TextTitiliumWeb style={{lineHeight: '20px', fontWeight: '400', color: 'rgba(255, 255, 255, 0.5)'}}>Clear</TextTitiliumWeb>
                    </RowCoreCenter>
                </RowSpacebetweenDesktop>
                <GridFilters>
                    <div style={{display: 'flex', alignItems: 'center', border: '1.5px solid #5E0835', borderRadius: '1px', padding: '2px'}}>
                        <InputSearch type="text" placeholder="Search" value={inputSearch} onChange={handleInputSearch} style={{width: '50%'}}/>
                        <div style={{width: '20px', marginRight: '1%'}}>
                            <ImgIcon src={icon_search}/>
                        </div>
                    </div>
                    
                    { sortByStatus ?
                        <Dropdown style={{width: '100%'}}>
                            <DropdownToggle variant="success" id="dropdown-basic">
                                <RowSpaceBetween>
                                    <RowCoreCenter style={{padding: '0 2% 0 2%', width: '90%'}}>
                                        <TextTitiliumWeb style={{fontWeight: '400', lineHeight: '20px', marginLeft: '5%', fontSize: '13px'}}>Status <span style={{color: '#E71383'}}>({statusNameSelected})</span></TextTitiliumWeb>
                                    </RowCoreCenter>
                                    <RowCoreCenter style={{width: '10%', justifyContent: 'flex-end', marginRight: '2%'}}>
                                        <IconSvg icon={'/icons/icon_type_arrow_navbar.svg'} color={'white'} width={'10px'}/>
                                    </RowCoreCenter>
                                </RowSpaceBetween>
                            </DropdownToggle>

                            <DropdownMenu>
                                { statusOptions.map((option, index) => (
                                    <DropdownItem onClick={() => filterByStatus(option)} style={{backgroundColor: statusSelected === option.key ? '#E71383' : 'transparent'}}>
                                        <RowCoreCenter>
                                            <IconSvg icon={option.icon} color={'#ffffff'}/>
                                            <TextOption>{option.name}</TextOption>
                                        </RowCoreCenter>
                                    </DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                        :
                        null
                    }
                    { sortByType ?
                        <Dropdown>
                            <DropdownToggle variant="success" id="dropdown-basic">
                                <RowSpaceBetween>
                                    <RowCoreCenter style={{padding: '0 2% 0 2%', width: '90%'}}>
                                        <TextTitiliumWeb style={{fontWeight: '400', lineHeight: '20px', marginLeft: '5%', fontSize: '13px'}}>Type <span style={{color: '#E71383'}}>({typeNameSelected})</span></TextTitiliumWeb>
                                    </RowCoreCenter>
                                    <RowCoreCenter style={{width: '10%', justifyContent: 'flex-end', marginRight: '2%'}}>
                                        <IconSvg icon={'/icons/icon_type_arrow_navbar.svg'} color={'white'} width={'10px'}/>
                                    </RowCoreCenter>
                                </RowSpaceBetween>
                            </DropdownToggle>

                            <DropdownMenu>
                                { typeOptions.map((option, index) => (
                                    <DropdownItem onClick={() => filterByType(option)} style={{backgroundColor: typeSelected === option.key ? '#E71383' : 'transparent'}}>
                                        <RowCoreCenter>
                                            <IconSvg icon={option.icon} color={'#ffffff'}/>
                                            <TextOption>{option.name}</TextOption>
                                        </RowCoreCenter>
                                    </DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                        :
                        null
                    }
                    { sortByClass ?
                        <Dropdown style={{width: '100%'}}>
                            <DropdownToggle variant="success" id="dropdown-basic">
                                <RowSpaceBetween>
                                    <RowCoreCenter style={{padding: '0 2% 0 2%', width: '90%'}}>
                                        <TextTitiliumWeb style={{fontWeight: '400', lineHeight: '20px', marginLeft: '5%', fontSize: '13px'}}>Class{commanders ? '/bonus' : ''}<span style={{color: '#E71383'}}> ({classNameSelected})</span></TextTitiliumWeb>
                                    </RowCoreCenter>
                                    <RowCoreCenter style={{width: '10%', justifyContent: 'flex-end', marginRight: '2%'}}>
                                        <IconSvg icon={'/icons/icon_type_arrow_navbar.svg'} color={'white'} width={'10px'}/>
                                    </RowCoreCenter>
                                </RowSpaceBetween>
                            </DropdownToggle>
                            <DropdownMenu style={{width: countParameters === 3 ? '110%' : '100%'}}>
                                { classOptions.map((option, index) => (
                                    <DropdownItem onClick={() => filterByClass(option)} style={{backgroundColor: classSelected === option.key ? '#E71383' : 'transparent'}}>
                                        <RowCoreCenter>
                                            <IconSvg icon={option.icon} color={'#ffffff'}/>
                                            <TextOption>{option.name}</TextOption>
                                        </RowCoreCenter>
                                    </DropdownItem>
                                ))
                                }
                            </DropdownMenu>
                        </Dropdown>
                        : 
                        null
                    }
                    <RowCore style={{justifyContent: 'center', alignItems: 'center'}} onClick={handleBtnClear}>
                        <ImgIcon src={icon_clear} style={{width: '20px', height: '23px', marginRight: '10%'}}/>
                        <TextTitiliumWeb style={{lineHeight: '20px', fontWeight: '400', color: 'rgba(255, 255, 255, 0.5)', fontSize: '13px'}}>Clear</TextTitiliumWeb>
                    </RowCore>
                </GridFilters>
            </ContainerVoid>
            {children(searchResults)}
        </>
    );
}