import { Container, TextTitiliumWeb, GridViewCoreScroll3 , GridViewCoreScroll4, TextPoppins, TextPoppinsBold, BtnPolygon, ImgIcon, ContainerInput } from '../containers/CoreElement';
import styled from "styled-components";

export const ContainerGeneral = styled(Container)`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), rgba(231, 19, 131, 0.3);
    box-shadow: none;
    box-sizing: content-box;
    display: flex;
    padding-bottom:0px;
    margin-top: 15px;
`
export const ContainerImgProfile = styled.div`
    width: 200px;
    height: auto;
    position: relative;
    display: inline-block;

    @media(max-width: 600px) {
        display: none;
    }
`
export const CardContainerImage = styled.img  `
   width: 100%;
`

export const InputSetAmount = styled.input`
    border: none;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    height: 50px;
    width: 90%;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        display: none;
        -moz-appearance: textfield;
    }
`

export const ContainerInputCardSelected = styled(ContainerInput)`
    width: 100%;
`
export const DamagedCard = styled.div `
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.4), rgba(255, 0, 0, 0.4)), url(LadyBug-01.png), url(greenColorized-pattern-01.png), linear-gradient(180deg, #531330 0%, #991558 100%);
    background-blend-mode: normal, normal, difference, normal;
    width: 100%;
    height: 98%;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`   
export const ImgRepair = styled(ImgIcon)`
    margin-right: 0%;
    width: 60%;
    height: max-content;
`   
export const ContainerPolygonLeft = styled(BtnPolygon)`
    width: 30%;
    height: 25px;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);    
    clip-path: polygon(0 100%, 0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%);
    position: absolute;
    top: 176.5px;
    left: 0px;
    /* -webkit-clip-path: polygon(); */
    /* -webkit-clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%); */
`
export const ContainerPolygonRight = styled(ContainerPolygonLeft)`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    left: auto;
    right: 0px;
    /* -webkit-clip-path: polygon(); */
    /* -webkit-clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%); */
`

export const TextPoppinsInfo = styled(TextPoppins)`
    font-size: 11px;
`
export const TextPoppinsInfoBold = styled(TextPoppinsBold)`
    font-size: 11px;
`
export const TextTextTitiliumWebInfo = styled(TextTitiliumWeb)`
    font-size: 11px;
`

export const Element = styled.div`
    padding: 27px;
    width: 100%;
`

export const Container1 = styled.div`
    width:100%;
    display: flex;
`

export const Image = styled.img `
    border: 1px solid #E61382;
    width: 100%;
    

`

export const Container1Text = styled.div `
    width:100%;
    padding: 0px 18px 0px 18px;
`

export const TextTitle = styled(TextTitiliumWeb)`
    font-weight:bold;
    display: flex;
    justify-content: center;
    font-size: 45px;
`

export const Text = styled(TextTitiliumWeb)`
    font-weight: 100;
    letter-spacing:0px;

    @media(max-width: 600px) {
        font-size: 12px;
    }
    
`

export const TextBold = styled(TextTitiliumWeb)`
    font-weight:bold;
    letter-spacing:0px;

    @media(max-width: 600px) {
        font-size: 12px;
    }
`

export const ContainerSection = styled.div`
    border: 1px solid #E61382;
    background: rgba(47, 4, 26, 0.7);
    padding: 12px;
    margin-top: 9px;
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
`

export const ContainerSectionDesktop = styled(ContainerSection)`

    @media(max-width: 600px) {
        display: none;
    }
`

export const ContainerSectionCellphone = styled(ContainerSection)`
    justify-content: center;
    flex-direction: column;
    
    @media(min-width: 601px) {
        display: none;
    }
`

export const ContainerSection1 = styled.div`
    display: flex;
    gap:30px;
    align-items: center;
`

export const ContainerSectionStat = styled(ContainerSection1)`
    gap: 10px;
`
export const ContainerSection2 = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    margin-top: 9px;

    @media(max-width: 600px) {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 10px;
    }
`

export const ContainerSection2Stats = styled.div  `
    border: 1px solid #E61382;
    background: rgba(231, 19, 131, 0.2);
    display: flex;

    padding-left: 18px;
    gap: 9px;
    align-items: center;
    padding-right: 9px;
`

export const ContainerStats = styled(GridViewCoreScroll3)`
    overflow-y: scroll;
    height: 180px;
    display: block;
    border: 1px solid #6C11DE; 
    background: #2F041A;
    mix-blend-mode: unset;
    padding: 7px;
`

export const ContainerStatsGold = styled(GridViewCoreScroll4)`
    overflow-y: scroll;
    height: 180px;
    display: block;
    border: 1px solid rgba(255, 196, 0, 1);
    background: #2F041A;
    mix-blend-mode: unset;
    padding: 7px;
`



export const ContainerSection2Element = styled.div  `
    border: 1px solid #E61382;
    background: rgba(47, 4, 26, 0.7);
    width:100%;
    display: flex;
`

export const ContainerSection2ElementIcon = styled.div  `
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ContainerSection2ElementButton = styled.button  `
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E71383;
    width: 100%;
    border: none;
    column-gap: 3px;
    cursor: pointer;
`

export const Icon = styled.img`
`

export const StatsContainer = styled.div`
    display: flex;
    width: 100%;
    gap: 9px;
`
export const ContainerLevel = styled.div `
    position: absolute;
    display: flex; 

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    width: 15%;
    position: absolute;
    bottom: 0%;
    right: 0px;

    align-items:center;
    justify-content: center;
    gap: 3px;

    border: 1px solid #E71383;
    border-bottom: 0px;
    border-right: 0px;
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
`
export const ContainerFP = styled.div `
    position: absolute;
    display: flex; 

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    width: 15%;
    position: absolute;
    bottom: 0%;
    left: 0px;

    align-items:center;
    justify-content: center;
    gap: 3px;

    border: 1px solid #E71383;
    border-bottom: 0px;
    border-right: 0px;
    clip-path: polygon(0 100%, 0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%);
`