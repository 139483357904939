import React, { useState, useEffect } from "react";

import {
    useNavigate,
  } from "react-router-dom";

import { ImgIcon, RowSpaceBetween } from "../../../../../../containers/CoreElement";
import { ContainerGeneral, ContainerOption, ContainerOptionRefresh, ContainerOptionShowAll, ContainerOptionsCellphone, ContainerOptionsDesktop, IconOption, NumberCoins, TextCoins, TextTitle, TextTitleShowAll } from "../../MissionsElement";
import IconSvg from "../../../../../../hooks/IconSvg";

import info_icon from '../../../../../../assets/images/info_icon.svg'
import icon_difficulty_easy from '../../../../../../assets/images/icon_difficulty_easy.svg'
import icon_difficulty_medium from '../../../../../../assets/images/icon_difficulty_medium.svg'
import icon_difficulty_hard from '../../../../../../assets/images/icon_difficulty_hard.svg'
import mission_expedition from '../../../../../../assets/images/mission_expedition.png'
import icon_refresh from '../../../../../../assets/images/icon_refresh.svg'
import crown_coin from '../../../../../../assets/images/crown_coin.png'
import ReusableRectangle from "../../../../../../hooks/ReusableRectangle";
import { HTTP } from "../../../../../http/http-common";
import { Loader } from "../../../../../../hooks/Loader";

export function Expeditions(props){

    const navigate = useNavigate();
    const [routeTimes, setRouteTimes] = useState([]);
    const [loader, setLoader] = useState(false);
    const [routeTimeSelected, setRouteTimeSelected] = useState(false);

    const sendRoute = (mission) => {
        mission.min_spent = routeTimeSelected.time;
        mission.route_time_code = routeTimeSelected.code;
        console.log(mission);
        navigate("/routes/setup/" + mission.id, {state: mission});
    };

    const fetchRoutesTimes = () => {
        setLoader(true);
    
        HTTP.request('routes/times/')
          .then((res) => {
            console.log(res);
            if (res.data.result){
                setRouteTimes(res.data.response);
                setRouteTimeSelected(res.data.response[0])
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
    };

    useEffect(() => {
        fetchRoutesTimes();
    }, []);

    return(
        <>
        <Loader loading={loader}/>
        <ContainerOptionsDesktop>
            <RowSpaceBetween>
            { true ?
            <>
            <ContainerOptionShowAll>
                <IconSvg icon={info_icon} color={'#ffffff'}/>
                <TextTitleShowAll>
                Show All
                </TextTitleShowAll>
            </ContainerOptionShowAll>
            <ContainerOption>
                <IconOption src={icon_difficulty_easy}/>
                <TextTitle>
                Easy
                </TextTitle>
            </ContainerOption>
            <ContainerOption>
                <IconOption src={icon_difficulty_medium}/>
                <TextTitle>
                Medium
                </TextTitle>
            </ContainerOption>
            <ContainerOption>
                <IconOption src={icon_difficulty_hard}/>
                <TextTitle>
                Hard
                </TextTitle>
            </ContainerOption>
            </>
            :
            null
            }
            <ContainerOptionRefresh onClick={props.fetchMissionsRefresh}>
                <IconOption src={icon_refresh}/>
                <TextTitle>
                REFRESH
                </TextTitle>
                <TextCoins>
                <ImgIcon src={crown_coin}/>
                <NumberCoins>{props.missionsRefreshValue}</NumberCoins>
                </TextCoins>
            </ContainerOptionRefresh>
            </RowSpaceBetween>
        </ContainerOptionsDesktop>
        <ContainerOptionsCellphone>
            
            <RowSpaceBetween>
                <ContainerOptionShowAll>
                    <IconSvg icon={info_icon} color={'#ffffff'}/>
                    <TextTitleShowAll>
                    Show All
                    </TextTitleShowAll>
                </ContainerOptionShowAll>
                <ContainerOption>
                    <IconOption src={icon_difficulty_easy}/>
                    <TextTitle>
                    Easy
                    </TextTitle>
                </ContainerOption>
            </RowSpaceBetween>
            <RowSpaceBetween>
                <ContainerOption>
                    <IconOption src={icon_difficulty_medium}/>
                    <TextTitle>
                    Medium
                    </TextTitle>
                </ContainerOption>
                <ContainerOption>
                    <IconOption src={icon_difficulty_hard}/>
                    <TextTitle>
                    Hard
                    </TextTitle>
                </ContainerOption>
            </RowSpaceBetween>
            <ContainerOptionRefresh onClick={props.fetchMissionsRefresh}>
                <IconOption src={icon_refresh}/>
                <TextTitle>
                REFRESH
                </TextTitle>
                <TextCoins>
                <ImgIcon src={crown_coin}/>
                <NumberCoins>{props.missionsRefreshValue}</NumberCoins>
                </TextCoins>
            </ContainerOptionRefresh>
        </ContainerOptionsCellphone>
        <ContainerGeneral>
            {props.missions.map((mission) => (
                <ReusableRectangle

                missions = {true}
                image = {mission_expedition}
                outpost = {mission.outpost}
                difficulty = {'Easy'}
                type = {mission.p_mission_economic.type_of_route_name}
                mission_id = {mission.id}
                specs = {mission.expected_reward}
                enemy_ships = {mission.horde}
                // min_spent = {convertTimestampMinutes(mission.min_spent*2)}
                min_spent = {routeTimes}
                value2 = {200}
                value1Second = {mission.commander_enemy_class}
                value2Second = "value2"
                value3Second = "value3"
                value4Second = "value4"
                buttonText = "Send Fleet"
                isSendFleet = {false}
                action = {() => sendRoute(mission)}
                setRouteTimeSelected = {setRouteTimeSelected}
                routeTimeSelected = {routeTimeSelected}

                height="130%"
                margin="0px 3px 54px 0px"
                >
                </ReusableRectangle>
            ))}
        </ContainerGeneral>
        </>
    );
}