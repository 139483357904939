import React, { useState, useEffect } from "react";
import { HTTP } from '../../../../../../http/http-common';
import { useNavigate } from 'react-router-dom'

import icon_medical from '../../../../../../../assets/images/icon_medical.svg'
import icon_heal_all from '../../../../../../../assets/images/icon_heal_all.svg'
import icon_heal from '../../../../../../../assets/images/icon_heal.svg'
import crown_coin from '../../../../../../../assets/images/crown_coin.png'
import icon_stats from '../../../../../../../assets/images/icon_stats.svg'
import icon_commander_damage from '../../../../../../../assets/images/icon_commander_damage.svg';
import icon_more from '../../../../../../../assets/images/icon_more.svg'

import { ContainerPolygon, PolygonLeft, PolygonRight, RowCore, RowSpaceBetween, RowSpaceBetweenButtons, TextPoppins, TextPoppinsBold } from "../../../../../../../containers/CoreElement";
import { DropDownStatsContainer, DropDownStatsRow, DropDownStatsIcon, BtnInstantHealBits, BtnInstantHealCoins, BtnOptionHealAll, BtnOptionInstantHealAll, BtnSelectCard, CardCommander, ColInfo, ColStats, ContainerCommanders, ContainerGrid, ContainerIconMedical, ContainerImgInfoProfile, ContainerImgProfile, ContainerInfoCommanders, ContainerInfoStats, ContainerOptions2, ContainerStats, ContainerStatsProfile, ContainerTextIconButton, DamagedCard, DescriptionInfoCommanders, GridViewCoreCommanders, ImgCoin, ImgIconMedical2, ImgIconStats, ImgInfoProfile, ImgMedical, ImgRepair, NumberCoins, Row, RowCoreBonus, RowCoreCenter, RowRevive, RowSpaceBetweenCenter, StatsBold, TextCoins, TextPoppinsInfo, TextPoppinsInfoBold, TextRevive, TextSelectCard, TextTextTitiliumWebInfo, TextTitiliumWebOptions2, TextTitleCard, TitleCardCommander, TitleInfoCommanders, TitleStats, TitleStatsCard } from "../../../CommanderElement";
import GridSpecs from "../../../../../../../hooks/GridSpecs";
import { FilterForGrids } from "../../../../../../../hooks/FilterForGrids";
import { DropdownStats } from "../../../../../../../hooks/DropdownStats";
import Pagination from "../../../../../../../hooks/Pagination";
import PopUp from "../../../../../../../hooks/PopUp";
import { Loader } from "../../../../../../../hooks/Loader";
import { updateNavs } from "../../../../../../utils/utils";

export function Injured(props){

    const [commander, setCommander] = useState({});
    const [commanders, setCommanders] = useState([]);
    const [valueHealAll, setValueHealAll] = useState(0);
    const [valueInstantHealAll, setValueInstantHealAll] = useState(0);

    const [loader, setLoader] = useState(false);

    const [popUpHealAll, setPopUpHealAll] = useState(false);
    const [popUpInstantHealAll, setPopUpInstantHealAll] = useState(false);
    const [popUpHeal, setPopUpHeal] = useState(false);
    const [popUpInstantHeal, setPopUpInstantHeal] = useState(false);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');

    const [, setIndex] = useState(-1);
    
    const [statsDropDown, setStatsDropDown] = useState(false);

    let navigate = useNavigate();

    useEffect(() => {
        updateNavs();
        fetchCommanderhHealingsAllValues();
        fetchCommandersInjured();
    }, [])

    useEffect(() => {
        props.setActiveRoute(props.activeRoute);
    }, [props])
    
    const btnClickSelected = (coman, index) => {
        setCommander(coman);
        console.log(index, coman);
        setIndex(index);
        props.extendedHeader(false);
    }

    const healCommander = (commanderId, instantHeal) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"commander_id": commanderId, "instant_healing": instantHeal},
            method: "POST"
        };
        HTTP.request('commander/heal/', config)
        .then((res) => {
            if (res.data.result){
                props.extendedHeader(true);
                if (instantHeal){
                    navigate('/commanders/med-bay/pick-up')
                }else{
                    navigate('/commanders/med-bay/healing')
                }
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
  
    }

    const healAllCommander = (instantHeal) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"instant_healing": instantHeal},
            method: "POST"
        };
        HTTP.request('commander/heal/all/', config)
        .then((res) => {
            if (res.data.result){
                props.extendedHeader(true);
                if (instantHeal){
                    navigate('/commanders/med-bay/pick-up')
                }else{
                    navigate('/commanders/med-bay/healing')
                }
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
  
    }

    const fetchCommandersInjured= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('commander/injured/', config)
          .then((res) => {
            if (res.data.result){
                setCommanders(res.data.commanders)
            }
          })
          .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
          })
          .finally(() => {
            setLoader(false);
          })
      };

    const fetchCommanderhHealingsAllValues= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('commander/heal/all/values/', config)
          .then((res) => {
            if (res.data.result){
              setValueHealAll(res.data.values["cost_heal_bits"]);
              setValueInstantHealAll(res.data.values["cost_heal_crowns"]);
            }
          })
          .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
          })
          .finally(() => {
            setLoader(false);
          })
    };

    const handleDropDownStats = () => {
        setStatsDropDown(!statsDropDown);
    }

    return(
        <>
        <Loader loading={loader}/>
        {valueHealAll > 0 && valueInstantHealAll > 0 ?
            <ContainerOptions2>
                <RowSpaceBetween>
                    <BtnOptionHealAll onClick={() => setPopUpHealAll(true)}>
                        <ImgIconMedical2 src={icon_medical}/>
                        <TextTitiliumWebOptions2>HEAL ALL</TextTitiliumWebOptions2>
                        <TextCoins>
                            <ImgCoin src='/icons/icon_type_blue_coin_inventory.svg' />
                            <NumberCoins>{valueHealAll}</NumberCoins>
                        </TextCoins>
                    </BtnOptionHealAll>
                    <BtnOptionInstantHealAll onClick={() => setPopUpInstantHealAll(true)}>
                        <ImgIconMedical2 src={icon_heal_all}/>
                        <TextTitiliumWebOptions2>INSTANT HEAL ALL</TextTitiliumWebOptions2>
                        <TextCoins>
                            <ImgCoin src={crown_coin} />
                            <NumberCoins>{valueInstantHealAll}</NumberCoins>
                        </TextCoins>
                    </BtnOptionInstantHealAll>
                </RowSpaceBetween>
            </ContainerOptions2>
            :
            null
        }
        { props.selected ?
            <ContainerInfoCommanders >
                <div style={{padding: '20px'}}>
                <Row>
                    <ContainerImgInfoProfile style={{width: "auto", height: "auto"}}>
                    <ImgInfoProfile src={commander.commander.image_frame}/>
                    </ContainerImgInfoProfile>
                    <ColInfo>
                    <TitleInfoCommanders>
                        <span>{commander.commander.name}</span>
                    </TitleInfoCommanders>
                    <DescriptionInfoCommanders>
                        <span>{commander.commander.description}</span>
                    </DescriptionInfoCommanders>
                    </ColInfo>
                </Row>
                <ContainerInfoStats>
                    <GridSpecs specs={commander.commander.specs} spaceBetweenColumns={'10px'} width={'15%'} columns={2}/>
                    <TitleStats>Fleet Points: <StatsBold>+{commander.commander.fp}</StatsBold></TitleStats>
                    <TitleStats>LVL: <StatsBold>{commander.commander.level}</StatsBold></TitleStats>
                    <TitleStats>Class: <StatsBold>{commander.commander.class}</StatsBold></TitleStats>
                    <TitleStats>Bonus: <StatsBold>{commander.commander.bonus.name}</StatsBold></TitleStats>
                </ContainerInfoStats>
                <div>
                    <ContainerStats onClick={handleDropDownStats}>
                        <RowSpaceBetweenCenter>
                            <RowCoreCenter>
                            <ImgIconStats src={icon_stats}/>
                            <TextPoppinsBold>Stats</TextPoppinsBold>
                            </RowCoreCenter>
                            <ImgIconStats src={icon_more}/>
                        </RowSpaceBetweenCenter>
                    </ContainerStats>
                    {
                        statsDropDown ?

                            <DropDownStatsContainer>
                                <DropDownStatsRow>
                                    <DropDownStatsIcon src='/icons/icon_type_fleet_points_dropdown.svg'></DropDownStatsIcon><TitleStats>Fleet Points: <StatsBold>+{commander.commander.fp}</StatsBold></TitleStats>
                                </DropDownStatsRow>
                                <DropDownStatsRow>
                                    <DropDownStatsIcon src='/icons/icon_type_shield_dropdown.svg'></DropDownStatsIcon><TitleStats>Shield: <StatsBold>{commander.commander.bonus.shield}%</StatsBold></TitleStats>
                                </DropDownStatsRow>
                                <DropDownStatsRow>
                                    <DropDownStatsIcon src='/icons/icon_type_armor_dropdown.svg'></DropDownStatsIcon><TitleStats>Armor: <StatsBold>{commander.commander.bonus.armor}%</StatsBold></TitleStats>
                                </DropDownStatsRow>
                                <DropDownStatsRow>
                                    <DropDownStatsIcon src='/icons/icon_type_damage_dropdown.svg'></DropDownStatsIcon><TitleStats>Damage: <StatsBold>{commander.commander.bonus.damage}%</StatsBold></TitleStats>
                                </DropDownStatsRow>
                                <DropDownStatsRow>
                                    <DropDownStatsIcon src='/icons/icon_type_load_dropdown.svg'></DropDownStatsIcon><TitleStats>Load: <StatsBold>{commander.commander.bonus.load}%</StatsBold></TitleStats>
                                </DropDownStatsRow>
                            </DropDownStatsContainer>
                        :
                            null
                    }       
                </div>         
                <RowSpaceBetweenButtons>
                    {commander.is_destroyed ?
                        <>
                            <BtnInstantHealBits onClick={() => setPopUpHeal(true)}>
                                <div style={{backgroundColor: '#2F041A', width: '40px', height: '30px' , display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <ImgMedical src={icon_heal}/>
                                </div>
                                <ContainerTextIconButton>
                                    <RowRevive>
                                    <TextRevive>
                                        HEAL
                                    </TextRevive>
                                    <TextCoins>
                                        <ImgCoin src='/icons/icon_type_blue_coin_inventory.svg' />
                                        <NumberCoins style={{lineHeight: '0'}}>{commander.commander.cost_heal_bits}</NumberCoins>
                                    </TextCoins>
                                    </RowRevive>
                                </ContainerTextIconButton>
                            </BtnInstantHealBits>
                            <BtnInstantHealCoins onClick={() => setPopUpInstantHeal(true)}>
                                <ContainerIconMedical>
                                    <ImgMedical src={icon_heal}/>
                                </ContainerIconMedical>
                                <ContainerTextIconButton>
                                    <RowRevive>
                                    <TextRevive>
                                        INSTANT HEAL
                                    </TextRevive>
                                    <TextCoins>
                                        <ImgCoin src={crown_coin} />
                                        <NumberCoins>{commander.commander.cost_heal_crowns}</NumberCoins>
                                    </TextCoins>
                                    </RowRevive>
                                </ContainerTextIconButton>
                            </BtnInstantHealCoins>
                        </>
                        :
                        null
                    }
                </RowSpaceBetweenButtons>
                </div>
            </ContainerInfoCommanders>
            : null
        }

        <ContainerCommanders style={{height: commanders.length !== 0 ? '690px' : '180px'}}>
            <FilterForGrids allElements={commanders} commanders sortByType sortByClass>
                { elementsToFilter => (
                    <ContainerGrid>
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not injured commanders">
                            { elementsToRender => (
                                <GridViewCoreCommanders>
                                    {elementsToRender.map((com, index) => (
                                    <CardCommander className='card-commander' key={com.commander.id} >
                                        <TitleCardCommander>
                                        <TextTitleCard>
                                            {com.commander.name}
                                        </TextTitleCard>
                                        </TitleCardCommander>
                                        <ContainerImgProfile>
                                        {com.is_destroyed ?
                                            <DamagedCard>
                                                <ImgRepair src={icon_commander_damage}/>
                                            </DamagedCard>
                                            :
                                            null
                                        }
                                        <ImgInfoProfile src={com.commander.image}/>
                                        <ContainerPolygon>
                                            <PolygonLeft>
                                                <TextPoppinsInfo>FP:<TextPoppinsInfoBold>+{com.commander.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                                            </PolygonLeft>
                                        </ContainerPolygon>
                                        <ContainerPolygon style={{right: '0'}}>
                                            <PolygonRight>
                                                <TextTextTitiliumWebInfo>Lvl {com.commander.level}</TextTextTitiliumWebInfo>
                                            </PolygonRight>
                                        </ContainerPolygon>
                                        </ContainerImgProfile>
                                        <ContainerStatsProfile>
                                        <GridSpecs specs={com.commander.specs} spaceBetweenColumns={'7px'} marginTop={'2%'}/>
                                        <ColStats>
                                            <RowCore style={{marginTop:"3px", marginBottom:"9px"}}>
                                            <TitleStatsCard>Class: <StatsBold>{com.commander.class}</StatsBold></TitleStatsCard>
                                            </RowCore>
                                            <RowCoreBonus>
                                            <TitleStatsCard>Bonus: <StatsBold>{com.commander.bonus.name}</StatsBold></TitleStatsCard>
                                            </RowCoreBonus>
                                        </ColStats>

                                        </ContainerStatsProfile>
                                        <DropdownStats textButton='Stats' iconButton={icon_stats} jsonObj={com.commander.bonus}/>
                                        <BtnSelectCard className='btn-select' onClick={() => btnClickSelected(com, index)}>
                                        <TextSelectCard>Select</TextSelectCard>
                                        </BtnSelectCard>
                                    </CardCommander>
                                    ))}
                                </GridViewCoreCommanders>
                            )
                            }
                        </Pagination>
                    </ContainerGrid>
                )
                }
            </FilterForGrids>
        </ContainerCommanders>

        <PopUp show={popUpHealAll} onClose={() => setPopUpHealAll(false)} onConfirm={() => healAllCommander(false)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to heal all?
            </TextPoppins>
        </PopUp>

        <PopUp show={popUpInstantHealAll} onClose={() => setPopUpInstantHealAll(false)} onConfirm={() => healAllCommander(true)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to heal all instant?
            </TextPoppins>
        </PopUp>

        <PopUp show={popUpHeal} onClose={() => setPopUpHeal(false)} onConfirm={() => healCommander(commander.id, false)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to heal?
            </TextPoppins>
        </PopUp>

        <PopUp show={popUpInstantHeal} onClose={() => setPopUpInstantHeal(false)} onConfirm={() => healCommander(commander.id, true)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to heal instant?
            </TextPoppins>
        </PopUp>

        <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
            <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            {textServerError}
            </TextPoppins>
        </PopUp>
        </>
    );
}