//Librerias INIT
import React from 'react'
import {useState} from 'react'

//CSS
import '../containers/hooks/styles.css'

//Elements
import { 
    DropDownArrowImage,
    DropDownBlockBoldText,
    DropDownBlockIcon, 
    DropDownBlockText, 
    DropDownButtonContainer, 
    DropDownButtonContainerGeneral, 
    DropDownButtonImage, 
    DropDownButtonText, 
    DropDownContainer, 
    DropDownConteinerGeneral, 
    DropDownConteinerGeneralSpacerForScroll,
    DropDownGeneral } 
from "./DropdownStatsElement";

export function DropdownStats({textButton = 'Default', iconButton, ships=false, jsonObj}) {

    //arrow
    //isOpen

    const [isOpen, setIsOpen] = useState(false);
    const [Arrow, setArrow] = useState(false);


    const handleDropdown = () => {
        setIsOpen(!isOpen);
    
        Arrow ? setArrow(false) : setArrow(true);
    }   
    
    console.log(jsonObj)
    return(
        <>
        <DropDownGeneral>
     
            {isOpen && (
            <DropDownConteinerGeneralSpacerForScroll>

            <DropDownConteinerGeneral className='DropDownStats'>

                    <DropDownContainer>
                        <DropDownBlockIcon src={'/icons/icon_shield.svg'}></DropDownBlockIcon>
                        <DropDownBlockText>Shield: </DropDownBlockText>
                        <DropDownBlockBoldText>{jsonObj.shield}%</DropDownBlockBoldText>
                    </DropDownContainer>
                    <DropDownContainer>
                        <DropDownBlockIcon src={'/icons/icon_armor.svg'}></DropDownBlockIcon>
                        <DropDownBlockText>Armor: </DropDownBlockText>
                        <DropDownBlockBoldText>{jsonObj.armor}%</DropDownBlockBoldText>
                    </DropDownContainer>
                    <DropDownContainer>
                        <DropDownBlockIcon src={'/icons/icon_damage.svg'}></DropDownBlockIcon>
                        <DropDownBlockText>Damage: </DropDownBlockText>
                        <DropDownBlockBoldText>{jsonObj.damage}%</DropDownBlockBoldText>
                    </DropDownContainer>
                    <DropDownContainer>
                        <DropDownBlockIcon src={'/icons/icon_type_rocket_headdown.svg'}></DropDownBlockIcon>
                        <DropDownBlockText>Load: </DropDownBlockText>
                        <DropDownBlockBoldText>{jsonObj.load}%</DropDownBlockBoldText>
                    </DropDownContainer>

            </DropDownConteinerGeneral>
            </DropDownConteinerGeneralSpacerForScroll>
            )}
            <DropDownButtonContainerGeneral onClick={handleDropdown} >
                <DropDownButtonContainer>
                    <div style={{ display:"flex" , alignItems:"center", justifyContent:"center"}}>
                        <DropDownButtonImage src={iconButton}></DropDownButtonImage>
                    </div>
                    <DropDownButtonText>{textButton}</DropDownButtonText>
                    <div style={{ display:"flex" , alignItems:"center", justifyContent:"center"}}>
                    { Arrow ? <DropDownArrowImage src='/icons/icon_special_stats_arrow_hidden.svg' ></DropDownArrowImage> : <DropDownArrowImage src='/icons/icon_special_stats_arrow_show.svg' ></DropDownArrowImage> }
                    </div>
                </DropDownButtonContainer>
            </DropDownButtonContainerGeneral>
        </DropDownGeneral>


      
        </>
    )
}