import React, { useState, useEffect } from "react";
import { HTTP } from '../../../../../../http/http-common';
import { useNavigate } from 'react-router-dom'

import icon_pickup_all from '../../../../../../../assets/images/icon_pickup_all.svg'
import icon_stats from '../../../../../../../assets/images/icon_stats.svg'

import { ContainerPolygon, PolygonRight, PolygonLeft , RowCore, RowSpaceBetween, TextPoppins} from "../../../../../../../containers/CoreElement";
import { BtnOptionPickUpAll, BtnSelectCard, CardCommander, ColStats, ContainerCommanders, ContainerGrid, ContainerImgProfile, ContainerOptions2, ContainerStatsProfile, GridViewCoreCommanders, ImgIconMedical2, ImgInfoProfile, RowCoreBonus, StatsBold, TextSelectCard, TextTextTitiliumWebInfo, TextTitiliumWebOptions2, TextTitleCard, TitleCardCommander, TitleStatsCard, TextPoppinsInfoBold, TextPoppinsInfo } from "../../../CommanderElement";
import GridSpecs from "../../../../../../../hooks/GridSpecs";
import { FilterForGrids } from "../../../../../../../hooks/FilterForGrids";
import { DropdownStats } from "../../../../../../../hooks/DropdownStats";
import Pagination from "../../../../../../../hooks/Pagination";
import PopUp from "../../../../../../../hooks/PopUp";
import { Loader } from "../../../../../../../hooks/Loader";
import { updateNavs } from "../../../../../../utils/utils";

export function PickUp(props){

    const [commanderCloneCode, setCommanderCloneCode] = useState(-1);
    const [commanders, setCommanders] = useState([]);

    const [loader, setLoader] = useState(false);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');

    const [popUpPickupNow, setPopUpPickupNow] = useState(false);
    const [popUpPickupAll, setPopUpPickupAll] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        updateNavs();
        fetchCommandersPickup();
    }, [])
    

    useEffect(() => {
        props.setActiveRoute(props.activeRoute)
    }, [props])
    

    const fetchCommanderPickUp = (cloneCode) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"clone_code": cloneCode},
            method: "POST"
        };
        HTTP.request('commander/clone/pickup/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/commanders/crew')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    };


    const fetchCommandersPickup= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('commander/clone/pickup/process/', config)
          .then((res) => {
            if (res.data.result){
                setCommanders(res.data.pickups)
            }
          })
          .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
          })
          .finally(() => {
            setLoader(false);
          })
    };


    const fetchCommanderPickUpAll = () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          method: "POST"
        };
        HTTP.request('commander/clone/all/pickup/', config)
          .then((res) => {
            if (res.data.result){
                navigate('/commanders/crew')
            }
          })
          .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
          })
          .finally(() => {
            setLoader(false);
          })
      };

    const handlePickupNow = (cloneCode) => {
        setCommanderCloneCode(cloneCode);
        setPopUpPickupNow(true);
    }

    console.log(commanders)
    return(
        <>
        <Loader loading={loader}/>
        <ContainerOptions2 onClick={() => setPopUpPickupAll(true)}>
            <RowSpaceBetween>
                <BtnOptionPickUpAll>
                <ImgIconMedical2 src={icon_pickup_all}/>
                <TextTitiliumWebOptions2>PICKUP ALL</TextTitiliumWebOptions2>
                </BtnOptionPickUpAll>
            </RowSpaceBetween>
        </ContainerOptions2>

        <ContainerCommanders style={{height: commanders.length !== 0 ? '690px' : '180px'}}>
            <FilterForGrids allElements={commanders} commanders sortByClass>
                { elementsToFilter => (
                    <ContainerGrid>
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData="There are not pick up commanders">
                            { elementsToRender => (
                                <GridViewCoreCommanders>
                                    {elementsToRender.map((com) => (
                                    <CardCommander className='card-commander' key={com.id} >
                                        <TitleCardCommander>
                                        <TextTitleCard>
                                            {com.commander.name}
                                        </TextTitleCard>
                                        </TitleCardCommander>
                                        <ContainerImgProfile>
                                        <ImgInfoProfile src={com.commander.image}/>
                                        <ContainerPolygon>
                                            <PolygonLeft>
                                                <TextPoppinsInfo>FP:<TextPoppinsInfoBold>+{com.commander.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                                            </PolygonLeft>
                                        </ContainerPolygon>
                                        <ContainerPolygon style={{right: '0'}}>
                                            <PolygonRight>
                                                <TextTextTitiliumWebInfo>Lvl {com.commander.level}</TextTextTitiliumWebInfo>
                                            </PolygonRight>
                                        </ContainerPolygon>
                                        </ContainerImgProfile>
                                        <ContainerStatsProfile>
                                        <GridSpecs specs={com.commander.specs} spaceBetweenColumns={'7px'} marginTop={'2%'}/>
                                        <ColStats>
                                            <RowCore style={{marginTop:"3px", marginBottom:"9px"}}>
                                            <TitleStatsCard>Class: <StatsBold>{com.commander.class.name}</StatsBold></TitleStatsCard>
                                            </RowCore>
                                            <RowCoreBonus>
                                            <TitleStatsCard>Bonus: <StatsBold>{com.commander.bonus.name}</StatsBold></TitleStatsCard>
                                            </RowCoreBonus>
                                        </ColStats>

                                        </ContainerStatsProfile>
                                        <DropdownStats textButton='Stats' iconButton={icon_stats} jsonObj={com.commander.bonus}/>
                                        <BtnSelectCard className='btn-select' onClick={() => handlePickupNow(com.clone_code)}>
                                            <TextSelectCard>Pickup Now</TextSelectCard>
                                        </BtnSelectCard>
                                    </CardCommander>
                                    ))}
                                </GridViewCoreCommanders>
                            )
                            }
                        </Pagination>
                    </ContainerGrid>
                )
                }
            </FilterForGrids>
        </ContainerCommanders>

        <PopUp show={popUpPickupAll} onClose={() => setPopUpPickupAll(false)} onConfirm={() => fetchCommanderPickUpAll()}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to pickup all?
            </TextPoppins>
        </PopUp>

        <PopUp show={popUpPickupNow} onClose={() => setPopUpPickupNow(false)} onConfirm={() => fetchCommanderPickUp(commanderCloneCode)}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Are you sure you want to pickup now?
            </TextPoppins>
        </PopUp>

        <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
            <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            {textServerError}
            </TextPoppins>
        </PopUp>
        </>
    );
}