import React, { useState, useEffect } from "react";

import {
  RowCoreCenter,
  RowSpaceBetween,
  TextPoppins,
} from "../containers/CoreElement";
import "../containers/hooks/styles.css";
import {
  RectangleContainerGeneral,
  RectangleContainerGeneral2,
  RectangleImage,
  RectangleEmptyContainerGeneral,
  RectangleEmpty,
  RectangleContainerComplete,
  RectangleRowContainerFirst,
  RectangleRowContainerSecond,
  RectangleRowsContainer,
  RectangleEmptyContainerGeneral2,
  RectangleRowText,
  RectangleRowFirstContainer2,
  RectangleRowSecondContainerStats,
  Icon,
  RectangleRowFirstContainerIconAndText,
  Button,
  TextButton,
  TextStats,
  IconButton,
  RectangleElementFirst,
  RectangleRowsContainerRow,
  RectangleButtonContainerDesktop,
  RectangleButtonContainerCellphone,
  RowEnemyFleet,
  ButtonSelectRouteTime,
} from "./ReusableRectangleElement";
import GridSpecs from "../hooks/GridSpecs";
import PopUp from "./PopUp";
import IconSvg from "./IconSvg";

import icon_expeditions from "../assets/images/icon_expeditions.svg";
import icon_mining from "../assets/images/icon_mining.svg";
import { convertTimestampMinutes } from "../components/utils/utils";

export function ReusableRectangleEmpty() {
  return (
    <>
      {
        <RectangleEmptyContainerGeneral>
          <RectangleEmptyContainerGeneral2>
            <RectangleImage />
            <RectangleEmpty></RectangleEmpty>
          </RectangleEmptyContainerGeneral2>
        </RectangleEmptyContainerGeneral>
      }
    </>
  );
}

const ReusableRectangle = (props) => {
  const [, setIsHeight] = useState(props.height);
  const [showPopUpSendFleet, setShowPopUpSendFleet] = useState(false);

  const setShowPopUpSendFleetAction = () => {
    if (props.isSendFleet) {
      setShowPopUpSendFleet(true);
    } else {
      props.action();
    }
  };

  const changeRouteTime = (route_time) => {
    props.setRouteTimeSelected(route_time);
  };

  useEffect(() => {
    setIsHeight(props.height);
  }, [props.height]);

  return (
    <>
      <RectangleContainerGeneral style={{ margin: props.margin }}>
        <RectangleContainerGeneral2>
          {props.image ? <RectangleImage src={props.image} /> : null}
          <RectangleContainerComplete
            style={{ backgroundColor: props.rectangleBackground }}
          >
            <RectangleRowsContainer>
              <RectangleRowsContainerRow style={{ padding: "1% 0%" }}>
                <RectangleRowFirstContainer2 style={{marginTop: '1%'}}>
                  <RectangleRowFirstContainerIconAndText>
                    <Icon
                      style={{ marginRight: "3px" }}
                      src="/icons/icon_type_clock_white.svg"
                    ></Icon>
                    {props.min_spent ? (
                      <>
                        {props.min_spent instanceof Array ? (
                          <>
                            {props.min_spent.map((route_time) => (
                              <ButtonSelectRouteTime
                                onClick={() => changeRouteTime(route_time)}
                                style={
                                  route_time.code ===
                                  props.routeTimeSelected.code
                                    ? { border: "1px solid rgb(231, 19, 131)" }
                                    : { border: "none" }
                                }
                              >
                                <RectangleRowText>
                                  {" "}
                                  {convertTimestampMinutes(
                                    route_time.time * 2
                                  )}{" "}
                                </RectangleRowText>
                              </ButtonSelectRouteTime>
                            ))}
                          </>
                        ) : (
                          <RectangleRowText>
                            {" "}
                            {props.min_spent}{" "}
                          </RectangleRowText>
                        )}
                      </>
                    ) : null}
                  </RectangleRowFirstContainerIconAndText>
                </RectangleRowFirstContainer2>
              </RectangleRowsContainerRow>
              <RectangleRowsContainerRow style={{ padding: "2% 0%" }}>
                <RectangleRowContainerFirst>
                  <RowSpaceBetween
                    style={{
                      width: props.type === "mining_mission" ? "40%" : "43%",
                      gap: "12px",
                    }}
                  >
                    {props.missions ? (
                      <>
                        {props.type === "mining_mission" ? (
                          <>
                            <RectangleElementFirst>
                              <IconSvg
                                icon={icon_mining}
                                color={"#ffffff"}
                                width={"20px"}
                              />
                              <RectangleRowText>
                                {" "}
                                Mining Mission{" "}
                              </RectangleRowText>
                            </RectangleElementFirst>
                            <RectangleElementFirst>
                              <RectangleRowText> Difficulty: </RectangleRowText>
                              <RectangleRowText style={{ fontWeight: "bold" }}>
                                {" "}
                                {props.difficulty}{" "}
                              </RectangleRowText>
                            </RectangleElementFirst>
                          </>
                        ) : (
                          <>
                            <RectangleElementFirst>
                              <IconSvg
                                icon={icon_expeditions}
                                color={"#ffffff"}
                                width={"15px"}
                              />
                              <RectangleRowText>
                                {" "}
                                Expedition Mission{" "}
                              </RectangleRowText>
                            </RectangleElementFirst>
                            <RectangleElementFirst>
                              <RectangleRowText> Difficulty: </RectangleRowText>
                              <RectangleRowText style={{ fontWeight: "bold" }}>
                                {" "}
                                {props.difficulty}{" "}
                              </RectangleRowText>
                            </RectangleElementFirst>
                          </>
                        )}
                      </>
                    ) : null}
                  </RowSpaceBetween>
                </RectangleRowContainerFirst>
              </RectangleRowsContainerRow>
              {props.type === "aurum_expedition" ? (
                <RectangleRowsContainerRow>
                  <RectangleRowSecondContainerStats>
                    <TextStats>
                      {" "}
                      {props.missions ? "Enemy Fleet" : "Stats"}{" "}
                    </TextStats>
                  </RectangleRowSecondContainerStats>
                  {props.missions ? (
                    <RowEnemyFleet>
                      <RectangleElementFirst>
                        <RectangleRowText>
                          {" "}
                          {`1x Commander ${props.value1Second}`}{" "}
                        </RectangleRowText>
                      </RectangleElementFirst>
                      {props.enemy_ships.map((ship) => (
                        <RectangleElementFirst>
                          <RectangleRowText>
                            {" "}
                            {ship.quantity}x {ship.name}{" "}
                          </RectangleRowText>
                        </RectangleElementFirst>
                      ))}
                    </RowEnemyFleet>
                  ) : (
                    <RectangleRowContainerSecond>
                      <RectangleElementFirst>
                        <RectangleRowText> Difficulty: </RectangleRowText>
                        <RectangleRowText>
                          {" "}
                          {props.value1Second}{" "}
                        </RectangleRowText>
                      </RectangleElementFirst>
                      <RectangleElementFirst>
                        <RectangleRowText> Reward: </RectangleRowText>
                        <RectangleRowText>
                          {" "}
                          {props.value2Second}{" "}
                        </RectangleRowText>
                      </RectangleElementFirst>
                      <RectangleElementFirst>
                        <RectangleRowText> Distance: </RectangleRowText>
                        <RectangleRowText>
                          {" "}
                          {props.value3Second}{" "}
                        </RectangleRowText>
                      </RectangleElementFirst>
                      <RectangleElementFirst>
                        <RectangleRowText> Risk: </RectangleRowText>
                        <RectangleRowText>
                          {" "}
                          {props.value4Second}{" "}
                        </RectangleRowText>
                      </RectangleElementFirst>
                    </RectangleRowContainerSecond>
                  )}
                </RectangleRowsContainerRow>
              ) : null}
              {props.type === "mining_mission" || props.specs.length > 0 ? (
                <RectangleRowsContainerRow style={{ border: "0px" }}>
                  {props.missions ? (
                    <>
                      <RectangleRowSecondContainerStats>
                        <TextStats> Potential Rewards</TextStats>
                      </RectangleRowSecondContainerStats>
                      <RectangleRowContainerSecond>
                        <GridSpecs
                          style={{ height: "18px" }}
                          specs={props.specs}
                          rewards={true}
                          columns={6}
                          spaceBetweenColumns={"15px"}
                          width={"300%"}
                        />
                      </RectangleRowContainerSecond>
                    </>
                  ) : null}
                </RectangleRowsContainerRow>
              ) : (
                <RectangleRowsContainerRow style={{ border: "0px" }}>
                  {props.missions ? (
                    <>
                      <RectangleRowSecondContainerStats>
                        <TextStats> Potential Rewards</TextStats>
                      </RectangleRowSecondContainerStats>
                      <RectangleRowContainerSecond>
                        <GridSpecs
                          style={{ height: "18px" }}
                          specs={[{ name: "Aurum", quantity: "(Cargo based)" }]}
                          rewards={true}
                          columns={1}
                          spaceBetweenColumns={"15px"}
                          width={"300%"}
                        />
                      </RectangleRowContainerSecond>
                    </>
                  ) : null}
                </RectangleRowsContainerRow>
              )}
              <RowCoreCenter style={{ justifyContent: "center" }}>
                {props.action ? (
                  <RectangleButtonContainerCellphone>
                    <Button
                      onClick={() => setShowPopUpSendFleetAction()}
                      style={{
                        width: "110px",
                        height: "39px",
                        backgroundColor: "rgba(231, 19, 131, 1)",
                        clipPath:
                          "polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)",
                        WebkitClipPath:
                          "polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)",
                      }}
                    >
                      <IconButton src={props.buttonIcon}></IconButton>
                      <TextButton>{props.buttonText}</TextButton>
                    </Button>
                  </RectangleButtonContainerCellphone>
                ) : null}
              </RowCoreCenter>
            </RectangleRowsContainer>

            {props.action ? (
              <RectangleButtonContainerDesktop>
                <Button
                  onClick={() => setShowPopUpSendFleetAction()}
                  style={{
                    width: "110px",
                    height: "39px",
                    backgroundColor: "rgba(231, 19, 131, 1)",
                    clipPath:
                      "polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)",
                    WebkitClipPath:
                      "polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%)",
                  }}
                >
                  <IconButton src={props.buttonIcon}></IconButton>
                  <TextButton>{props.buttonText}</TextButton>
                </Button>
              </RectangleButtonContainerDesktop>
            ) : null}
          </RectangleContainerComplete>
        </RectangleContainerGeneral2>
      </RectangleContainerGeneral>

      <PopUp
        show={showPopUpSendFleet}
        onClose={() => setShowPopUpSendFleet(false)}
        onlyConfirm={false}
        onConfirm={props.action}
      >
        <TextPoppins style={{ letterSpacing: "0" }}>
          Are you sure to send the fleet?
        </TextPoppins>
      </PopUp>
    </>
  );
};
export default ReusableRectangle;
