//Libraries INIT
import React, { useState, useEffect } from 'react';

import '../containers/hooks/styles.css'

import {
    CardContainerGeneral,
    CardContainerTitle,
    CardContainerImage,
    CardInfo,
    CardInfo2,
    TextTitle,
    Text,
    TextBold,
    Icon,
    InstantForge,
    Cancel,
    TextPoppinsInfo,
    TextTextTitiliumWebInfo,
    TextPoppinsInfoBold,
    ContainerImgProfile
} from './ReusableCardElement';

import { ContainerPolygon, ImgIcon, PolygonLeft, PolygonRight, TextTitiliumWeb } from '../containers/CoreElement';

import { convertProgressToPercentege } from '../components/utils/utils';
import GridSpecs from './GridSpecs';
import HealingBar from './HealingBar';



//Shipyard-Forge Process
export function ReusableCardProcess(props) {
    


    const [count, setCount] = useState(0);

    useEffect(() => {
        setInterval(() => {
            setCount(prevCount => prevCount + 0.5);
        }, 1000);
    }, []);

    return (
        <>
            <CardContainerGeneral>
                <CardContainerTitle><TextTitle>{props.item.ship.name}</TextTitle></CardContainerTitle>
                <div style={{display:"flex", position:"relative"}}>
                    <ContainerImgProfile>
                        <CardContainerImage src={props.item.ship.image}></CardContainerImage>
                    </ContainerImgProfile>
                    <ContainerPolygon>
                        <PolygonLeft>
                            <TextPoppinsInfo>FP:<TextPoppinsInfoBold>{props.item.ship.fp}</TextPoppinsInfoBold></TextPoppinsInfo>
                        </PolygonLeft>
                    </ContainerPolygon>
                    <ContainerPolygon style={{right: '0'}}>
                        <PolygonRight>
                            <TextTextTitiliumWebInfo>LVL {1}</TextTextTitiliumWebInfo>
                        </PolygonRight>
                    </ContainerPolygon>
                </div>
                <CardInfo>
                    <div style={{marginTop: '4%'}}>
                        <GridSpecs specs={props.specs} spaceBetweenColumns={'10px'} columns={4} width={'200px'}/>
                    </div>
                    <Text>Class: <TextBold>{props.item.ship.class}</TextBold></Text>
                </CardInfo>
                
                <CardInfo2>
                    <HealingBar percent={convertProgressToPercentege(props.item, count)}/>
                    <InstantForge onClick={() => props.actionInstantForge()}>
                        <Icon src="/icons/icon_type_instant_forge.svg"></Icon>
                        <TextTitiliumWeb style={{fontSize:"15px", fontWeight: '400'}}>INSTANT FORGE</TextTitiliumWeb> 
                        <ImgIcon src={"/icons/icon_type_crown_coin_grey.png"} style={{marginLeft: '1%', marginRight: '0%', width: '20px', height: '20px'}}/>
                        <TextBold style={{fontSize:"12px"}}>{props.item.ship.cost_instant_forge}</TextBold>

                    </InstantForge>
                    <Cancel onClick={props.actionCancel}>
                        <Icon src="/icons/icon_type_cancel.svg"></Icon>
                        <TextTitiliumWeb style={{fontSize:"14px"}}>Cancel</TextTitiliumWeb>
                    </Cancel>
                </CardInfo2>
         
            </CardContainerGeneral>

        </>
    )
};