import { BottomNavigationAction, Fab, Paper } from "@mui/material"
import styled from "styled-components"


export const PaperBottomNav = styled(Paper)`
    position: fixed; 
    bottom: 0; 
    left: 0;
    right: 0;
    z-index: 9999;
    @media(min-width: 961px) {
        display: none;
    }
`

export const BottomNavAction = styled(BottomNavigationAction)`
    gap: 3px;
    /* color: white !important; */
`

export const BottomNavActionAnimation = styled(BottomNavAction)`
    
    animation: fadeIn 0.5s ease-in-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`

export const FabCustom = styled(Fab)`
    position: absolute !important;
    transform: translate(-50%, -50%);
    left: 50%;
`
