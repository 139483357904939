import React, { useState, useEffect } from 'react';
import { HTTP } from '../components/http/http-common';

import { 
    PopupOverlay,
    ContainerPopUp,
    BtnClosePopUpMsg,
    ImgIconCloseMsg,
    SectionDiv,
    Title,
    BuyButton,
    Icon,
    TextButton,
    ContainerResource,
    MenuLink,
    ColDesktop,
    ContainerResourceCellphone,
    ContainerWithdrawContent,
    ContainerSMCWBalance,
    TextSMCWBalance,
    TitleSMCWBalance,
    IconCoin,
    WithdrawButton,
    TextWithdraw
} 
from "./PopUpDetailsElement";

import icon_close_x from '../assets/images/icon_close_x.svg'

import { numberToThousandSeparator, numberToThousandSeparatorLeaderboardRanking } from '../components/utils/utils';
import { ColCore, RowCore, TextPoppins } from '../containers/CoreElement';
import PopUp from './PopUp';
import { Loader } from './Loader';

export default function PopUpDetails(props) {

    const [maxMineral, setMaxMineral] = useState(0);
    const [crownsAvailables, setCrownsAvailables] = useState(0);
    const [minerals, setMinerals] = useState({});

    const [rank, setRank] = useState(false);
    const [countRanking, setCountRanking] = useState("...");
    const [collectedAurum, setCollectedAurum] = useState("...");
    const [collateralValues, setCollateralValues] = useState({});

    const fetchPlayerCrowns = () => {
    
        var config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('player/crowns/balance/', config)
          .then((res) => {
            console.log(res);
            if (res.data["result"]){
              setCrownsAvailables(res.data["crowns_available"]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    };


    const fetchRanking = () => {
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('leader/ranking/', config)
            .then((res) => {
                if (res.data.result) {
                    console.log(res.data.response.rank)
                    setRank(res.data.response.rank)
                    setCollectedAurum(res.data.response.collected_aurum)
                    setCountRanking(res.data.response.count_ranking)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const fetchParameterMaxMineral = () => {
        const config = {
            method: "GET"
        };
        HTTP.request('parameter/mineral/allowed/', config)
            .then((res) => {
                if (res.data.result) {
                    setMaxMineral(res.data.value)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getCollateralLockValues = () => {
        var config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            method: "GET"
        };

        HTTP.request('collateral/values/', config)
            .then((res) => {
                console.log(res);
                if (res.data.result) {
                    setCollateralValues(res.data.values)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getMinerals = () => {
        var config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            method: "GET"
        };

        HTTP.request('minerals/', config)
        .then((res) => {
            if (res.data.result) {
                setMinerals(res.data.minerals)
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        console.log(props.show)
        if (props.show) {
            getMinerals();
            fetchPlayerCrowns();
            fetchRanking();
            fetchParameterMaxMineral();
            getCollateralLockValues();
            fetchMetaData();
        }
    }, [props.show])

    const [loader, setLoader] = useState(false);
    const [metadataSMCW, setMetadataSMCW] = useState({});

    const [openPopUpValidation, setOpenPopUpValidation] = useState(false);
    const [showPopUpInfo, setShowPopUpInfo] = useState(false);
    const [textPopUp, setTextPopUp] = useState('');

    const [requeriment, setRequeriment] = useState(false);

    const fetchMetaData = () => {
        setLoader(true);
        
    
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            method: "POST"
        };
    
        HTTP.request('metadata/smcw/', config)
        .then((res) => {
            if (res.data.result){
                setMetadataSMCW(res.data.response)
            }
        })
        .catch((err) => {
        console.log(err);
        })
        .finally(() => {
          setLoader(false);
        })
    };

    const fetchWithdraw = () => {
        setLoader(true);
        
        if (metadataSMCW.user_smcw_claimed >= metadataSMCW.minimum_value_to_withdraw){
            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
                method: "POST"
            };
        
            HTTP.request('withdraw/smcw/', config)
            .then((res) => {
                if (res.data.result){
                    setShowPopUpInfo(true);
                    setTextPopUp(res.data.response)
                }
            })
            .catch((err) => {
            console.log(err);
            })
            .finally(() => {
              setLoader(false);
            })
        }else{
            setRequeriment(true)
        }
    
    };


    return(
        <>
        <Loader loading={loader}/>
        { props.show ?
        <PopupOverlay>
            <ContainerPopUp>
            <BtnClosePopUpMsg onClick={props.onClose}>
                <ImgIconCloseMsg src={icon_close_x}/>
            </BtnClosePopUpMsg>
            <SectionDiv>
                <div style={{width:"30%"}}>
                <Title style={{fontSize:"20px", fontWeight:"bold", borderBottom:"0.5px solid #E71383"}}>
                    Details
                </Title>
                </div>
            </SectionDiv>
            <SectionDiv>
                <Title style={{fontSize:"14px", display:"flex", alignItems:"center"}}>Current Week Collected Aurum: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_mineral.svg"></Icon>{numberToThousandSeparatorLeaderboardRanking(collectedAurum)}</Title>
                <Title style={{fontSize:"14px", display:"flex", alignItems:"center"}}>Leaderboard Ranking: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_leaderboard.svg"></Icon>{rank} of {countRanking}</Title>
                { collateralValues.osm_pledge ? 
                    <div style={{display:"flex"}}>
                        <Title style={{fontSize:"14px", display:"flex", alignItems:"center"}}>Olympus Syndicate Membership: <MenuLink to={'/leaderboard'}><Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_header_bar.svg"></Icon> Active </MenuLink></Title>
                    </div>
                :
                    <div style={{display:"flex"}}>
                        <Title style={{fontSize:"14px", display:"flex", alignItems:"center"}}>Olympus Syndicate Membership: <MenuLink to={'/leaderboard'}><Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_header_bar_off.svg"></Icon> Inactive </MenuLink></Title><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0x1630a6aa99c35e2e5884ee741d1f1b06b7fe776c/179', '_blank')}><TextButton>Buy</TextButton></BuyButton>
                    </div>
                }
            </SectionDiv>
            <SectionDiv>
                <div style={{width:"30%"}}>
                <Title style={{fontSize:"20px", fontWeight:"bold", borderBottom:"0.5px solid #E71383"}}>
                    Currencies
                </Title>
                </div>
            </SectionDiv>
            <RowCore style={{background: 'transparent', gap: '30px'}}>
                <ContainerResource>
                    CROWN: <Icon style={{margin:"0px 3px 0px 3px", fontSize: '14px'}} src="/icons/icon_type_crown_coin_grey.png"></Icon> {numberToThousandSeparator(crownsAvailables)}
                    <MenuLink style={{marginLeft: '3%'}}><BuyButton onClick={()=>window.open('https://spacemisfits.com/user/crown/', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                </ContainerResource>
                <ContainerResource>
                    BITS: <Icon style={{margin:"0px 3px 0px 3px", fontSize: '14px'}} src="/icons/icon_type_blue_coin_header.svg"></Icon>{numberToThousandSeparatorLeaderboardRanking(minerals.bits)}
                </ContainerResource>
                <ContainerResource>
                    <ContainerWithdrawContent>
                        <ContainerSMCWBalance>
                            <TitleSMCWBalance>
                                <TextSMCWBalance>
                                    <IconCoin src={"/icons/icon_type_coin.svg"}/>
                                    SMCW:
                                    <TextSMCWBalance>
                                    {numberToThousandSeparatorLeaderboardRanking(metadataSMCW.user_smcw_claimed)}
                                    </TextSMCWBalance>
                                </TextSMCWBalance>
                            </TitleSMCWBalance>
                        </ContainerSMCWBalance>
                        <WithdrawButton  onClick={()=> { metadataSMCW.user_smcw_claimed >= metadataSMCW.minimum_value_to_withdraw ? setOpenPopUpValidation(true) : setRequeriment(true)}}>
                            <TextWithdraw>
                                WITHDRAW
                            </TextWithdraw>
                        </WithdrawButton>
                    </ContainerWithdrawContent>
                </ContainerResource>
            </RowCore>
            <SectionDiv>
                <div style={{width:"30%"}}>
                <Title style={{fontSize:"20px", fontWeight:"bold", borderBottom:"0.5px solid #E71383"}}>
                    Resources
                </Title>
                </div>
            </SectionDiv>
            <RowCore style={{gap: '40px'}}>
                <ColCore style={{gap: '5px'}}>
                    <ContainerResource style={{gap: '20px'}}>
                        <RowCore style={{alignItems: 'center', fontSize: '14px'}}>Peroxium: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_peroxium.svg"></Icon> <span style={{color: minerals.peroxium > maxMineral ? '#FF0D0D' : 'white'}}>{numberToThousandSeparatorLeaderboardRanking(minerals.peroxium)}</span> / {numberToThousandSeparatorLeaderboardRanking(maxMineral)}</RowCore>
                        <MenuLink><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/10', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                    </ContainerResource>
                    <ContainerResource style={{gap: '20px'}}>
                        <RowCore style={{alignItems: 'center', fontSize: '14px'}}>Trantium: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_trantium.svg"></Icon> <span style={{color: minerals.trantium > maxMineral ? '#FF0D0D' : 'white'}}>{numberToThousandSeparatorLeaderboardRanking(minerals.trantium)}</span> / {numberToThousandSeparatorLeaderboardRanking(maxMineral)} </RowCore>
                        <MenuLink><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/11', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                    </ContainerResource>
                    <ContainerResource style={{gap: '20px'}}>
                        <RowCore style={{alignItems: 'center', fontSize: '14px'}}>Obscurian: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_crystal.png"></Icon> <span style={{color: minerals.obscurian > maxMineral ? '#FF0D0D' : 'white'}}>{numberToThousandSeparatorLeaderboardRanking(minerals.obscurian)}</span> / {numberToThousandSeparatorLeaderboardRanking(maxMineral)} </RowCore>
                        <MenuLink><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/9', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                    </ContainerResource>
                    <ContainerResourceCellphone style={{gap: '20px'}}>
                        <RowCore style={{alignItems: 'center', fontSize: '14px'}}>Mercoxium: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_mercoxium.png"></Icon> <span style={{color: props.mercoxium > maxMineral ? '#FF0D0D' : 'white'}}>{numberToThousandSeparatorLeaderboardRanking(props.mercoxium)}</span> / {numberToThousandSeparatorLeaderboardRanking(maxMineral)} </RowCore>
                        <MenuLink><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/8', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                    </ContainerResourceCellphone>
                    <ContainerResourceCellphone style={{gap: '20px'}}>
                        <RowCore style={{alignItems: 'center', fontSize: '14px'}}>Crelyte: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_crelyte.svg"></Icon> <span style={{color: props.crelyte > maxMineral ? '#FF0D0D' : 'white'}}>{numberToThousandSeparatorLeaderboardRanking(props.crelyte)}</span> / {numberToThousandSeparatorLeaderboardRanking(maxMineral)} </RowCore>
                        <MenuLink><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/7', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                    </ContainerResourceCellphone>
                </ColCore>
                <ColDesktop style={{gap: '5px'}}>
                    <ContainerResource style={{gap: '20px'}}>
                        <RowCore style={{alignItems: 'center', fontSize: '14px'}}>Mercoxium: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_mercoxium.png"></Icon> <span style={{color: minerals.mercoxium > maxMineral ? '#FF0D0D' : 'white'}}>{numberToThousandSeparatorLeaderboardRanking(minerals.mercoxium)}</span> / {numberToThousandSeparatorLeaderboardRanking(maxMineral)} </RowCore>
                        <MenuLink><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/8', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                    </ContainerResource>
                    <ContainerResource style={{gap: '20px'}}>
                        <RowCore style={{alignItems: 'center', fontSize: '14px'}}>Crelyte: <Icon style={{margin:"0px 3px 0px 3px"}} src="/icons/icon_type_crelyte.png"></Icon> <span style={{color: minerals.crelyte > maxMineral ? '#FF0D0D' : 'white'}}>{numberToThousandSeparatorLeaderboardRanking(minerals.crelyte)}</span> / {numberToThousandSeparatorLeaderboardRanking(maxMineral)} </RowCore>
                        <MenuLink><BuyButton onClick={()=>window.open('https://marketplace.spacemisfits.com/item/56/0xd9a5d8be5f2de19cd1678dc36f30b05977847c13/7', '_blank')}><TextButton>Buy</TextButton></BuyButton></MenuLink>
                    </ContainerResource>
                </ColDesktop>
            </RowCore>
            </ContainerPopUp>

            <PopUp show={openPopUpValidation} onClose={() => setOpenPopUpValidation(false)} onConfirm={() => fetchWithdraw()}>
                <TextPoppins style={{letterSpacing: '0'}}>
                    Are you sure you want to withdraw your SMCW?
                </TextPoppins>
            </PopUp>

            <PopUp show={requeriment} onClose={() => setRequeriment(false)} onlyConfirm={true}>
                <TextPoppins style={{letterSpacing: '0'}}>
                    The minimum you can withdraw is 1,000 SMCW
                </TextPoppins>
            </PopUp>

            <PopUp show={showPopUpInfo} onClose={() => window.location.reload()} onConfirm={() => window.location.reload()}>
                <TextPoppins style={{letterSpacing: '0'}}>
                    {textPopUp}
                </TextPoppins>
            </PopUp>
        </PopupOverlay>
        : null
        }
        
        </>
    )

}