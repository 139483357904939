import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ContainerSign, SignInForm, BtnSignIn, TextButtons, ResponsiveContainer, ResponsiveAlert, ContainerAlert, FormResetPasswordLink } from './SignflowElement'
import { FormControl, FormLabel, SignUpImage, FormTitle, FormErrorHeader, ErrorItems, FormSignInLink, FieldErrorStatus, FieldErrorHeaderStatus, PasswordDone, FormPassWordError, PassWordErrorItems, PasswordErrorStatus, InputConfirmPassword, InputPassword } from '../Register/RegisterElement';
import { BiLockAlt } from 'react-icons/bi';
import { AiOutlineEyeInvisible, AiOutlineEye, AiOutlineCheck, AiFillCloseCircle, AiFillCheckCircle } from 'react-icons/ai';
import { GoAlert } from 'react-icons/go';
import help_icon_pink from '../../assets/images/info_icon_pink.svg'

import sign_banner from '../../assets/images/new-sign-banner.png'

import '../../styles/Signflow.css'

import { ImgIcon, RowCore, TextPoppins } from '../../containers/CoreElement'
import { HTTP } from '../http/http-common';
import { Loader } from '../../hooks/Loader';

export function NewPassword() {
    let navigate = useNavigate();
    let { token } = useParams();
    console.log(token)

    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const [success, setSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState({});
    const [error, setError] = useState({});
    const [errorHeader, setErrorHeader] = useState('');
    const passwordRegex = /[!@#$%^&*()-/,.?":{}|<>]/g;
    const [loader, setLoader] = useState(false);

    const verifyToken = () => {
        setLoader(true);
        var config = {
          data: { "password": password, "password_confirmation": confirmPassword},
          method: "POST"
        };
    
        HTTP.request('password/reset/' + token + '/', config)
            .then((res) => {
                if (res.data.result) {
                  setSuccess(true);
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoader(false);
            });
    }
    

    const handleSubmit = async e => {
        e.preventDefault();

        let errors = {};
        let msgErrorHeader = '';
        if (!password) {
            errors.passwordEnter = 'Please enter your password';
        } else if (password && password.length < 8) {
            errors.passwordEnter = 'Invalid password';
        } else if (password && !password.match(passwordRegex)) {
            errors.passwordEnter = 'Invalid password';
        } else if (password && !password.match(/[0-9]/g)) {
            errors.passwordEnter = 'Invalid password';
        } else {
            delete errors.passwordEnter;
        }

        if (!confirmPassword) {
            errors.confirmPasswordEnter = 'Please enter your confirm password';
        } else if (confirmPassword && confirmPassword.length < 8) {
            errors.confirmPasswordEnter = 'Invalid confirmation password';
        } else if (confirmPassword && !confirmPassword.match(passwordRegex)) {
            errors.confirmPasswordEnter = 'Invalid confirmation password';
        } else if (confirmPassword && !confirmPassword.match(/[0-9]/g)) {
            errors.confirmPasswordEnter = 'Invalid confirmation password';
        } else {
            delete errors.confirmPasswordEnter;
        }

        if (password !== confirmPassword) {
            errors.passwordMatch = 'Passwords do not match';
        } else {
            delete errors.passwordMatch;
        }

        if (password === confirmPassword && Object.keys(errors).length === 0 && (passwordError.errorMinimum === false || passwordError.errorSpecial === false || passwordError.errorNumber === false)) {
            try {
                verifyToken();
            } catch (e) {
                errors.emailExists = 'The token has expired.';
            }
        } else {
            msgErrorHeader = 'Please check the errors marked in red and click the Submit button again after fixing them.';
        }
        setErrorHeader(msgErrorHeader);
        setError(errors);
    }

    const onChangePassword = (e) => {
        let passwordErrors = {};
        delete error.passwordEnter;
        delete error.passwordMatch;
        if (e.target.value.length < 8) {
            passwordErrors.errorMinimum = true;
        } else {
            passwordErrors.errorMinimum = false;
        }
        if (e.target.value && !e.target.value.match(passwordRegex)) {
            passwordErrors.errorSpecial = true;
        } else {
            passwordErrors.errorSpecial = false;
        }
        if (e.target.value && !e.target.value.match(/[0-9]/g)) {
            passwordErrors.errorNumber = true;
        } else {
            passwordErrors.errorNumber = false;
        }
        if (e.target.name === 'password') {
            setPassword(e.target.value);
        } else {
            setConfirmPassword(e.target.value);
        }
        setPasswordError(passwordErrors)
    }

    return (
        <>
            <Loader loading={loader}/>
            <ResponsiveContainer>
                <ContainerSign>
                    <SignUpImage>
                        <img src={sign_banner} alt="form" width={'100%'} />
                    </SignUpImage>
                    {!success ? (<SignInForm onSubmit={handleSubmit}>
                        <FormTitle style={{fontSize: '28px', fontWeight: '700'}}>Reset Password</FormTitle>
                        <div style={{ marginTop: '15px' }}>
                            <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px', marginTop: '15px', marginBottom: '15px' }}>
                                Enter and confirm your new password
                            </TextPoppins>
                        </div>
                        {errorHeader &&
                            <FormErrorHeader>
                                <ErrorItems style={{ fontWeight: 'bold', fontSize: 12, gap: 5 }}>
                                    <div style={{ width: 4, height: 30, backgroundColor: '#FF0000' }} />
                                    <AiFillCloseCircle color='FF0000' size={28} />
                                    <TextPoppins style={{ gap: 4, color: 'white' }}>ERROR</TextPoppins>
                                    <FieldErrorHeaderStatus>{errorHeader}</FieldErrorHeaderStatus>
                                </ErrorItems>
                            </FormErrorHeader>}
                        <FormLabel>Password</FormLabel>
                        <FormControl onInvalid={error.passwordEnter}>
                            <BiLockAlt color='E61383' size={24} />
                            <InputPassword onChange={onChangePassword} name='password' type={showPassword ? 'text' : 'password'} placeholder={" Insert your password"} />
                            {!error.passwordEnter && passwordError.errorMinimum === false && passwordError.errorSpecial === false && passwordError.errorNumber === false && password &&
                                <PasswordDone position={password.length}><AiFillCheckCircle color='E61383' size={16} /></PasswordDone>}
                            {showPassword ? <AiOutlineEye color='E61383' size={24} onClick={() => setShowPassword(false)} /> : <AiOutlineEyeInvisible color='E61383' size={24} onClick={() => setShowPassword(true)} />}
                        </FormControl>
                        {error.passwordEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.passwordEnter}</FieldErrorStatus></ErrorItems>}
                        <FormLabel>Confirm Password</FormLabel>
                        <FormControl onInvalid={error.confirmPasswordEnter}>
                            <BiLockAlt color='E61383' size={24} />
                            <InputConfirmPassword onChange={onChangePassword} name='confirmPassword' type={showPassword ? 'text' : 'password'} placeholder={" Confirm your password"} />
                            {!error.confirmPasswordEnter && passwordError.errorMinimum === false && passwordError.errorSpecial === false && passwordError.errorNumber === false && (confirmPassword === password) &&
                                <PasswordDone position={confirmPassword.length}><AiFillCheckCircle color='E61383' size={16} /></PasswordDone>}
                            {showPassword ? <AiOutlineEye color='E61383' size={24} onClick={() => setShowPassword(false)} /> : <AiOutlineEyeInvisible color='E61383' size={24} onClick={() => setShowPassword(true)} />}
                        </FormControl>
                        {error.confirmPasswordEnter && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.confirmPasswordEnter}</FieldErrorStatus></ErrorItems>}
                        {error.passwordMatch && <ErrorItems><GoAlert color='E61383' size={18} /><FieldErrorStatus>{error.passwordMatch}</FieldErrorStatus></ErrorItems>}
                        {((password && password.length > 0) || (confirmPassword && confirmPassword.length > 0)) &&
                            <FormPassWordError>
                                <PassWordErrorItems>
                                    <AiOutlineCheck color={passwordError.errorMinimum === false ? 'E61383' : '626262'} size={18} />
                                    <PasswordErrorStatus checked={passwordError.errorMinimum === false}>{"Minimum 8 characters"}</PasswordErrorStatus>
                                </PassWordErrorItems>
                                <PassWordErrorItems>
                                    <AiOutlineCheck color={passwordError.errorSpecial === false ? 'E61383' : '626262'} size={18} />
                                    <PasswordErrorStatus checked={passwordError.errorSpecial === false}>{"At least 1 special character"}</PasswordErrorStatus>
                                </PassWordErrorItems>
                                <PassWordErrorItems>
                                    <AiOutlineCheck color={passwordError.errorNumber === false ? 'E61383' : '626262'} size={18} />
                                    <PasswordErrorStatus checked={passwordError.errorNumber === false}>{"At least 1 number"}</PasswordErrorStatus>
                                </PassWordErrorItems>
                            </FormPassWordError>
                        }
                        <BtnSignIn type="submit">
                            <TextButtons>Submit</TextButtons>
                        </BtnSignIn>
                        <FormSignInLink>Have an account? <a href="/sign-in">Sign In</a></FormSignInLink>
                    </SignInForm>) : (
                        <SignInForm>
                            <FormTitle style={{fontSize: '28px', fontWeight: '700'}}>Password Reset Confirmation</FormTitle>
                            <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px' }}>
                                <img src={"/images/activated-icon.png"} alt="recovery" width={72} height={79} />
                            </div>
                            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                                <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px' }}>
                                    Your password has been changed successfully. You can now sign in using your new password.
                                </TextPoppins>
                            </div>
                            <FormResetPasswordLink style={{ textAlign: 'left', marginTop: '20px', marginBottom: '25px' }}>If after this process you still can not recover your account, contact us through our <a href="https://discord.gg/space-misfits-560969562753859585" style={{ textDecoration: 'underline' }} target='_blank' rel="noreferrer">Discord Support Tickets.</a></FormResetPasswordLink>
                            <BtnSignIn onClick={() => navigate('/sign-in')} style={{ marginTop: '10px' }}>
                                <TextButtons>Return to Sign In</TextButtons>
                            </BtnSignIn>
                        </SignInForm>
                    )}
                </ContainerSign>
            </ResponsiveContainer>
            <ResponsiveAlert>
                <ContainerAlert>
                    <RowCore style={{ borderLeft: '5px solid #E71383', alignItems: 'center' }}>
                        <ImgIcon src={help_icon_pink} style={{ width: '15px', height: '15px', marginLeft: '3%' }} />
                        <TextPoppins style={{ fontSize: '11px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%' }}>
                            Fleet Alpha v1 is not optimized for mobile/tablet devices and it will not be supported until the next version following our roadmap, you can play Fleet using the desktop version. We apologize for any inconvenience caused.
                        </TextPoppins>
                    </RowCore>
                </ContainerAlert>
            </ResponsiveAlert>
        </>
    )
}
