import React, { useEffect, useState } from 'react'
import { ContainerTable, FleetData, IconFleet, TableBase, TableBodyMain, TableFootMain, TableHeaderMain, TdData, TdDataFoot, TextV1, ThData, TrData } from './TableExportElement'
import '../../styles/core.css'
import GridSpecs from '../../hooks/GridSpecs'
import InputSetAmount from '../../hooks/InputSetAmount'
import { mineralToIcon } from '../../components/utils/utils'
import { HTTP } from '../../components/http/http-common'

export default function TableExport({cols=[], data=[], onClickRemove, setCrownCost, importItems=false}) {


    let amount = 0;
    let crownCost = 0;

    console.log(data);
    // data.forEach((item) => {
    //     if(!importItems){
    //         if(item.length !== 0){
    //             amount += item[0].specs.includes('is_mineral') ? item[1] : item.length;
    //             crownCost += item[0].hasOwnProperty('commander')
    //                 ? item[0].commander.crown_cost * item.length
    //                 : item[0].crown_cost * (item[0].type === 'mineral' ? 1 : item.length);
    //         }
    //     }else{
    //         amount += item.userBalance;
    //     }
    // });

    const [mCC, setmCC] = useState(null);
    const [mCSO, setmCSO] = useState(null);
    const [mCSA, setmCSA] = useState(null);
    const [mCMO, setmCMO] = useState(null);
    const [mCMA, setmCMA] = useState(null);
    const [crownPrice, setCrownPrice] = useState(null);
    


    const costParams = () => {
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            method: "GET"
        };
        HTTP.request('parameter/costs/', config)
        .then((res) => {
            if (res.data.result){
                setmCC(res.data.costs.minting_cost_commanders)
                setmCSO(res.data.costs.minting_cost_ships_one)
                setmCSA(res.data.costs.minting_cost_ships_additional)
                setmCMO(res.data.costs.minting_cost_mineral_one)
                setmCMA(res.data.costs.minting_cost_mineral_additional)
                setCrownPrice(res.data.costs.crown_price)
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }


    useEffect(() => {
        setCrownCost(crownCost);
        costParams();
    }, [crownCost, setCrownCost, data])


    const getPriceCommanders = (value) => {
        var total_cost = 0
        total_cost =+ value * parseFloat(mCC)
        return total_cost / parseFloat(crownPrice)
    }
    
    const getPriceMinerals = (value) => {
        value = value * 1000
        console.log(value.value)
        var total_cost = 0
        var cost_per_10 = parseFloat(mCMO)
        var count = (value / 1000)
        if (count < 10){
            total_cost += cost_per_10
        }else{
            total_cost += cost_per_10 + ((count-10) * parseFloat(mCMA))
        }
        return total_cost / parseFloat(crownPrice)
    }

    const getPriceShips = (value) => {
        var total_cost = 0;
        console.log(value)
        if(value === 1){
            total_cost += parseFloat(mCSO);
        }else{
            total_cost += parseFloat(mCSO);
            total_cost += (value - 1) * parseFloat(mCSA)
        }
        return total_cost / parseFloat(crownPrice)
    }

    // eslint-disable-next-line array-callback-return
    data.map((item, index) => { 
        amount += item[0].hasOwnProperty('commander') || item[0].hasOwnProperty('item_type') ? item.length : ( item[0].type === 'ship' ? item.length : item[0].value);
        crownCost += item[0].hasOwnProperty('commander') ? getPriceCommanders(item.length) : ( item[0].type === 'ship' ? getPriceShips(item.length) : item[0].hasOwnProperty('item_type') ? 0 : getPriceMinerals(item[0].value));
    });
    

  return (
    <>
        <ContainerTable>
            <TableBase className='table main'>
                <TableHeaderMain >
                    <TrData>
                        {
                        cols.map((column) => (
                            <ThData style={{width: column.width}}>
                            {
                                <div>
                                    {column.Header}
                                </div>
                            }
                            </ThData>
                        ))
                        }
                    </TrData>
                </TableHeaderMain>
                <TableBodyMain>
                    { data.map((item, index) => {
                        return(
                            <TrData id={index + "_row"}>
                            {/* <TdData>
                                <IconActionX onClick={() => removeElement(row)} src={action_x}/>
                            </TdData> */}
                            <TdData>
                                <FleetData>
                                    { !importItems ?
                                        <>
                                        { item[0].hasOwnProperty('specs') || item[0].hasOwnProperty('item_type') ?
                                            <>
                                            <IconFleet src={item[0].hasOwnProperty('commander') ? item[0].commander.image : item[0].image}/>
                                            <TextV1>
                                                {item[0].hasOwnProperty('commander') ? item[0].commander.name : item[0].name}
                                            </TextV1>
                                            </>
                                            :
                                            <>
                                            <IconFleet src={mineralToIcon(item[0].name, true)}/>
                                            <TextV1 style={{textTransform: 'capitalize'}}>
                                                {item[0].name}
                                            </TextV1>
                                            </>
                                        }
                                        </>
                                        :
                                        <>
                                        <IconFleet src={item.image}/>
                                        <TextV1>
                                            {item.name}
                                        </TextV1>
                                        </>
                                    }
                                </FleetData>
                            </TdData>
                            <TdData>
                                {Array.isArray(item) ? item[0].hasOwnProperty('specs') || item[0].hasOwnProperty('item_type') ? item.length : item[0].value : item.userBalance}
                            </TdData>
                            <TdData>
                                { !importItems ?
                                    <>
                                    <GridSpecs specs={item[0].specs} columns={4} spaceBetweenColumns={'4px'}/>
                                    
                                    </>
                                    :
                                    null
                                }
                            </TdData>
                                <TdData>
                                    {item[0].hasOwnProperty('commander') ? parseInt(getPriceCommanders(item.length), 10) : ( item[0].type === 'ship' ? parseInt(getPriceShips(item.length), 10) : item[0].hasOwnProperty('item_type') ? 0 : parseInt(getPriceMinerals(item[0].value), 10))}
                                </TdData>
                            <TdData>
                                <InputSetAmount item={item} index={index} placeholder={0} onClickRemove={onClickRemove} importItems={importItems}/>
                            </TdData>
                            </TrData>
                        )
                    })
                    }
                </TableBodyMain>
            </TableBase>
        </ContainerTable>
        <TableFootMain>
            <TdDataFoot colSpan={2} >
                <strong>Totals</strong>
            </TdDataFoot>
            <TdDataFoot>
                <strong>{amount}</strong>
            </TdDataFoot>
            <TdDataFoot>
                <strong>{parseInt(crownCost, 10)}</strong>
            </TdDataFoot>

        </TableFootMain>
    </>
  )
}
