import styled from "styled-components";
import { ContainerBasic, ContainerVoid, GridViewCore, ImgIcon, ColCore, RowCore, TextPoppins, TextTitiliumWeb, GridViewCoreScroll2, Img, ContainerBasicBG } from "../../../../containers/CoreElement";
import {
    Link
} from "react-router-dom";

export const ContainerFooterHeader = styled(ContainerBasic)`
    justify-content: flex-end;
    padding: 0px;
    height: 35px;
    width: 100%;
    background: rgba(141, 12, 80, 0.5);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E71383;
`

export const ContainerValidations = styled(GridViewCore)`
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 100%;
`
export const SubElementValidations = styled.div`

`

export const ContainerValidationsMain = styled(ColCore)`
    margin-top: 20px;
    background: #520830;
    border: 1px solid #E71383;
    padding: 10px;
`

export const ContainerQrPopup = styled(ColCore)`
    width: 655px;
    gap: 20px;
`

export const ContainerQrCode = styled.div`
    width: 175px;
    height: 39.98px;
    background: #220313;
    justify-content: center;
    align-items: center;
    display: flex;
`

export const ContainerRentalTime = styled(ContainerVoid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240.51px;
    height: -webkit-fill-available;
    background: #CF1839;
    border: 2px solid #CF1839;
    padding-inline: 10px;
`
export const ContainerWeekReset = styled(ContainerVoid)`
    display: flex;
    align-items: center;
    width: 240.51px;
    padding-inline: 10px;
`
export const ContainerCollateralLock = styled(ContainerWeekReset)`
    background: #E71383;
`
export const IconWeekReset = styled(ImgIcon)`
    width: 20px;
    height: 20px;
    margin-right: 4%;
`
export const ContainerSectionMain = styled(ColCore)`
    
`

export const ContainerSelectOutpost = styled(ColCore)`
    margin-top: 20px;
    gap: 20px;
`

export const ContainerBIP = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
`


export const SubElementSelectOutpost = styled.div`
    border: 1px solid #E71383;
    background: rgba(231, 19, 131, 0.2);
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
`

export const SubElementContainerSelectOutpost = styled(ColCore)`
    position: relative;
`

export const SubDataSelectOutpost = styled(ColCore)`
    padding: 10px;
    align-items: flex-start;
`

export const ContainerGridSpecs = styled.div`
    height: 38px;
    width: 51px;
`

export const ContainerQrLinkWallet = styled(ColCore)`
    justify-content: center;
    align-items: center;
`

export const QrLinkWallet = styled.img`
    width: 175px;
    height: 175px;
`

export const Btn = styled.button`
    background: #E71383;
    border: 1px solid #E71383;
    color: white;
    padding: 12px 24px;
    cursor: pointer;
`

export const IconOff = styled.img`
    width: 30px;
    height: 30px;
`

export const IconOutpost = styled.img`
    width: 173px;
    height: 173px;
`

export const Header = styled(ContainerVoid)`
    background: linear-gradient(0deg, rgba(231, 19, 131, 0.05), rgba(231, 19, 131, 0.05)), rgba(0, 0, 0, 0.1);
    border: 0.5px solid #D51279;
    gap: 8px;
    padding: 10px;
    display: flex;
`

export const ContainerOthersItems = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`

export const TextTitiliumWebCustom1 = styled(TextTitiliumWeb)`
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0px;
`

export const TextTitiliumWebCustom2 = styled(TextTitiliumWebCustom1)`
    font-weight: 400;
`
export const TextTitiliumWebTimeLeft = styled(TextTitiliumWebCustom2)`
    color: #E71383;
`
export const TextTitiliumWebTimeLeftBold = styled(TextTitiliumWebTimeLeft)`
    color: #ffffff;
    font-weight: bold;
`
export const TextTitiliumWebNumBold = styled(TextTitiliumWebTimeLeftBold)`

`

export const ContainerGeneral = styled(GridViewCore) `
    display: block;
    max-height: 500px;
    margin-top: 2%;

    @media (max-width: 600px) {
        margin-bottom: 20%;
    }
`

export const ContainerAlert = styled(ContainerBasicBG) `
    padding: 30px 15px;
    height: fit-content;
    margin-top: 2%;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(0deg, #E71383, #E71383),
    linear-gradient(0deg, rgba(231, 19, 131, 0.2), rgba(231, 19, 131, 0.2));
`

export const ContainerMsgAlert = styled(ContainerVoid) `
    width: 100%;
    padding: 10px 25px;
    background:#8D0C50B2;
`

export const RowMsg = styled(RowCore) `
    border-left: 3px solid #E71383; 
    align-items: center;
`

export const ImgInfo = styled(ImgIcon) `
    width: 15px; 
    height: 15px;
    margin-left: 2%;
`

export const TextInfo = styled(TextTitiliumWeb) `
    font-size: 15px; 
    text-align: left;
    line-height: 18px;
    font-weight: 300;
    color: #FFFFFFB2;
`

export const TextTitiliumWebCustom3 = styled(TextTitiliumWebCustom1)`
    font-weight: 700;
    font-size: 24px;
    line-height: 36.5px;
    letter-spacing: 0.02em;
`

export const BottomBorder = styled.div`
    border-bottom: 1px solid #E71383;
    width: 100%;
    margin-top: 10px;
`

export const ContainerSection = styled(ContainerVoid)`
    gap: 10px;
`

export const BtnLinkOutpost = styled.button`
    background: #E71383;
    border: 1px solid #E71383;
    height: 43px;
    position: absolute;
    bottom: 0;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
`

export const TextPoppinsCustom1 = styled(TextPoppins)`
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0px;
`

export const TextPoppinsCustom2 = styled(TextPoppinsCustom1)`
    color: rgba(207, 24, 57, 1);
`

export const TextPoppinsCustom6 = styled(TextPoppinsCustom1)`
    color: rgba(255, 184, 0, 1);
`

export const TextPoppinsCustom5 = styled(TextPoppinsCustom1)`
    color: rgba(231, 19, 131, 1);
`

export const TextPoppinsCustom3 = styled(TextPoppinsCustom1)`
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
`

export const TextPoppinsCustom4 = styled(TextPoppinsCustom1)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
`

export const RectangleContainerGeneral = styled.div`
    margin: 0px 3px 15px 0px;

`

export const RectangleContainerGeneral2  = styled.div  `
    display: inline-flex;
    margin: 0px 0px 0px 0px;
    width: 100%;
`
export const RectangleImage = styled(Img) `
    border: 1px solid #E71383;
    width: 130px;
    height: 130px;
    height: auto;

    @media (max-width: 600px) {
        display: none;
    }
`
export const RectangleContainerComplete = styled.div`
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    width: 100%;
    justify-content: space-between;
    display: flex;
    color: white;
    border-left: 0px;
    
    @media (max-width: 1160px) {
        width: 100%;
        padding: 12px;
    }

    @media (max-width: 600px) {
        width: 100%;
        border-left: 1px solid #E71383;;
        padding: 7px;
    }
`
export const RectangleRowsContainer = styled.div`
    width: 100%;
    padding: 20px;
`

export const RectangleRowContainerFirst = styled.div`
    /* border: 1px solid transparent;
    border-image: linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px; */
    padding-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const ContainerPercentComplete = styled(ContainerVoid)`
  border-top: 1px solid #CA1173;  
  border-bottom: 1px solid #CA1173; 
`



export const RectangleRowFirstContainer1 = styled.div`
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
`

export const RectangleRowFirstContainer1Outposts = styled.div`
    display: grid;
    grid-column-gap: 36px;
    grid-template-columns: repeat(2, 1fr);
`

export const RectangleRowFirstContainer2 = styled.div`
    
    display:inline-flex;
`

export const RectangleRowFirstContainerIconAndText = styled.div`  
    display:inline-flex;
`

export const RectangleRowSecondContainerStats = styled.div`
    padding-top: 10px;
    padding-bottom: 5px;
`

export const ContainerIconClock = styled.div`
    margin-right: 5px;
`

export const RectangleRowContainerSecond = styled.div`
    width: 50%;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4,1fr);
    margin-right: 2%;
`
export const RectangleRowContainerNumShips = styled(RectangleRowContainerSecond)`
    width: 5%;
`

export const RectangleRowText = styled(TextTitiliumWeb)`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    color: #FFFFFF;
`

export const RectangleRowTextRoutes = styled(TextTitiliumWeb)`
    
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 300;
    background-color:rgba(141, 12, 80, 0.5);
    border: 1px solid rgba(231, 19, 131, 1);
    height: 21px; 
    line-height: 157%;
    padding: 0px 3px 0px 3px;
    margin: 10px 6px 0px 6px; 
`

export const RectangleRowTextPurple = styled(TextTitiliumWeb)`
    color: rgba(231, 19, 131, 1);
    font-family: 'Titillium Web';
    font-style: normal;
    letter-spacing: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
`
export const RectangleRowTextPurpleValueIcon = styled(RectangleRowTextPurple)`
`

export const TextStats = styled(RectangleRowTextPurple)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
`

export const TextButton = styled(TextTitiliumWeb)`
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
`
///
export const RectangleButtonContainer = styled.div `
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 1px solid transparent;
    border-image: linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;
`

export const RectangleButtonContainerV2 = styled.div `
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 1px solid transparent;
`
export const RectangleButtonContainerV2Desktop = styled(RectangleButtonContainerV2) `

    @media (max-width: 600px) {
        display: none;
    }
`

export const RowCoreCellphone = styled(RowCore) `

    margin-top: 8%;
    justify-content: center;


    @media (min-width: 601px) {
        display: none;
    }
`

export const Icon = styled.img`

`
export const IconButton = styled.img`
    width: 20px;
`

export const ImgCommander = styled.img`
    width: 20px;
    height: 20px;
`
export const TextNameCommander = styled(TextPoppins)`
    font-style: italic;
    font-size: 11px;
    line-height: 19px;
    color: #FFFFFF;
`

export const Button = styled.button`
    width: 40px;
    height: 40px;
    background: #8D0C50;
    border: 1px solid #E71383;
`

export const ButtonV2 = styled.button`
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    background-size: cover;
`

export const RoutesContainerText = styled(ContainerBasic)`
    padding: 0px;
    height: 30%;
    margin: 0px 3px 0px 3px;
    background : rgba(141, 12, 80, 0.5);
`

export const RoutesContainerText2 = styled(ContainerBasic)`
    padding: 0px 9px 0px 9px;
    margin: 0px 3px 0px 3px;
    background-color : #530830;
    margin: 0px 150px 0px 150px;
`

export const ContainerMyFleet = styled.div`
    box-sizing: border-box;
    padding: 20px;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
    background: rgba(94, 8, 53, 0.1);
    background-size: 100% 100%;
    border: 1px solid rgba(231, 19, 131, 1);
    position: relative;
    color: white;
`
export const TittleV1 = styled.div`
    justify-content: left;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
`
export const IconTittle = styled.img`
    margin-right: 10px;
`
export const TittleTextV1 = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
`
export const ContainerTableMyFleet = styled.div`
    gap: 13px;
    border-radius: 4px;
    color: white;
    padding-bottom: 0;
    position: relative;
`

export const FleetResumeGeneral = styled.div`
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 1168px;
    height: 342px;
    justify-content: space-between;
    display: table-row;
    color: white;
    padding: 3px 15px 15px 15px;
    @media (max-width: 1160px) {
        width: 75%;
       
        padding: 12px;
    }
`

export const FleetResumeRows = styled.div`
    border-bottom: 1px solid #E71383;
    border-radius: 2px;
    width: 98%;
    height: 60px;
    display: flex;
    align-items: center;
`

export const FleetResumeRowTotal = styled.div`
    
    border-radius: 2px;
    width: 96%;
    height: 36px;
    display: flex;
    align-items: center;
`

export const FleetResumeRowTotalContainer = styled.div`
    
    border-radius: 2px;
    width: 99%;
    height: 36px;
    display: flex;
    align-items: center;
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid rgba(141, 12, 80, 0.5);
`

export const FleetResumeRowsFleet = styled.div`
    width: 300px;
    height: 42px;
  
    display: flex;
    align-items: center;
`

export const FleetResumeRowsAmount = styled.div`
    width: 122px;
    height: 42px;
    
`



export const FleetResumeRowsEquipment = styled.div`
    width: 184px;
    height: 42px;
 
    display: flex;
    align-items: center;
`
export const FleetResumeRowsType = styled.div`
    width: 184px;
    height: 42px;
   
    display: flex;
    align-items: center;

`
export const FleetResumeRowsStat = styled.div`
    width: 96px;
    height: 42px;
    
`

export const FleetResumeRowsContainerGeneral = styled.div`
    width: 99%;
    height: 270px;
    display: block;
    
    border: 3px solid rgba(231, 19, 131, 1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    padding: 0px 0px 0px 6px;
`
export const FleetResumeRowsContainerRows = styled(GridViewCoreScroll2)`
    width: 99%;
    height: 210px;
    display: block;
    overflow-y: scroll;
    
    
   
`

export const FleetResumeTitles = styled(TextTitiliumWeb)`
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: 300;
    color:  #A9A7BB;
`

export const RowIcons = styled.img`
    width: 39x;
    height: 39px;
`

export const FleetResumeContainerTitle = styled.div`
    display: flex;
`

//DropDownDashboard

export const ContainerDropDownDashboard = styled.div`
    box-sizing: border-box;
    padding: 9px;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
    background: rgba(94, 8, 53, 0.1);
    background-size: 100% 100%;
    border: 1px solid rgba(231, 19, 131, 1);
    border-top:0px;
`

export const ContainerDropDownDashboard2 = styled.div`
    padding: 9px;
    border: 1px solid rgba(231, 19, 131, 1);
    display: flex;
    margin-bottom: 15px;
`

export const RectangleEmptyDashboard = styled.div`
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 765px;
    height: 130px;

   
    border-left: 0px;
    
    @media (max-width: 1160px) {
        width: 75%;
        padding: 12px;
    }
`

export const RectangleEmptyContainerGeneralDashboard = styled.div`

    margin: 0px 0px 15px 0px;
`

export const RectangleEmptyContainerGeneral2Dashboard  = styled.div  `
    display: inline-flex;
    margin: 0px 0px 15px 0px;
`
///Rectangle Complete
export const RectangleContainerGeneralDashboard = styled.div`
    margin: 0px 3px 54px 0px;
    display: grid;

`

export const RectangleElementFirstDashboard = styled.div`

`

export const RectangleContainerGeneral2Dashboard  = styled.div  `
    display: inline-flex;
    margin: 0px 0px 0px 0px;
    height:130%;
`

export const RectangleImageDashboard = styled(Img) `
    border: 1px solid #E71383;
    width: 180px;
    height: 130px;
    height: auto;
`

export const RectangleContainerCompleteDashboard = styled.div`
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    width: 100%;
    justify-content: space-between;
    display: flex;
    color: white;
    border-left: 0px;
    
    @media (max-width: 1160px) {
        width: 75%;
       
        padding: 12px;
    }
`

export const RectangleContainerCompleteRentedDashboard = styled.div`
    background: linear-gradient(0deg,rgb(0 0 0 / 0%),rgba(0,0,0,0.5)),rgba(231,19,131,0.2);
    border: 1px solid #E71383;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: grid;
    color: white;
    border-left: 0px;
    
    @media (max-width: 1160px) {
        width: 75%;
       
        padding: 12px;
    }
`

export const RectangleRowsContainerDashboard = styled.div`
    width: 100%;
    padding: 0px 12px 0px 12px;
`

export const RectangleRowsContainerRowDashboard = styled.div`
    width: 100%;

    border: 1px solid transparent;
    border-image: linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
`

export const RectangleRowContainerFirstDashboard = styled(RowCore)`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const GridViewEnemyShips = styled(GridViewCore)`
    grid-template-columns: repeat(6, 1fr);
`

export const RectangleRowFirstContainer1Dashboard = styled.div`
    display: grid;
    grid-column-gap: 0px;
    grid-template-columns: repeat(4, 1fr);
`

export const RectangleRowFirstContainer1OutpostsDashboard = styled.div`
    display: grid;
    grid-column-gap: 36px;
    grid-template-columns: repeat(2, 1fr);
`

export const RectangleRowFirstContainer2Dashboard = styled.div`
    
    display:inline-flex;
`

export const RectangleRowFirstContainerIconAndTextDashboard = styled.div`  
    display:inline-flex;
`

export const RectangleRowSecondContainerStatsDashboard = styled.div`

`

export const RectangleRowContainerSecondDashboard = styled.div`
    width: 100%;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: repeat(4,1fr);
    height: 21px;
`
export const RectangleRowContainerSecondMissionsDashboard = styled(RectangleRowContainerSecond)`
    display: flex;
    gap: 20px;
`

export const RectangleRowTextDashboard = styled(TextTitiliumWeb)`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 19px;
    color: #FFFFFF;
`

export const RectangleRowTextRoutesDashboard = styled(TextTitiliumWeb)`
    
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 300;
    background-color:rgba(141, 12, 80, 0.5);
    border: 1px solid rgba(231, 19, 131, 1);
    height: 21px; 
    line-height: 157%;
    padding: 0px 3px 0px 3px;
    margin: 10px 6px 0px 6px; 
`

export const RectangleRowTextPurpleDashboard = styled(TextTitiliumWeb)`
    color: rgba(231, 19, 131, 1);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
`
export const RectangleRowTextPurpleValueIconDashboard = styled(RectangleRowTextPurple)`
`

export const TextStatsDashboard = styled(RectangleRowTextPurple)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
`

export const TextButtonDashboard = styled(TextTitiliumWeb)`
    font-size: 15px;
    font-weight: medium;
    letter-spacing: 0px;
`
///
export const RectangleButtonContainerDashboard = styled.div `
    width: 180px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-left: 1px solid transparent;
    border-image: linear-gradient(270deg, rgba(141, 12, 80, 0.45) 4.92%, #CA1173 52.91%, rgba(141, 12, 80, 0.45) 99.92%);
    border-image-slice: 1;
`

export const IconDashboard = styled.img`

`

export const IconButtonDashboard = styled.img`

`

export const ButtonDashboard = styled.button`

    border: 1px solid rgba(231, 19, 131, 1);
    width: 110px;
    height: 70px;
    background-color:rgba(231, 19, 131, 1);
    clip-path: "";
    -webkit-clip-path: "";
    cursor: pointer;


    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
   
`


export const RoutesContainerTextDashboard = styled(ContainerBasic)`
    padding: 0px;
    height: 30%;
    margin: 0px 3px 0px 3px;
    background : rgba(141, 12, 80, 0.5);
`

export const RoutesContainerText2Dashboard = styled(ContainerBasic)`
    padding: 0px 9px 0px 9px;
    margin: 0px 3px 0px 3px;
    background-color : #530830;
    margin: 0px 150px 0px 150px;
`



export const FleetResumeGeneralDashboard = styled.div`
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 1168px;
    height: 342px;
    justify-content: space-between;
    display: table-row;
    color: white;
    padding: 3px 15px 15px 15px;
    @media (max-width: 1160px) {
        width: 75%;
       
        padding: 12px;
    }
`

export const FleetResumeRowsDashboard = styled.div`
    border-bottom: 1px solid #E71383;
    border-radius: 2px;
    width: 98%;
    height: 60px;
    display: flex;
    align-items: center;
`

export const FleetResumeRowTotalDashboard = styled.div`
    
    border-radius: 2px;
    width: 96%;
    height: 36px;
    display: flex;
    align-items: center;
`

export const FleetResumeRowTotalContainerDashboard = styled.div`
    
    border-radius: 2px;
    width: 99%;
    height: 36px;
    display: flex;
    align-items: center;
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid rgba(141, 12, 80, 0.5);
`

export const FleetResumeRowsFleetDashboard = styled.div`
    width: 300px;
    height: 42px;
  
    display: flex;
    align-items: center;
`

export const FleetResumeRowsAmountDashboard = styled.div`
    width: 122px;
    height: 42px;
    
`



export const FleetResumeRowsEquipmentDashboard = styled.div`
    width: 184px;
    height: 42px;
 
    display: flex;
    align-items: center;
`
export const FleetResumeRowsTypeDashboard = styled.div`
    width: 184px;
    height: 42px;
   
    display: flex;
    align-items: center;

`
export const FleetResumeRowsStatDashboard = styled.div`
    width: 96px;
    height: 42px;
    
`

export const FleetResumeRowsContainerGeneralDashboard = styled.div`
    width: 99%;
    height: 270px;
    display: block;
    
    border: 3px solid rgba(231, 19, 131, 1);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
`
export const FleetResumeRowsContainerRowsDashboard = styled(GridViewCoreScroll2)`
    width: 99%;
    height: 210px;
    display: block;
    overflow-y: scroll;
    
   
`

export const FleetResumeTitlesDashboard = styled(TextTitiliumWeb)`
    font-size: 18px;
    letter-spacing: 0px;
    font-weight: 300;
    color:  #A9A7BB;
`

export const RowIconsDashboard = styled.img`
    width: 39x;
    height: 39px;
`

export const FleetResumeContainerTitleDashboard = styled.div`
    display: flex;
`

export const ButtonContainer = styled(Link)`

    border: 1px solid rgba(231, 19, 131, 1);
    width: 110px;
    height: 40px;
    background-color:rgba(231, 19, 131, 1);
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
    cursor: pointer;


    align-items: center;
    justify-content: center;
    display: flex;
    color: white;
   
`


