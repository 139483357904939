import React, { useCallback, useEffect, useState } from 'react'
import { ContainerMessagesMain, ContainerMessagesScroll } from '../../MessagesElement'
import Pagination from '../../../../../../hooks/Pagination'
import { Message } from '../../hooks/Message'
import { SystemMessage } from '../../hooks/SystemMessage';
import { HTTP } from '../../../../../http/http-common';
import { RouteMessage } from '../../hooks/RouteMessage';
import { Loader } from '../../../../../../hooks/Loader';
import { TextPoppins } from '../../../../../../containers/CoreElement';
import PopUp from '../../../../../../hooks/PopUp';

export default function AllMsg({setShowSections, extendedHeader, extended}) {

  const [loader, setLoader] = useState(false);
  const [messages, setMessages] = useState([]);
  const [showPopUpMsg, setShowPopUpMsg] = useState(false);
  const [data, setData] = useState({});
  const [, setShips] = useState([]);
  const [, setRewards] = useState([]);

  const [popupServerError, setPopupServerError] = useState(false);
  const [textServerError, setTextServerError] = useState('Server Error');

  const fetchMessages = useCallback(() => {
      setLoader(true);
      var config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          data: {"messages_filter":"all"}
      };
      HTTP.request('messages/', config)
      .then((res) => {
          var data = [];
          res.data.forEach((element) => {
            console.log(element);
              if(element.trash === false){
                data.push(element)
              }
          })
          setMessages(data);
      })
      .catch((err) => {
          console.log(err);
          setPopupServerError(true);
          setTextServerError(err.response.statusText);
      })
      .finally(() => {
          setLoader(false);
        })
  }, []);

  const fetchCountMessages = () => {
    if (sessionStorage.getItem('token')){
      var config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
      };
      HTTP.request('messages/count/', config)
      .then((res) => {
          if (res.data.result){
            document.getElementById("count-messages").innerText = res.data.count;
          }
      })
      .catch((err) => {
          console.log(err);
          setPopupServerError(true);
          setTextServerError(err.response.statusText);
      });
    }
  };

  const clickBtnClose = () => {
      setShowPopUpMsg(false);
  };

  const openMessage = (message) => {
      setLoader(true);
      const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          data: {"message": message},
          method: "POST"
      };
      HTTP.request('message/is/open/', config)
      .then(() => {

      })
      .catch((err) => {
          console.log(err);
          setPopupServerError(true);
          setTextServerError(err.response.statusText);
      })
      .finally(() => {
        setLoader(false);
      })
  }

  const clickBtnViewMsg = (msg) => {
      openMessage(msg.id)
      console.log(msg);
      setShips(msg.ships);
      setRewards(msg.rewards);
      setShowPopUpMsg(true);
      setData(msg);
      setShowSections(false);
      extendedHeader(false);
  };


  useEffect(() => {
      fetchCountMessages();
      fetchMessages();
      setShowPopUpMsg(extended);
  }, [extended, fetchMessages])

  return (
    <>
      <Loader loading={loader}/>
      { !showPopUpMsg ?
        <ContainerMessagesMain>
        <Pagination elements={messages} elementsPerPage={9} >
            {data => (
                <ContainerMessagesScroll>
                    <>
                        { data.map((mesg) => (
                                <Message 
                                action={() => clickBtnViewMsg(mesg)}
                                item = {mesg}
                                ></Message>
                            ))
                        }
                    </>
                </ContainerMessagesScroll>
            )}
        </Pagination>
        </ContainerMessagesMain>
        :
        <div>
            { data.is_route_message ?
                
                <RouteMessage  
                    image={messages}
                    expectedReward={data.result.expected_reward}
                    actionClose={clickBtnClose} 
                    dataSummary={data.result.data_ships}
                    type={data.type}
                    created_at={data.created_at}
                    title={data.title}
                    text={data.text}
                    textPurple={data.title}
                    rewardsCondition={data.result.battle_win}
                    rewards={data.result.carry_reward}
                    isOpen={data.is_open}
                    isRoute={data.is_route_message}
                    date={data.created_at}
                    dataRoute={data.route}
                    routeDifficulty={data.route.p_mission_economic["difficulty"]}
                    enemys={data.route.horde}
                    idRoute={data.route.id}
                    was_claimed={data.was_claimed}
                    reward_id={data.reward_id}
                    commander={data.commanderxuser}
                ></RouteMessage>

            :
                <SystemMessage

                    actionClose={clickBtnClose} 
                    type={data.type}
                    created_at={data.created_at}
                    title={data.title}
                    text={data.text}
                    textPurple={data.title}
                    isOpen={data.is_open}
                    isRoute={data.is_route_message}
                    date={data.created_at}

                ></SystemMessage>
            }
        </div>
        }

    <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
          {textServerError}
        </TextPoppins>
    </PopUp>
    </>
  )
}
