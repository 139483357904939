import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../../../http/http-common';

import background_outpost from '../../../../../../assets/images/outpost_image.png'
import '../../../../../../components/Home/Pages/Outposts/hooks/styles.css'
import ReusableRectangleRented from '../../../../../../hooks/ReusableRectangleRented'

import ReusableRectangleOutposts from '../../../../../../hooks/ReusableRectangleOutposts'
import { 
  ContainerOutpost,
  ContainerGeneral
} from '../YourOutposts/YourOutpostsElement'
import { Loader } from '../../../../../../hooks/Loader';

export function YourOutposts() {

  const [loader, setLoader] = useState(false);
  const [outposts, setOutposts] = useState([]);

  useEffect(() => {
    fetchMissions();
  }, []);


  const fetchMissions = () => {
    setLoader(true);
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      method: "GET"
    };

    HTTP.request('get_your_outposts', config)
      .then((res) => {
        console.log(res);
        setOutposts(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })

  };

  const putToRent = (id) => {
    setLoader(true);
    var config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      data: {"outpost":id},
      method: "POST"
    };

    HTTP.request('rent_your_outpost', config)
      .then((res) => {
        console.log(res);
        fetchMissions()
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })

  };

  return (
    <>
    <Loader loading={loader}/>
    <ContainerGeneral style={{marginTop:"15px", width:"100%"}} className='ContainerGeneral'>
      <div>
        {outposts.map((outpost) => (
          <ContainerOutpost style={{display:"table", width:"100%"}}>
            <>
              { (outpost.in_rent || outpost.is_rented)  &&
              <ReusableRectangleRented 
              image = {background_outpost}
              time = "22h 26min"
              ></ReusableRectangleRented>
              }
            </>
            <>
              { !(outpost.in_rent || outpost.is_rented) &&
           <ReusableRectangleOutposts  
                
           image = {background_outpost}
           
           //Outpost:
           value1 = {outpost.name}
           //Max Routes:
           value2 = {outpost.max_routes}
           value3 = "value3"
           value4 = {outpost.price_rent}
           
           textValue_icon1 = "20 Hours"
           
           value1Second = "v"
           value2Second = "value2"
           value3Second = "value3"
           value4Second = "value4"
           
           buttonText = "Select Route"

           action = {() =>  putToRent(outpost.id)}
           
           //buttonColor = "rgba(231, 19, 131, 1)"
           
           ></ReusableRectangleOutposts>
              }
            </>
          </ContainerOutpost>
        ))}
      </div>
    </ContainerGeneral>
    </>
    
  )

}
