import styled from 'styled-components'

import background_enemies_fleet from '../../../../assets/images/background_enemies_fleet.jpg'

import modal from '../../../../assets/images/modal.png'

export const ContainerPopup = styled.div`
    background-image: url(${modal});
    background-size: 100% 100%;
    width: 20%;
    height: auto;
    padding-bottom: 10px;
    position: fixed;
    z-index: 50;
    top: 20%;
    left: 40%;
    color: white;
    text-align: center;
`

export const PopupSuccess = styled.img`

`

export const ContainerMissionDataSpa = styled.div`
    justify-content: space-between;
    display: flex;
`

export const ContainerMission = styled.div`
    box-sizing: border-box;
    margin: 20px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    border: 1px solid #E71383;
    background: rgba(0, 0, 0, 0.1);
    position: relative;
`

export const MissionArrow = styled.img`
    top: -20px;
    right: -20px;
    position: absolute;
    cursor: pointer;
}
    
`

export const MissionName = styled.h1`
    color: #FFFFFF;
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    padding-left: 20px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

export const MissionInfo = styled.div`
    padding-inline: 20px;
    justify-content: space-between;
    display: flex;
`

export const ContainerMissionContainerImg = styled.div`
    display: flex;
    align-items: end;
    width: 10%;
`

export const ContainerMissionImg = styled.img`
    width: 100%;
`

export const ContainerMissionData = styled.div`
    width: 88%;
    background: rgba(231, 19, 131, 0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
`

export const ContainerMissionData1 = styled.div`
    width: 55%;
    margin-top: 20px;
    margin-left: 20px;
    color: white;

    @media (max-width: 1160px) {
        width: 45%;
    }
`

export const ContainerMissionData2 = styled.div`
    width: 200px;
    justify-content: right;
    display: flex;
    margin-top: 20px;
    margin-right: 20px;
    color: white;
`

export const ContainerMissionData3 = styled.div`

`

export const SendFleet = styled.button`
    
`

export const ContainerMissionDataDesc = styled.span`
`

export const ContainerMissionDataContainerDuration = styled.div`
    width: 100%;
    height: 42px;
    background: linear-gradient(0deg,rgba(0,0,0,0.2),rgba(0,0,0,0.2)),rgb(19 203 231 / 47%);
    border: 0.5px solid #13CBE7;
    border-radius: 2px;
    justify-content: space-between;
    display: flex;
    align-items: center;
`

export const ContainerMissionDataDurationClockImg = styled.img`
    margin-right: 5px;
`

export const ContainerMissionDataCharac = styled.div`
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: white;
`

export const ContainerMissionDataCharacElement = styled.div`
    width: 45%;
    height: 42px;
    background: linear-gradient(0deg,rgba(0,0,0,0.2),rgba(0,0,0,0.2)),rgb(231 19 131 / 47%);
    border: 0.5px solid #E71383;
    border-radius: 2px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin: 2%;
`

export const ContainerMissionDataCharacElement1 = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    justify-content: center;
    display: flex;

`

export const ContainerMissionDataCharacElementDivi = styled.img`
    
`

export const ContainerMissionDataCharacElement2 = styled.div`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-right: 15px;
    justify-content: center;
    display: flex;

`

export const MissionEnemiesFleet = styled.div`
    margin-inline: 20px;
    background: linear-gradient(0deg, rgba(238, 141, 28, 0.25), rgba(238, 141, 28, 0.25)), linear-gradient(90.06deg, #000000 60.41%, rgba(0, 0, 0, 0.81) 75.05%, rgba(0, 0, 0, 0) 99.95%), url(${background_enemies_fleet});
    border: 1px solid #EE8D1C;
    border-radius: 2px;
    background-size: 100% 100%;
    padding: 20px;
`

export const MissionEnemiesFleetContainerTable = styled.div`
    width: 60%;
`

export const ContainerMissionEnemiesFleet = styled.div`

`