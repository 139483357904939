import styled from 'styled-components'
import { ContainerVoid, RowSpaceBetween, BtnPolygon, TextPoppins } from '../../containers/CoreElement'

export const IconSMA = styled.img`
    width: 450px;

`

export const ContainerSign = styled.div`
    width: 50%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;

    @media (max-width: 1230px) {
        margin-top: 40px;
        width: 90%;
        margin-bottom: 5%;
    }
`

export const SignInForm = styled.form`
    width: 100%;
    padding: 50px;
    background: #5E0835;
    border: 1px solid #E61383;
`;
 
export const ResponsiveContainer = styled.div`
    /* @media (max-width: 1200px) {
        display: none;
    } */
`
export const ResponsiveAlert = styled.div`
    @media (min-width: 1201px) {
        display: none;
    }
`

export const ContainerAlert = styled(ContainerVoid)`
    width: 80%;
    background: rgba(141, 12, 80, 0.7);
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const ForgotLink = styled.div`
    font-size: 18px;
    color: white;
    a {
        color: #E61383;
        text-decoration: underline;
        font-family: 'Poppins';
    }
`;
export const RowItems = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
   
export const RowSpaceBetweenButtons = styled(RowSpaceBetween)`
    margin-top: 15%;
    gap: 20px;
    width: 100%;
`

export const BtnSignIn = styled(BtnPolygon)`
    color: white;
    width: 100%;
    background: rgba(141, 12, 80, 0.70);
    height: 54px;
    margin-top: 30px;
    /* @media (max-width: 1000px) {
        width: 304px;
    } */
`
export const TextButtons = styled(TextPoppins)`
    font-weight: 600;
    letter-spacing: 0.02em;
    font-size: 18px;
    padding: 12px 0px;
`
export const FormSignInLink = styled(TextPoppins)`
    text-align: center;
    color: white;
    font-size: 18px;
    margin-top: 10px;
    font-family: 'Poppins';
    a {
        color: #E61383;
        text-decoration: underline;
        font-family: 'Poppins';
    }
`;

export const FormResetPasswordLink = styled(FormSignInLink)`
    font-size: 18px;
    line-height: 24px;
`;