import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BtnSignIn, TextButtons } from '../Signflow/SignflowElement'
import { ContainerRegister, SignUpDiv, SignUpImage, FormSupportLink, FormTitle } from './RegisterElement';

import sign_banner from '../../assets/images/new-sign-banner.png'

import '../../styles/Signflow.css'

import { TextPoppins } from '../../containers/CoreElement'
import { HTTP } from '../http/http-common';
import { Loader } from '../../hooks/Loader';

export function Confirmation() {

  let navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  
  const handleResend = async () => {
    setLoader(true);
    var config = false;
    if (location.state){
      config = {
        data: {"email": location.state.email},
        method: "POST"
      };
    }else if(sessionStorage.getItem('email_to_confirm')){
      config = {
        data: {"email": sessionStorage.getItem('email_to_confirm')},
        method: "POST"
      };
    }
    console.log(config)
    if (config) {
      console.log("enter")
      HTTP.request('resend/confirm/', config)
        .then((res) => {
            if (res.data.result) {
                window.location.reload();
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoader(false);
        });
    }
  }

  return (
    <>
      <Loader loading={loader}/>
      {/* <ResponsiveContainer> */}
        <ContainerRegister>
          <SignUpImage>
            <img src={sign_banner} alt="form" width={'100%'} />
          </SignUpImage>
          <SignUpDiv>
            <FormTitle style={{fontSize: 28, fontWeight: '700'}}>Activate Your Email</FormTitle>
            <div style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', padding: '20px' }}>
              <img src={"/images/activated-icon.png"} alt="recovery" width={72} height={79} />
            </div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px' }}>
              {"We have sent the activation link instructions to the email address you provided during your sign up."}
              </TextPoppins>
            </div>
            <div style={{ marginTop: '15px', marginBottom: '10px' }}>
              <TextPoppins style={{ color: 'white', lineHeight: '24px', fontSize: '18px' }}>
                {`If you haven't received it, please check your spam folder or use the “Resend activation link” button.`}
              </TextPoppins>
            </div>
            <FormSupportLink style={{ textAlign: 'left', marginTop: '25px', marginBottom: '15px' }}>
              If after this process you still can not recover your account, contact us through our <a href="https://discord.gg/space-misfits-560969562753859585" style={{ textDecoration: 'underline' }} target='_blank' rel="noreferrer">Discord Support Tickets.</a>
            </FormSupportLink>
            <BtnSignIn onClick={() => handleResend()} style={{ marginTop: '10px' }}>
              <TextButtons>Resend Activation Link</TextButtons>
            </BtnSignIn>
            <BtnSignIn onClick={() => navigate('/sign-in')} style={{ marginTop: '20px' }}>
              <TextButtons>Return to Sign In</TextButtons>
            </BtnSignIn>
          </SignUpDiv>
        </ContainerRegister>
      {/* </ResponsiveContainer> */}
      {/* <ResponsiveAlert>
        <ContainerAlert>
          <RowCore style={{ borderLeft: '5px solid #E71383', alignItems: 'center' }}>
            <ImgIcon src={help_icon_pink} style={{ width: '15px', height: '15px', marginLeft: '3%' }} />
            <TextPoppins style={{ fontSize: '11px', textAlign: 'left', lineHeight: '18px', marginLeft: '1%' }}>
              Fleet Alpha v1 is not optimized for mobile/tablet devices and it will not be supported until the next version following our roadmap, you can play Fleet using the desktop version. We apologize for any inconvenience caused.
            </TextPoppins>
          </RowCore>
        </ContainerAlert>
      </ResponsiveAlert> */}
    </>
  )
}