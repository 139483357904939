import styled from "styled-components";

import { BtnPolygon, ContainerBasic, ContainerVoid, GridViewCore, ImgIcon, RowCore, RowCoreCenter, RowSpaceBetween, TextPoppins, TextPoppinsBold, TextTitiliumWeb } from "../../../../containers/CoreElement";

export const ContainerMyFleet = styled.div`
    box-sizing: border-box;
    padding: 20px;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
    background: rgba(94, 8, 53, 0.1);
    background-size: 100% 100%;
    border: 1px solid rgba(231, 19, 131, 1);
    position: relative;
    color: white;

`

export const TittleV1 = styled.div`
    justify-content: left;
    align-items: center;
    display: flex;
    margin-bottom: 20px;
`
export const TittleFleetSetup = styled(TittleV1)`
    padding: 20px;
    margin-bottom: 0px;
`

export const SetupOption = styled.div`
    width: 50%;
    height: 48px;
    justify-content: center;
    align-items: center;
    display: flex;
`

export const IconTittle = styled.img`
    margin-right: 10px;
`

export const TittleTextV2 = styled(TextTitiliumWeb)`
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
`


export const ContainerAvailableSlots = styled(ContainerBasic)`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), rgba(78, 6, 44, 0.25);
    border: 1.33091px solid #5E0835;
    margin-bottom: 2%;
    padding: 5px;
`
export const ContainerForce = styled(ContainerAvailableSlots)`
    padding: 0;
    border: 1.33091px solid #ffffff;
    height: 40px;
`
export const ContainerOptions = styled(ContainerVoid)`
    padding: 0;
    height: 40px;
    margin-bottom: 2%;
`
export const ContainerWeek = styled(ContainerVoid)`
    width: 100%;
    background-color: #EF0707;
`
export const ContainerFair = styled(ContainerWeek)`
    background-color: #FAD000;
`
export const ContainerStrong = styled(ContainerWeek)`
    background-color: #92FA00;
`

export const RowCenter = styled(RowCore)`
    width: 50%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
export const InputSetAmount = styled.input`
    border: none;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    height: 40px;
    width: 70%;
`


export const RowSpaceBetween100 = styled(RowSpaceBetween)`
    height: 100%;
`
export const RowAvailableSlots1 = styled(RowCenter)`
    width: 70%;
`
export const ContainerIconCommanders = styled.div`
    margin-right: 2%;

    @media (max-width: 600px) {
        display: none;
    }
`
export const IconsAvailableSlots = styled(ImgIcon)`
    width: 25px;
    height: 25px;
`
export const IconsAvailableSlotsMargin = styled(IconsAvailableSlots)`
    margin-left: 5%;
`
export const TextTitlesAvailableSlots = styled(TextPoppinsBold)`
    margin-right: 2%;
`

export const TextTitiliumLineHeight = styled(TextTitiliumWeb)`
    line-height: 0px;
`
export const TextTitiliumBlack = styled(TextTitiliumLineHeight)`
    color: black;
`

export const GridViewAvailableSlots = styled(GridViewCore)`
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

export const RowJustifyCenter = styled(RowCoreCenter)`
    justify-content: center;
`

export const TittleTextV3 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 20px;
`

export const TittleTextV4 = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-right: 5px;
`

export const TittleTextV5 = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
`

export const QuickAccessElements = styled.img`
    width: auto ;
    height: 32px;
    margin-right: 20px;
`

export const QuickAccessContainer = styled.div`
    display: flex;
`

export const TittleV2 = styled.div`
    display: flex;
    margin-bottom: 20px;
`

export const TittleV3 = styled.div`
    display: flex;
    margin-top: 4%;
`

export const TittleTextV1 = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
`

export const TittleTextV6 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #9019E6;
`

export const ContainerPolygonLeftAdd = styled(BtnPolygon)`
    width: 35%;
    height: 25px;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);    
    clip-path: polygon(0 100%, 0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%);
    position: absolute;
    bottom: 0px;
    left: 0px;
`
export const ContainerPolygonLeftRemove = styled(ContainerPolygonLeftAdd)`
    top: 159px;
`
export const ContainerPolygonRightAdd = styled(BtnPolygon)`
    width: 30%;
    height: 25px;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    position: absolute;
    bottom: 0px;
    right: 0px;
`

export const ContainerPolygonRightRemove = styled(ContainerPolygonRightAdd)`
    top: 159px;
`
export const ContainerLifeBar = styled.div`
    margin-top: 5%;
`
export const ContainerBtnBack = styled(ContainerBasic)`
    width: 96.3%;
    height: 40px;
    color: white;
    padding: 0;
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid #E71383;
    border-radius: 2px;
    margin-left: 1.8%;
`
export const RowBtnBack = styled(RowCore)`
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`
export const BtnBack = styled(ContainerVoid)`
    cursor: pointer;
`
export const ImgBtnBack = styled(ImgIcon)`
    width: 15px;
    height: auto;
    margin-left: 60%; 
    margin-right: 20%;
`

export const TextBtnBack = styled(TextPoppins)`
    font-size: 18px;
`

export const TextPoppinsInfo = styled(TextPoppins)`
    font-size: 11px;
`
export const TextPoppinsInfoBold = styled(TextPoppinsBold)`
    font-size: 11px;
`

export const TextTextTitiliumWebInfo = styled(TextTitiliumWeb)`
    font-size: 11px;
`

export const TittleTextV7 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
`

export const ContainerTableMyFleet = styled.div`
    border-radius: 4px;
    color: white;
    padding-bottom: 0;
    position: relative;
`

export const ContainerFleetSetup = styled.div`
    margin-top: 2%;
    color: white;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
    background: rgba(94, 8, 53, 0.1);
    background-size: 100% 100%;
    border: 1px solid rgba(231, 19, 131, 1);
    
`

export const TittleBarV1 = styled.div`
    background: rgba(141, 12, 80, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
`

export const FleetSetupOptions = styled.div`
    background: rgba(141, 12, 80, 0.5);
    border: 1px solid #E71383;
    height: 48px;
    display: flex;
    margin-bottom: 20px;
    cursor: pointer;
`

export const ComposeYourFleetLayer1 = styled.div`

`

export const ComposeYourFleetLayer2 = styled.div`
    padding: 5px;
`

export const ComposeYourFleetLayer3 = styled.div`
    padding: 10px;
    box-shadow: inset 0px 0px 25px 25px rgb(94 8 53 / 45%);
`

export const GridViewComposeYourFleet = styled(GridViewCore)`
    margin-top: 3%;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 1160px) {
        grid-template-columns: repeat(1, 1fr);
    }
    
`
export const ContainerGridView = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    color: #ffffff;
    display: flex;
    width: 100%;
    height: 600px;
    flex-direction: column;
    padding: 15px;
`

export const GridMain = styled(GridViewCore)`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;

    @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
    }
`


export const ElementsComposeYourFleet = styled.div`
    margin: 0px 10px 1px 0px;
    height: fit-content;
    border: 1.33091px solid #E71383;
`

export const ContainerDataCYF = styled.div`
    padding: 15px;
    background: rgba(231, 19, 131, 0.2);
    border: 1.33091px solid #E71383;
`

export const NameElementCYF = styled.div`
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 8px;
    gap: 4px;
    background: #48052C;
`

export const TextNameElementCYF = styled.span`
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
`

export const ImageElementCYFContainer = styled.div`
    position: relative;
`

export const ImageElementCYF = styled.img`
    width: 100%;
    
`

export const TypesContainer = styled.div`

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 10px;
    
`
export const TypeIcon = styled.img`
    width: 47px;
    height: 32px;
    
`

export const ContainerStats = styled.div`
    background: #2F041A;
    border: 1.33px solid #6C11DE;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    padding: 15px 0px 0px 15px;
    gap: 12px;
`

export const IconStat = styled.img`
    width: 25px;
    height: 25px;
    margin-right: 10px;
`

export const StatElement = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 15px;
`

export const ButtonStats = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9019E6;
    border-width: 0px 1.33px 1.33px 1.33px;
    border-style: solid;
    border-color: #6C11DE;
    padding: 7.32px 14.64px;
    gap: 13.31px;
`

export const ButtonAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 10px;
    background: #E71383;
    cursor: pointer;
`
export const ButtonRemove = styled(ButtonAdd)`

`

export const IconSpecialStats = styled.img`
    width: 20.42px;
    height: 20.83px;
`

export const IconSpecialStatsArrow = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding: 15px 0px 0px 15px;
`