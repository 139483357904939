import styled from "styled-components";
import { BtnPolygon, ContainerBasicBG, ContainerVoid, ImgIcon, ColCore, RowCore, RowSpaceBetween, TextPoppins, TextPoppinsBold, TextTitiliumWeb } from "./../../containers/CoreElement";


export const ContainerValidations = styled.div`

    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
    display: flex;
    mix-blend-mode: lighten;
    grid-row-gap: 20px;
`
export const SubElementValidations = styled.div`
    width: 100%;
    border: 0.5px solid #E71383;
    padding-bottom: 10px;
`

export const ContainerValidationsMain = styled(ColCore)`
    margin-top: 20px;
    background: #520830;
    border: 1px solid #E71383;
    padding: 10px;
    width: 70%;

    @media (max-width: 600px) {
        width: 100%
    }
`

export const TitleMain = styled(TextPoppins)`
    letter-spacing: 0;
    font-size: 28px;
    font-weight: 700;
`
export const SubTitle = styled(TextPoppins)`
    letter-spacing: 0.02em;
    font-size: 12px;
    font-weight: 700;
    color: #13CBE7;
`
export const TitleSetting = styled(TextTitiliumWeb)`
    text-align: left;
    letter-spacing: 0.02em;
    color: #E71383;
    font-size: 18px;
    line-height: 30px;
    margin-top: 2%;
`
export const TextSetting = styled(TitleSetting)`
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0;
`
export const ContainerWarning = styled(ContainerBasicBG)`
    width: 60.5%;
    padding: 5px;
    background-color: #230313;
`
export const RowCenter = styled(RowCore)`
    align-items: center;
`
export const RowDisableNotifications = styled(RowSpaceBetween)`
    align-items: center;
    width: 25%;
`
export const IconWarning = styled(ImgIcon)`
    width: 20px;
    height: 20px;
    margin-right: 1%;
`
export const TextWarning = styled(TextPoppins)`
    font-weight: 400;
    color: #FFFFFF;
    letter-spacing: 0.02em;
    line-height: 20px;
    font-size: 12px;
    text-align: left;
`
export const BtnInput = styled(BtnPolygon)`
    width: 10%;
    height: 100%;

    @media (max-width: 1410px) {
        width: 20%
    }

    @media (max-width: 600px) {
        width: 50%
    }
`
export const BtnUpdateProfileData = styled(BtnPolygon)`
    margin-top: 2%;
    width: 250px;
`
export const RowInput = styled(RowSpaceBetween)`
    width: 70%;
    gap: 10px;
`
export const RowMain2FA = styled(RowCore)`
    margin-top: 1%;
    width: 70%;
`
export const Row2FA = styled(RowMain2FA)`
    width: 80%;
    justify-content: space-between;
`
export const InputForm = styled.input`
    border: 1px solid #E71383;
    border-radius: 2px;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    height: 45px;
    width: 80%;
`

export const InputFormCodeWallet = styled(InputForm)`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    width: 90%;

    @media (max-width: 1280px) {
        font-size: 13px;
    }

    @media (max-width: 600px) {
        font-size: 11px;
    }
`

export const RowDesktop = styled(RowSpaceBetween)`
    @media (max-width: 600px) {
        display: none;
    }
`

export const ColCellphone = styled(ColCore)`
    @media (min-width: 601px) {
        display: none;
    }
`
export const Separator = styled.hr`
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin: 20px 0; /* Opcional: agrega margen superior e inferior al separador */
`

export const ContainerQrPopup = styled(ColCore)`
    width: 655px;
    gap: 20px;
`

export const ContainerTextHelp = styled.div`
    background: rgba(141, 12, 80, 0.7);
    border: 0px;
    padding: 10px;
`

export const ContainerQrCode = styled.div`
    width: 175px;
    height: 39.98px;
    background: #220313;
    justify-content: center;
    align-items: center;
    display: flex;
`

export const ContainerSectionMain = styled.div`
    width: 100%;
`

export const ContainerQrLinkWallet = styled(ColCore)`
    justify-content: center;
    align-items: center;
`

export const QrLinkWallet = styled.img`
    width: 175px;
    height: 175px;
`

export const ContainerBtn = styled.div`
    height: 45px;
    width: 20%;
`

export const Btn = styled.button`
    background: #E71383;
    border: 1px solid #E71383;
    color: white;
    cursor: pointer;
    width: 100%;
    height: 45px;
    justify-content: center;
    align-items: center;
    display: flex;
`

export const IconOff = styled.img`
    width: 30px;
    height: 30px;
`

export const Header = styled(ContainerVoid)`
    background: linear-gradient(0deg, rgba(231, 19, 131, 0.05), rgba(231, 19, 131, 0.05)), rgba(0, 0, 0, 0.1);
    /* border: 0.5px solid #D51279; */
    gap: 8px;
    padding: 10px;
    display: flex;
    justify-content: left;
`

export const TextTitiliumWebCustom1 = styled(TextTitiliumWeb)`
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0px;
`

export const TextTitiliumWebCustom8 = styled(TextTitiliumWeb)`
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0px;
    justify-content: center;
    width: 100%;
    align-items: center;
    display: flex;
`

export const ContainerSection = styled(ContainerVoid)`
    gap: 10px;
`

export const TextPoppinsCustom1 = styled(TextPoppins)`
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0px;
`

export const TextPoppinsCustom2 = styled(TextPoppinsCustom1)`
    color: #E71383;
`

export const TextPoppinsCustom8 = styled(TextPoppinsCustom1)`
    gap: 5px;
    color: white;
    display: flex;
`

export const TextPoppinsCustom6 = styled(TextPoppinsCustom1)`
    color: rgba(255, 184, 0, 1);
`

export const TextPoppinsCustom5 = styled(TextPoppinsCustom1)`
    color: rgba(231, 19, 131, 1);
`

export const TextPoppinsCustom3 = styled(TextPoppinsCustom1)`
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
`

export const TextPoppinsCustom4 = styled(TextPoppinsCustom1)`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
`

export const TextPoppinsCustom7 = styled(TextPoppinsCustom1)`
    font-weight: 299px;
    font-size: 18px;
    line-height: 18px;
    opacity: 0.9;
`

export const TextBold = styled(TextPoppinsBold)`
    letter-spacing: 0px;
    font-size: 12px;
`

