import styled from "styled-components";
import '../../containers/hooks/styles.css'

export const TableBase = styled.table.attrs(() => ({className: 'scroll2'}))`
    width: 99%;
    height: 210px;
    display: block;
    overflow-y: auto;
`

export const TdData = styled.td`
    width: 120px;
    font-size: 14px;
    padding: 15px;
    padding-left: 0;
    align-items: center;

    @media (max-width: 600px) {
        font-size: 10px;
    }
`

export const TdDataFoot = styled.div`
    width: 120px;
    font-size: 14px;
    align-items: center;

    @media (max-width: 600px) {
        font-size: 10px;
    }
`

export const ContainerTable = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    background-blend-mode: hard-light;
    border: 1px solid rgba(231, 19, 131, 1);
    padding: 0px 20px 20px 20px;

    @media (max-width: 600px) {
        padding: 0px;
    }
`

export const ContainerTitle = styled.div`
    display: flex;
    align-items:center;
    justify-content:center;
    background:rgba(231, 19, 131, 1);
`

export const TextTitle = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
`

export const TrData = styled.tr`
    text-align: left;
    border-bottom: 2px solid rgba(231, 19, 131, 1);
`

export const ThData = styled.th`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #A9A7BB;

    @media (max-width: 600px) {
        font-weight: 300;
        font-size: 10px;
    }
`

export const FleetData = styled.div`
    justify-content: left;
    align-items: center;
    text-align: center;
    display: flex;
`

export const TypeData = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
`

export const TableFootMain = styled.div`
    background: rgba(141, 12, 80, 0.5);
    height: 28px;
    display: grid !important;
    grid-template-columns: 200px 205px 68px 68px 68px 68px 10px;
    padding-inline: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 24px;
    border: 1px solid rgba(231, 19, 131, 1);


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    @media (max-width: 1140px) {
        grid-template-columns: 150px 160px 60px 60px 60px 60px 60px;
    }
    
    @media (max-width: 600px) {
        grid-template-columns: 50px 60px 30px 30px 30px 30px 30px;
    }
`

export const TableBodyMain = styled.tbody`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
`

export const TableHeaderMain = styled.thead`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    height: 50px;
    border-bottom: 2px solid #A855F1;
`

export const IconActionX = styled.img`
    margin-left: 15px;
    cursor: pointer;
`

export const IconFleet = styled.img`
    margin-right: 10px;
    width: 30px;
    height: 30px;

    @media (max-width: 600px) {
        display: none;
    }
`

export const IconType = styled.img`
    width: 35px;
    height: 25px;
`

export const TextV1 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    @media (max-width: 600px) {
        font-size: 10px;
    }
`