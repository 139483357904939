import styled from "styled-components";
import { ColCore, ImgIcon, TextPoppins, TextPoppinsBold, TextTitiliumWeb } from "../containers/CoreElement";
import bgCard from '../assets/images/bg_card.png'

export const Card = styled.div`
    height: fit-content;
    /* margin-left: 15px; */
    margin-right: 12px;
    /* margin-bottom: 20px; */
    display: flex;
    flex-direction: column;
    background: rgba(231, 19, 132, 0.1);
    border: 1.33091px solid #E71383;
`

export const TitleCard = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const TextTitle = styled.span`
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
`

export const ContainerImgProfile = styled.div`
    width: auto;
    height: auto;
    position: relative;
    display: inline-block;
`

export const ImgInfoProfile = styled.img`
    width: 100%;
    height: 100%;
`

export const ImgInfoProfileBg = styled.img`
    width: 100%;
    height: 100%;
    background: url(${bgCard});
    background-size: cover;

`

export const TextPoppinsInfo = styled(TextPoppins)`
    font-size: 11px;
`
export const TextPoppinsInfoBold = styled(TextPoppinsBold)`
    font-size: 11px;
`
export const TextTextTitiliumWebInfo = styled(TextTitiliumWeb)`
    font-size: 11px;
`

export const ContainerStatsProfile = styled.div`
    width: 92%;
    padding: 5px;
    margin-left: 2%;
`

export const TitleStatsCard = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    /* line-height: 24px; */
    line-height: 18px;
    color: #FFFFFF;
`

export const StatsBold = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
`

export const InputSetAmount = styled.input`
    border: none;
    outline: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    height: 50px;
    width: 90%;

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        display: none;
        -moz-appearance: textfield;
    }


`

export const ButtonAdd = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    gap: 10px;
    background: #E71383;
    cursor: pointer;
`

export const TittleTextV7 = styled.span`
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
`

export const IconArrow = styled(ImgIcon)`
    width: 11px;
    height: 7px;
    margin-right: 0px;
    cursor: pointer;
`
export const ContainerInput = styled.div`
    width: 70%;
    display: flex; 
    flex-direction: row;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
`

export const ColButtons = styled(ColCore)`
    width: 15%;
    justify-content: center;
    align-items: center; 
    gap: 5px;
`