import styled from 'styled-components'
import '../containers/hooks/styles.css'

export const ContainerMain = styled.div`
    width: 100%;
    padding-inline: 2.5%;
    display: flex;
    flex-direction: column;
`

export const Container = styled.div`

    box-sizing: border-box;
    padding-bottom: 20px;
    width: 100%;
    border: 1px solid rgba(231,19,131,0.3);
    box-shadow: inset 0px 0px 20px #000000;
`

export const ContainerNoBorder = styled.div`
    box-sizing: border-box;
    padding-bottom: 20px;
    width: 100%;
    box-shadow: inset 0px 0px 20px #000000;

    @media (max-width: 960px) {
        margin-bottom: 10%;
    }
`

export const ContainerBasic = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    border: 0.1px solid #E71383;
    border-radius: 2px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 15px;
    height: auto;
`

export const ContainerVoid = styled.div`
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    color: #ffffff;
    height: auto;
`

export const ContainerBasicBG = styled(ContainerBasic)`
    background: rgba(231, 19, 131, 0.2);
    
    width: 100%;
`

export const RowCore = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;


`
export const RowCoreCenter = styled(RowCore)`
    align-items: center;
`
export const RowSpaceBetween = styled(RowCore)`
    justify-content: space-between;
    padding: 0px 10px;
`
export const RowSpaceBetweenButtons = styled(RowCore)`
    justify-content: space-between;
    gap: 2%;
`
export const ColCore = styled.div`
    display: flex;
    flex-direction: column;
`

export const Img = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`

export const ImgIcon = styled(Img)`
    margin-right: 2%;
`

export const TextOrbitron = styled.span`
    text-align: center;
    font-family: 'Orbitron';
    font-style: normal;
    font-weight: 900;
    font-size: 13.5px;
    line-height: 45px;
    color: white;
    text-transform: uppercase;
`

export const TextOrbitronBold = styled(TextOrbitron)`
    font-weight: bold;
` 

export const TextTitiliumWeb = styled.span`
    text-align: center;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 45px;
    color: #FFFFFF;


`

export const TextPoppins = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
    line-height: 35px;
    color: #FFFFFF;
`

export const TextPoppinsBold = styled(TextPoppins)`
    font-weight: bold;
`

export const GridViewCore = styled.div.attrs(() => ({className: 'scroll'}))`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    mix-blend-mode: lighten;
    grid-row-gap: 20px;
`

export const GridViewCoreWithoutScroll = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    mix-blend-mode: lighten;
    grid-row-gap: 20px;
`
export const GridViewSpecs = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    mix-blend-mode: lighten;
    grid-row-gap: 20px;
    display: grid;
    grid-auto-flow: dense;
    /* grid-template-columns: repeat(auto-fill, calc((100% / 3)) - ((5px * (3 - 1)) / 3));
    grid-template-columns: repeat(auto-fill, calc((100% / 4) - 3px)); */
    mix-blend-mode: lighten;
    /* grid-row-gap: 5px;
    grid-column-gap: 3px; */
`

export const GridViewCoreScroll2 = styled(GridViewCore).attrs(() => ({className: 'scroll2'}))`
`

export const GridViewCoreScroll3 = styled(GridViewCore).attrs(() => ({className: 'scroll3'}))`
`

export const GridViewCoreScroll4 = styled(GridViewCore).attrs(() => ({className: 'scroll4'}))`
`


// Outposts, Routes, Dashboard - Rectangles 



export const RectangleContainerGeneral  = styled.div  `
    width: 895px;
    height: 130px;
    display: inline-flex;
    margin: 0px 0px 15px 0px;
`

export const RectangleImage = styled(Img) `
    width: 130px;
    height: 130px;
`

export const RectangleContainerWithoutRent = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 765px;
    justify-content: space-between;
    display: flex;
    color: white;
    
    @media (max-width: 1160px) {
        width: 75%;
        display: block;
        padding: 12px;
    }
`

export const RectangleContainerAlreadyRented = styled.div`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(231, 19, 131, 0.2);
    border: 1px solid #E71383;
    border-radius: 2px;
    width: 765px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: inline-grid;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;

    @media (max-width: 1160px) {
        width: 75%;
        padding: 12px;
    }

`

export const BtnPolygon = styled.button`
    border: none;
    background-color: #E71383;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
    -webkit-clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%);
`

export const ContainerPolygon = styled.div`
    position: absolute;
    bottom: 0px;
    width: 25%; 
    height: 27px; 
`

export const PolygonLeft = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),linear-gradient(0deg, rgba(94, 8, 53, 0.5), rgba(94, 8, 53, 0.5));
    clip-path: polygon(0 100%, 0 0, calc(100% - 8px) 0, 100% 8px, 100% 100%, 0 100%);
    position: relative;

    &::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px;
        border-color: #E71383 #E71383 transparent transparent;
    }
`

export const PolygonRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),linear-gradient(0deg, rgba(94, 8, 53, 0.5), rgba(94, 8, 53, 0.5));
    clip-path: polygon(0 8px, 8px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    position: relative;

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px;
        border-color: #E71383 transparent transparent #E71383;
    }
`

export const ContainerInput = styled.div`
    width: 70%;
    display: flex; 
    flex-direction: row;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #2F041A;
`
export const ColButtons = styled(ColCore)`
    width: 15%;
    justify-content: center;
    align-items: center; 
    gap: 5px;
`
export const IconArrow = styled(ImgIcon)`
    width: 11px;
    height: 7px;
    margin-right: 0px;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 8px;
        height: 4px;
        margin-right: 3%;
    }
`
export const ChildrenHeaderDesktop = styled.span`
    
    @media(max-width: 600px) {
        display: none;
    }
`
export const ChildrenHeaderCellphone = styled.span`
    
    @media(min-width: 601px) {
        display: none;
    }
`
export const ContainerIconSvg = styled.div`
    @media (max-width: 600px) {
        display: none;
    }
`