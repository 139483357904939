import styled from "styled-components";
import { Img, TextPoppins, TextPoppinsBold, TextTitiliumWeb } from "../containers/CoreElement";

export const DropDownGeneral = styled.div ` 
    text-align: center;
    
`

export const DropDownConteinerGeneralSpacerForScroll = styled.div`
    height: 90px;
    background-color: #2F041A; 
    border:1.33px solid #9019E6;
    border-bottom: 0px ;
    padding: 2px 3px 0px 1px; 
`

export const DropDownConteinerGeneral = styled.div`
    height: 90px;
    overflow-y: scroll;
    padding: 12px 0px 0px 6px; 
    border: none;
`

export const DropDownButtonContainerGeneral = styled.button`
    width: 100%;
    height: 50px;
    background-color: #9019E6;
    border: 1.33px solid #6C11DE;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
`

export const DropDownButtonContainer = styled.div `
    flex-direction: row;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content:center;
    width: 54%;
    
`
export const DropDownButtonImage = styled(Img) `
    justify-content:center;
    align-items: center;
    height: 18px;
    width: 21px;
`
export const DropDownArrowImage = styled(DropDownButtonImage) `
    height: 7px;
    width: 13px;
    justify-content:center;
    align-items: center;

`
export const DropDownButtonText = styled(TextTitiliumWeb)`
    /* Unique Traits */
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 35px;
    margin-right: 5%;
    margin-left: 5%;
`

export const DropDownBlockBoldText = styled(TextPoppinsBold) `
    color: white;
    padding-left: 6px;
    letter-spacing: 0;
    font-size:12px;
`
export const DropDownBlockIcon = styled(Img)`
    margin-left: 9px;
    width: 15px;
    height: 15px;
    margin-bottom: 3px;
    margin-right: 5px;
`
export const DropDownBlockText = styled(TextPoppins) `
    font-size:12px;
    letter-spacing: 0;
    line-height: 15px;
    font-weight:100;
    font-style: none;

`
export const DropDownContainer = styled.div`
    background-color: #2F041A;
    width: 100%;
    margin-bottom: 9px;
    height: 30px;
    color: white;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    display: -webkit-flex;
`