import React, { useState, useEffect } from 'react';

import { Container } from '../../../../containers/CoreElement'

import { HTTP } from '../../../http/http-common';

import { 
  ContainerMission, 
  MissionName, MissionInfo, 
  ContainerMissionImg, 
  ContainerMissionData, 
  ContainerMissionData1, 
  ContainerMissionData2, 
  ContainerMissionDataDesc, 
  ContainerMissionDataContainerDuration,
  ContainerMissionDataCharac,
  ContainerMissionDataCharacElement,
  ContainerMissionContainerImg,
  ContainerMissionDataCharacElement1,
  ContainerMissionDataCharacElement2,
  ContainerMissionDataCharacElementDivi,
  ContainerMissionDataDurationClockImg,
  MissionArrow,
  MissionEnemiesFleet,
  ContainerMissionEnemiesFleet,
  MissionEnemiesFleetContainerTable,
  ContainerMissionData3,
  SendFleet,
  ContainerPopup,
  PopupSuccess,
  ContainerMissionDataSpa

 } from './MissionsActiveElement';

import {
  useNavigate
} from "react-router-dom";

import data_charac_element_divi from '../../../../assets/images/data_charac_element_divi.svg'
import data_charac_element_divi_blue from '../../../../assets/images/data_charac_element_divi_blue.svg'
import clock_duration from '../../../../assets/images/clock_duration.svg'
import arrow_down_mission from '../../../../assets/images/arrow_down_mission.svg'
import arrow_up_mission from '../../../../assets/images/arrow_up_mission.svg'

import "typeface-orbitron";

import modal_success from '../../../../assets/images/modal_success.svg'
import { Loader } from '../../../../hooks/Loader';
import { updateNavs } from '../../../utils/utils';

export function MissionsActive() {
  const navigate = useNavigate();
  const [missions, setMissions] = useState([]);
  const [show, setshow] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const [loader, setLoader] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  }

  const closePopup = () => {
    setShowPopup(false);
    navigate("/routes/active");
  }

  useEffect(() => {
    updateNavs();
    fetchMissions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleShow = (id) => {
    document.getElementById(id).classList.toggle("hide-div");
    setshow(!show);
  }

  const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
  };

  const showResult = () => {
    openPopup();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setMissions(prevArray => {
        const newArray = [...prevArray]; // Copia el array original
        for (let index = 0; index < newArray.length; index++) {
          if (newArray[index].time_left > 0){
            newArray[index].time_left = newArray[index].time_left - 0.5;
          }else{
            newArray[index].time_left = 0
          }
          if (newArray[index].time_left <= 0){
            newArray[index].is_completed = true;
          }
        }
        return newArray; // Devuelve el nuevo array actualizado
      });
    }, 1000); // Actualiza cada segundo
    return () => clearInterval(interval);
  }, []);

  const fetchMissions = () => {
    setLoader(true);
    HTTP.request('expeditions/active', config)
      .then((res) => {
        console.log(res);
        setMissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };


  return (
    <>
    <Loader loading={loader}/>
      <Container>
      <div>
        {missions.map((mission) => (
          <ContainerMission key={mission.id}>
            {showPopup && (
              <ContainerPopup className="popup">
                <PopupSuccess src={modal_success}/>
                <h1>You WIN!</h1>
                <h1>Commander +500xp</h1>
                <button onClick={closePopup}>Close</button>
              </ContainerPopup>
            )}
            <MissionArrow onClick={() => toggleShow(mission.id + "_container_fleet")} src={show ? arrow_up_mission : arrow_down_mission} />
            <MissionName>
              {mission.name}
            </MissionName>
            <MissionInfo>
              <ContainerMissionContainerImg>
                <ContainerMissionImg src={mission.icon}></ContainerMissionImg>
              </ContainerMissionContainerImg>
              <ContainerMissionData>
                <ContainerMissionDataSpa>
                  <ContainerMissionData1>
                    <ContainerMissionDataDesc>
                      {mission.description}
                    </ContainerMissionDataDesc>
                  </ContainerMissionData1>
                  <ContainerMissionData2>
                    <ContainerMissionDataContainerDuration>
                      <ContainerMissionDataCharacElement1>
                        <ContainerMissionDataDurationClockImg src={clock_duration}/> TIME LEFT
                      </ContainerMissionDataCharacElement1>
                      <ContainerMissionDataCharacElementDivi src={data_charac_element_divi_blue} />
                      <ContainerMissionDataCharacElement2>
                        {mission.time_left} Seconds
                      </ContainerMissionDataCharacElement2>
                    </ContainerMissionDataContainerDuration>
                  </ContainerMissionData2>
                </ContainerMissionDataSpa>
                <ContainerMissionDataCharac>

                    <ContainerMissionDataCharacElement>
                      <ContainerMissionDataCharacElement1>
                        DIFFICULTY
                      </ContainerMissionDataCharacElement1>
                      <ContainerMissionDataCharacElementDivi src={data_charac_element_divi} />
                      <ContainerMissionDataCharacElement2>
                        {mission.difficulty_value}
                      </ContainerMissionDataCharacElement2>
                    </ContainerMissionDataCharacElement>
                    <ContainerMissionDataCharacElement>
                      <ContainerMissionDataCharacElement1>
                        REWARD
                      </ContainerMissionDataCharacElement1>
                      <ContainerMissionDataCharacElementDivi  src={data_charac_element_divi} />
                      <ContainerMissionDataCharacElement2>
                        {mission.reward}
                      </ContainerMissionDataCharacElement2>
                    </ContainerMissionDataCharacElement>
                    <ContainerMissionDataCharacElement>
                      <ContainerMissionDataCharacElement1>
                        MODIFIER
                      </ContainerMissionDataCharacElement1>
                      <ContainerMissionDataCharacElementDivi  src={data_charac_element_divi} />
                      <ContainerMissionDataCharacElement2>
                        -
                      </ContainerMissionDataCharacElement2>
                    </ContainerMissionDataCharacElement>
                    <ContainerMissionDataCharacElement>
                      <ContainerMissionDataCharacElement1>
                        FUEL COST
                      </ContainerMissionDataCharacElement1>
                      <ContainerMissionDataCharacElementDivi src={data_charac_element_divi} />
                      <ContainerMissionDataCharacElement2>
                        -
                      </ContainerMissionDataCharacElement2>
                    </ContainerMissionDataCharacElement>
                  </ContainerMissionDataCharac>
              </ContainerMissionData>
            </MissionInfo>
            <ContainerMissionEnemiesFleet className='hide-div' id={mission.id + "_container_fleet"}>
              <MissionName style={{color: "#EE8D1C"}}>
                Enemies Fleet
              </MissionName>
              <MissionEnemiesFleet>
                <MissionEnemiesFleetContainerTable>
                </MissionEnemiesFleetContainerTable>
              </MissionEnemiesFleet>
            </ContainerMissionEnemiesFleet>
            {mission.is_completed && (
            <ContainerMissionData3>
              <SendFleet onClick={() => showResult(mission.id)}>
                Show Result
              </SendFleet>
            </ContainerMissionData3>
            )}
          </ContainerMission>
        ))}
      </div>
       {/*<PopupGfg></PopupGfg>*/}
    </Container>
    </>
  
  )
}