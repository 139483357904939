import React, { useState, useEffect } from 'react';

import { 
  ContainerIconSvg,
ContainerMain, RowSpaceBetween, TextPoppins
 } from '../../../../containers/CoreElement'

import { HTTP } from '../../../http/http-common';

import HeaderScreen from '../../../../hooks/HeaderScreen'

import ImageHeader from '../../../../assets/images/test_header_routes.jpg'
import icon_expeditions from '../../../../assets/images/icon_expeditions.svg'
import icon_mining from '../../../../assets/images/icon_mining.svg'

import { 
  // ButtonGenerateRoute,
  ContainerTitleExpeditions,
  TextTitle,
  BtnOption
} from './MissionsElement';

import {
  useNavigate,
  useLocation,
  Route,
  Routes
} from "react-router-dom";

import "typeface-orbitron";
import IconSvg from '../../../../hooks/IconSvg';
import { Expeditions } from './pages/Expeditions/Expeditions';
import { Mining } from './pages/Mining/Mining';
import { Loader } from '../../../../hooks/Loader';
import { globalData, updateNavs } from '../../../utils/utils';
import PopUp from '../../../../hooks/PopUp';

export function Missions() {
  const navigate = useNavigate();
  const location = useLocation();
  const [aurumExpeditions, setAurumExpeditions] = useState([]);
  const [miningMissions, setMiningMissions] = useState([]);
  const [missionsRefreshValue, setMissionsRefreshValue] = useState([]);
  const [activeRoute, setActiveRoute] = useState(location.pathname);
  const [popUpRefresh, setPopUpRefresh] = useState(false);

  const [loader, setLoader] = useState(false);

  const [popupBuyCrown, setPopupBuyCrown] = useState(false)

  const handleClick = (path) => {
      setActiveRoute(path);
      navigate(path);
  };
  

  useEffect(() => {
    updateNavs();
    fetchMiningMissions();
    fetchAurumExpeditions();
    fetchMissionsRefreshValue();
  }, []);

  const fetchMissionsRefreshValue = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };
    HTTP.request('missions/refresh/value/', config)
    .then((res) => {
        if (res.data.result){
            setMissionsRefreshValue(res.data.value);
        }
    })
    .catch((err) => {
    console.log(err);
    })
    .finally(() => {
      setLoader(false);
    })
  };

  const fetchMissionsRefresh = () => {

    updateNavs().then(() => {
      if(globalData.crownsAvailable > 0){
        setPopUpRefresh(true)
      }else{  
        setPopupBuyCrown(true)
      }
    })
  };

  const fetchMissionsRefreshCall = () => {
    setLoader(true);
    let type_of_route_name = null;
    if (activeRoute === '/routes/expeditions') {
      type_of_route_name = 'aurum_expedition';
    }else{
      type_of_route_name = 'mining_mission';
    } 

    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
        data: {type_of_route_name: type_of_route_name},
        method: "POST"
    };

    HTTP.request('missions/refresh/', config)
    .then((res) => {
        if (res.data.result){
          window.location.reload();
        }
    })
    .catch((err) => {
    console.log(err);
    })
    .finally(() => {
      setLoader(false);
    })
  };


  const fetchMiningMissions = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };

    HTTP.request('mining/expeditions/', config)
      .then((res) => {
        console.log(res);
        setMiningMissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };

  const fetchAurumExpeditions = () => {
    setLoader(true);
    const config = {
        headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
    };

    HTTP.request('aurum/expeditions/', config)
      .then((res) => {
        console.log(res);
        setAurumExpeditions(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };


  // const generateRoute = () => {
  //   setLoader(true);
  //   let type_of_route_name = null;
  //   if (activeRoute === '/routes/expeditions') {
  //     type_of_route_name = 'aurum_expedition';
  //   }else{
  //     type_of_route_name = 'mining_mission';
  //   } 
  //   const config = {
  //     headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
  //     method: "POST",
  //     data: {type_of_route_name: type_of_route_name}
  //   };
  //   HTTP.request('generate_route/', config)
  //     .then((res) => {
  //       console.log(res);
  //       fetchMiningMissions();
  //       fetchAurumExpeditions();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       setLoader(false);
  //     })
  // };


  return (
    <>
    <Loader loading={loader}/>
    <ContainerMain>
      <HeaderScreen extended={true} imgHeader={ImageHeader} title="MISSIONS" icon="/icons/icon_type_header_routes.svg" textHelp="Hello">
        This is your mission panel, find out what's in space, but watch out for pirates! - Aurum rewards are based on your cargo capacity
      </HeaderScreen>
      {/* <ButtonGenerateRoute onClick={() => generateRoute()}>
        Generate Mission
      </ButtonGenerateRoute> */}
      <ContainerTitleExpeditions>
        <RowSpaceBetween>
          <BtnOption style={{backgroundColor: activeRoute === '/routes/expeditions' ? '#FF0087' : 'transparent', width:'100%'}} onClick={() => handleClick('/routes/expeditions')}>
            <ContainerIconSvg>
              <IconSvg icon={icon_expeditions} color={activeRoute === '/routes/expeditions' ? '#FFFFFF' : '#d2b8cd'}/>
            </ContainerIconSvg>
            <TextTitle style={{color: activeRoute === '/routes/expeditions' ? '#FFFFFF' : '#d2b8cd'}}>
              Expeditions
            </TextTitle>
          </BtnOption>
          <BtnOption style={{backgroundColor: activeRoute === '/routes/mining' ? '#FF0087' : 'transparent'}} onClick={() => handleClick('/routes/mining')}>
            <ContainerIconSvg>
              <IconSvg icon={icon_mining} color={activeRoute === '/routes/mining' ? '#FFFFFF' : '#d2b8cd'}/>
            </ContainerIconSvg>
            <TextTitle style={{color: activeRoute === '/routes/mining' ? '#FFFFFF' : '#d2b8cd'}}>
              Mining
            </TextTitle>
          </BtnOption>
        </RowSpaceBetween>
      </ContainerTitleExpeditions>
      <Routes>
        <Route path="expeditions" element={<Expeditions missions={aurumExpeditions} missionsRefreshValue={missionsRefreshValue} fetchMissionsRefresh={fetchMissionsRefresh}/>}/>
        <Route path="mining" element={<Mining missions={miningMissions} missionsRefreshValue={missionsRefreshValue} fetchMissionsRefresh={fetchMissionsRefresh}/>}/>
      </Routes>
    </ContainerMain>
    <PopUp show={popUpRefresh} onClose={() => setPopUpRefresh(false)} onConfirm={() => fetchMissionsRefreshCall()}>
        <TextPoppins style={{letterSpacing: '0'}}>
            Are you sure you want to refresh your {activeRoute === '/routes/expeditions'? "Aurum Expedition" : "Mining Mission"}?
        </TextPoppins>
    </PopUp>
    
    <PopUp show={popupBuyCrown} onClose={() => setPopupBuyCrown(false)} onConfirm={() => window.open('https://spacemisfits.com/signin/', '_blank')} textBtnNo="Dismiss" textBtnYes="Buy CROWN">
        <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            Oops, it seems like you're short on CROWN. <br/>
            No worries! You can easily purchase more to unlock this cool feature.
        </TextPoppins>
    </PopUp>
    </>
  
  )
}