import { ContainerIconSvg } from '../containers/CoreElement.jsx'
import '../containers/hooks/styles.css'

import {
    Container1,
    Container1Bar,
    Container1BarContainerElement,
    Container1BarContainerIconAndText,
    Container1BarIcon,
    Container1BarContainerText,
    Container1Padding,
    Text1
    } from '../hooks/FilterBarElement.jsx'
import IconSvg from './IconSvg'



export function FilterBar(props) {


    const action = (element) => {
      element.action()
    }


    return (
    <Container1 style={{background: props.background, boxShadow: props.boxShadow}}>
      <Container1Bar>
        <Container1Padding style={{gridTemplateColumns:props.columns, background:props.backgroundGeneral}}>
        <> 
          { props.elements.map((element => (
            <Container1BarContainerElement >

              <Container1BarContainerIconAndText onClick={() => action(element)} style={{background:element.backgroundItem, cursor: 'pointer', boxShadow: element.boxShadow}}>

                  <Container1BarContainerText>
                    
                    <ContainerIconSvg>
                      <IconSvg icon={element.icon} color={element.color}/>
                    </ContainerIconSvg>
                    <Text1 style={{color: element.color}}>{element.text}</Text1>

                    <ContainerIconSvg>
                      <Container1BarIcon style={{paddingLeft:"15px"}} src={element.iconValue}></Container1BarIcon>
                      <Text1 style={{paddingLeft:"3px"}}>{element.value}</Text1>
                    </ContainerIconSvg>
                    
                  </Container1BarContainerText>

              </Container1BarContainerIconAndText> 

            </Container1BarContainerElement>

          )))
          }
        </>
        </Container1Padding>
      </Container1Bar>
    </Container1>
    )
    
};
