import styled from "styled-components";

import {BtnPolygon, ImgIcon, TextOrbitron, TextPoppins, TextPoppinsBold, TextTitiliumWeb} from '../containers/CoreElement';

export const CardContainerGeneral = styled.div`

    margin-bottom: 20px;
    display: inherit;
    background: rgba(231, 19, 131, 0.2);
    border: 1.33091px solid #E71383;
`
export const ContainerPolygonLeft = styled(BtnPolygon)`
    width: 30%;
    height: 25px;
    border: 1px solid #E71383;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);    
    clip-path: polygon(0 100%, 0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%);
    position: absolute;
    left: 0px;
    bottom: 0px;
    /* -webkit-clip-path: polygon(); */
    /* -webkit-clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%); */
`
export const ContainerPolygonRight = styled(ContainerPolygonLeft)`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
    left: auto;
    right: 0px;
    /* -webkit-clip-path: polygon(); */
    /* -webkit-clip-path: polygon(0 15px, 15px 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%); */
`

export const TextPoppinsInfo = styled(TextPoppins)`
    font-size: 11px;
`
export const TextPoppinsInfoBold = styled(TextPoppinsBold)`
    font-size: 11px;
`
export const TextTextTitiliumWebInfo = styled(TextTitiliumWeb)`
    font-size: 11px;
`

export const CardContainerTitle = styled.div  `

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CardContainerTitleEndsIn = styled.div  `
    width: 100%;
    height: 15%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    display: flex;
    background:rgba(231, 19, 131, 1);
    column-gap: 3px;
`

export const Container = styled.div  `
    align-items: center;
    display: flex;
    column-gap: 6px;
`

export const ContainerVertical = styled.div  `
    display: grid;
    vertical-align: middle;
    width: 30%;
`

export const TextTitle = styled(TextOrbitron)`
    
`

export const Text = styled(TextPoppins)`
    letter-spacing: 0px;
    font-size: 13px;
`

export const TextBold = styled(TextPoppinsBold)`
    letter-spacing: 0px;
    font-size: 12px;
`

export const CardContainerImage = styled.img  `
   width: 100%;
   height: 100%;
   display: inline-table;
`
export const ContainerImgProfile = styled.div`
    width: auto;
    height: auto; 
    position: relative;
`


export const CardInfo = styled.div  `
    width: 100%;
    display: grid;
    padding-left: 9px;
    padding-bottom: 9px;
`

export const CardButton = styled.div  `
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    display: flex;
    background:rgba(231, 19, 131, 1);

    clip-path: "";
    -webkit-clip-path: "";
    cursor: pointer;
    border: 1px solid rgba(231, 19, 131, 1);
`

export const Icon = styled.img`
    
`

export const TextCoins = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NumberCoins = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5%;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    color: #FFFFFF;
`


export const ProcessCotainer = styled.div `
    height:39px;
    width: 100%;
    background: #FF47A9;
    position: relative;
    display: table;
    
`

export const Process = styled.div `
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    transition: width 0.35s;
    background: #E71383;
`

export const InstantForge = styled.div `
    height:39px;
    width: 100%;
    display: flex;
    gap: 9px;
    justify-content:center;
    align-items: center;
    transition: width 0.35s;
    background: linear-gradient(0deg, #AA68D8, #AA68D8), #A40A5B;
    cursor: pointer;
`

export const Cancel = styled.div `
    height:39px;
    width: 100%;
    display: flex;
    gap: 9px;
    justify-content:center;
    align-items: center;
    transition: width 0.35s;
    background: #CF1839;
    cursor: pointer;
`
export const CardInfo2 = styled.div  `
    width: 100%;
    display: grid;
`



export const ContainerLevel = styled.div `
    position: absolute;
    display: flex; 

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    width: 20%;
    position: absolute;
    bottom: 0%;
    right: 0px;

    align-items:center;
    justify-content: center;
    gap: 3px;

    border: 1px solid #E71383;
    border-bottom: 0px;
    border-right: 0px;
    clip-path: polygon(0 15px, 15px 0, 100% 0, 100% 100%, 100% 100%, 0 100%);
`
export const ContainerFP = styled.div `
    position: absolute;
    display: flex; 

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(94, 8, 53, 0.5);
    width: 20%;
    position: absolute;
    bottom: 0%;
    left: 0px;

    align-items:center;
    justify-content: center;
    gap: 3px;

    border: 1px solid #E71383;
    border-bottom: 0px;
    border-right: 0px;
    clip-path: polygon(0 100%, 0 0, calc(100% - 15px) 0, 100% 15px, 100% 100%, 0 100%);
`

export const DamagedCard = styled.div `
    background: linear-gradient(0deg, rgba(255, 0, 0, 0.4), rgba(255, 0, 0, 0.4)), url(LadyBug-01.png), url(greenColorized-pattern-01.png), linear-gradient(180deg, #531330 0%, #991558 100%);
    background-blend-mode: normal, normal, difference, normal;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`   
export const ImgRepair = styled(ImgIcon)`
    margin-right: 0%;
    width: 60%;
    height: max-content;
`   