//Librerias INIT
import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../http/http-common';

//CSS
import '../../../../components/Home/Pages/Messages/hooks/styles.css'

//Components
import HeaderScreen from '../../../../hooks/HeaderScreen';
//Icons
import icon_close_x from '../../../../assets/images/icon_close_x.svg'
import messages_header from '../../../../assets/images/messages_header.png'
import icon_routes from '../../../../assets/images/icon_routes.svg'
import icon_others from '../../../../assets/images/icon_others.svg'
import icon_star_grey from '../../../../assets/images/icon_star_grey.svg'
import icon_trash from '../../../../assets/images/icon_trash.svg'
import icon_msg_grey from '../../../../assets/images/icon_msg_grey.svg'
import message from '../../../../assets/images/message.svg'
import info_icon_white from '../../../../assets/images/info_icon_white.svg'

//Elements
import { 
    ImgInfo,
    ContainerOptions,
    OptionShowAll,
    TextOptionMessages,
    OptionsCenter,
    OptionDeleteAll,
    ImgIconCloseMsg,
    ContainerPopUpDelMsg,
    BtnClosePopUpDelMsg,
    ColInfoPopUp,
    TextInfoPopUp,
    BtnDialogNo,
    TextDialogNo,
    BtnDialogYes,
    TextDialogYes,
    OptionsCenterResp,
    ContainerOptionsResp,
    OptionDeleteAllResp,
    ContainerOptionsDesktop,
    ContainerOptionsCellphone,
} from "./MessagesElement"
import { RowSpaceBetween, ContainerMain, TextPoppins } from '../../../../containers/CoreElement';
import { Loader } from '../../../../hooks/Loader';
import { changeStyle, updateNavs } from '../../../utils/utils';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AllMsg from './Pages/ShowAll/AllMsg';
import RoutesMsg from './Pages/Routes/RoutesMsg';
import SystemMsg from './Pages/System/SystemMsg';
import FavoritesMsg from './Pages/Favorites/FavoritesMsg';
import OthersMsg from './Pages/Others/OthersMsg';
import TrashMsg from './Pages/Trash/TrashMsg';
import PopUp from '../../../../hooks/PopUp';


export function Messages(){

    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = (path) => {
        navigate(path);
    };



    const [showPopUpDelMsg, setShowPopUpDelMsg] = useState(false);

    const [loader, setLoader] = useState(false);
    const [extended, setExtended] = useState(true);
    const [showSections, setShowSections] = useState(true);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');
 
    const clickBtnClose = () => {

    };

    const clickBtnCloseDelMsg = () => {
        setShowPopUpDelMsg(false);
    };

      const trashAllMessages = () => {
        setLoader(true);
        if (sessionStorage.getItem('token')){
            const config = {
                headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
                method: "POST"
            };
            HTTP.request('message/all/trash/', config)
            .then(() => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                setPopupServerError(true);
                setTextServerError(err.response.statusText);
            })
            .finally(() => {
              setLoader(false);
            })
        }
      };


      useEffect(() => {
        updateNavs();
    }, [])

    const msgBack = () => {
        setExtended(true);
        setShowSections(true);
    }

    return (
        <>  
        <Loader loading={loader}/>
        <ContainerMain>

            <HeaderScreen extended={extended} onClickBack={() => msgBack()} imgHeader={messages_header} icon={message} title={'MESSAGES'}>
            Find your messages here, all your route results, events and more
            </HeaderScreen>
            { showSections ?
            <>
            <ContainerOptionsDesktop>
                <ContainerOptions>
                    <OptionShowAll onClick={()=>handleClick('/messages/all')} style={{backgroundColor: changeStyle('/messages/all', location.pathname)}}>
                        <ImgInfo src={info_icon_white}/>
                        <TextOptionMessages>Show All</TextOptionMessages>
                    </OptionShowAll>
                    <OptionsCenter onClick={()=>handleClick('/messages/routes')} style={{backgroundColor: changeStyle('/messages/routes', location.pathname)}}>
                        <ImgInfo src={icon_routes}/>
                        <TextOptionMessages>Routes</TextOptionMessages>
                    </OptionsCenter>
                    <OptionsCenter onClick={()=>handleClick('/messages/system')} style={{backgroundColor: changeStyle('/messages/system', location.pathname)}}>
                        <ImgInfo src={icon_msg_grey}/>
                        <TextOptionMessages>System</TextOptionMessages>
                    </OptionsCenter>
                    <OptionsCenter onClick={()=>handleClick('/messages/favorites')} style={{backgroundColor: changeStyle('/messages/favorites', location.pathname)}}>
                        <ImgInfo src={icon_star_grey}/>
                        <TextOptionMessages>Favorites</TextOptionMessages>
                    </OptionsCenter>
                    <OptionsCenterResp onClick={()=>handleClick('/messages/others')} style={{backgroundColor: changeStyle('/messages/others', location.pathname)}}>
                        <ImgInfo src={icon_others}/>
                        <TextOptionMessages>Others</TextOptionMessages>
                    </OptionsCenterResp>
                    <OptionsCenterResp onClick={()=>handleClick('/messages/trash')} style={{backgroundColor: changeStyle('/messages/trash', location.pathname)}}>
                        <ImgInfo src={icon_trash}/>
                        <TextOptionMessages>Trash</TextOptionMessages>
                    </OptionsCenterResp>
                    <OptionDeleteAllResp onClick={()=>trashAllMessages()}>
                        <ImgInfo src={info_icon_white}/>
                        <TextOptionMessages>Delete All</TextOptionMessages>
                    </OptionDeleteAllResp>
                </ContainerOptions>
                <ContainerOptionsResp>
                    <OptionsCenter onClick={()=>handleClick('/messages/others')} style={{backgroundColor: changeStyle('/messages/others', location.pathname)}}>
                        <ImgInfo src={icon_others}/>
                        <TextOptionMessages>Others</TextOptionMessages>
                    </OptionsCenter>
                    <OptionsCenter onClick={()=>handleClick('/messages/trash')} style={{backgroundColor: changeStyle('/messages/trash', location.pathname)}}>
                        <ImgInfo src={icon_trash}/>
                        <TextOptionMessages>Trash</TextOptionMessages>
                    </OptionsCenter>
                    <OptionDeleteAll onClick={()=>trashAllMessages()}>
                        <ImgInfo src={info_icon_white}/>
                        <TextOptionMessages>Delete All</TextOptionMessages>
                    </OptionDeleteAll>
                </ContainerOptionsResp>
            </ContainerOptionsDesktop>
            <ContainerOptionsCellphone>
                <ContainerOptions>
                    <OptionShowAll onClick={()=>handleClick('/messages/all')} style={{backgroundColor: changeStyle('/messages/all', location.pathname)}}>
                        <ImgInfo src={info_icon_white}/>
                        <TextOptionMessages>Show All</TextOptionMessages>
                    </OptionShowAll>
                    <OptionsCenter onClick={()=>handleClick('/messages/routes')} style={{backgroundColor: changeStyle('/messages/routes', location.pathname)}}>
                        <ImgInfo src={icon_routes}/>
                        <TextOptionMessages>Routes</TextOptionMessages>
                    </OptionsCenter>

                </ContainerOptions>
                <ContainerOptions>
                    <OptionsCenter onClick={()=>handleClick('/messages/system')} style={{backgroundColor: changeStyle('/messages/system', location.pathname)}}>
                        <ImgInfo src={icon_msg_grey}/>
                        <TextOptionMessages>System</TextOptionMessages>
                    </OptionsCenter>
                    <OptionsCenter onClick={()=>handleClick('/messages/favorites')} style={{backgroundColor: changeStyle('/messages/favorites', location.pathname)}}>
                        <ImgInfo src={icon_star_grey}/>
                        <TextOptionMessages>Favorites</TextOptionMessages>
                    </OptionsCenter>
    
                </ContainerOptions>
                <ContainerOptions>
                    <OptionsCenter onClick={()=>handleClick('/messages/others')} style={{backgroundColor: changeStyle('/messages/others', location.pathname)}}>
                        <ImgInfo src={icon_others}/>
                        <TextOptionMessages>Others</TextOptionMessages>
                    </OptionsCenter>
                    <OptionsCenter onClick={()=>handleClick('/messages/trash')} style={{backgroundColor: changeStyle('/messages/trash', location.pathname)}}>
                        <ImgInfo src={icon_trash}/>
                        <TextOptionMessages>Trash</TextOptionMessages>
                    </OptionsCenter>
                </ContainerOptions>
                <ContainerOptions>
                    <OptionDeleteAll onClick={()=>trashAllMessages()} style={{width: '100%'}}>
                        <ImgInfo src={info_icon_white}/>
                        <TextOptionMessages>Delete All</TextOptionMessages>
                    </OptionDeleteAll>
                </ContainerOptions>
            </ContainerOptionsCellphone>
            </>
            :
            null
            }
            <Routes>
                <Route path="all" element={<AllMsg setShowSections={setShowSections} extendedHeader={setExtended} extended={!extended}/>}/>
                <Route path="routes" element={<RoutesMsg setShowSections={setShowSections} extendedHeader={setExtended} extended={!extended}/>}/>
                <Route path="system" element={<SystemMsg setShowSections={setShowSections} extendedHeader={setExtended} extended={!extended}/>}/>
                <Route path="favorites" element={<FavoritesMsg setShowSections={setShowSections} extendedHeader={setExtended} extended={!extended}/>}/>
                <Route path="others" element={<OthersMsg setShowSections={setShowSections} extendedHeader={setExtended} extended={!extended}/>}/>
                <Route path="trash" element={<TrashMsg setShowSections={setShowSections} extendedHeader={setExtended} extended={!extended}/>}/>
            </Routes>
       
        { showPopUpDelMsg ?
            <ContainerPopUpDelMsg>
                <BtnClosePopUpDelMsg onClick={clickBtnCloseDelMsg}>
                    <ImgIconCloseMsg src={icon_close_x}/>
                </BtnClosePopUpDelMsg>
                <ColInfoPopUp>
                    <TextInfoPopUp>
                        Are you sure that you want to delete this message?<br/>
                        You can recover it in the Trash before 15 days.
                    </TextInfoPopUp>
                </ColInfoPopUp>
                <RowSpaceBetween>
                    <BtnDialogNo onClick={clickBtnClose}>
                        <TextDialogNo>
                            No, take me back
                        </TextDialogNo>
                    </BtnDialogNo>
                    <BtnDialogYes onClick={clickBtnClose}>
                        <TextDialogYes>
                            Yes
                        </TextDialogYes>
                    </BtnDialogYes>
                </RowSpaceBetween>
            </ContainerPopUpDelMsg>
     
            : null
        }


        {/* <PopUp show={showPopUp} onClose={() => setShowPopUp(false)} onConfirm={() => alert('Click Btn OK')}>
            <TextPoppins style={{letterSpacing: '0'}}>
                Scan the QR code with your Enjin Wallet or enter the code manually.<br/>
                (Remember to have added JumpNet within your available blockchains)
            </TextPoppins>
            <Img src='https://borealtech.com/wp-content/uploads/2018/10/codigo-qr-1024x1024-1.jpg' style={{width: '100px'}}/>
        </PopUp> */}

        </ContainerMain>

        <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
            <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
            {textServerError}
            </TextPoppins>
        </PopUp>
        </>
    )
}