import React, { useState, useEffect } from "react";

import { useLocation, useNavigate, Route, Routes } from "react-router-dom";

import icon_pick_up from '../../../../../../assets/images/icon_pick_up.svg'
import icon_clone from '../../../../../../assets/images/icon_clone.svg'

import { RowSpaceBetween } from "../../../../../../containers/CoreElement";
import { BtnOptions1, ContainerOptions2, TextTitiliumWebOptions1 } from "../../CommanderElement";
import { Clone } from "./Clone/Clone";
import { PickUp } from "./PickUp/PickUp";
import IconSvg from "../../../../../../hooks/IconSvg";

export function CloneLab(props){

    const location = useLocation();
    const navigate = useNavigate();
  
    const [activeRoute, setActiveRoute] = useState(location.pathname);
  
    const handleClick = (path) => {
        setActiveRoute(path);
        navigate(path);
    };

    useEffect(() => {
        props.setActiveRoute(props.activeRoute);
    }, [props])
  
    
    return(
        <>
        <ContainerOptions2>
            <RowSpaceBetween>
                <BtnOptions1 onClick={() => handleClick('/commanders/clone-lab/clone')} style={{backgroundColor: activeRoute === '/commanders/clone-lab/clone' ? '#A40A5B' : 'transparent'}}>
                    <IconSvg icon={icon_clone} width={'18px'} color={activeRoute === '/commanders/clone-lab/clone' ? '#FFFFFF' : '#d2b8cd'}/>
                    <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/clone-lab/clone' ? '#FFFFFF' : '#d2b8cd'}}>CLONE</TextTitiliumWebOptions1>
                </BtnOptions1>
                <BtnOptions1 onClick={() => handleClick('/commanders/clone-lab/pick-up')} style={{backgroundColor: activeRoute === '/commanders/clone-lab/pick-up' ? '#A40A5B' : 'transparent'}}>
                    <IconSvg icon={icon_pick_up} width={'20px'} color={activeRoute === '/commanders/clone-lab/pick-up' ? '#FFFFFF' : '#d2b8cd'}/>
                    <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/clone-lab/pick-up' ? '#FFFFFF' : '#d2b8cd'}}>PICK UP</TextTitiliumWebOptions1>
                </BtnOptions1>
            </RowSpaceBetween>
        </ContainerOptions2>
        <Routes>
            <Route path="clone" element={<Clone commandersParam={props.commandersParam} extendedHeader={props.extendedHeader} selected={props.selected} setActiveRoute={setActiveRoute} activeRoute='/commanders/clone-lab/clone'/>}/>
            <Route path="pick-up" element={<PickUp commandersParam={props.commandersParam} extendedHeader={props.extendedHeader} selected={props.selected} setActiveRoute={setActiveRoute} activeRoute='/commanders/clone-lab/pick-up'/>}/>
        </Routes>

        </>
    );
}