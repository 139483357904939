export function ColsMyCommander() {
 const columns = [
    //  {
    //    Header: "Action",
    //    width: "5%",
    //    accessor: "action"
    //  },
     {
      Header: "Commander",
      width: "15%",
      accessor: "commander"
     },
     {
       Header: "Amount",
       width: "5%",
       accessor: "amount"
     },
     {
      Header: "Type",
      width: "15%",
      accessor: "type"
    },
    {
      Header: "FP",
      width: "5%",
      accessor: "fp"
    },
    {
      Header: "Cargo",
      width: "5%",
      accessor: "cargo"
    },
    {
      Header: "Shield",
      width: "5%",
      accessor: "Shield"
    },
    {
      Header: "Armor",
      width: "5%",
      accessor: "armor"
    },
    {
      Header: "Damage",
      width: "5%",
      accessor: "damage"
    }
  ];

 return columns;
}
