//Librerias INIT
import React, { useState } from 'react';
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom';

//CSS
import '../../../../components/Home/Pages/Commander/hooks/styles.css'

//Components
import HeaderScreen from '../../../../hooks/HeaderScreen';

import icon_med_bay from '../../../../assets/images/icon_med_bay.svg'
import icon_crew from '../../../../assets/images/icon_crew.svg'
import icon_commnaders from '../../../../assets/images/icon_commanders.svg'
import commanders_header from '../../../../assets/images/commanders_header.png'


//Elements
import { 
  TextTitiliumWebOptions1,
  BtnOptions1,
  ContainerOptions1 } from './CommanderElement';

import { RowSpaceBetween, ContainerMain, ChildrenHeaderDesktop, ChildrenHeaderCellphone, ContainerIconSvg } from '../../../../containers/CoreElement';
import { Crew } from './Pages/Crew/Crew';
import { CloneLab } from './Pages/CloneLab/CloneLab';
import { MedBay } from './Pages/MedBay/MedBay';
import IconSvg from '../../../../hooks/IconSvg';



export function Commander() {

  const location = useLocation();
  const navigate = useNavigate();

  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const handleClick = (path) => {
      setActiveRoute(path);
      navigate(path);
      setExtended(true);
  };


  const [extended, setExtended] = useState(true);




  const btnClickBack = () => {
    setExtended(true);
  }

  return (
    <> 
    <ContainerMain>
      <HeaderScreen extended={extended} onClickBack={btnClickBack} title={'COMMANDERS'} icon={icon_commnaders} imgHeader={commanders_header}>
        <ChildrenHeaderDesktop>
          Select your Commanders, revive them, upgrade them. check their level etc... <br/>
          You can sell Cloned commanders (purchased with CROWN First) and NFT will allow you to save on fees and instant cloning.
        </ChildrenHeaderDesktop>
        <ChildrenHeaderCellphone>
          Select your Commanders, revive them, upgrade them. check their level etc...
        </ChildrenHeaderCellphone>
      </HeaderScreen>
      <ContainerOptions1>
        <RowSpaceBetween>
          <BtnOptions1 style={{backgroundColor: activeRoute === '/commanders/crew' ? '#FF0087' : 'transparent'}} onClick={() => handleClick('/commanders/crew')}>
            <ContainerIconSvg>
              <IconSvg icon={icon_crew} width={'20px'} color={activeRoute === '/commanders/crew' ? '#FFFFFF' : '#d2b8cd'}/>
            </ContainerIconSvg>
            <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/crew' ? '#FFFFFF' : '#d2b8cd'}}>CREW</TextTitiliumWebOptions1>
          </BtnOptions1>
          <BtnOptions1 style={{backgroundColor: activeRoute === '/commanders/clone-lab/clone' || activeRoute === '/commanders/clone-lab/pick-up' ? '#FF0087' : 'transparent'}} onClick={() => handleClick('/commanders/clone-lab/clone')}>
            <ContainerIconSvg>
              <IconSvg icon={'/icons/icon_clone_lab.svg'} width={'20px'} color={activeRoute === '/commanders/clone-lab/clone' || activeRoute === '/commanders/clone-lab/pick-up' ? '#FFFFFF' : '#d2b8cd'}/>
            </ContainerIconSvg>
            <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/clone-lab/clone' || activeRoute === '/commanders/clone-lab/pick-up' ? '#FFFFFF' : '#d2b8cd'}}>CLONE LAB</TextTitiliumWebOptions1>
          </BtnOptions1>
          <BtnOptions1 style={{backgroundColor: activeRoute === '/commanders/med-bay/injured' || activeRoute === '/commanders/med-bay/healing' || activeRoute === '/commanders/med-bay/pick-up' ? '#FF0087' : 'transparent'}} onClick={() => handleClick('/commanders/med-bay/injured')}>
            <ContainerIconSvg>
              <IconSvg icon={icon_med_bay} width={'20px'} color={activeRoute === '/commanders/med-bay/injured' || activeRoute === '/commanders/med-bay/healing' || activeRoute === '/commanders/med-bay/pick-up' ? '#FFFFFF' : '#d2b8cd'}/>
            </ContainerIconSvg>
            <TextTitiliumWebOptions1 style={{color: activeRoute === '/commanders/med-bay/injured' || activeRoute === '/commanders/med-bay/healing' || activeRoute === '/commanders/med-bay/pick-up' ? '#FFFFFF' : '#d2b8cd'}}>MED BAY</TextTitiliumWebOptions1>
          </BtnOptions1>
        </RowSpaceBetween>
      </ContainerOptions1>
      <Routes>
        <Route path="crew" element={<Crew setActiveRoute={setActiveRoute} activeRoute={'/commanders/crew'} extendedHeader={setExtended} selected={!extended}/>}/>
        <Route path="clone-lab" element={<CloneLab setActiveRoute={setActiveRoute} activeRoute={'/commanders/clone-lab/clone'} extendedHeader={setExtended} selected={!extended}/>}>
          <Route path="clone"/>
          <Route path="pick-up"/>
        </Route>
        <Route path="med-bay" element={<MedBay setActiveRoute={setActiveRoute} activeRoute={'/commanders/med-bay/injured'} extendedHeader={setExtended} selected={!extended}/>}>
          <Route path="injured"/>
          <Route path="healing"/>
          <Route path="pick-up"/>
        </Route>
      </Routes>
    </ContainerMain>
    </>
  )
}