//Libraries INIT
import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../../../http/http-common';
import { useNavigate } from 'react-router-dom'



import { 
    ContainerElements, ContainerRepairBay, Text
} from './RepairBayElement'


import ReusableCardRepairBay from "../../../../../../hooks/ReusableCardRepairBay";

import { convertProgressToPercentege } from '../../../../../utils/utils';
import PopUp from '../../../../../../hooks/PopUp';
import Pagination from '../../../../../../hooks/Pagination';
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids';
import { Loader } from '../../../../../../hooks/Loader';
import { FilterBar } from '../../../../../../hooks/FilterBar';
import { TextPoppins } from '../../../../../../containers/CoreElement';





export function RepairBay() {
    const [count, setCount] = useState(0);
    const [cardsRepairBay, setCardsRepairBay] = useState([]);
    const [popUpCancel, setPopUpCancel] = useState(false)
    const [popUpInstant, setPopUpInstant] = useState(false);
    const [cardSelected, setCardSelected] = useState({});
    const [loader, setLoader] = useState(false);
    const [elementsRepairInProcess, setElementsRepairInProcess] = useState([]);
    const [popUpInstantRepairAll, setPopUpInstantRepairAll] = useState(false);

    const [popupServerError, setPopupServerError] = useState(false);
    const [textServerError, setTextServerError] = useState('Server Error');

    let navigate = useNavigate();

    const fetchShipsRepairAllValuesProcess= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/repair/all/values/process/', config)
          .then((res) => {
            if (res.data.result){
              var elements_repair_in_process = [
                { 'iconValue' : '/icons/icon_type_crown_coin_grey.png','icon' : '/icons/icon_type_repair_all.svg', 'text' :'INSTANT REPAIR ALL', 'value' : res.data.values["cost_repair_crowns"], 'action' : () => setPopUpInstantRepairAll(true), 'backgroundItem' : "#AA68D8"},
              ]
              setElementsRepairInProcess(elements_repair_in_process);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      };

    const fetchShipsRepairAllProcess = () => {
    setLoader(true);
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
      method: "POST"
    };
    HTTP.request('ships/intant/all/repair/process/', config)
      .then((res) => {
        if (res.data.result){
          console.log("All repaired Ships successfullly!");
          navigate('/shipyard/repair-bay/pick-up')
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      })
  };
    
    useEffect(() => {
        fetchShipsRepairAllValuesProcess();
        fetchShipsRepairBay();
        setInterval(() => {
            setCount(prevCount => prevCount + 0.5);
        }, 1000);
    }, []);

    const fetchShipsRepairBay = () => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/repair/process/', config)
        .then((res) => {
            if (res.data.result){
                setCardsRepairBay(res.data.repairs)
            }
        })
        .catch((err) => {
        console.log(err);
        setPopupServerError(true);
        setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    };
    
    const instantRepairShip = (repairCode) => {
        setLoader(true);
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"repair_code": repairCode},
            method: "POST"
        };
        HTTP.request('ships/intant/repair/process/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/shipyard/repair-bay/pick-up')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    }


    const cancelRepair = (repairCode) => {
        setLoader(true);
        console.log(repairCode)
        const config = {
            headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
            data: {"repair_code": repairCode},
            method: "POST"
        };
        HTTP.request('ships/repair/cancel/', config)
        .then((res) => {
            if (res.data.result){
                navigate('/shipyard/repair-bay/damaged')
            }
        })
        .catch((err) => {
            console.log(err);
            setPopupServerError(true);
            setTextServerError(err.response.statusText);
        })
        .finally(() => {
            setLoader(false);
          })
    }

    const selectPopUpInstant = (card) => {
        setCardSelected(card);
        setPopUpInstant(true);
    }

    const selectCancelRepair = (card) => {
        setCardSelected(card);
        setPopUpCancel(true);
    }

  return (

        <>
            <Loader loading={loader}/>
            {elementsRepairInProcess.length > 0 ?
              <FilterBar                                
                elements = {elementsRepairInProcess}
                columns = "repeat(1,1fr)"                 
              ></FilterBar>
              :
                null
            }
            <ContainerRepairBay style={{height: cardsRepairBay.length !== 0 ? '650px' : '170px'}}>
                <FilterForGrids allElements={cardsRepairBay} sortByClass>
                    { elementsToFilter => (
                        <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData='There are not ships on repairing'>
                            { elementsToRender => (
                                <ContainerElements>
                                    {elementsToRender.map((card, index) => (
                                    <ReusableCardRepairBay 
                                        item={card.shipxuser}
                                        percentage={convertProgressToPercentege(card, count)}
                                        actionInstantRepair={() => selectPopUpInstant(card)}
                                        actionCancel={() => selectCancelRepair(card)}
                                    ></ReusableCardRepairBay>
                                    ))}
                                </ContainerElements>
                            )
                            }
                        </Pagination>
                    )
                    }
                </FilterForGrids>
            </ContainerRepairBay>

            <PopUp show={popUpInstant} onClose={() => setPopUpInstant(false)} onConfirm={() => instantRepairShip(cardSelected.repair_code)}>
                <Text>Are you sure you want to perform an instant repair?</Text>
            </PopUp>

            <PopUp show={popUpCancel} onClose={() => setPopUpCancel(false)} onConfirm={() => cancelRepair(cardSelected.repair_code)}>
                <Text>Are you sure you want to cancel the repair?</Text>
            </PopUp>

            <PopUp show={popupServerError} onClose={() => setPopupServerError(false)} onlyConfirm>
                <TextPoppins style={{fontWeight: '400', fontSize: '15px', lineHeight: '27px'}}>
                {textServerError}
                </TextPoppins>
            </PopUp>

            <PopUp show={popUpInstantRepairAll} onClose={() => setPopUpInstantRepairAll(false)} onConfirm={() => fetchShipsRepairAllProcess(true)}>
                <Text>Are you sure you want to repair all instant?</Text>
            </PopUp>
        </>

 


  )

}