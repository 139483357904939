import React from 'react'
import {ChildrenHeaderCellphone, ChildrenHeaderDesktop, ContainerIconSvg, RowSpaceBetween } from '../../../../containers/CoreElement'
import HeaderScreen from '../../../../hooks/HeaderScreen'
import { BtnOptions1, ContainerOptions1, TextTitiliumWebOptions1 } from './ExportImportElement';
import IconSvg from '../../../../hooks/IconSvg';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Export from './Pages/Export/Export';
import Import from './Pages/Import/Import';
import nft_gateway_header from '../../../../assets/images/nft_gateway_header.png'
import nft_gateway_icon from '../../../../assets/images/icon_nft_gateway.svg'
// import { HTTP } from '../../../http/http-common';

export default function ExportImport() {

    const location = useLocation();
    const navigate = useNavigate();
    const [activeRoute, setActiveRoute] = useState(location.pathname);

    // const [codeProcessDone, setCodeProcessDone] = useState(false);

    const handleClick = (path) => {
        setActiveRoute(path);
        navigate(path);
    };

    // const checkCodeProcess = async () => {
    //     var config = {
    //         headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
    //         method: "POST"
    //     };

    //     await HTTP.request('altura/status/wallet/', config)
    //         .then((res) => {
    //         if (res.data.result){
    //             setCodeProcessDone(true)
    //         }else{
    //             setCodeProcessDone(false)
    //         }
    //         })
    //         .catch((err) => {
    //         console.log(err);
    //         });
    // }
      

  return (
    <>
        <HeaderScreen extended={true} imgHeader={nft_gateway_header} icon={nft_gateway_icon} title={'NFT GATEWAY'} widthIcon="30px">
            <ChildrenHeaderDesktop>
                Export (mint) your goods to trade them in the blockchain marketplace or import them (unmint) them to use them in Fleet!
                <br/>
                You can freely mint your resources and those items which you have the required blueprint NFT
            </ChildrenHeaderDesktop>
            <ChildrenHeaderCellphone>
                Export (mint) your goods to trade them in the blockchain marketplace or import them (unmint) them to use them in Fleet!
            </ChildrenHeaderCellphone>
        </HeaderScreen>
        <ContainerOptions1>
            <RowSpaceBetween>
                <BtnOptions1 onClick={() => handleClick('/altura/export')} style={{backgroundColor: activeRoute === '/altura/export' ? '#FF0087' : 'transparent'}}>
                    <ContainerIconSvg>
                        <IconSvg icon={'/icons/icon_type_export-import.svg'} width={'20px'} color={activeRoute === '/altura/export' ? '#FFFFFF' : '#d2b8cd'}/>
                    </ContainerIconSvg>
                    <TextTitiliumWebOptions1 style={{color: activeRoute === '/altura/export' ? '#FFFFFF' : '#d2b8cd'}}>
                        EXPORT BAY
                    </TextTitiliumWebOptions1>
                </BtnOptions1>
                <BtnOptions1 onClick={() => handleClick('/altura/import')} style={{backgroundColor: activeRoute === '/altura/import' ? '#FF0087' : 'transparent'}}>
                    <ContainerIconSvg>
                        <IconSvg icon={'/icons/icon_type_export-import.svg'} width={'20px'} color={activeRoute === '/altura/export' ? '#FFFFFF' : '#d2b8cd'}/>
                    </ContainerIconSvg>
                    <TextTitiliumWebOptions1 style={{color: activeRoute === '/altura/import' ? '#FFFFFF' : '#d2b8cd'}}>
                        IMPORT BAY
                    </TextTitiliumWebOptions1>
                </BtnOptions1>
            </RowSpaceBetween>
        </ContainerOptions1>
        <Routes>
            <Route path="export" element={<Export/>}/>
            <Route path="import" element={<Import/>}/>
        </Routes>

    </>
  )
}
