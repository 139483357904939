//Libraries INIT
import React, { useState, useEffect } from 'react';
import { HTTP } from '../../../../../http/http-common';

import { 
    ContainerElements, ContainerRepairBay
} from './RepairBayElement'


import ReusableCardRegular from "../../../../../../hooks/ReusableCardRegular";

import CardHangarSelected from '../../../../../../hooks/CardHangarSelected';
import Pagination from '../../../../../../hooks/Pagination';
import { FilterForGrids } from '../../../../../../hooks/FilterForGrids';
import { Loader } from '../../../../../../hooks/Loader';
import { FilterBar } from '../../../../../../hooks/FilterBar';
import { updateNavs } from '../../../../../utils/utils';
import PopUp from '../../../../../../hooks/PopUp';
import { Text } from '../../ShipyardElement';
import { useNavigate } from 'react-router-dom';

export function RepairBayDamaged(props) {

    const [selectedInfo, setSelectedInfo] = useState({});
    const [cardsRepairBayDamaged, setCardsRepairBayDamaged] = useState([])
    const [loader, setLoader] = useState(false);
    const [elementsRepairBayDamaged, setElementsRepairBayDamaged] = useState([]);
    const [popUpInstantRepairAllHangar, setPopUpInstantRepairAllHangar] = useState(false);
    const [popUpRepairAllHangar, setPopUpRepairAllHangar] = useState(false);

    const navigate = useNavigate();

    const handleExtended = (card, index) => {
      console.log(card);
      console.log(index);
      props.closeHeader(true);
      props.closeCardSelect(true);
      setSelectedInfo(card);      
    };

    const fetchShipsDamaged= () => {
      setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/damaged/', config)
          .then((res) => {
            if (res.data.result){
                setCardsRepairBayDamaged(res.data.ships)
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
    
      };

      const fetchShipsRepairAll = (instantRepair) => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` },
          data: {"instant_repair": instantRepair},
          method: "POST"
        };
        HTTP.request('ships/repair/all/', config)
          .then((res) => {
            if (res.data.result){
              if (instantRepair){
                console.log("All Ships repaired successfullly!");
                navigate('/shipyard/repair-bay/pick-up')
                setPopUpInstantRepairAllHangar(false)
              }else{
                console.log("Start process repair all Ships successfully!");
                navigate('/shipyard/repair-bay/repairing')
                setPopUpInstantRepairAllHangar(false)
              }
              setPopUpRepairAllHangar(false);
              setPopUpInstantRepairAllHangar(false);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      };

      const fetchShipsRepairAllValues= () => {
        setLoader(true);
        const config = {
          headers: { Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token')).token}` }
        };
        HTTP.request('ships/repair/all/values/', config)
          .then((res) => {
            if (res.data.result){
              var elements_hangar = [
                {'iconValue' : '/icons/icon_type_blue_coin_inventory.svg','icon' : '/icons/icon_type_repair_all.svg', 'text' :'REPAIR ALL', 'value' : res.data.values["cost_repair_bits"], 'action' : () => setPopUpRepairAllHangar(true), 'backgroundItem' : "rgba(255, 0, 136, 0.0)", 'boxShadow':'0px 4px 4px 0px rgba(0, 0, 0, 0.336) inset'},
                { 'iconValue' : '/icons/icon_type_crown_coin_grey.png','icon' : '/icons/icon_type_repair_all.svg', 'text' :'INSTANT REPAIR ALL', 'value' : res.data.values["cost_repair_crowns"], 'action' : () => setPopUpInstantRepairAllHangar(true), 'backgroundItem' : "#AA68D8", 'boxShadow':'0px 4px 4px 0px rgba(0, 0, 0, 0.336) inset'},
              ]
              console.log(elements_hangar);
              setElementsRepairBayDamaged(elements_hangar);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoader(false);
          })
      };
    
    
      useEffect(() => {
        updateNavs();
        fetchShipsDamaged();
        fetchShipsRepairAllValues();
      }, []);

    return (

      <>
       <Loader loading={loader}/>
       {elementsRepairBayDamaged.length > 0 ?
        <FilterBar                             
          elements = {elementsRepairBayDamaged}
          columns = "repeat(2,1fr)"
        ></FilterBar>
        :
        null
      }
      { props.isOpen ? 
        <CardHangarSelected item={selectedInfo}/>
        :
        null
      }
      <ContainerRepairBay style={{height: cardsRepairBayDamaged.length !== 0 ? '650px' : '170px'}}>
        <FilterForGrids allElements={cardsRepairBayDamaged} sortByClass>
          { elementsToFilter => (
            <Pagination elements={elementsToFilter} elementsPerPage={6} textNoData='There are not damaged ships'>
              { elementsToRender => (
                <ContainerElements>
                  {elementsToRender.map((card, index) => (
                    <>
                    <ReusableCardRegular 
                      item={card}
                      action={() => handleExtended(card, index)}
                      buttonText={"Select"}
                    ></ReusableCardRegular>
                    </>
                  ))}
                </ContainerElements>
              )
              }
            </Pagination>
          )
          }
        </FilterForGrids>

      </ContainerRepairBay>

      <PopUp show={popUpRepairAllHangar} onClose={() => setPopUpRepairAllHangar(false)} onConfirm={() => fetchShipsRepairAll(false)}>
          <Text>Are you sure you want to repair all?</Text>
      </PopUp>

      <PopUp show={popUpInstantRepairAllHangar} onClose={() => setPopUpInstantRepairAllHangar(false)} onConfirm={() => fetchShipsRepairAll(true)}>
        <Text>Are you sure you want to repair all instant?</Text>
      </PopUp>
      </>

 


  )

}