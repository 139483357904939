import styled from 'styled-components'
import {
    RowSpaceBetween,
    TextTitiliumWeb
    } from'../containers/CoreElement.jsx'

export const Container1 = styled.div `

    display: block;
    border: 0.5px solid #E71383;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    /* box-shadow: 0px 4px 4px 0px #000000 inset; */
    /* background: rgba(141, 12, 80, 0.7); */



`

export const Container1Padding = styled.div `
    width: 100%;
    height: 40px;
    border-right: 0px;
    display: grid;

    border-top: 0px;
    justify-content: space-between;

`

export const Container1Bar = styled.div `
  
    height: 40px;

    background-color: rgba(141, 12, 80, 0.7);
    width: 100%;
    display: table;

    border-top: 0px;
    

    
`
export const TextCoins = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

export const NumberCoins = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-left: 5%;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;
    color: #FFFFFF;
`


export const Container1BarContainerElement = styled(RowSpaceBetween) `
    height: 38px;
    display: contents;
    width:100%;
    justify-content: space-between;
`
///
export const Container1BarContainerIconAndText = styled.div `

    height: 40px;
    
    display: flex;
    justify-content: center;
`
export const Container1BarIcon = styled.img `
    height: 18px;
    margin-right: 9px;
`

export const Container1BarContainerText = styled.div `
    height: 40px;
    justify-content: space-between;
    align-items: center;
    display:flex;
    /* border: 2px solid transparent; */

    @media (max-width: 600px) {
        justify-content: center;
    }

    
`
///
export const Container1BarText = styled.h1`
    width: calc(100%/3*1);
    font-size: 14px;
    color: white;
    font-weight: 500;
    /* h1 margin correction */
    margin-block-start: 0.65em;
    margin-block-end: 0.72em;
    display: flex;
    
`

export const Text1 = styled(TextTitiliumWeb)`
    margin-left: 8px;
    font-weight: 400;
    line-height: 35px;

    @media (max-width: 600px) {
        font-size: 12px;
        text-align: center;
    }
    
`

export const Text2 = styled.div`
    margin-left: 9px;
    font-size: 14px;
    color: white;
    font-weight: 500;
    /* h1 margin correction */
    margin-block-start: 0em;
    margin-block-end: 0em;
`
