import styled from "styled-components";
import { ContainerBasic, ContainerBasicBG, ContainerVoid, Img, ImgIcon, RowCore, RowSpaceBetween, TextTitiliumWeb } from "../../../../containers/CoreElement";

export const ContainerMain = styled.div`

`

export const ContainerOptions1 = styled(ContainerBasicBG)`
    width: 100%;
    background: rgba(141, 12, 80, 0.7);
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #E71383;
    height: 40px;
    margin-bottom: 2%;
    padding: 0;
`

export const BtnOptions1 = styled(ContainerVoid)`
    height: 100%;
    width: 100%;
    cursor: pointer;
`

export const ImgIconOptions = styled(ImgIcon)`
    width: 25px;
    height: auto;
    margin-right: 3%;
`
export const ImgIconOptionsCurrentWeek = styled(ImgIconOptions)`
    width: 20px;
`
export const TextTitiliumWebOptions1 = styled(TextTitiliumWeb)`
    font-weight: 400;
    line-height: 35px;
    margin-left: 3%;
`

export const TextTitiliumWebNormal = styled(TextTitiliumWeb)`
    font-weight: 200;
    line-height: 0px;
    letter-spacing: 0px;
`

export const ContainerRow = styled(ContainerBasicBG)`
    width: 100%;
    clip-path: polygon(0 13px, 9px 0, calc(100% - 9px) 0, 100% 13px, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 10px, 10px 0, calc(100% - 10px) 0, 100% 10px, 100% 100%, 0 100%);
    color: white;
    padding: 0;
    background: linear-gradient(0deg, rgba(231, 19, 131, 0.05), rgba(231, 19, 131, 0.05)), rgba(0, 0, 0, 0.1);
    border-width: 0.5px 0px;
    border-style: solid;
    border-color: #8D0C50;
    border-radius: 2px;
    justify-content: flex-start;
    margin-bottom: 1%;
`
export const RowSpaceBetweenRewards = styled(RowSpaceBetween)`
    margin-inline: 1%;
    width: 100%;
    justify-content: space-between;

    @media(max-width: 600px) {
        flex-direction: column;
    }
`
export const TextCoins = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const ImgCoin = styled(ImgIcon)`
    width: 20px;
    height: 20px;
    margin-left: 1%;
    margin-right: 1%;
`

export const NumberCoins = styled(TextTitiliumWeb)`
    font-weight: 400;
    letter-spacing: 0;
`
export const RowCoreCenter = styled(RowCore)`
    align-items: center;
    
    @media(max-width: 600px) {
        justify-content: center;
    }
`

export const RowCoreEnd = styled(RowCoreCenter)`
    justify-content: flex-end;

    @media(max-width: 600px) {
        justify-content: center;
    }
`
export const ContainerLeaderboard = styled(ContainerBasic)`
    display: block;

    @media(max-width: 960px) {
        margin-bottom: 10%;
    }

    @media(max-width: 600px) {
        margin-bottom: 20%;
    }
`
export const TextTitiliumWebHeaders = styled(TextTitiliumWeb)`
    line-height: 40px;
    font-weight: 400;
`
export const TextTitiliumWebPosition = styled(TextTitiliumWebHeaders)`
    margin-left: 3%;
`
export const RowCore50 = styled(RowCore)`
    width: 50%;
`
export const RowCoreUsername = styled(RowCore50)`
    align-items: center;
`
export const RowCoreLeft = styled(RowCore50)`
    margin-left: 1%;
`
export const RowCoreRight = styled(RowCore50)`
    margin-right: 1%;
    justify-content: flex-end;
`
export const ImgProfile = styled(Img)`
    width: 25px;
    height: 25px;
    margin-right: 1%;
`